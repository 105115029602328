import { ref } from 'vue';

const usePerfil = () => {

    const fieldIdUnico = ref(localStorage.getItem('idUnico'));
    const fieldNombreCompleto = ref(localStorage.getItem('nombreCompleto'));
    const fieldNombreUsuario = ref(localStorage.getItem('nombreUsuario'));
    const fieldCorreo = ref(localStorage.getItem('correo'));
    const fieldPermiso = ref(localStorage.getItem('permiso'));

    return{
        fieldIdUnico,
        fieldNombreCompleto,
        fieldNombreUsuario,
        fieldCorreo,
        fieldPermiso
    };

};



export default usePerfil;