import axios from 'axios';

const urlAcceso = 'https://appapisam.samsuite.net/api/acceso/logeo';
const url = 'https://appapisam.samsuite.net/api/controlGeneral/controlGeneral';
const urlXmlFactura = 'https://appapisam.samsuite.net/api/controlGeneral/GenerarXML33';
const urlEnvioCorreoFlujos = 'https://appapisam.samsuite.net/api/controlGeneral/envioCorreoFlujos';
const urlFile = 'https://appapisam.samsuite.net/api/controlGeneral/'

// const urlAcceso = 'http://localhost:8095/api/acceso/logeo';
// const url = 'http://localhost:8095/api/controlGeneral/controlGeneral';
// const urlXmlFactura = 'http://localhost:8095/api/controlGeneral/GenerarXML33';
// const urlEnvioCorreoFlujos = 'http://localhost:8095/api/controlGeneral/envioCorreoFlujos';
// const urlFile = 'http://localhost:8095//api/controlGeneral/'

export const acceso = async(usuario, contrasena) => {

    try
    {
        const data = await axios.post(urlAcceso, 
            {
                numero : '11',
                proceso : '23',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'accesoClientesFlujo',
                    nombreUsuario: usuario,
                    clave: contrasena
                },
                mostrarMensaje: false
            }
        );

        return data;
    }
    catch(err)
    {
        return err;
    }

};

export const verificarIdUnico = async(idUnico) => {

    try
    {
        const data = await axios.post(url, 
            {
                numero : '11',
                proceso : '23',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'verificarIdUnicoClientesFlujo',
                    idUnico: idUnico
                },
                mostrarMensaje: false
            }
        );

        return data.data;
    }
    catch(err)
    {
        return err;
    }

};

export const operacion = async(parametros) => {
    try
    {
        const data = await axios.post(url, parametros);
        return data.data;
    }
    catch(err)
    {
        return err;
    }
};

export const operacionEnvioCorreo = async(parametros) => {
    try
    {
        const data = await axios.post(urlEnvioCorreoFlujos, parametros);
        return data.data;
    }
    catch(err)
    {
        return err;
    }
};

export const archivosApi = async(ruta, parametros, nombreArchivo) => {
    let urlApi = `${urlFile}${ruta}`;
    let json = {};

    json = JSON.parse(JSON.stringify(parametros));

    await axios({
        url: urlApi,
        method: 'POST',
        responseType: 'blob',
        data: json
    }).then((response) => {
        
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', nombreArchivo);
        document.body.appendChild(fileLink);

        fileLink.click();
    })
    .catch(function (error) {
    console.log(error);
    });

};

export const operacionXmlFactura = async(parametros) => {
    try
    {
        const data = await axios.post(urlXmlFactura, parametros);
        return data.data;
    }
    catch(err)
    {
        return err;
    }
};




