import { createRouter, createWebHashHistory } from "vue-router";

import permiso from './permiso.js';

import Principal from '../views/pages/principal/Principal.vue';
import GrupoEmpresarial from '../views/pages/grupoempresarial/GrupoEmpresarial.vue';
import Promotores from '../views/pages/promotores/Promotores.vue';
import Empleados from '../views/pages/empleados/Empleados.vue';
import Flujos from '../views/pages/flujos/Flujos.vue';
import Autorizaciones from '../views/pages/autorizaciones/Autorizaciones.vue';

import Acceso from '../views/pages/acceso/Acceso.vue';
import Perfil from '../views/pages/perfil/Perfil.vue';
import Actividad from '../views/pages/actividad/Actividad.vue';

import Correos from '../views/pages/correos/Correos.vue';

import SamSuiteNomina from '../views/pages/samsuite/SamSuiteNomina.vue';

import Cliente from '../views/pages/cliente/Cliente.vue';

import AccesoCliente from '../views/pages/accesoCliente/AccesoCliente.vue';

import NotFound from '../views/pages/notFound/NotFound.vue';

import NuevaSolicitud from '../views/pages/nuevasolicitud/NuevaSolicitud.vue';

const routes = [

  // {
  //   path: "/",
  //   name: "",
  //   redirect: ""
  // },
  {
    path: "/principal",
    name: "Principal",
    beforeEnter: permiso,
    component: Principal,
  },
  {
    path: "/grupoempresarial",
    name: "GrupoEmpresarial",
    beforeEnter: permiso,
    component: GrupoEmpresarial,
  },
  {
    path: "/nuevasolicitud",
    name: "NuevaSolicitud",
    beforeEnter: permiso,
    component: NuevaSolicitud,
  },
  {
    path: "/promotores",
    name: "Promotores",
    beforeEnter: permiso,
    component: Promotores,
  },
  {
    path: "/empleados",
    name: "Empleados",
    beforeEnter: permiso,
    component: Empleados,
  },
  {
    path: "/flujos",
    name: "Flujos",
    beforeEnter: permiso,
    component: Flujos,
  },
  {
    path: "/autorizaciones",
    name: "Autorizaciones",
    beforeEnter: permiso,
    component: Autorizaciones,
  },


  {
    path: "/",
    name: "Acceso",
    // beforeEnter: permiso,
    component: Acceso,
  },
  {
    path: "/perfil",
    name: "Perfil",
    beforeEnter: permiso,
    component: Perfil,
  },
  {
    path: "/correos",
    name: "Correos",
    beforeEnter: permiso,
    component: Correos,
  },
  {
    path: "/actividad",
    name: "Actividad",
    beforeEnter: permiso,
    component: Actividad,
  },

  {
    path: "/samsuitenomina",
    name: "SamSuiteNomina",
    beforeEnter: permiso,
    component: SamSuiteNomina,
  },

  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
  },



  {
    path: "/accesocliente/:idUnico",
    name: "accesoCliente",
    // beforeEnter: permiso,
    component: AccesoCliente,
  },

  {
    path: "/cliente",
    name: "Cliente",
    // beforeEnter: permiso,
    component: Cliente,
  }

  
];

const history = createWebHashHistory();

const router = createRouter({
  history,
  routes,
  // linkActiveClass: "active",
});

export default router;
