<template>
  
  <div class="contenedor1">

    <div class="contenido1">

      <div class="row">

        <div class="col" style="text-align: right;">
          <label class="labelClass">idUnico: </label>
        </div>

        <div class="col">
          <input type="text" class="form-control" disabled="true" v-model="fieldIdUnico" >
        </div>

      </div>
      <br>
      
      <div class="row">

        <div class="col" style="text-align: right;">
          <label class="labelClass">Nombre Completo: </label>
        </div>

        <div class="col">
          <input type="text" class="form-control" disabled="true" v-model="fieldNombreCompleto">
        </div>

      </div>
      <br>

      <div class="row">

        <div class="col" style="text-align: right;">
          <label class="labelClass">Nombre Usuario: </label>
        </div>

        <div class="col">
          <input type="text" class="form-control" disabled="true" v-model="fieldNombreUsuario" >
        </div>

      </div>
      <br>

      <div class="row">

        <div class="col" style="text-align: right;">
          <label class="labelClass">Correo: </label>
        </div>

        <div class="col">
          <input type="text" class="form-control" disabled="true" v-model="fieldCorreo" >
        </div>

      </div>
      <br>

      <div class="row">

        <div class="col" style="text-align: right;">
          <label class="labelClass">Perimiso: </label>
        </div>

        <div class="col">
          <input type="text" class="form-control" disabled="true" v-model="fieldPermiso" >
        </div>

      </div>


    </div>

  </div>


</template>

<script>
import proceso from './js/proceso';
export default {
  name: "Perfil",
  components: {},
  setup(){
    return{
      ...proceso()
    }
  }
}
</script>

<style>
  @import './css/estilos.css';
</style>