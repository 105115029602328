<template>

    <div class="card-body">
      <h5 class="card-title">Solicitudes</h5>

      <div class="row">

        <div class="col">

            <div class="form-group">
                <label>Grupo Empresarial:</label>
                <select v-model="fieldSelectGrupoEmpresarial" class="form-control" :disabled="botonConsultar.disableConstroles">
                    <option value="S/D">Selecciona una opcion.</option>
                    <option v-for=" ({idUnico, grupoEmpresarial}) in jsonGrupoEmpresarial" :key="idUnico" :value="idUnico">{{grupoEmpresarial}}</option>
                </select>
            </div>

        </div>

        <div class="col">

            <div class="form-group">
                <label>Estatus:</label>
                <select class="form-control" v-model="fieldEstatusConsultaSolicitudes" :disabled="botonConsultar.disableConstroles">
                    <option value="S/D">Selecciona una opcion.</option>
                    <option value="Pendiente">Pendiente</option>
                    <option value="Aprobado">Aprobado</option>
                    <option value="Cancelado">Cancelado</option>
                </select>
            </div>

        </div>

        <div class="col">

            <div class="form-group">
                <label>Fecha:</label>
                <Datepicker class="form-control" v-model="fieldFechaConsultaSolicitudes" :disabled="botonConsultar.disableConstroles"/>
            </div>

        </div>

        <div class="col">

            <div class="form-group">
                <br>
                <button type="button" class="btn btn-primary" @click="handleConsultarSolicitudes">{{botonConsultar.texto}}</button>
            </div>
        </div>
        <div class="col"></div>
        <div class="col"></div>
        <div class="col"></div>
      </div>

      <div class="row">
          <div class="tablaSolicitudes">
              
              <table class="table mb-0" style="width: 100%;">

                <thead>
                    <tr>
                        
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" v-if="fieldEstatusConsultaSolicitudes === 'Pendiente' ? true : false ">Opciones</th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Estatus</th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Fecha Solicitud</th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Plantilla</th>
                        
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Total Depositos</th>

                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Monto Asimilado</th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Monto Efectivo / Sin Recibo</th>

                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Comision Pesos</th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">comision Pesos Extra</th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">I.V.A.</th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Devolucion I.V.A.</th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Retorno</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(registro, index) in jsonSolicitudesConsulta" :key="index">

                        <td v-if="fieldEstatusConsultaSolicitudes === 'Cancelado' || fieldEstatusConsultaSolicitudes === 'Aprobado' ? false : true ">
                            <button type="button" class="btn btn-success btn-sm" @click="handleAprobarSolicitud(registro)" :disabled="procesoAprobacion">Aprobar</button>
                            &nbsp;
                            <button type="button" class="btn btn-danger btn-sm" @click="handleCancelarSolicitud(registro)" :disabled="procesoAprobacion">Cancelar</button>
                        </td>

                        <td v-if="fieldEstatusConsultaSolicitudes === 'Aprobado' ? true : false ">
                            <button type="button" class="btn btn-success btn-sm" @click="handleMostrarFacturas(registro)" :disabled="procesoAprobacion">Mostrar Registros Fact.</button>
                        </td>

                        <td>
                            {{ registro.estatus }}
                        </td>

                        <td>
                            <h6 class="mb-0 text-xs" style="text-align: center;">{{ registro.fechaSolicitud }}</h6>
                        </td>

                        <td>
                            <h6 class="mb-0 text-xs" style="text-align: center;">{{ registro.nombrePlantilla }}</h6>
                        </td>

                        <td>
                            <h6 class="mb-0 text-xs" style="text-align: center;">{{ registro.totalDepositos }}</h6>
                        </td>

                        <td>
                            <h6 class="mb-0 text-xs" style="text-align: center;">{{ registro.montoAsimilado }}</h6>
                        </td>

                        <td>
                            <h6 class="mb-0 text-xs" style="text-align: center;">{{ registro.montoEfectivoSinRecibo }}</h6>
                        </td>

                        <td>
                            <h6 class="mb-0 text-xs" style="text-align: center;">{{ registro.comisionPesos }}</h6>
                        </td>

                        <td>
                            <h6 class="mb-0 text-xs" style="text-align: center;">{{ registro.comisionPesosExtra }}</h6>
                        </td>

                        <td>
                            <h6 class="mb-0 text-xs" style="text-align: center;">{{ registro.iva }}</h6>
                        </td>

                        <td>
                            <h6 class="mb-0 text-xs" style="text-align: center;">{{ registro.devIva }}</h6>
                        </td>

                        <td>
                            <h6 class="mb-0 text-xs" style="text-align: center;">{{ registro.retorno }}</h6>
                        </td>


                    </tr>
                </tbody>
            </table>

          </div>
      </div>

    </div>

    <comp-modal v-if="modalFacturas" size="width: 1200px; height: 690px;" sizeCuerpo="height:550px">
        <template v-slot:encabezado>
        <label><h5>Registros Facturas</h5></label>
      </template>
      <template v-slot:cuerpo>
          
          <div class="classContenedor">

            <div class="row">

                <table class="table mb-0">

                    <thead>
                        <tr>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Razon Social</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Cliente Razon Social</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Sub Total</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">I.V.A.</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Total</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Estatus</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Opciones</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(registro, index) in jsonRegistrosFactura" :key="index">
                            <td>
                                <h6 class="mb-0 text-xs">{{ registro.empresa_razon_social }}</h6>
                            </td>

                            <td>
                                <h6 class="mb-0 text-xs">{{ registro.cliente_razon_social }}</h6>
                            </td>

                            <td>
                                <h6 class="mb-0 text-xs">{{ registro.sub_total }}</h6>
                            </td>

                            <td>
                                <h6 class="mb-0 text-xs">{{ registro.iva }}</h6>
                            </td>

                            <td>
                                <h6 class="mb-0 text-xs">{{ registro.total }}</h6>
                            </td>

                            <td>
                                <h6 class="mb-0 text-xs">{{ registro.estatus }}</h6>
                            </td>

                            <td>
                              <button type="button" class="btn btn-success btn-sm" @click="handleDescargarComprobantePago(registro.comprobanteFileBase64, registro.comprobanteFileName)">Comprobante</button>
                              &nbsp;
                              <button type="button" class="btn btn-success btn-sm" @click="handleDescargarXml(registro.stringXML, registro.serie, registro.folio )">XML Sin Timbrar</button>
                              &nbsp;
                              <button :disabled="registro.Pendiente === 'Pendiente' ? false : true " type="button" class="btn btn-success btn-sm" @click="handleDescargarXml(registro.stringXML, registro.serie, registro.folio )">XML Timbrar</button>
                            </td>

                        </tr>
                    </tbody>

                </table>


            </div>


        </div>
          
      </template>

      <template v-slot:pie>

        <button type="button" class="btn btn-danger" @click="modalFacturas = false">
            <i class="fa fa-window-close" aria-hidden="true" style="color: white; font-size: 15px;"></i>
        </button>

      </template>

    </comp-modal>

</template>

<script>
import proceso from './js/proceso';
import Datepicker from 'vue3-datepicker';
import CompModal from '../../componentes/modal/modal.vue';
export default {
  name: "Autorizaciones",
  components: {Datepicker, CompModal},
  setup(){
    return{
      ...proceso()
    }
  }
}
</script>

<style scoped>
  @import './css/estilos.css';
</style>