<template>

  <div class="bg-white">
    <main class="mt-0 main-content">
      <section>
        <div class="page-header min-vh-100">
          <div class="container">
            <div class="row">
              
              <div class="col-6 d-lg-flex d-none h-100 my-auto pe-0 ps-0 position-absolute top-0 start-0 text-center justify-content-center flex-column">
                <div class="position-relative h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center imagenNotFound"></div>
              </div>

              <div class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column ms-auto me-auto ms-lg-auto me-lg-5">
                
                <div class="card card-plain">
                  
                  <div class="pb-0 card-header bg-transparent mb-4">
                    <h4 class="font-weight-bolder">{{encabezado}}</h4>
                    <p class="mb-0">
                      {{texto}}
                    </p>
                  </div>
                  
                  <div class="card-body">
                    <!-- <form role="form"> -->

                    <!-- </form> -->


                  </div>

                </div>

              </div>

            </div>

          </div>
        </div>
      </section>
    </main>
  </div>
  
</template>

<script>
import proceso from './js/proceso.js';
export default {
  name: "NotFound",
  components: {},
  setup(){

    return{
      ...proceso()
    }

  }
};
</script>
<style scoped>
    @import './css/estilos.css';
</style>