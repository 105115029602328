import { ref } from 'vue';
import { notify } from "@kyvg/vue3-notification";

import { operacion } from '@/helpers/axios';

const useCorreos = () => {

    const recordsCorreos = ref([]);
    const fieldIdUnicoCorreo = ref('');
    const fieldNombre = ref('');
    const fieldCorreoElectronico = ref('');

    const disabledBtnControl = ref({
        btnNuevoDisabled: false,
        btnEditarDisabled: true,
        btnCancelarDisabled: true
    });
    const disabledForm = ref(true);


    // HANDLE'S

    const handleTablaCorreos = async() => {
        const data = await operacion(
            {
                numero : '51',
                proceso : '8',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'tablaCorreos'
                },
                mostrarMensaje: false
            }
        );
        
        recordsCorreos.value = {
            encabezados: ['Nombre', 'Correo Electronico'],
            camposMostrar: ['nombre', 'correoElectronico'],
            registros: data.tablas.Table1,
            opcionesRegistro: false,
            opcionesRegistroActualizar: true,
            opcionesRegistroGuardar: true
        };
    };
    handleTablaCorreos();

    const handleNuevo = () => {
        disabledBtnControl.value = {
            btnNuevoDisabled: true,
            btnEditarDisabled: true,
            btnCancelarDisabled: false
        };
        disabledForm.value = false;
    };

    const handleEditar = () => {
        disabledForm.value = false;
    };

    const handleCancelar = () => {
        disabledBtnControl.value = {
            btnNuevoDisabled: false,
            btnEditarDisabled: true,
            btnCancelarDisabled: true
        };
        disabledForm.value = true;
        fieldIdUnicoCorreo.value = '';
        fieldNombre.value = '';
        fieldCorreoElectronico.value = '';
    };

    const handleGuardarCorreo = async() => {

        if(fieldIdUnicoCorreo.value === '')
        {
            const data = await operacion(
                {
                    numero : '51',
                    proceso : '8',
                    opcionTD : 'TODO',
                    parametros : true,
                    datosReceptor : 
                    {
                        opcion: 'nuevoCorreo',
                        Pnombre: fieldNombre.value,
                        PcorreoElectronico: fieldCorreoElectronico.value
                    },
                    mostrarMensaje: true
                }
            );
            if(data.bandera === 0)
            {
                notify({
                    title: "Correos",
                    text: "El registro se realizo con exito.",
                });
            }
            else
            {
                notify({
                    title: "Correos",
                    text: data.mensaje
                });
            }
        }
        else
        {
            const data = await operacion(
                {
                    numero : '51',
                    proceso : '8',
                    opcionTD : 'TODO',
                    parametros : true,
                    datosReceptor : 
                    {
                        opcion: 'actualizarCorreo',
                        PidUnicoCorreos: fieldIdUnicoCorreo.value,
                        Pnombre: fieldNombre.value,
                        PcorreoElectronico: fieldCorreoElectronico.value
                    },
                    mostrarMensaje: true
                }
            );
            if(data.bandera === 0)
            {
                notify({
                    title: "Correos",
                    text: "La actualizacion se realizo con exito.",
                });
            }
            else
            {
                notify({
                    title: "Correos",
                    text: data.mensaje
                });
            }
        }

        handleCancelar();
        handleTablaCorreos();
    };

    const handleSelectDato = (dato) => {
        fieldIdUnicoCorreo.value = dato.idUnicoCorreos;
        fieldNombre.value = dato.nombre;
        fieldCorreoElectronico.value = dato.correoElectronico;
        disabledBtnControl.value = {
            btnNuevoDisabled: true,
            btnEditarDisabled: false,
            btnCancelarDisabled: false
        };
    };

    return{
        recordsCorreos,
        fieldIdUnicoCorreo,
        fieldNombre,
        fieldCorreoElectronico,
        disabledBtnControl,
        disabledForm,

        handleNuevo,
        handleEditar,
        handleCancelar,
        handleGuardarCorreo,
        handleSelectDato
    }
};


export default useCorreos;