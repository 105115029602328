<template>

    <div class="row">

        <div class="col">

            <div class="form-group">
                <label>Grupo Empresarial:</label>
                <select v-model="fieldSelectGrupoEmpresarial" class="form-control" :disabled="processBotonSeleccionarGrupoEmpresarial.seleccionado">
                    <option value="0">Selecciona una opcion.</option>
                    <option v-for=" ({idUnico, grupoEmpresarial}) in jsonGrupoEmpresarial" :key="idUnico" :value="idUnico">{{grupoEmpresarial}}</option>
                </select>
            </div>

        </div>
        <div class="col">
            <div class="form-group">
                <br>
                <button type="button" class="btn btn-info mb-2" @click="handleProcesoBotonSeleccionarGrupoEmpresarial">{{processBotonSeleccionarGrupoEmpresarial.texto}}</button>
            </div>
        </div>
        <div class="col">
        </div>

        <div class="col"></div>

    </div>

    <div class="contenedor">

        <ul class="nav nav-tabs" disabled>

            <li class="nav-item">
                <span class="nav-link" style="cursor:pointer;" :class="processSeleccionPest.seleccion01 ? 'active' : ''" @click="handleSelect('01')"><i class="fa fa-address-card-o" style="color: blue; font-size: 15px;"></i> Informacion General</span>
            </li>

            <li class="nav-item">
                <span class="nav-link" style="cursor:pointer;" :class="processSeleccionPest.seleccion02 ? 'active' : ''" @click="handleSelect('02')"><i class="fa fa-sliders" style="color: blue; font-size: 15px;"></i> Parametros</span>
            </li>

            <li class="nav-item">
                <span class="nav-link" style="cursor:pointer;" :class="processSeleccionPest.seleccion03 ? 'active' : ''" @click="handleSelect('03')"><i class="fa fa-users" style="color: blue; font-size: 15px;"></i> Beneficiarios</span>
            </li>

            <li class="nav-item">
                <span class="nav-link" style="cursor:pointer;" :class="processSeleccionPest.seleccion04 ? 'active' : ''" @click="handleSelect('04')"><i class="fa fa-book" style="color: blue; font-size: 15px;"></i> Facturacion</span>
            </li>

            <li class="nav-item">
                <span class="nav-link" style="cursor:pointer;" :class="processSeleccionPest.seleccion05 ? 'active' : ''" @click="handleSelect('05')"><i class="fa fa-credit-card-alt" style="color: blue; font-size: 15px;"></i> Financiamiento</span>
            </li>
            
            <li class="nav-item">
                <span class="nav-link" style="cursor:pointer;" :class="processSeleccionPest.seleccion06 ? 'active' : ''" @click="handleSelect('06')"><i class="fa fa-file-text-o" style="color: blue; font-size: 15px;"></i> Bancos Layout</span>
            </li>
            
            <li class="nav-item">
                <span class="nav-link" style="cursor:pointer;" :class="processSeleccionPest.seleccion07 ? 'active' : ''" @click="handleSelect('07')"><i class="fa fa-envelope" style="color: blue; font-size: 15px;"></i> Correos de solicitudes</span>
            </li>

        </ul>


        <div v-if="processSeleccionPest.seleccion01" class="divParametros">

            <!-- DATOS GENERALES GRUPO EMPRESARIAL -->
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Datos del grupo empresarial</h5>
                    <br>

                    <div class="row">
                        <div class="col">

                            <div class="form-group">
                                <label>idUnico:</label>
                                <input type="text" class="form-control" v-model="fieldFormDatosGenerales.idUnico" disabled>
                            </div>

                        </div>

                        <div class="col">

                            <div class="form-group">
                                <label>Grupo Empresarial:</label>
                                <input type="text" class="form-control" v-model="fieldFormDatosGenerales.grupoEmpresarial" disabled>
                            </div>

                        </div>

                        <div class="col">

                            <div class="form-group">
                                <label>Descripcion:</label>
                                <input type="text" class="form-control" v-model="fieldFormDatosGenerales.descripcion" disabled>
                            </div>

                        </div>

                    </div>

                    <div class="row">
                        <div class="col">

                            <div class="form-group">
                                <label>Ultimo Movimiento Parametros:</label>
                                <input type="text" class="form-control" v-model="fieldFormDatosGenerales.ultimoMovimientoParametros" disabled>
                            </div>

                        </div>

                        <div class="col">

                            <div class="form-group">
                                <label>Ultima Solicitud:</label>
                                <input type="text" class="form-control" v-model="fieldFormDatosGenerales.ultimaSolicitud" disabled>
                            </div>

                        </div>

                        <div class="col">

                            <div class="form-group">
                                <label>Saldo A Favor:</label>
                                <input type="text" class="form-control" v-model="fieldFormDatosGenerales.saldoAFavor" disabled>
                            </div>

                        </div>

                        <div class="col">

                            <div class="form-group">
                                <label>Financiamiento:</label>
                                <input type="text" class="form-control" v-model="fieldFormDatosGenerales.financiamiento" disabled>
                            </div>

                        </div>

                    </div>

                    <!-- <div class="row">

                        <div class="col">

                            <div class="form-group">
                                <label>Acceso Cliente: {{fieldFormDatosGenerales.activoAcceso === 1 ? 'Activado' : 'Desactivado'}}</label>
                                <br>
                                <button type="button" @click="handleActivarDesactivarAcceso" class="btn btn-info" :disabled="!processBotonSeleccionarGrupoEmpresarial.seleccionado">{{ fieldFormDatosGenerales.activoAcceso === 1 ? 'Desactivar acceso' : 'Activar acceso' }}</button>
                            </div>

                        </div>

                        <div class="col">

                            <div class="form-inline">
                                <div class="form-group mb-2">
                                    <div class="row"><label>Clave Acceso:</label></div>
                                    <div class="row">
                                        <div class="col">
                                            <input type="text" class="form-control" v-model="fieldFormDatosGenerales.claveAcceso" :disabled="!processBotonSeleccionarGrupoEmpresarial.seleccionado">
                                        </div>
                                        <div class="col">
                                            <button type="button" @click="handleUpdateClaveAcceso" class="btn btn-info" :disabled="!processBotonSeleccionarGrupoEmpresarial.seleccionado">Guardar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col">

                            <div class="form-group">
                                <label>Logo Empresa:</label>
                                <br>
                                <label for="file-input-00"><img :src="fieldFormDatosGenerales.logo" width="50" height="50" /></label>
                                <input type="file" id="file-input-00" accept=".jpg, .jpeg, .png" lang="es" class="mt-1" style="position: fixed; top: -100em" :disabled="!processBotonSeleccionarGrupoEmpresarial.seleccionado" @change="handleUpdateLogoEmpresa($event)" />

                            </div>

                        </div>

                    </div> -->

                </div>
            </div>

            <!-- TABLA EMPRESAS CLIENTE -->
            <div class="card">
                <div class="card-body">
                    <label><h6>Empresas Cliente</h6></label>
                    <table class="table mb-0">

                        <thead>
                            <tr>
                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Razon Social</th>
                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">R.F.C.</th>
                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Siglas</th>
                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nombre Corto</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="(registro, index) in jsonTablaClientes" :key="index">
                                <td>
                                    <h6 class="mb-0 text-xs">{{ registro.razonSocial }}</h6>
                                </td>

                                <td>
                                    <h6 class="mb-0 text-xs">{{ registro.rfc }}</h6>
                                </td>

                                <td>
                                    <h6 class="mb-0 text-xs">{{ registro.siglas }}</h6>
                                </td>

                                <td>
                                    <h6 class="mb-0 text-xs">{{ registro.nombreCorto }}</h6>
                                </td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>


        </div>

        <div v-if="processSeleccionPest.seleccion02" class="divParametros">

            <div class="card">

                <div class="card-body">

                    <div class="row">
                        <div class="col">

                            <div class="form-group">
                                <label>Plantilla ( Seleccionada: {{fieldNombrePlantillaSeleccionada}}):</label>
                                <select class="form-control" v-model="fieldSelectPlantillaSeleccionada" @change="handleDatosPlantillas">
                                    <option value="S/D">Selecciona una opcion.</option>
                                    <option v-for="({idUnicoPlantilla, nombrePlantilla}) in jsonPlantillas.registros" :key="idUnicoPlantilla" :value="idUnicoPlantilla">{{nombrePlantilla}}</option>
                                </select>
                            </div>

                        </div>
                        <div class="col">

                            <div class="row">
                                <div class="col">
                                    <br>
                                    <button type="button" class="btn btn-info" @click="handleSeleccionPlantilla" :disabled="fieldSelectPlantillaSeleccionada === '0' ? true : false">Seleccionar</button>
                                </div>
                                <div class="col">
                                    <br>
                                    <button type="button" style="width: 200px;" class="btn btn-success" @click="handleGrabarParametrosDatosPlantilla" :disabled="fieldSelectPlantillaSeleccionada === '0' ? true : false"><i class="fa fa-floppy-o" style="font-size: 15px;"></i> Guardar Parametros</button>
                                </div>
                            </div>

                        </div>
                        <div class="col"></div>
                        <div class="col">
                            <br>
                            <button type="button" class="btn btn-info" @click="handleAbrirPlantilla">Catalogo plantillas</button>
                        </div>
                    </div>

                    <div class="row marco">

                        <div class="col">

                            <div class="row encabezado">

                                <div class="col izquierda">
                                    <span><label>Promotor</label></span>
                                </div>

                                <div class="col derecha">
                                    <a href="../manuales/pdfPromotor.pdf" target="_blank"><label><i class="fa fa-yelp" style="color: red; font-size: 15px;"></i></label></a>
                                </div>

                            </div>

                            <div class="row">
                                
                                <div class="col">
                                
                                    <div class="form-group">
                                        <label>Promotor:</label>
                                        <select class="form-control" @change="handleValidadrPromotorComision" v-model="fieldSelectPromotores" :disabled="fieldSelectPlantillaSeleccionada === '0' ? true : false">
                                            <option value="S/D">Selecciona una opcion.</option>
                                            <option v-for="{idUnicoPromotor, nombreCompleto} in jsonPromotores" :key="idUnicoPromotor" :value="idUnicoPromotor">{{nombreCompleto}}</option>
                                        </select>
                                    </div>

                                </div>

                                <div class="col">
                                    <div class="form-group">
                                        <label>comision (%):</label>
                                        <input type="text" v-model.lazy="fieldPorcentajePromotores" @keypress="isDecimal($event)" class="form-control" :disabled="fieldSelectPlantillaSeleccionada === '0' ? true : false">
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Operacion promotor:</label>
                                        <input type="text" v-model.lazy="fieldOperacionPorcentajePromotores" class="form-control" :disabled="fieldSelectPlantillaSeleccionada === '0' ? true : false">
                                    </div>
                                </div>
                                <div class="col"></div>
                                
                            </div>

                        </div>

                    </div>

                    <div class="row marco">

                        <div class="col">

                            <div class="row encabezado">

                                <div class="col izquierda">
                                    <span><label>Comision por deposito</label></span>
                                </div>

                                <div class="col derecha">
                                    <a href="../manuales/pdfDepositos.pdf" target="_blank"><label><i class="fa fa-yelp" style="color: red; font-size: 15px;"></i></label></a>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col">

                                    <div class="form-group">
                                        <label>Deposito asimilado (%):</label>
                                        <input type="text" @keypress="isDecimal($event)" v-model.lazy="fieldPorcentajeComisionPorDepositoMovAsimilado" class="form-control" :disabled="fieldSelectPlantillaSeleccionada === '0' ? true : false">
                                    </div>

                                </div>

                                <div class="col">

                                    <div class="form-group">
                                        <label>Deposito normal (%):</label>
                                        <input type="text" @keypress="isDecimal($event)" v-model.lazy="fieldPorcentajeComisionPorDepositoMovNormal" class="form-control" :disabled="fieldSelectPlantillaSeleccionada === '0' ? true : false">
                                    </div>

                                </div>

                                <div class="col">

                                    <div class="form-group" style="width: 800px;">
                                        <label>Operacion deposito:</label>
                                        <input type="text" v-model.lazy="fieldPorcentajeComisionOperacion" class="form-control" :disabled="fieldSelectPlantillaSeleccionada === '0' ? true : false">
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="row marco">

                        <div class="col">

                            <div class="row encabezado">

                                <div class="col izquierda">
                                    <span><label>Comision por retorno</label></span>
                                </div>

                                <div class="col derecha">
                                    <a href="../manuales/pdfRetorno.pdf" target="_blank"><label><i class="fa fa-yelp" style="color: red; font-size: 15px;"></i></label></a>
                                </div>

                            </div>

                            <div class="row">

                                <div class="col">

                                    <div class="form-group">
                                        <label>Retorno asimilado (%):</label>
                                        <input type="text" @keypress="isDecimal($event)" v-model.lazy="fieldPorcentajeComision01Asimilado" class="form-control" :disabled="fieldSelectPlantillaSeleccionada === '0' ? true : false">
                                    </div>

                                </div>

                                <div class="col">

                                    <div class="form-group">
                                        <label>Retorno normal (%):</label>
                                        <input type="text" @keypress="isDecimal($event)" v-model.lazy="fieldPorcentajeComision01Normal" class="form-control" :disabled="fieldSelectPlantillaSeleccionada === '0' ? true : false">
                                    </div>

                                </div>

                                <div class="col">

                                    <div class="form-group" style="width: 800px;">
                                        <label>Operacion retorno:</label>
                                        <input type="text" v-model.lazy="fieldPorcentajeComision01Operacion" class="form-control" :disabled="fieldSelectPlantillaSeleccionada === '0' ? true : false">
                                    </div>

                                </div>
                                
                            </div>
                        
                        </div>

                    </div>

                    <div class="row marco">
                        
                        <div class="col">

                            <div class="row encabezado">

                                <div class="col izquierda">
                                    <span><label>Operacion</label></span>
                                </div>

                                <div class="col derecha">
                                    <!-- <a><label><i class="fa fa-yelp" style="color: red; font-size: 15px;"></i></label></a> -->
                                </div>

                            </div>

                            <div class="row">

                                <div class="row">
                                    <div class="col">

                                    </div>
                                    
                                    <div class="col">

                                        <div class="form-group">
                                            <label>Operacion total disponible cliente:</label>
                                            <input type="text" v-model.lazy="fieldOperacionTotalDisponibleCliente" class="form-control" :disabled="fieldSelectPlantillaSeleccionada === '0' ? true : false">
                                        </div>

                                    </div>

                                    <div class="col">

                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">

                                    </div>
                                    
                                    <div class="col">

                                        <div class="form-group">
                                            <label>Comision (%):</label>
                                            <input type="text" @keypress="isDecimal($event)" v-model.lazy="fieldComisionPesosPorcentaje" class="form-control" :disabled="fieldSelectPlantillaSeleccionada === '0' ? true : false">
                                        </div>

                                    </div>

                                    <div class="col">

                                        <div class="form-group">
                                            <label>Operacion comision:</label>
                                            <input type="text" v-model.lazy="fieldComisionPesosOperacion" class="form-control" :disabled="fieldSelectPlantillaSeleccionada === '0' ? true : false">
                                        </div>

                                    </div>

                                    <div class="col">

                                    </div>
                                </div>

                                <div class="row">

                                    <div class="col">
                                        <div class="form-check">
                                            <label>
                                                Activar comision extra
                                                <input class="form-check-input" v-model="fieldActivarComisionPesosExtra" type="checkbox" :disabled="fieldSelectPlantillaSeleccionada === '0' ? true : false">
                                            </label>
                                        </div>
                                    </div>
                                    
                                    <div class="col">

                                        <div class="form-group">
                                            <label>Comision extra (%):</label>
                                            <input type="text" @keypress="isDecimal($event)" v-model.lazy="fieldComisionPesosExtraPorcentaje" class="form-control" :disabled="!fieldActivarComisionPesosExtra">
                                        </div>

                                    </div>

                                    <div class="col">

                                        <div class="form-group" style="width: 700px;">
                                            <label>Operacion comision extra:</label>
                                            <input type="text" class="form-control" v-model.lazy="fieldComisionPesosExtraOperacion" :disabled="!fieldActivarComisionPesosExtra">
                                        </div>

                                    </div>

                                    <div class="col">

                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">

                                    </div>
                                    
                                    <div class="col">

                                        <div class="form-group">
                                            <label>I.V.A. (%):</label>
                                            <input type="text" @keypress="isDecimal($event)" v-model.lazy="fieldIVAPorcentaje" class="form-control" :disabled="fieldSelectPlantillaSeleccionada === '0' ? true : false">
                                        </div>

                                    </div>

                                    <div class="col">

                                        <div class="form-group">
                                            <label>Operacion I.V.A.:</label>
                                            <input type="text" v-model.lazy="fieldIVAOperacion" class="form-control" :disabled="fieldSelectPlantillaSeleccionada === '0' ? true : false">
                                        </div>

                                    </div>

                                    <div class="col">

                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">

                                    </div>
                                    
                                    <div class="col">

                                        <div class="form-group">
                                            <label>Devolucion I.V.A. (%):</label>
                                            <input type="text" @keypress="isDecimal($event)" v-model.lazy="fieldDevolucionIVAPorcentaje" class="form-control" :disabled="fieldSelectPlantillaSeleccionada === '0' ? true : false">
                                        </div>

                                    </div>

                                    <div class="col">

                                        <div class="form-group">
                                            <label>Operacion devolucion I.V.A.:</label>
                                            <input type="text" v-model.lazy="fieldDevolucionIVAOperacion" class="form-control" :disabled="fieldSelectPlantillaSeleccionada === '0' ? true : false">
                                        </div>

                                    </div>

                                    <div class="col">

                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">

                                    </div>
                                    
                                    <div class="col">

                                        <div class="form-group">
                                            <label>Retorno (%):</label>
                                            <input type="text" @keypress="isDecimal($event)" v-model.lazy="fieldRetornoPorcentaje" class="form-control" :disabled="fieldSelectPlantillaSeleccionada === '0' ? true : false">
                                        </div>

                                    </div>

                                    <div class="col">

                                        <div class="form-group">
                                            <label>Operacion retorno:</label>
                                            <input type="text" v-model.lazy="fieldRetornoOperacion" class="form-control" :disabled="fieldSelectPlantillaSeleccionada === '0' ? true : false">
                                        </div>

                                    </div>

                                    <div class="col">

                                    </div>
                                </div>
                                
                                
                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>

        <div v-if="processSeleccionPest.seleccion03" class="divParametros">
            <div class="card">
                <div class="card-body">

                    <div class="row" style="border: 2px solid RGB(120,131,24); border-radius: 5px; margin-bottom: 10px;">

                        <span style="background-color: RGBA(120,131,24,0.48); border-radius: 3px; width: 100%;"><label>Catalogo de beneficiarios</label></span>

                        <div class="row">

                            <div class="col">
                                <br>
                                <div class="row">

                                    <div class="col">
                                        <div class="d-grid gap-2">
                                            <button type="button" class="btn btn-outline-info" @click="handleModalGrupo">Catalogo de Grupos</button>
                                        </div>
                                    </div>

                                    <div class="col">
                                        <div class="d-grid gap-2">
                                            <button type="button" class="btn btn-outline-info" @click="handleModalBeneficiarios">Formulario Beneficiarios</button>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div class="row">


                            </div>

                            <div class="row">
                                <div class="col">

                                    <div class="divTabla02">

                                        <table class="table mb-0">

                                            <thead>
                                                <tr>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Grupo</th>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Tipo Alta</th>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Codigo</th>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nombre Completo / Razon Social</th>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">rfc</th>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">curp</th>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">cuenta banacaria</th>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">clabe bancaria</th>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">activo</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr v-for="(registro, index) in jsonBeneficiarios" :key="index">
                                                    <td>
                                                        <h6 class="mb-0 text-xs">{{ registro.nombreGrupo }}</h6>
                                                    </td>

                                                    <td>
                                                        <h6 class="mb-0 text-xs">{{ registro.tipoAlta }}</h6>
                                                    </td>

                                                    <td>
                                                        <h6 class="mb-0 text-xs">{{ registro.codigoBeneficiario }}</h6>
                                                    </td>

                                                    <td v-if="registro.tipoAlta === 'Persona'">
                                                        <h6 class="mb-0 text-xs">{{ registro.apellidoPaterno }} {{ registro.apellidoMaterno }} {{ registro.nombre }}</h6>
                                                    </td>

                                                    <td v-if="registro.tipoAlta === 'Empresa'">
                                                        <h6 class="mb-0 text-xs">{{ registro.razonSocial }}</h6>
                                                    </td>

                                                    <td>
                                                        <h6 class="mb-0 text-xs">{{ registro.rfc }}</h6>
                                                    </td>

                                                    <td>
                                                        <h6 class="mb-0 text-xs">{{ registro.curp }}</h6>
                                                    </td>

                                                    <td>
                                                        <h6 class="mb-0 text-xs">{{ registro.cuentaBanco }}</h6>
                                                    </td>

                                                    <td>
                                                        <h6 class="mb-0 text-xs">{{ registro.clabeBanco }}</h6>
                                                    </td>

                                                    <td>
                                                        <h6 class="mb-0 text-xs">{{ registro.activo === 1 ? 'Si' : 'No' }}</h6>
                                                    </td>

                                                    <td>
                                                        <button type="button" class="btn btn-info btn-sm" @click="handleOperacionesBeneficiarios('editar', registro)">Editar</button>
                                                    </td>
                                                </tr>
                                            </tbody>

                                        </table>
                                    
                                    </div>

                                </div>
                            </div>


                        </div>

                    </div>

                </div>
            </div>
        </div>

        <div v-if="processSeleccionPest.seleccion04" class="divParametros">

            <div class="card">
                <div class="card-body">

                    <div class="row" style="border: 2px solid RGB(120,131,24); border-radius: 5px; margin-bottom: 10px;">

                        <span style="background-color: RGBA(120,131,24,0.48); border-radius: 3px; width: 100%;"><label>Empresa a facturar</label></span>

                        <div class="row">

                            <div class="col" style="margin-top: 5px; margin-bottom: 5px;">
                                <div class="col tableBorder">

                                    <ul class="list-group" style="font-size: 11px;">
                                        <li v-for="({idSysCPEmpresasRP, razonSocial, serieFactura}) in jsonEmpresasInternas" :key="idSysCPEmpresasRP" class="list-group-item d-flex justify-content-between align-items-center">
                                            {{razonSocial}}
                                            <span class="badge badge-primary badge-pill" style="color:red">
                                                <select class="form-control" @change="handleChangeSerie(fieldSelectGrupoEmpresarial, idSysCPEmpresasRP, $event)" >
                                                    <option value="S/D">Sin Dato</option>
                                                    <option v-for="({serie}) in jsonCatalogosSerieEmpresas.filter( e => e.idSysCPEmpresasRP === idSysCPEmpresasRP )" :key="serie" :value="serie" :selected="serieFactura === serie ? true : false ">{{serie}}</option>
                                                </select>
                                            </span>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                            
                        </div>


                    </div>

                    <div class="row" style="border: 2px solid RGB(120,131,24); border-radius: 5px; margin-bottom: 10px;">

                        <span style="background-color: RGBA(120,131,24,0.48); border-radius: 3px; width: 100%;"><label>Conceptos factura</label></span>

                        <div class="row">

                            <div class="row">
                                <div class="col">

                                    <div class="form-group">
                                        <label>Empresa:</label>
                                        <select class="form-control" v-model="fieldEmpresasConcepto" @change="handleSelectEmpresasConcepto">
                                            <option value="S/D">Selecciona una opcion.</option>
                                            <option v-for="({idSysCPEmpresasRP, razonSocial}) in jsonEmpresasInternas" :key="idSysCPEmpresasRP" :value="idSysCPEmpresasRP">{{razonSocial}}</option>
                                        </select>
                                    </div>

                                </div>

                                <div class="col">
                                    <br>
                                    <button type="button" class="btn btn-info btn-sm" @click="handleModalConcepto" :disabled="disabledBotonAgregarConcepto">Agregar Concepto</button>
                                </div>

                            </div>

                            <div class="col" style="margin-top: 5px; margin-bottom: 5px;">

                                <div class="col tableBorder">
                                    <table class="table mb-0">

                                        <thead>
                                            <tr>
                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Prod / Serv</th>
                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Clave Unidad</th>
                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Metodo Pago</th>
                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Forma Pago</th>
                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Descripcion</th>
                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Opciones</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr v-for="(registro, index) in jsonConceptosFactura.filter( e => e.fieldEmpresasConcepto === fieldEmpresasConcepto )" :key="index">
                                                <td>
                                                    <h6 class="mb-0 text-xs">{{ registro.prodServ }}</h6>
                                                </td>

                                                <td>
                                                    <h6 class="mb-0 text-xs">{{ registro.claveUnidad }}</h6>
                                                </td>

                                                <td>
                                                    <h6 class="mb-0 text-xs">{{ registro.metodoPago }}</h6>
                                                </td>

                                                <td>
                                                    <h6 class="mb-0 text-xs">{{ registro.formaPago }}</h6>
                                                </td>

                                                <td>
                                                    <h6 class="mb-0 text-xs">{{ registro.descripcion }}</h6>
                                                </td>

                                                <td>
                                                    <button type="button" class="btn btn-info btn-sm" @click="handleFacturarEditar(registro)" :disabled="fieldIdUnicoConcepto === '' ? false : true">Editar</button>
                                                    <button type="button" class="btn btn-info btn-sm" @click="handleFacturarQuitar(registro)" :disabled="fieldIdUnicoConcepto === '' ? false : true">Quitar</button>
                                                </td>
                                            </tr>
                                        </tbody>

                                    </table>

                                </div>

                            </div>
                            
                        </div>


                    </div>

                    <div class="row" style="border: 2px solid RGB(120,131,24); border-radius: 5px; margin-bottom: 10px;">

                        <span style="background-color: RGBA(120,131,24,0.48); border-radius: 3px; width: 100%;"><label>Empresa Cliente Uso CFDI</label></span>

                        <div class="row">

                            <div class="col">
                                <div class="col tableBorder" style="margin-top: 5px; margin-bottom: 5px;">
                                    
                                    <ul class="list-group" style="font-size: 11px;">
                                        <li v-for="({idSysTSEmpresas, razonSocial, usoCFDI}) in jsonTablaClientes" :key="idSysTSEmpresas" class="list-group-item d-flex justify-content-between align-items-center">
                                            {{razonSocial}}
                                            <span class="badge badge-primary badge-pill" style="color:red">
                                                <select class="form-control" @change="handleChangeUsoCFDI(fieldSelectGrupoEmpresarial, idSysTSEmpresas, $event)" >
                                                    <option value="S/D">Sin Dato</option>
                                                    <option v-for="({idCatUsoCFDI, descripcion}) in jsonCatalogosSATCFDI" :key="idCatUsoCFDI" :value="idCatUsoCFDI" :selected="usoCFDI === idCatUsoCFDI ? true : false">[{{idCatUsoCFDI}}] {{descripcion}}</option>
                                                </select>
                                            </span>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                            
                        </div>


                    </div>

                </div>
            </div>
            
        </div>

        <div v-if="processSeleccionPest.seleccion05" class="divParametros">

            <div class="card">

                <div class="card-body">
                    <div class="row" style="border: 2px solid RGB(120,131,24); border-radius: 5px; margin-bottom: 10px;">

                        <span style="background-color: RGBA(120,131,24,0.48); border-radius: 3px; width: 100%;"><label>Financiamiento</label></span>

                        <div class="row">
                            <div class="col"></div>
                            <div class="col"></div>

                            <div class="col">
                                <div class="form-check">
                                    <label>
                                        Activar financiamiento
                                        <input class="form-check-input" v-model="fieldActivarFinanciamiento" type="checkbox">
                                    </label>
                                </div>
                            </div>
                            
                            <div class="col">

                                <div class="form-group" style="width: 250px;">
                                    <label>Limite total financiamiento ($):</label>
                                    <input type="text" :disabled="!fieldActivarFinanciamiento" v-model.lazy="fieldFinanciamiento" @keypress="isDecimal02($event)" class="form-control" >
                                </div>

                            </div>
                            <div class="col">
                                <br>
                                <button type="button" @click="handleActualizarFinanciamiento" style="width: 250px;" class="btn btn-success"><i class="fa fa-floppy-o" style="font-size: 10px;"></i> Guardar Financiamiento</button>
                            </div>
                            <div class="col"></div>
                            <div class="col"></div>
                        </div>


                    </div>
                </div>

            </div>
            
        </div>

        <div v-if="processSeleccionPest.seleccion06" class="divParametros">

            <div class="card">
                <div class="card-body">

                    <div class="row" style="border: 2px solid RGB(120,131,24); border-radius: 5px; margin-bottom: 10px;">

                        <span style="background-color: RGBA(120,131,24,0.48); border-radius: 3px; width: 100%;"><label>Empresa por la que se dispersa</label></span>

                        <div class="row">
                            <div class="col">
                            
                            <div class="form-group">
                                <label>Empresa por la que se dispersa:</label>
                                <select class="form-control" v-model.lazy="fieldEmpresaPagadora">
                                    <option value="S/D">Selecciona una opcion.</option>
                                    <option v-for="{idSysCPEmpresasRP, razonSocial} in jsonEmpresasPagadoras" :key="idSysCPEmpresasRP" :value="idSysCPEmpresasRP">{{razonSocial}}</option>
                                </select>
                            </div>

                        </div>
                        <div class="col"></div>
                        </div>

                    </div>
                    
                    <div class="row" style="border: 2px solid RGB(120,131,24); border-radius: 5px; margin-bottom: 10px;">

                        <span style="background-color: RGBA(120,131,24,0.48); border-radius: 3px; width: 100%;"><label>Logica de generacion de layout bancarios</label></span>

                        <div class="row m-3">

                            <div class="col margenCol">

                                <draggable
                                    tag="ul"
                                    :list="jsonBancosEmpresa"
                                    class="list-group"
                                    handle=".handle"
                                    item-key="clabeBancaria"
                                >
                                    <template #item="{ element, index }">
                                        
                                        <li class="list-group-item handle" style="cursor: -webkit-grab; cursor: grab;">
                                        
                                            <i>
                                            
                                                <span>{{index + 1}} - </span>
                                                <span>&nbsp;</span>
                                                <span class="text">{{ element.descripcion }} [ {{element.cuentaBancaria}} - {{element.clabeBancaria}} ]</span>
                                                <span>&nbsp; &nbsp;</span>

                                            </i>
                                        
                                        </li>

                                    </template>
                                </draggable>

                            </div>

                            <div class="col margenCol">
                                <p>Reglas de creacion de layout bancarios:</p>
                                <ol>
                                    <li>Layout bancario mismo banco nomina.</li>
                                    <li>Layout bancario mismo banco.</li>
                                    <li>Layout interbancario.</li>
                                </ol>
                            </div>

                        </div>

                        <div class="row m-3">
                            
                            <div class="col">
                                <div class="d-grid gap-2 col-6 mx-auto">
                                    <button class="btn btn-info" @click="handleConfiguracionBancos" type="button">Guardar configuracion</button>
                                </div>
                            </div>

                        </div>

                    </div>
                    
                </div>
            </div>

        </div>

        <div v-if="processSeleccionPest.seleccion07" class="divParametros">

            <div class="card">
                <div class="card-body">
                    
                    <div class="row" style="border: 2px solid RGB(120,131,24); border-radius: 5px; margin-bottom: 10px;">

                        <span style="background-color: RGBA(120,131,24,0.48); border-radius: 3px; width: 100%;"><label>Enviar correo a</label></span>

                        <div class="row">

                            <div class="col">
                            
                                <div class="form-group">
                                    <label>Correo:</label>
                                    <select class="form-control" v-model="fieldSelectCorreo">
                                        <option value="S/D">Selecciona una opcion.</option>
                                        <option v-for="{idUnicoCorreos, nombre, correoElectronico} in jsonCorreos" :key="idUnicoCorreos" :value="idUnicoCorreos">{{nombre}} - {{correoElectronico}} </option>
                                    </select>
                                </div>

                            </div>



                            <div class="col">

                                <div class="form-group">
                                    <br>
                                    <button type="button" class="btn btn-info" @click="handleAgregarCorreo">Agregar</button>
                                </div>

                            </div>
                            
                        </div>

                        <div class="row">
                            <div class="col tableBorder">
                                <table class="table mb-0">

                                    <thead>
                                        <tr>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nombre</th>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Correo Electronico</th>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Eliminar</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="(registro, index) in jsonCorreosAsignados" :key="index">
                                            <td>
                                                <h6 class="mb-0 text-xs">{{ registro.nombre }}</h6>
                                            </td>

                                            <td>
                                                <h6 class="mb-0 text-xs">{{ registro.correoElectronico }}</h6>
                                            </td>

                                            <td>
                                                <button type="button" class="btn btn-info btn-sm" @click="handleQuitarCorreo(registro)">Quitar</button>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>

                            </div>
                            <hr>
                        </div>

                    </div>
                    
                </div>
            </div>

        </div>

    </div>

    <!-- ================================================================================================================================== -->
    <!-- ================================================================================================================================== -->
    <!-- ================================================================================================================================== -->
    <!-- ================================================================================================================================== -->
    <!-- ================================================================================================================================== -->

    <!-- Modales -->

    <!-- ================================================================================================================================== -->
    <!-- ================================================================================================================================== -->
    <!-- ================================================================================================================================== -->
    <!-- ================================================================================================================================== -->
    <!-- ================================================================================================================================== -->

    <!-- Modal plantillas [- OK -]-->
    <comp-modal v-if="processModalPlantilla.open" size="width: 1100px; height: 670px;" sizeCuerpo="height:600px">
        <template v-slot:encabezado>
        <label><h5>Catalogo de plantillas</h5></label>
      </template>
      <template v-slot:cuerpo>
          
          <div class="row">

            <div class="col">

                <div class="form-group">
                <label>Nombre de la plantilla:</label>
                <input type="text" class="form-control" style="text-transform:uppercase;" v-model="fieldNombrePlantilla" />
                </div>

            </div>

            <div class="col">
                
                <div class="form-group">
                <label>Descripcion de la plantilla:</label>
                <input type="text" class="form-control" style="text-transform:uppercase;" v-model="fieldDescripcionPlantilla" />
                </div>

            </div>

            <div class="col">

                <div class="form-group">
                <br />
                <button type="button" class="btn btn-dark mb-2" :disabled="disableButtonGuardarPlantilla" @click="handleGuardarDatosPlantilla" ><i class="fa fa-floppy-o" style="color: white; font-size: 15px;"></i></button>
                &nbsp;
                <button type="button" class="btn btn-dark mb-2" :disabled="disableButtonEditarGuardarPlantilla" @click="handleGuardarDatosPlantilla"><i class="fa fa-pencil" style="color: white; font-size: 15px;"></i>&nbsp;&nbsp;&nbsp;<i class="fa fa-floppy-o" style="color: white; font-size: 15px;"></i></button>
                &nbsp;
                <button type="button" class="btn btn-dark mb-2" :disabled="disableButtonCancelarGuardarPlantilla" @click="handleCancelarDatosPlantilla"><i class="fa fa-times" style="color: white; font-size: 15px;"></i></button>
                &nbsp;
                <button type="button" class="btn btn-dark mb-2" @click="handleCerrarModalPlantilla"><i class="fa fa-sign-out" style="color: white; font-size: 15px;"></i></button>
                </div>

            </div>
            
        </div>

        <div class="tablaGrupoEmpresas">
          <table class="table mb-0">
            <thead>
                <tr>
                    <th v-for="(encabezado, index) in jsonPlantillas.encabezados" :key="index" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">{{encabezado}}</th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Opcion</th>
                </tr>
            </thead>
            <tbody>
              <tr v-for="(registro, index) in jsonPlantillas.registros" :key="index">
                  <td v-for="( dato, index ) in jsonPlantillas.camposMostrar" :key="index">
                      <h6 class="mb-0 text-xs">{{ registro[dato] }}</h6>
                  </td>
                  <td>
                    <button type="button" class="btn btn-dark btn-sm" @click="handleEditarDatosPlantilla(registro)">Editar</button>
                  </td>
              </tr>
            </tbody>
          </table>
        </div>
          
      </template>
    </comp-modal>

    <!-- Modal catalogo grupo [- OK -]-->
    <comp-modal v-if="modalGrupos" size="width: 1100px; height: 670px;" sizeCuerpo="height:600px">
        <template v-slot:encabezado>
        <label><h5>Catalogo de grupos</h5></label>
      </template>
      <template v-slot:cuerpo>
          
          <div class="row">

            <div class="col">

                <div class="form-group">
                <label>Nombre:</label>
                <input type="text" class="form-control" style="text-transform:uppercase;" v-model="fieldNombreGrupo" />
                </div>

            </div>

            <div class="col">
                
                <div class="form-group">
                <label>Descripcion:</label>
                <input type="text" class="form-control" style="text-transform:uppercase;" v-model="fieldDescripcionGrupo" />
                </div>

            </div>

            <div class="col">

                <div class="form-group">
                <br />
                <button type="button" class="btn btn-dark mb-2" :disabled="disableButtonGuardarGrupo" @click="handleEditarDatosGrupo('Guardar', {})" ><i class="fa fa-floppy-o" style="color: white; font-size: 15px;"></i></button>
                &nbsp;
                <button type="button" class="btn btn-dark mb-2" :disabled="disableButtonEditarGuardarGrupo" @click="handleEditarDatosGrupo('Actualizar', {})"><i class="fa fa-pencil" style="color: white; font-size: 15px;"></i>&nbsp;&nbsp;&nbsp;<i class="fa fa-floppy-o" style="color: white; font-size: 15px;"></i></button>
                &nbsp;
                <button type="button" class="btn btn-dark mb-2" :disabled="disableButtonCancelarGuardarGrupo" @click="handleEditarDatosGrupo('Cancelar', {})"><i class="fa fa-times" style="color: white; font-size: 15px;"></i></button>
                &nbsp;
                <button type="button" class="btn btn-dark mb-2" @click="handleModalGrupo"><i class="fa fa-sign-out" style="color: white; font-size: 15px;"></i></button>
                </div>

            </div>
            
        </div>

        <div class="tablaGrupoEmpresas">
          <table class="table mb-0">
            <thead>
                <tr>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nombre</th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Descripcion</th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Opcion</th>
                </tr>
            </thead>
            <tbody>
              <tr v-for="(registro, index) in jsonGrupos" :key="index">
                  
                  <td>
                      <h6 class="mb-0 text-xs">{{ registro.nombre }}</h6>
                  </td>

                  <td>
                      <h6 class="mb-0 text-xs">{{ registro.descripcion }}</h6>
                  </td>

                  <td>
                    <button type="button" class="btn btn-dark btn-sm" @click="handleEditarDatosGrupo('Editar', registro)">Editar</button>
                    &nbsp;
                    <button type="button" class="btn btn-dark btn-sm" @click="handleEditarDatosGrupo('Eliminar', registro)">Eliminar</button>
                  </td>
              </tr>
            </tbody>
          </table>
        </div>
          
      </template>
    </comp-modal>

    <!-- Modal catalogo beneficiarios [- OK -]-->
    <comp-modal v-if="modalBeneficiarios" size="width: 900px; height: 780px;" sizeCuerpo="height:710px">
        <template v-slot:encabezado>
        <label><h5>Formulario beneficiarios</h5></label>
      </template>
      <template v-slot:cuerpo>

        <div class="m-3">

            <div class="row">

                <div class="col">

                    <div class="mb-3" v-if="idUnicoBeneficiario === '' ? true : false ">
                        <label class="form-label">Tipo alta:</label>
                        <select class="form-control" v-model="fieldBeneficiarioTipoAlta">
                            <option value="Persona">Persona</option>
                            <option value="Empresa">Empresa</option>
                        </select>
                    </div>

                    <div class="mb-3" v-else>
                        <label class="form-label">Tipo alta:</label>
                        <select class="form-control" v-model="fieldBeneficiarioTipoAlta">
                            <option :value="fieldBeneficiarioTipoAlta">{{fieldBeneficiarioTipoAlta}}</option>
                        </select>
                    </div>

                </div>

                <div class="col">

                    <div class="mb-3">
                        <label class="form-label"><span style="color: red">*</span>Grupo:</label>
                        <select class="form-control" v-model="fieldBeneficiarioGrupo">
                            <option value="S/D">Selecciona una opcion.</option>
                            <option v-for="({idUnicoGrupo, nombre}, index) in jsonGrupos" :key="index" :value="idUnicoGrupo">{{nombre}}</option>
                        </select>
                    </div>

                </div>

                <div class="col">

                    <div class="mb-3" v-if="fieldBeneficiarioTipoAlta === 'Persona'">
                        <label class="form-label"><span style="color: red">*</span>Uso CFDI:</label>
                        <select class="form-control" v-model="fieldBeneficiarioUsoCFDI">
                            <option value="S/D">Selecciona una opcion.</option>
                            <option v-for="({idCatUsoCFDI, descripcion}, index) in jsonCatalogosSATCFDI" :key="index" :value="idCatUsoCFDI">{{idCatUsoCFDI}} - {{descripcion}}</option>
                        </select>
                    </div>

                </div>


            </div>

            <div class="row" v-if="fieldBeneficiarioTipoAlta === 'Persona'">

                <div class="col">

                    <div class="row">
                        <label class="form-label"><span style="color: red">*</span>Apellido Paterno:</label>
                    </div>

                    <div class="row">
                        <div class="mb-3">
                            <input type="text" class="form-control text-uppercase" v-model="fieldBeneficiarioApellidoPaterno">
                        </div>
                    </div>

                </div>

                <div class="col">

                    <div class="row">
                        <label class="form-label">Apellido Materno:</label>
                    </div>

                    <div class="row">
                        <div class="mb-3">
                            <input type="text" class="form-control text-uppercase" v-model="fieldBeneficiarioApellidoMaterno">
                        </div>
                    </div>

                </div>

                <div class="col">

                    <div class="row">
                        <label class="form-label"><span style="color: red">*</span>Nombre (s):</label>
                    </div>

                    <div class="row">
                        <div class="mb-3">
                            <input type="text" class="form-control text-uppercase" v-model="fieldBeneficiarioNombre">
                        </div>
                    </div>

                </div>

            </div>

            <div class="row" v-if="fieldBeneficiarioTipoAlta === 'Persona'">

                <div class="col">

                    <div class="mb-3">
                        <label class="form-label"><span style="color: red">*</span>R.F.C.:</label>
                        <input type="text" class="form-control text-uppercase" maxlength="13" v-model="fieldBeneficiarioRFC">
                    </div>

                </div>

                <div class="col">
                    
                    <div class="mb-3">
                        <label class="form-label"><span style="color: red">*</span>C.U.R.P.:</label>
                        <input type="text" class="form-control text-uppercase" maxlength="18" v-model="fieldBeneficiarioCURP">
                    </div>

                </div>

            </div>

            <div class="row" v-if="fieldBeneficiarioTipoAlta === 'Empresa'">

                <div class="col">

                    <div class="row">
                        <label class="form-label"><span style="color: red">*</span>Razon social</label>
                    </div>

                    <div class="row">
                        <div class="mb-3">
                            <input type="text" class="form-control text-uppercase" v-model="fieldBeneficiarioEmpresaRazonSocial">
                        </div>
                    </div>

                </div>

            </div>

            <div class="row">

                <div class="col">

                    <div class="mb-3">
                        <label class="form-label"><span style="color: red">*</span>Cuenta banco:</label>
                        <input type="text" class="form-control text-uppercase" @keypress="isNumber($event)" maxlength="11" v-model="fieldBeneficiarioCuentaBanco">
                    </div>

                </div>

                <div class="col">
                    
                    <div class="mb-3">
                        <label class="form-label"><span style="color: red">*</span>Clabe banco:</label>
                        <input type="text" class="form-control text-uppercase" @keypress="isNumber($event)" maxlength="18" v-model="fieldBeneficiarioClabeBanco">
                    </div>

                </div>

                <div class="col">
                    
                    <div class="mb-3">
                        <label class="form-label"><span style="color: red">*</span>Banco:</label>
                        <select class="form-control" disabled v-model="fieldBeneficiarioBanco">
                            <option value="S/D">Selecciona una opcion.</option>
                            <option v-for="({idCatBanco, descripcion}, index) in jsonBancos" :key="index" :value="idCatBanco">{{idCatBanco}} - {{descripcion}}</option>
                        </select>
                    </div>

                </div>

            </div>

            <div class="row">

                <div class="col">

                    <div class="mb-3">
                        <label class="form-label">Numero instituto banco santander:</label>
                        <input type="text" class="form-control text-uppercase" @keypress="isNumber($event)" maxlength="5" v-model="fieldBeneficiarioNumeroInstitutoBancoSantander">
                    </div>

                </div>

                <div class="col">
                    
                    <div class="mb-3">
                        <label class="form-label">Clave transferencia banco santander:</label>
                        <input type="text" class="form-control text-uppercase"  maxlength="6" v-model="fieldBeneficiarioClaveTransferenciaBancoSantander">
                    </div>

                </div>

                <div class="col">
                    
                    <div class="mb-3">
                        <label class="form-label">Numero instituto kuspit:</label>
                        <input type="text" class="form-control text-uppercase" @keypress="isNumber($event)" maxlength="2" v-model="fieldBeneficiarioNumeroInstitutoKuspit">
                    </div>

                </div>

            </div>

            <div class="row">

                <div class="col">
                    
                    <div class="mb-3">
                        <label class="form-label">Activo:</label>
                        <select class="form-control" v-model="fieldBeneficiarioActivo">
                            <option value="1">Si</option>
                            <option value="0">No</option>
                        </select>
                    </div>

                </div>

            </div>

            <div class="row">
                <div class="d-grid gap-2 col-6 mx-auto">
                    <button class="btn btn-info" type="button" :disabled="disableButtonBeneficiarioGuardar" @click="handleOperacionesBeneficiarios('grabar', {})">Guardar</button>
                    <button class="btn btn-danger" type="button" @click="handleModalBeneficiarios">Cerrar</button>
                </div>
            </div>

        </div>
          
      </template>
    </comp-modal>

     <!-- Registros Conceptos [- OK -]-->
    <comp-modal v-if="modalConceptos" size="width: 800px; height: 490px;" sizeCuerpo="height:390px">
        <template v-slot:encabezado>
        <label><h5>Registro de concepto</h5></label>
      </template>
      <template v-slot:cuerpo>
          
          <div class="row">

            <div class="col">

                <div class="form-group">
                    <label>Concepto a facturar:</label>
                    <input type="text" class="form-control" v-model.lazy="fieldFacturaProdServ" @keypress="isNumber($event)" maxlength="8" :disabled="fieldIdUnicoConcepto === '' ? false : true">
                </div>

            </div>

            <div class="col">
                <div class="form-group">
                    <label>Clave unidad:</label>
                    <input type="text" class="form-control" v-model.lazy="fieldFacturaClaveUnidad" style="text-transform: uppercase;" maxlength="3">
                </div>
            </div>

        </div>

        <div class="row">

            <div class="col">

                <div class="form-group">
                    <label>Descripcion de concepto:</label>
                    <input type="text" class="form-control" v-model.lazy="fieldFacturaDescripcion" style="text-transform: uppercase;" maxlength="250" >
                </div>

            </div>

        </div>

        <div class="row">

            

            <div class="col">
                <div class="form-group">
                    <label>Metodo de pago:</label>
                    <select class="form-control" v-model="fieldSelectFacturaMetodoPago">
                        <option value="S/D">Selecciona una opcion.</option>
                        <option v-for="{idCatMetodoPago, descripcion} in jsonMetodoPago" :key="idCatMetodoPago" :value="idCatMetodoPago">({{idCatMetodoPago}}) {{descripcion}}</option>
                    </select>
                </div>
            </div>

            <div class="col">
                <div class="form-group">
                    <label>Forma de pago:</label>
                    <select class="form-control" v-model="fieldSelectFacturaFormaPago">
                        <option value="S/D">Selecciona una opcion.</option>
                        <option v-for="{idCatFormaPago, descripcion} in jsonFormaPago" :key="idCatFormaPago" :value="idCatFormaPago">({{idCatFormaPago}}) {{descripcion}}</option>
                    </select>
                </div>
            </div>

            <div class="col">
                <div class="row">
                    <div class="col">
                        <br>
                        <button type="button" class="btn btn-info" @click="handleFacturaGuardar" :disabled="disabledButtonGrabarFacturaConcepto" ><i class="fa fa-floppy-o" aria-hidden="true"></i></button>
                    </div>
                    <div class="col">
                        <br>
                        <button type="button" class="btn btn-warning" @click="handleFacturaCancelar" ><i class="fa fa-times-circle" aria-hidden="true"></i></button>
                    </div>
                </div>
            </div>
        </div>
          
      </template>

      <template v-slot:pie>


      </template>

    </comp-modal>

</template>

<script>
import proceso from './js/proceso';
import CompModal from '../../componentes/modal/modal.vue';
import draggable from 'vuedraggable'
export default {
  name: "Grupo-Empresarial",
  components: { CompModal, draggable },
  setup(){
    return{
      ...proceso()
    }
  }
}
</script>

<style scoped>
  @import './css/estilos.css';
</style>       