import { verificarIdUnico } from '../helpers/axios.js';

const permiso = async(to, from, next) => {

    if(localStorage.getItem('usuario') === null || localStorage.getItem('usuario') === '')
    {
        next({ path: '/' });
    }
    else
    {   
        const dataUsuario = JSON.parse(localStorage.getItem('usuario'));
        const data = await verificarIdUnico(dataUsuario.idUnico);
        if(data.tablas.Table1.length > 0)
        {
            localStorage.setItem('permiso', dataUsuario.permiso);
            localStorage.setItem('idUnico', dataUsuario.idUnico);
            localStorage.setItem('nombreCompleto', dataUsuario.nombreCompleto);
            localStorage.setItem('nombreUsuario', dataUsuario.nombreUsuario);
            localStorage.setItem('correo', dataUsuario.correo);
            // next({ path: '/principal' });
            next();
        }
        else
        {
            next({ path: '/' });
            localStorage.clear();
        }
    }

};

export default permiso;