<template>

    <div>
    
    </div>

</template>
<script>
import proceso from './js/proceso';
export default {
    name: 'principal',
    components:{},
    
    setup(){
        return{
            ...proceso()
        }
    }

}
</script>
<style scoped>
    @import './css/estilos.css';
</style>