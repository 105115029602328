<template>
  <notifications style="z-index: 100500;" />
  <sidenav
    :custom_class="this.$store.state.mcolor"
    :class="[
      this.$store.state.isTransparent
    ]"
    class="fixed-start"
    v-if="this.$store.state.showSidenav"
  />

  <main
    class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
    :style="this.$store.state.isRTL ? 'overflow-x: hidden' : ''"
  >
    <!-- nav -->
    <navbar
      :class="[navClasses]"
      :textWhite="this.$store.state.isAbsolute ? 'text-white opacity-8' : ''"
      :minNav="navbarMinimize"
      v-if="this.$store.state.showNavbar"
    />
    <router-view />
    <!-- <app-footer v-show="this.$store.state.showFooter" /> -->

  </main>

</template>
<script>
import Navbar from '@/layout/Navbars/Navbar.vue'; //"@/examples/Navbars/Navbar.vue";
import Sidenav from "@/layout/Sidenav";
// import AppFooter from "@/layout/Footer.vue";
import { mapMutations } from "vuex";
export default {
  name: "App",
  components: {
    Navbar,
    Sidenav,
    // AppFooter,
  },
  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
  },
  computed: {
    navClasses() {
      return {
        "position-sticky blur shadow-blur mt-4 left-auto top-1 z-index-sticky": this.$store.state.isNavFixed,
        "position-absolute px-4 mx-0 w-100 z-index-2": this.$store.state.isAbsolute,
        "px-0 mx-4 mt-4": !this.$store.state.isAbsolute,
      };
    },
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
  },
};
</script>
