<template>
  
  <div class="bg-white sizePag">

    <ul class="nav nav-tabs">
      <li class="nav-item">
        <span class="nav-link" style="cursor:pointer;" :class="seleccionPest.seleccion01 ? 'active' : ''" @click="handleSelect('C')">Empresas Clientes</span>
      </li>
      <li class="nav-item">
        <span class="nav-link" style="cursor:pointer;" :class="seleccionPest.seleccion02 ? 'active' : ''" @click="handleSelect('I')">Empresas Internas</span>
      </li>
      <li class="nav-item">
        <span class="nav-link" style="cursor:pointer;" :class="seleccionPest.seleccion03 ? 'active' : ''" @click="handleSelect('U')">Usuarios</span>
      </li>
    </ul>

    <div class="container select02" v-if="seleccionPest.seleccion01">

      <div class="row">

          <div class="col">

              <div class="form-group">
                  <label>Grupo Empresarial</label>
                  <select class="form-control" v-model="fieldSelectGrupoEmpresarialClientes" :disabled="disabledProcesoClientesGrupoEmpresarial.select" >
                      <option value="0">Selecciona una opcion.</option>
                      <option v-for="({idUnico, grupoEmpresarial}) in jsonGrupoEmpresarial.registros" :key="idUnico" :value="idUnico" >{{grupoEmpresarial}}</option>
                  </select>
              </div>

          </div>
          <div class="col">
              <div class="form-group">
                  <br>
                  <button type="button" class="btn btn-dark mb-2" @click="handleSeleccionarGrupoEmpresarialCliente" :disabled="disabledProcesoClientesGrupoEmpresarial.buttonSeleccionar">{{disabledProcesoClientesGrupoEmpresarial.textoBotonSeleccionar}}</button>
                  &nbsp;
                  <button type="button" class="btn btn-dark mb-2" @click="handleAbrirModalGrupoEmpresarial" ><i class="fa fa-pencil" style="color: white; font-size: 15px;"></i>&nbsp;&nbsp;Grupo Empresarial</button>
              </div>
          </div>
          <div class="col"></div>

      </div>
      
      <div class="card card-frame">

            <div class="row">
                <div class="col-sm">

                    <button class="btn btn-info" type="button" @click="handleActualizarRegistros" :disabled="disabledProcesoClientesGrupoEmpresarial.buttonActualizar">
                        <span>Actualizar</span>
                    </button>
                    &nbsp;
                    <button class="btn btn-info" type="button" @click="handleGuardarRegistros" :disabled="disabledProcesoClientesGrupoEmpresarial.buttonGuardar">
                        <span>Guardar</span>
                    </button>

                </div>

            </div>

      </div>

      <div class="table-responsive">
         <table class="table mb-0">
           <thead>
              <tr>
                  <th>
                      <div class="form-check">
                          <label class="text-uppercase font-weight-bolder opacity-7" for="defaultCheck1">
                              Seleccionar
                          </label>
                      </div>
                  </th>
                  <th v-for="(encabezado, index) in jsonData.encabezados" :key="index" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">{{encabezado}}</th>
              </tr>
          </thead>
          <tbody>
            <tr v-for="(registro, index) in jsonData.registros" :key="index">
                <td>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" name="cliente" :value="registro.idSysTSEmpresas" v-model="datosSeleccionados">
                    </div>
                </td>
                <td v-for="( dato, index ) in jsonData.camposMostrar" :key="index">
                    <h6 class="mb-0 text-xs">{{ registro[dato] }}</h6>
                </td>
            </tr>
          </tbody>
         </table>
      </div>

    </div>

    <div class="container select02" v-if="seleccionPest.seleccion02">

      <div class="row">

          <div class="col">

              <div class="form-group">
                  <label>Grupo Empresarial</label>
                  <select class="form-control" v-model="fieldSelectGrupoEmpresarialInternas" :disabled="disabledProcesoInternasGrupoEmpresarial.select" >
                      <option value="0">Selecciona una opcion.</option>
                      <option v-for="({idUnico, grupoEmpresarial}) in jsonGrupoEmpresarial.registros" :key="idUnico" :value="idUnico" >{{grupoEmpresarial}}</option>
                  </select>
              </div>

          </div>
          <div class="col">
              <div class="form-group">
                  <br>
                  <button type="button" class="btn btn-dark mb-2" @click="handleSeleccionarGrupoEmpresarialInternas" :disabled="disabledProcesoInternasGrupoEmpresarial.buttonSeleccionar">{{disabledProcesoInternasGrupoEmpresarial.textoBotonSeleccionar}}</button>
                  &nbsp;
                  <button type="button" class="btn btn-dark mb-2" @click="handleAbrirModalGrupoEmpresarial" ><i class="fa fa-pencil" style="color: white; font-size: 15px;"></i>&nbsp;&nbsp;Grupo Empresarial</button>
              </div>
          </div>
          <div class="col"></div>

      </div>
      
      <div class="card card-frame">

            <div class="row">
                <div class="col-sm">

                    <button class="btn btn-info" type="button" @click="handleActualizarRegistrosInternas" :disabled="disabledProcesoInternasGrupoEmpresarial.buttonActualizar">
                        <span>Actualizar</span>
                    </button>
                    &nbsp;
                    <button class="btn btn-info" type="button" @click="handleGuardarRegistrosInternas" :disabled="disabledProcesoInternasGrupoEmpresarial.buttonGuardar">
                        <span>Guardar</span>
                    </button>

                </div>

            </div>

      </div>

      <div class="table-responsive">
         <table class="table mb-0">
           <thead>
              <tr>
                  <th>
                      <div class="form-check">
                          <label class="text-uppercase font-weight-bolder opacity-7" for="defaultCheck1">
                              Seleccionar
                          </label>
                      </div>
                  </th>
                  <th v-for="(encabezado, index) in jsonDataInternas.encabezados" :key="index" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">{{encabezado}}</th>
              </tr>
          </thead>

          <tbody>

            <tr v-for="(registro, index) in jsonDataInternas.registros" :key="index">
                
                <td>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" name="cliente" :value="registro.idSysCPEmpresasRP" v-model="datosSeleccionadosInternas">
                    </div>
                </td>

                <td v-for="( dato, index ) in jsonDataInternas.camposMostrar" :key="index">

                    <h6 class="mb-0 text-xs">{{ registro[dato] }}</h6>

                </td>
            

            </tr>

          </tbody>

         </table>

      </div>

    </div>

    <div class="container select01" v-if="seleccionPest.seleccion03">
      
      <div class="card card-frame">

            <div class="row">
                <div class="col-sm">

                    <button class="btn btn-info" type="button" @click="handleActualizarRegistrosUsuarios">
                        <span>Actualizar</span>
                    </button>
                    &nbsp;
                    <button class="btn btn-info" type="button" @click="handleGuardarRegistrosUsuarios">
                        <span>Guardar</span>
                    </button>

                </div>

            </div>

      </div>

      <div class="table-responsive">

        <table class="table mb-0">

            <thead>
              <tr>
                  <th>
                      <div class="form-check">
                          <label class="text-uppercase font-weight-bolder opacity-7" for="defaultCheck1">
                              Seleccionar
                          </label>
                      </div>
                  </th>
                  <th v-for="(encabezado, index) in jsonDataUsuario.encabezados" :key="index" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">{{encabezado}}</th>
              </tr>
            </thead>

            <tbody>

              <tr v-for="(registro, index) in jsonDataUsuario.registros" :key="index">
                  
                  <td>
                      <div class="form-check">
                          <input class="form-check-input" type="checkbox" name="cliente" :value="registro.idUsuario" v-model="datosSeleccionadosUsuario">
                      </div>
                  </td>

                  <td v-for="( dato, index ) in jsonDataUsuario.camposMostrar" :key="index">

                      <h6 class="mb-0 text-xs">{{ registro[dato] }}</h6>

                  </td>
              

              </tr>

            </tbody>

        </table>

      </div>

    </div>



    <!-- Modal grupo empresarial  -->
    <comp-modal v-if="processModalGrupoEmpresarial.open" size="width: 1100px; height: 670px;" sizeCuerpo="height:600px">
      
      <template v-slot:encabezado>
        <label><h5>{{ processModalGrupoEmpresarial.title }}</h5></label>
      </template>

      <template v-slot:cuerpo>

        <div class="row">
          <div class="col">

            <div class="form-group">
              <label>Grupo Empresarial:</label>
              <input type="text" class="form-control" style="text-transform:uppercase;" v-model="fieldGrupoEmpresarial" />
            </div>

          </div>
          <div class="col">
            
            <div class="form-group">
              <label>Descripcion:</label>
              <input type="text" class="form-control" style="text-transform:uppercase;" v-model="fieldGrupoEmpresarialDescripcion" />
            </div>

          </div>
          <div class="col">
            
            <div class="form-group">
              <label>Siglas:</label>
              <input type="text" class="form-control" style="text-transform:uppercase" maxlength="3" v-model="fieldGrupoEmpresarialSiglas" />
            </div>

          </div>
          <div class="col">

            <div class="form-group">
              <br />
              <button type="button" class="btn btn-dark mb-2" :disabled="disableButtonGuardarGrupoEmpresarial" @click="handleGuardarDatosGrupoEmpresarial"><i class="fa fa-floppy-o" style="color: white; font-size: 15px;"></i></button>
              &nbsp;
              <button type="button" class="btn btn-dark mb-2" :disabled="disableButtonEditarGuardarGrupoEmpresarial" @click="handleGuardarDatosGrupoEmpresarial"><i class="fa fa-pencil" style="color: white; font-size: 15px;"></i>&nbsp;&nbsp;&nbsp;<i class="fa fa-floppy-o" style="color: white; font-size: 15px;"></i></button>
              &nbsp;
              <button type="button" class="btn btn-dark mb-2" :disabled="disableButtonCancelarGuardarGrupoEmpresarial" @click="handleCancelarDatosGrupoEmpresarial"><i class="fa fa-times" style="color: white; font-size: 15px;"></i></button>
              &nbsp;
              <button type="button" class="btn btn-dark mb-2" @click="handleCerrarModalGrupoEmpresarial" ><i class="fa fa-sign-out" style="color: white; font-size: 15px;"></i></button>
            </div>

          </div>

        </div>

        <div class="tablaGrupoEmpresas">
          <table class="table mb-0">
            <thead>
                <tr>
                    <th v-for="(encabezado, index) in jsonGrupoEmpresarial.encabezados" :key="index" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">{{encabezado}}</th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Opcion</th>
                </tr>
            </thead>
            <tbody>
              <tr v-for="(registro, index) in jsonGrupoEmpresarial.registros" :key="index">
                  <td v-for="( dato, index ) in jsonGrupoEmpresarial.camposMostrar" :key="index">
                      <h6 class="mb-0 text-xs">{{ registro[dato] }}</h6>
                  </td>
                  <td>
                    <button type="button" class="btn btn-dark btn-sm" @click="handleEditarDatosGrupoEmpresarial(registro)">Editar</button>
                  </td>
              </tr>
            </tbody>
          </table>
        </div>

      </template>

    </comp-modal>
    
    
  </div>

</template>

<script>
import proceso from './js/proceso';
import CompModal from '../../componentes/modal/modal.vue';
export default {
  name: "SamSuiteNomina",
  components: {CompModal},
  setup(){
    return{
      ...proceso()
    }
  }
}
</script>

<style scoped>
  @import './css/estilos.css';
</style>