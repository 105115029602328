import { ref, onBeforeMount, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';

const body = document.getElementsByTagName("body")[0];

const useNotFound = () => {
    const store = useStore();

    const encabezado = ref('Not Found');
    const texto = ref('Pagnia no encontrada');

    

    onBeforeMount( () => {
        store.commit('toggleEveryDisplay');
        store.commit('toggleHideConfig');
        body.classList.remove("bg-gray-100");
    } );

    onBeforeUnmount( () => {
        store.commit('toggleEveryDisplay');
        store.commit('toggleHideConfig');
        body.classList.add("bg-gray-100");
    } );

    return{

        encabezado,
        texto

    }
};

export default useNotFound;