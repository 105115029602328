import { ref, onBeforeMount, onBeforeUnmount } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { operacion } from '@/helpers/axios';
import { notify } from '@kyvg/vue3-notification';

const body = document.getElementsByTagName("body")[0];

const useAccesoCliente = () => {
    const route = useRoute();
    const store = useStore();
    const router = useRouter();

    const idUnico = ref(route.params.idUnico);
    const logo = ref('');
    const nombreGrupoEmpresarial = ref('');
    const activoAcceso = ref(0);
    const claveAcceso = ref('');

    onBeforeMount( () => {
        store.commit('toggleEveryDisplay');
        store.commit('toggleHideConfig');
        body.classList.remove("bg-gray-100");
    } );

    onBeforeUnmount( () => {
        store.commit('toggleEveryDisplay');
        store.commit('toggleHideConfig');
        body.classList.add("bg-gray-100");
    } );

    const handleVerificarAcceso = async() => {
        const data = await operacion(
            {
                numero : '51',
                proceso : '5',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'verificarAcceso',
                    PidUnico: idUnico.value
                },
                mostrarMensaje: true
            }
        );
        logo.value = data.tablas.Table[0].logoEmpresa;
        nombreGrupoEmpresarial.value = data.tablas.Table[0].grupoEmpresarial;
        activoAcceso.value = data.tablas.Table[0].activoAcceso;
        localStorage.clear();
    };
    handleVerificarAcceso();

    const iniciarSesion = async() => {
        const data = await operacion(
            {
                numero : '51',
                proceso : '5',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'iniciarSesion',
                    PidUnico: idUnico.value,
                    PclaveAcceso: claveAcceso.value
                },
                mostrarMensaje: true
            }
        );
        if(data.bandera === 0)
        {
            notify({
                title: "Acceso Cliente",
                text: data.mensaje
            });
            localStorage.setItem('permiso', 'C');
            localStorage.setItem('idUnico', idUnico.value)
            localStorage.setItem('siglas', data.tablas.Table1[0].siglas)
            localStorage.setItem('cliente', JSON.stringify(data.tablas.Table1[0]))
            router.replace({name: 'Cliente'}); 
        }
        else
        {
            notify({
                title: "Acceso Cliente",
                text: data.mensaje
            });
        }
    };


    return{
        idUnico,
        logo,
        nombreGrupoEmpresarial,
        activoAcceso,
        claveAcceso,

        iniciarSesion
    }

};


export default useAccesoCliente;