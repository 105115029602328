import { ref } from 'vue';
import { notify } from "@kyvg/vue3-notification";

import { operacion } from '@/helpers/axios';

const usePromotores = () => {

    const recordsPromotores = ref([]);
    const fieldIdUnicoPromotor = ref('');
    const fieldNombrePromotor = ref('');
    const fieldCorreoContacto = ref('');
    const fieldOtroDatoContacto = ref('');

    const refNombrePromotor = ref(null);

    const disabledBtnControl = ref({
        btnNuevoDisabled: false,
        btnEditarDisabled: true,
        btnCancelarDisabled: true
    });
    const disabledForm = ref(true);


    // HANDLE'S

    const handleTablaPromotores = async() => {
        const data = await operacion(
            {
                numero : '51',
                proceso : '3',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'tablaPromotores'
                },
                mostrarMensaje: false
            }
        );
        
        recordsPromotores.value = {
            encabezados: ['Nombre Completo', 'Correo Contacto', 'Otro Dato Contacto'],
            camposMostrar: ['nombreCompleto', 'correoContacto', 'otroDatoContacto'],
            registros: data.tablas.Table1,
            opcionesRegistro: false,
            opcionesRegistroActualizar: true,
            opcionesRegistroGuardar: true
        };
    };
    handleTablaPromotores();

    const handleNuevo = () => {
        disabledBtnControl.value = {
            btnNuevoDisabled: true,
            btnEditarDisabled: true,
            btnCancelarDisabled: false
        };
        disabledForm.value = false;
    };

    const handleEditar = () => {
        disabledForm.value = false;
    };

    const handleCancelar = () => {
        disabledBtnControl.value = {
            btnNuevoDisabled: false,
            btnEditarDisabled: true,
            btnCancelarDisabled: true
        };
        disabledForm.value = true;
        fieldIdUnicoPromotor.value = '';
        fieldNombrePromotor.value = '';
        fieldCorreoContacto.value = '';
        fieldOtroDatoContacto.value = '';
    };

    const handleGuardarPromotor = async() => {

        if(fieldIdUnicoPromotor.value === '')
        {
            const data = await operacion(
                {
                    numero : '51',
                    proceso : '3',
                    opcionTD : 'TODO',
                    parametros : true,
                    datosReceptor : 
                    {
                        opcion: 'nuevoPromotor',
                        PnombreCompleto: fieldNombrePromotor.value,
                        PCorreoContacto: fieldCorreoContacto.value,
                        POtroDatoContacto: fieldOtroDatoContacto.value
                    },
                    mostrarMensaje: false
                }
            );
            if(data.bandera === 0)
            {
                notify({
                    title: "Promotores",
                    text: "El registro se realizo con exito.",
                });
            }
            else
            {
                notify({
                    title: "Promotores",
                    text: data.mensaje
                });
            }
        }
        else
        {
            const data = await operacion(
                {
                    numero : '51',
                    proceso : '3',
                    opcionTD : 'TODO',
                    parametros : true,
                    datosReceptor : 
                    {
                        opcion: 'actualizarPromotor',
                        PidUnicoPromotor: fieldIdUnicoPromotor.value,
                        PnombreCompleto: fieldNombrePromotor.value,
                        PCorreoContacto: fieldCorreoContacto.value,
                        POtroDatoContacto: fieldOtroDatoContacto.value
                    },
                    mostrarMensaje: false
                }
            );
            if(data.bandera === 0)
            {
                notify({
                    title: "Promotores",
                    text: "La actualizacion se realizo con exito.",
                });
            }
            else
            {
                notify({
                    title: "Promotores",
                    text: data.mensaje
                });
            }
        }

        handleCancelar();
        handleTablaPromotores();
    };

    const handleSelectDato = (dato) => {
        fieldIdUnicoPromotor.value = dato.idUnicoPromotor;
        fieldNombrePromotor.value = dato.nombreCompleto;
        fieldCorreoContacto.value = dato.correoContacto;
        fieldOtroDatoContacto.value = dato.otroDatoContacto;
        disabledBtnControl.value = {
            btnNuevoDisabled: true,
            btnEditarDisabled: false,
            btnCancelarDisabled: false
        };
    };

    return{
        recordsPromotores,
        fieldNombrePromotor,
        fieldCorreoContacto,
        fieldOtroDatoContacto,
        refNombrePromotor,
        disabledBtnControl,
        disabledForm,

        handleNuevo,
        handleEditar,
        handleCancelar,
        handleGuardarPromotor,
        handleSelectDato
    }
};


export default usePromotores;