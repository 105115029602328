import { ref, watch } from 'vue';
import { isNumber, isDecimal, isDecimal02, imagenToBase64, formatNumero, formatoMexico } from '@/helpers/general';
import { operacion } from '@/helpers/axios';
import { notify } from '@kyvg/vue3-notification';
import sin_logo from '../../../../assets/img/sin_logo.png';

// convertirFecha, convertirFechaMes
// archivosApi
// import xlsxParser from 'xlsx-parse-json';
// const idUnicoUsuario = ref(localStorage.getItem('idUnico'));

const shortid =  require('shortid');

const useClientes = () => {

    // Filtro Principal Declaraciones

    const jsonGrupoEmpresarial = ref([]);
    const fieldSelectGrupoEmpresarial = ref('0');
    const processBotonSeleccionarGrupoEmpresarial = ref({
        texto: 'Seleccionar',
        seleccionado: false
    });

    const processSeleccionPest = ref({
        seleccion01: true,
        seleccion02: false,
        seleccion03: false,
        seleccion04: false,
        seleccion05: false,
        seleccion06: false,
        seleccion07: false,
        seleccion08: false,
        seleccion09: false,
        seleccion10: false
    });

    // Informacion general declaraciones
    const fieldFormDatosGenerales = ref({
        idUnico: '',
        grupoEmpresarial: '',
        descripcion: '',
        ultimoMovimientoParametros: '',
        ultimaSolicitud: '',
        saldoAFavor: '',
        financiamiento: '',
        claveAcceso: '',
        activoAcceso: 0,
        logo: sin_logo
    });
    const jsonTablaClientes = ref([]);

    // Parametros Declaraciones
    const fieldSelectPlantillaSeleccionada = ref('S/D');
    const fieldNombrePlantillaSeleccionada = ref('');
    const jsonPlantillas = ref({});
    const processModalPlantilla = ref({
        open: false,
        title: 'Catalogo de plantillas'
    });

    const fieldSelectPromotores = ref('S/D');
    const jsonPromotores = ref([]);
    const fieldPorcentajePromotores = ref('0');
    const fieldOperacionPorcentajePromotores = ref('0');
    const jsonPromotoresComision = ref([]);
    const jsonEmpresasPagadoras = ref([]);
    const fieldEmpresaPagadora = ref('S/D');

    const fieldPorcentajeComisionPorDepositoMovAsimilado = ref('0');
    const fieldPorcentajeComisionPorDepositoMovNormal = ref('0');
    const fieldPorcentajeComisionOperacion = ref('');

    const fieldPorcentajeComision01Asimilado = ref('0');
    const fieldPorcentajeComision01Normal = ref('0');
    const fieldPorcentajeComision01Operacion = ref('');

    const fieldOperacionTotalDisponibleCliente = ref('0');
    const fieldComisionPesosPorcentaje = ref('0');
    const fieldComisionPesosOperacion = ref('0');
    const fieldActivarComisionPesosExtra = ref(false);
    const fieldComisionPesosExtraPorcentaje = ref('0');
    const fieldComisionPesosExtraOperacion = ref('0');
    const fieldIVAPorcentaje = ref('0');
    const fieldIVAOperacion = ref('0');
    const fieldDevolucionIVAPorcentaje = ref('0');
    const fieldDevolucionIVAOperacion = ref('0');
    const fieldRetornoPorcentaje = ref('0');
    const fieldRetornoOperacion = ref('0');

    // Parametros modal plantillas
    const fieldPlantillaIdUnico = ref('');
    const fieldNombrePlantilla = ref('');
    const fieldDescripcionPlantilla = ref('');
    const disableButtonGuardarPlantilla = ref(false);
    const disableButtonEditarGuardarPlantilla = ref(true);
    const disableButtonCancelarGuardarPlantilla = ref(true);
    const jsonParametrosDatosPlantilla = ref({});

    //Beneficiarios declaraciones
    const modalGrupos = ref(false);
    const jsonGrupos = ref([]);

    // Beneficiarios Modal grupos declaraciones
    const idUnicoGrupo = ref('');
    const fieldNombreGrupo = ref('');
    const fieldDescripcionGrupo = ref('');
    const disableButtonGuardarGrupo = ref(false);
    const disableButtonEditarGuardarGrupo = ref(true);
    const disableButtonCancelarGuardarGrupo = ref(true);

    // Beneficiarios Modal Beneficiarios declaraciones

    const modalBeneficiarios = ref(false);
    const jsonBeneficiarios = ref([]);
    const jsonBancos = ref([]);
    const jsonBancosSantander = ref([]);
    const idUnicoBeneficiario = ref('');
    const fieldBeneficiarioTipoAlta = ref('Persona');
    const fieldBeneficiarioGrupo = ref('S/D');
    const fieldBeneficiarioUsoCFDI = ref('P01');
    const fieldBeneficiarioApellidoPaterno = ref('');
    const fieldBeneficiarioApellidoMaterno = ref('');
    const fieldBeneficiarioNombre = ref('');
    const fieldBeneficiarioEmpresaRazonSocial = ref('');
    const fieldBeneficiarioRFC = ref('');
    const fieldBeneficiarioCURP = ref('');
    const fieldBeneficiarioCuentaBanco = ref('');
    const fieldBeneficiarioClabeBanco = ref('');
    const fieldBeneficiarioBanco = ref('S/D');
    const fieldBeneficiarioNumeroInstitutoBancoSantander = ref('');
    const fieldBeneficiarioClaveTransferenciaBancoSantander = ref('');
    const fieldBeneficiarioNumeroInstitutoKuspit = ref('');
    const fieldBeneficiarioActivo = ref(1);
    const disableButtonBeneficiarioGuardar = ref(true);

    //Facturacion Declaraciones
    const jsonEmpresasInternas = ref([]);
    const jsonCatalogosSATCFDI = ref([]);
    const jsonCatalogosSerieEmpresas = ref([])
    const fieldEmpresasConcepto = ref('S/D');

    //Facturacion modal conceptos
    const modalConceptos = ref(false);
    const fieldFacturaProdServ = ref('');
    const fieldFacturaDescripcion = ref('');
    const fieldFacturaClaveUnidad = ref('');
    const fieldSelectFacturaMetodoPago = ref('S/D');
    const fieldSelectFacturaFormaPago = ref('S/D');
    const disabledButtonGrabarFacturaConcepto = ref(true);
    const disabledBotonAgregarConcepto = ref(true);
    const jsonMetodoPago = ref([]);
    const jsonFormaPago = ref([]);
    const jsonProdServ = ref([])
    const jsonClaveUnidad = ref([]);
    const jsonConceptosFactura = ref([]);
    const fieldIdUnicoConcepto = ref('');

    //Financiamiento Declaraciones
    const fieldActivarFinanciamiento = ref(false);
    const fieldFinanciamiento = ref('0.00');

    //Bancos Layout Declaraciones
    const jsonBancosEmpresa = ref([]);

    //Correos Declaraciones
    const fieldSelectCorreo = ref('S/D');
    const jsonCorreos = ref([]);
    const jsonCorreosAsignados = ref([]);


    //#region Filtro Principal

        // Filtro Principal Handle

        const handleDatosGrupoEmpresarial = async() => {
            const data = await operacion(
                {
                    numero : '51',
                    proceso : '5',
                    opcionTD : 'TODO',
                    parametros : true,
                    datosReceptor : 
                    {
                        opcion: 'tablaGrupoEmpresarial'
                    },
                    mostrarMensaje: true
                }
            );
            jsonGrupoEmpresarial.value = data.tablas.Table1
        };
        handleDatosGrupoEmpresarial();

        const handleSelect = (selec) => {

            if(fieldSelectGrupoEmpresarial.value !== 0 && processBotonSeleccionarGrupoEmpresarial.value.seleccionado)
            {
                if(selec === '01')
                {
                    processSeleccionPest.value = {
                        seleccion01: true,
                        seleccion02: false,
                        seleccion03: false,
                        seleccion04: false,
                        seleccion05: false,
                        seleccion06: false,
                        seleccion07: false,
                        seleccion08: false,
                        seleccion09: false,
                        seleccion10: false
                    };
                }
                else if(selec === '02')
                {
                    processSeleccionPest.value = {
                        seleccion01: false,
                        seleccion02: true,
                        seleccion03: false,
                        seleccion04: false,
                        seleccion05: false,
                        seleccion06: false,
                        seleccion07: false,
                        seleccion08: false,
                        seleccion09: false,
                        seleccion10: false
                    };
                }
                else if(selec === '03')
                {
                    processSeleccionPest.value = {
                        seleccion01: false,
                        seleccion02: false,
                        seleccion03: true,
                        seleccion04: false,
                        seleccion05: false,
                        seleccion06: false,
                        seleccion07: false,
                        seleccion08: false,
                        seleccion09: false,
                        seleccion10: false
                    };
                }
                else if(selec === '04')
                {
                    processSeleccionPest.value = {
                        seleccion01: false,
                        seleccion02: false,
                        seleccion03: false,
                        seleccion04: true,
                        seleccion05: false,
                        seleccion06: false,
                        seleccion07: false,
                        seleccion08: false,
                        seleccion09: false,
                        seleccion10: false
                    };
                }
                else if(selec === '05')
                {
                    processSeleccionPest.value = {
                        seleccion01: false,
                        seleccion02: false,
                        seleccion03: false,
                        seleccion04: false,
                        seleccion05: true,
                        seleccion06: false,
                        seleccion07: false,
                        seleccion08: false,
                        seleccion09: false,
                        seleccion10: false
                    };
                }
                else if(selec === '06')
                {
                    processSeleccionPest.value = {
                        seleccion01: false,
                        seleccion02: false,
                        seleccion03: false,
                        seleccion04: false,
                        seleccion05: false,
                        seleccion06: true,
                        seleccion07: false,
                        seleccion08: false,
                        seleccion09: false,
                        seleccion10: false
                    };
                }
                else if(selec === '07')
                {
                    processSeleccionPest.value = {
                        seleccion01: false,
                        seleccion02: false,
                        seleccion03: false,
                        seleccion04: false,
                        seleccion05: false,
                        seleccion06: false,
                        seleccion07: true,
                        seleccion08: false,
                        seleccion09: false,
                        seleccion10: false
                    };
                }
                else if(selec === '08')
                {
                    processSeleccionPest.value = {
                        seleccion01: false,
                        seleccion02: false,
                        seleccion03: false,
                        seleccion04: false,
                        seleccion05: false,
                        seleccion06: false,
                        seleccion07: false,
                        seleccion08: true,
                        seleccion09: false,
                        seleccion10: false
                    };
                }
                else if(selec === '09')
                {
                    processSeleccionPest.value = {
                        seleccion01: false,
                        seleccion02: false,
                        seleccion03: false,
                        seleccion04: false,
                        seleccion05: false,
                        seleccion06: false,
                        seleccion07: false,
                        seleccion08: false,
                        seleccion09: true,
                        seleccion10: false
                    };
                }
                else if(selec === '10')
                {
                    processSeleccionPest.value = {
                        seleccion01: false,
                        seleccion02: false,
                        seleccion03: false,
                        seleccion04: false,
                        seleccion05: false,
                        seleccion06: false,
                        seleccion07: false,
                        seleccion08: false,
                        seleccion09: false,
                        seleccion10: true
                    };
                }
            }
            else
            {
                notify({
                    title: "Clientes",
                    text: "Debes de seleccionar una empresa primero",
                  });
            }
        };

        const handleProcesoBotonSeleccionarGrupoEmpresarial = async() => {
            processSeleccionPest.value = {
                seleccion01: true,
                seleccion02: false,
                seleccion03: false,
                seleccion04: false,
                seleccion05: false,
                seleccion06: false,
                seleccion07: false,
                seleccion08: false,
                seleccion09: false,
                seleccion10: false
            };
            jsonTablaClientes.value = [];
            jsonPlantillas.value = {};
            jsonPromotores.value = [];
            jsonEmpresasPagadoras.value = [];
            jsonBeneficiarios.value = [];
            jsonGrupos.value = [];
            jsonBancos.value = [];
            jsonCatalogosSATCFDI.value = [];
            fieldEmpresaPagadora.value = 'S/D';
            jsonConceptosFactura.value = [];
            fieldEmpresasConcepto.value = 'S/D';
            fieldActivarFinanciamiento.value = false;
            fieldFinanciamiento.value = '0.00';
            jsonBancosEmpresa.value = [];
            jsonCorreos.value = [];
            jsonCorreosAsignados.value = [];
            fieldEmpresaPagadora.value = 'S/D';
            if(fieldSelectGrupoEmpresarial.value !== '0')
            {
                if(processBotonSeleccionarGrupoEmpresarial.value.seleccionado)
                {
                    processBotonSeleccionarGrupoEmpresarial.value = {
                        texto: 'Seleccionar',
                        seleccionado: false
                    };
                    fieldSelectGrupoEmpresarial.value = '0';
                    fieldFormDatosGenerales.value = {
                        idUnico: '',
                        grupoEmpresarial: '',
                        descripcion: '',
                        ultimoMovimientoParametros: '',
                        ultimaSolicitud: '',
                        saldoAFavor: '',
                        credito: '',
                        claveAcceso: '',
                        activoAcceso: 0,
                        logo: sin_logo
                    }
                }
                else
                {
                    processBotonSeleccionarGrupoEmpresarial.value = {
                        texto: 'Editar',
                        seleccionado: true
                    };

                    const data = await operacion(
                        {
                            numero : '51',
                            proceso : '5',
                            opcionTD : 'TODO',
                            parametros : true,
                            datosReceptor : 
                            {
                                opcion: 'seleccionarDatosGrupoempresarial',
                                PidUnico: fieldSelectGrupoEmpresarial.value
                            },
                            mostrarMensaje: true
                        }
                    );

                    if(data.bandera === 0)
                    {
                        const ifLogo = data.tablas.Table1[0].logoEmpresa === '' || data.tablas.Table1[0].logoEmpresa === null ? sin_logo : data.tablas.Table1[0].logoEmpresa;
                        fieldFormDatosGenerales.value = {
                            idUnico: data.tablas.Table1[0].idUnico,
                            grupoEmpresarial: data.tablas.Table1[0].grupoEmpresarial,
                            descripcion: data.tablas.Table1[0].descripcion,
                            ultimoMovimientoParametros: data.tablas.Table1[0].ultimoMovimientoParametros,
                            ultimaSolicitud: data.tablas.Table1[0].ultimaSolicitud,
                            saldoAFavor: formatoMexico(Number(data.tablas.Table1[0].saldoAFavor)),
                            financiamiento: formatoMexico(Number(data.tablas.Table1[0].financiamiento)),
                            claveAcceso: data.tablas.Table1[0].claveAcceso,
                            activoAcceso: data.tablas.Table1[0].activoAcceso,
                            logo: ifLogo
                        };
                        fieldActivarFinanciamiento.value = data.tablas.Table1[0].activoFinanciamiento === 1 ? true : false;
                        fieldFinanciamiento.value = formatoMexico(Number(data.tablas.Table1[0].financiamiento));
                        jsonTablaClientes.value = data.tablas.Table2;
                        jsonPlantillas.value = [];
                        jsonParametrosDatosPlantilla.value = data.tablas.Table3;
                
                        if(data.tablas.Table3.find( e => e.seleccionado === 'Activo'))
                        {
                            const { idUnicoPlantilla, nombrePlantilla } = data.tablas.Table3.find( e => e.seleccionado === 'Activo');
                            fieldSelectPlantillaSeleccionada.value = idUnicoPlantilla;
                            fieldNombrePlantillaSeleccionada.value = nombrePlantilla;
                            const { jsonGenerales } = jsonParametrosDatosPlantilla.value.find( e => e.idUnicoPlantilla === idUnicoPlantilla );
                            if(jsonGenerales !== null)
                            {
                                const dataG = JSON.parse(jsonGenerales);
                                fieldSelectPromotores.value = dataG.fieldSelectPromotores;
                                fieldPorcentajePromotores.value = dataG.fieldPorcentajePromotores;
                                fieldOperacionPorcentajePromotores.value = dataG.fieldOperacionPorcentajePromotores;

                               
                                fieldPorcentajeComisionPorDepositoMovAsimilado.value = dataG.fieldPorcentajeComisionPorDepositoMovAsimilado;
                                fieldPorcentajeComisionPorDepositoMovNormal.value = dataG.fieldPorcentajeComisionPorDepositoMovNormal;
                                fieldPorcentajeComisionOperacion.value = dataG.fieldPorcentajeComisionOperacion;
                                
                                fieldPorcentajeComision01Asimilado.value = dataG.fieldPorcentajeComision01Asimilado;
                                fieldPorcentajeComision01Normal.value = dataG.fieldPorcentajeComision01Normal;
                                fieldPorcentajeComision01Operacion.value = dataG.fieldPorcentajeComision01Operacion;
                                
                                fieldOperacionTotalDisponibleCliente.value = dataG.fieldOperacionTotalDisponibleCliente;
                                fieldComisionPesosPorcentaje.value = dataG.fieldComisionPesosPorcentaje;
                                fieldComisionPesosOperacion.value = dataG.fieldComisionPesosOperacion;
                                
                                fieldActivarComisionPesosExtra.value = dataG.fieldActivarComisionPesosExtra;
                                fieldComisionPesosExtraPorcentaje.value = dataG.fieldComisionPesosExtraPorcentaje;
                                fieldComisionPesosExtraOperacion.value = dataG.fieldComisionPesosExtraOperacion;
                                
                                fieldIVAPorcentaje.value = dataG.fieldIVAPorcentaje;
                                fieldIVAOperacion.value = dataG.fieldIVAOperacion;
                                
                                fieldDevolucionIVAPorcentaje.value = dataG.fieldDevolucionIVAPorcentaje;
                                fieldDevolucionIVAOperacion.value = dataG.fieldDevolucionIVAOperacion;

                                fieldRetornoPorcentaje.value = dataG.fieldRetornoPorcentaje;
                                fieldRetornoOperacion.value = dataG.fieldRetornoOperacion;
                            }
                            else
                            {
                                fieldSelectPromotores.value = 'S/D';
                                fieldPorcentajePromotores.value = '0';
                                fieldOperacionPorcentajePromotores.value = '0';
                               
                                fieldPorcentajeComisionPorDepositoMovAsimilado.value = '0';
                                fieldPorcentajeComisionPorDepositoMovNormal.value = '0';
                                fieldPorcentajeComisionOperacion.value = '0';
                                
                                fieldPorcentajeComision01Asimilado.value = '0';
                                fieldPorcentajeComision01Normal.value = '0';
                                fieldPorcentajeComision01Operacion.value = '0';
                                
                                fieldOperacionTotalDisponibleCliente.value = '0';
                                fieldComisionPesosPorcentaje.value = '0';
                                fieldComisionPesosOperacion.value = '0';
                                
                                fieldActivarComisionPesosExtra.value = false;
                                fieldComisionPesosExtraPorcentaje.value = '0';
                                fieldComisionPesosExtraOperacion.value = '0';
                                
                                fieldIVAPorcentaje.value = '0';
                                fieldIVAOperacion.value = '0';
                                
                                fieldDevolucionIVAPorcentaje.value = '0';
                                fieldDevolucionIVAOperacion.value = '0';

                                fieldRetornoPorcentaje.value = '0';
                                fieldRetornoOperacion.value = '0';
                            }
                        }
                        else
                        {
                            fieldSelectPlantillaSeleccionada.value = 'S/D';
                            fieldNombrePlantillaSeleccionada.value = '';
                            fieldSelectPromotores.value = 'S/D';
                            fieldPorcentajePromotores.value = '0';
                            fieldOperacionPorcentajePromotores.value = '0';
                            
                            fieldPorcentajeComisionPorDepositoMovAsimilado.value = '0';
                            fieldPorcentajeComisionPorDepositoMovNormal.value = '0';
                            fieldPorcentajeComisionOperacion.value = '0';
                            
                            fieldPorcentajeComision01Asimilado.value = '0';
                            fieldPorcentajeComision01Normal.value = '0';
                            fieldPorcentajeComision01Operacion.value = '0';
                            
                            fieldOperacionTotalDisponibleCliente.value = '0';
                            fieldComisionPesosPorcentaje.value = '0';
                            fieldComisionPesosOperacion.value = '0';
                            
                            fieldActivarComisionPesosExtra.value = false;
                            fieldComisionPesosExtraPorcentaje.value = '0';
                            fieldComisionPesosExtraOperacion.value = '0';
                            
                            fieldIVAPorcentaje.value = '0';
                            fieldIVAOperacion.value = '0';
                            
                            fieldDevolucionIVAPorcentaje.value = '0';
                            fieldDevolucionIVAOperacion.value = '0';

                            fieldRetornoPorcentaje.value = '0';
                            fieldRetornoOperacion.value = '0';
                        }
                
                        jsonPlantillas.value = {
                            encabezados: ['Nombre Plantilla', 'Descripcion Plantilla', 'Seleccionado'],
                            camposMostrar: ['nombrePlantilla', 'descripcionPlantilla', 'seleccionado'],
                            registros: data.tablas.Table3,
                            opcionesRegistro: false,
                            opcionesRegistroActualizar: true,
                            opcionesRegistroGuardar: true
                        };

                        jsonBeneficiarios.value = data.tablas.Table4;
                        jsonGrupos.value = data.tablas.Table5;
                        jsonCatalogosSATCFDI.value = data.tablas.Table6;
                        jsonBancos.value = data.tablas.Table7;
                        jsonBancosSantander.value = data.tablas.Table8;
                        jsonEmpresasInternas.value = data.tablas.Table9;
                        jsonCatalogosSerieEmpresas.value = data.tablas.Table10;
                        jsonConceptosFactura.value = data.tablas.Table11.length > 0 ? JSON.parse( data.tablas.Table11[0].jsonConceptosFactura ) : [] ;
                        jsonCorreos.value = data.tablas.Table12;
                        jsonCorreosAsignados.value = data.tablas.Table13.length > 0 ? JSON.parse( data.tablas.Table13[0].jsonCorreoAsignado ) : [] ;
                        jsonEmpresasPagadoras.value = data.tablas.Table14;
                        jsonPromotores.value = data.tablas.Table15;
                        fieldEmpresaPagadora.value = data.tablas.Table16.length > 0 ? data.tablas.Table16[0].idSysCPEmpresasRP : 'S/D';
                    }
                }
            }
            else
            {
                notify({
                    title: "Clientes",
                    text: "Debes de elegir un grupo empresarial.",
                });
            }
        };

        const handleDataCatalogosFacturacion = async() => {
            const data = await operacion(
                {
                    numero : '51',
                    proceso : '6',
                    opcionTD : 'TODO',
                    parametros : true,
                    datosReceptor : 
                    {
                        opcion: 'catalogosSat'
                    },
                    mostrarMensaje: true
                }
            );
            if(data.bandera === 0)
            {
                jsonMetodoPago.value = data.tablas.Table1;
                jsonFormaPago.value = data.tablas.Table2;
                jsonProdServ.value = data.tablas.Table3;
                jsonClaveUnidad.value = data.tablas.Table4;
            }
            else
            {
                notify({
                    title: "Clientes",
                    text: data.mensaje
                });
            }
        };
        handleDataCatalogosFacturacion();
        
    //#endregion


    //#region Informacion general

        // Informacion general handle
        const handleActivarDesactivarAcceso = async() => {
            const data = await operacion(
                {
                    numero : '51',
                    proceso : '5',
                    opcionTD : 'TODO',
                    parametros : true,
                    datosReceptor : 
                    {
                        opcion: 'activarDesactivar',
                        PidUnico: fieldSelectGrupoEmpresarial.value
                    },
                    mostrarMensaje: true
                }
            );
    
            if(data.bandera === 0)
            {
                notify({
                    title: "Empresa General",
                    text: data.mensaje
                });
                fieldFormDatosGenerales.value.activoAcceso = data.tablas.Table1[0].activoAcceso
            }
            else
            {
                notify({
                    title: "Empresa General",
                    text: data.mensaje
                });
            }
        };

        const handleUpdateClaveAcceso = async() => {
            const data = await operacion(
                {
                    numero : '51',
                    proceso : '5',
                    opcionTD : 'TODO',
                    parametros : true,
                    datosReceptor : 
                    {
                        opcion: 'claveAcceso',
                        PidUnico: fieldSelectGrupoEmpresarial.value,
                        PclaveAcceso: fieldFormDatosGenerales.value.claveAcceso
                    },
                    mostrarMensaje: true
                }
            );
    
            if(data.bandera === 0)
            {
                notify({
                    title: "Empresa General",
                    text: data.mensaje
                });
            }
            else
            {
                notify({
                    title: "Empresa General",
                    text: data.mensaje
                });
            }
        };
    
        const handleUpdateLogoEmpresa = async({target}) => {
            const base64 = await imagenToBase64(target.files[0]);
            const data = await operacion(
                {
                    numero : '51',
                    proceso : '5',
                    opcionTD : 'TODO',
                    parametros : true,
                    datosReceptor : 
                    {
                        opcion: 'logoEmpresa',
                        PidUnico: fieldSelectGrupoEmpresarial.value,
                        PlogoEmpresa: base64.base64
                    },
                    mostrarMensaje: true
                }
            );
    
            if(data.bandera === 0)
            {
                notify({
                    title: "Empresa General",
                    text: data.mensaje
                });
                fieldFormDatosGenerales.value.logo = data.tablas.Table1[0].logoEmpresa;
            }
            else
            {
                notify({
                    title: "Empresa General",
                    text: data.mensaje
                });
            }
        };

    //#endregion


    //#region Parametros

        // Parametros Handle

        const handleSeleccionPlantilla = async() => {
            if( fieldSelectPlantillaSeleccionada.value !== 'S/D' )
            {
                const data = await operacion(
                    {
                        numero : '51',
                        proceso : '7',
                        opcionTD : 'TODO',
                        parametros : true,
                        datosReceptor : 
                        {
                            opcion: 'actualizarSeleccionPlantilla',
                            PidUnicoGrupoEmpresarial: fieldSelectGrupoEmpresarial.value,
                            PidUnicoPlantilla: fieldSelectPlantillaSeleccionada.value
                        },
                        mostrarMensaje: true
                    }
                );
    
                if(data.bandera === 0)
                {
                    fieldNombrePlantillaSeleccionada.value = data.tablas.Table1.find( e => e.seleccionado === 'Activo' ).nombrePlantilla;
                    notify({
                        title: "Plantillas",
                        text: data.mensaje
                    });
                }
                else
                {
                    notify({
                        title: "Plantillas",
                        text: data.mensaje
                    });
                }
            }
            else
            {
                notify({
                    title: "Plantillas",
                    text: 'Favor de elegir una plantilla.'
                });
            }

        };

        const handleGrabarParametrosDatosPlantilla = async() => {
            if( fieldSelectPlantillaSeleccionada.value !== 'S/D' )
            {
                let parametrosGenerales = {
                    fieldSelectPromotores: fieldSelectPromotores.value,
                    fieldPorcentajePromotores: fieldPorcentajePromotores.value,
                    fieldOperacionPorcentajePromotores: fieldOperacionPorcentajePromotores.value,
                    
                    fieldPorcentajeComisionPorDepositoMovAsimilado: fieldPorcentajeComisionPorDepositoMovAsimilado.value,
                    fieldPorcentajeComisionPorDepositoMovNormal: fieldPorcentajeComisionPorDepositoMovNormal.value,
                    fieldPorcentajeComisionOperacion: fieldPorcentajeComisionOperacion.value,
                    
                    fieldPorcentajeComision01Asimilado: fieldPorcentajeComision01Asimilado.value,
                    fieldPorcentajeComision01Normal: fieldPorcentajeComision01Normal.value,
                    fieldPorcentajeComision01Operacion: fieldPorcentajeComision01Operacion.value,
                    
                    fieldOperacionTotalDisponibleCliente: fieldOperacionTotalDisponibleCliente.value,
                    fieldComisionPesosPorcentaje: fieldComisionPesosPorcentaje.value,
                    fieldComisionPesosOperacion: fieldComisionPesosOperacion.value,
                    
                    fieldActivarComisionPesosExtra: fieldActivarComisionPesosExtra.value,
                    fieldComisionPesosExtraPorcentaje: fieldComisionPesosExtraPorcentaje.value,
                    fieldComisionPesosExtraOperacion: fieldComisionPesosExtraOperacion.value,
                    
                    fieldIVAPorcentaje: fieldIVAPorcentaje.value,
                    fieldIVAOperacion: fieldIVAOperacion.value,
                    
                    fieldDevolucionIVAPorcentaje: fieldDevolucionIVAPorcentaje.value,
                    fieldDevolucionIVAOperacion: fieldDevolucionIVAOperacion.value,

                    fieldRetornoPorcentaje: fieldRetornoPorcentaje.value,
                    fieldRetornoOperacion: fieldRetornoOperacion.value
                };
        
                const data = await operacion(
                    {
                        numero : '51',
                        proceso : '7',
                        opcionTD : 'TODO',
                        parametros : true,
                        datosReceptor : 
                        {
                            opcion: 'guardarParametros',
                            PidUnicoGrupoEmpresarial: fieldSelectGrupoEmpresarial.value,
                            PidUnicoPlantilla: fieldSelectPlantillaSeleccionada.value,
                            PparametrosGenerales: JSON.stringify(parametrosGenerales)
                        },
                        mostrarMensaje: true
                    }
                );
        
                if(data.bandera === 0)
                {
                    notify({
                        title: "Plantilla parametros",
                        text: data.mensaje
                    });
                }
                else
                {
                    notify({
                        title: "Plantillas parametros",
                        text: data.mensaje
                    });
                }
            }
            else
            {
                notify({
                    title: "Plantillas",
                    text: 'Favor de elegir una plantilla.'
                });
            }
        };

        const handleAbrirPlantilla = () => {
            processModalPlantilla.value = {
                open: true,
                title: 'Catalogo de Plantillas'
            };
        };
    
        const handleCerrarModalPlantilla = () => {
            processModalPlantilla.value = {
                open: false,
                title: 'Catalogo de Plantillas'
            };
            handleCancelarDatosPlantilla();
        };

        const handleCancelarDatosPlantilla = () => {
            fieldPlantillaIdUnico.value = '';
            fieldNombrePlantilla.value = '';
            fieldDescripcionPlantilla.value = '';
            disableButtonGuardarPlantilla.value = false;
            disableButtonEditarGuardarPlantilla.value = true;
            disableButtonCancelarGuardarPlantilla.value = true;
        };

        const handleDatosPlantillas = async() => {
            
           const { jsonGenerales } = jsonParametrosDatosPlantilla.value.find( e => e.idUnicoPlantilla === fieldSelectPlantillaSeleccionada.value);
           if(jsonGenerales !== null)
           {
               const dataG = JSON.parse(jsonGenerales);
               fieldSelectPromotores.value = dataG.fieldSelectPromotores;
               fieldPorcentajePromotores.value = dataG.fieldPorcentajePromotores;
               fieldOperacionPorcentajePromotores.value = dataG.fieldOperacionPorcentajePromotores;
               
               fieldPorcentajeComisionPorDepositoMovAsimilado.value = dataG.fieldPorcentajeComisionPorDepositoMovAsimilado;
               fieldPorcentajeComisionPorDepositoMovNormal.value = dataG.fieldPorcentajeComisionPorDepositoMovNormal;
               fieldPorcentajeComisionOperacion.value = dataG.fieldPorcentajeComisionOperacion;
               
               fieldPorcentajeComision01Asimilado.value = dataG.fieldPorcentajeComision01Asimilado;
               fieldPorcentajeComision01Normal.value = dataG.fieldPorcentajeComision01Normal;
               fieldPorcentajeComision01Operacion.value = dataG.fieldPorcentajeComision01Operacion;
               
               fieldOperacionTotalDisponibleCliente.value = dataG.fieldOperacionTotalDisponibleCliente;
               fieldComisionPesosPorcentaje.value = dataG.fieldComisionPesosPorcentaje;
               fieldComisionPesosOperacion.value = dataG.fieldComisionPesosOperacion;
               
               fieldActivarComisionPesosExtra.value = dataG.fieldActivarComisionPesosExtra;
               fieldComisionPesosExtraPorcentaje.value = dataG.fieldComisionPesosExtraPorcentaje;
               fieldComisionPesosExtraOperacion.value = dataG.fieldComisionPesosExtraOperacion;
               
               fieldIVAPorcentaje.value = dataG.fieldIVAPorcentaje;
               fieldIVAOperacion.value = dataG.fieldIVAOperacion;
               
               fieldDevolucionIVAPorcentaje.value = dataG.fieldDevolucionIVAPorcentaje;
               fieldDevolucionIVAOperacion.value = dataG.fieldDevolucionIVAOperacion;

               fieldRetornoPorcentaje.value = dataG.fieldRetornoPorcentaje;
               fieldRetornoOperacion.value = dataG.fieldRetornoOperacion;
           }
           else
           {
               fieldSelectPromotores.value = 'S/D';
               fieldPorcentajePromotores.value = '0';
               fieldOperacionPorcentajePromotores.value = '0';
               
               fieldPorcentajeComisionPorDepositoMovAsimilado.value = '0';
               fieldPorcentajeComisionPorDepositoMovNormal.value = '0';
               fieldPorcentajeComisionOperacion.value = '0';
               
               fieldPorcentajeComision01Asimilado.value = '0';
               fieldPorcentajeComision01Normal.value = '0';
               fieldPorcentajeComision01Operacion.value = '0';
               
               fieldOperacionTotalDisponibleCliente.value = '0';
               fieldComisionPesosPorcentaje.value = '0';
               fieldComisionPesosOperacion.value = '0';
               
               fieldActivarComisionPesosExtra.value = false;
               fieldComisionPesosExtraPorcentaje.value = '0';
               fieldComisionPesosExtraOperacion.value = '0';
               
               fieldIVAPorcentaje.value = '0';
               fieldIVAOperacion.value = '0';
               
               fieldDevolucionIVAPorcentaje.value = '0';
               fieldDevolucionIVAOperacion.value = '0';

               fieldRetornoPorcentaje.value = '0';
               fieldRetornoOperacion.value = '0';
           }
            
        };

    
        const handleGuardarDatosPlantilla = async() => {
            if(fieldPlantillaIdUnico.value === '')
            {
                if(fieldNombrePlantilla.value === '' || fieldDescripcionPlantilla.value === '')
                {
                    notify({
                        title: "Plantilla",
                        text: "Los campos de nombre plnatilla y descripcion no pueden estar vacios."
                    });
                }
                else
                {
                    const data = await operacion(
                        {
                            numero : '51',
                            proceso : '7',
                            opcionTD : 'TODO',
                            parametros : true,
                            datosReceptor : 
                            {
                                opcion: 'guardarPlantilla',
                                PidUnicoGrupoEmpresarial: fieldSelectGrupoEmpresarial.value,
                                PnombrePlantilla: fieldNombrePlantilla.value,
                                PdescripcionPlantilla: fieldDescripcionPlantilla.value
                            },
                            mostrarMensaje: true
                        }
                    );
                    if(data.bandera === 0)
                    {
                        jsonPlantillas.value = {
                            encabezados: ['Nombre Plantilla', 'Descripcion Plantilla', 'Seleccionado'],
                            camposMostrar: ['nombrePlantilla', 'descripcionPlantilla', 'seleccionado'],
                            registros: data.tablas.Table1,
                            opcionesRegistro: false,
                            opcionesRegistroActualizar: true,
                            opcionesRegistroGuardar: true
                        };
                        notify({
                            title: "Plantillas",
                            text: data.mensaje
                        });
                        handleCancelarDatosPlantilla();
                    }
                    else
                    {
                        notify({
                            title: "Plantillas",
                            text: data.mensaje
                        });
                    }
                }
            }
            else
            {
                if(fieldNombrePlantilla.value === '' || fieldDescripcionPlantilla.value === '')
                {
                    notify({
                        title: "Plantilla",
                        text: "Los campos de nombre plnatilla y descripcion no pueden estar vacios."
                    });
                }
                else
                {
                    const data = await operacion(
                        {
                            numero : '51',
                            proceso : '7',
                            opcionTD : 'TODO',
                            parametros : true,
                            datosReceptor : 
                            {
                                opcion: 'actualizarPlantilla',
                                PidUnicoPlantilla: fieldPlantillaIdUnico.value,
                                PidUnicoGrupoEmpresarial: fieldSelectGrupoEmpresarial.value,
                                PnombrePlantilla: fieldNombrePlantilla.value,
                                PdescripcionPlantilla: fieldDescripcionPlantilla.value
                            },
                            mostrarMensaje: true
                        }
                    );
                    if(data.bandera === 0)
                    {
                        jsonPlantillas.value = {
                            encabezados: ['Nombre Plantilla', 'Descripcion Plantilla', 'Seleccionado'],
                            camposMostrar: ['nombrePlantilla', 'descripcionPlantilla', 'seleccionado'],
                            registros: data.tablas.Table1,
                            opcionesRegistro: false,
                            opcionesRegistroActualizar: true,
                            opcionesRegistroGuardar: true
                        };
                        notify({
                            title: "Plantillas",
                            text: data.mensaje
                        });
                        handleCancelarDatosPlantilla();
                    }
                    else
                    {
                        notify({
                            title: "Plantillas",
                            text: data.mensaje
                        });
                    }
                }
            }
        };
    
        const handleEditarDatosPlantilla = (data) => {
            fieldPlantillaIdUnico.value = data.idUnicoPlantilla;
            fieldNombrePlantilla.value = data.nombrePlantilla;
            fieldDescripcionPlantilla.value = data.descripcionPlantilla;
            disableButtonGuardarPlantilla.value = true;
            disableButtonEditarGuardarPlantilla.value = false;
            disableButtonCancelarGuardarPlantilla.value = false;
        };

        const handleValidadrPromotorComision = () => {
            if(fieldSelectPromotores.value === 'S/D')
            {
                fieldPorcentajePromotores.value = '0';
                fieldOperacionPorcentajePromotores.value = '0';
            }
        };

        // Parametros Watch

        watch([
            fieldPorcentajePromotores, fieldOperacionPorcentajePromotores,
            fieldPorcentajeComisionPorDepositoMovAsimilado, fieldPorcentajeComisionPorDepositoMovNormal, fieldPorcentajeComisionOperacion,
            fieldPorcentajeComision01Asimilado, fieldPorcentajeComision01Normal, fieldPorcentajeComision01Operacion,
            fieldComisionPesosPorcentaje, fieldComisionPesosOperacion,
            fieldComisionPesosExtraPorcentaje, fieldComisionPesosExtraOperacion,
            fieldIVAPorcentaje, fieldIVAOperacion,
            fieldDevolucionIVAPorcentaje, fieldDevolucionIVAOperacion,
            fieldRetornoPorcentaje, fieldRetornoOperacion,
            fieldOperacionTotalDisponibleCliente
    
        ], (nuevo) => {
            nuevo[0] === undefined || nuevo[0].length === 0 ? fieldPorcentajePromotores.value = 0 : fieldPorcentajePromotores.value = nuevo[0] ;
            nuevo[1] === undefined || nuevo[1].length === 0 ? fieldOperacionPorcentajePromotores.value = 0 : fieldOperacionPorcentajePromotores.value = nuevo[1] ;
    
            nuevo[2] === undefined || nuevo[2].length === 0 ? fieldPorcentajeComisionPorDepositoMovAsimilado.value = 0 : fieldPorcentajeComisionPorDepositoMovAsimilado.value = nuevo[2] ;
            nuevo[3] === undefined || nuevo[3].length === 0 ? fieldPorcentajeComisionPorDepositoMovNormal.value = 0 : fieldPorcentajeComisionPorDepositoMovNormal.value = nuevo[3] ;
            nuevo[4] === undefined || nuevo[4].length === 0 ? fieldPorcentajeComisionOperacion.value = 0 : fieldPorcentajeComisionOperacion.value = nuevo[4] ;
    
            nuevo[5] === undefined || nuevo[5].length === 0 ? fieldPorcentajeComision01Asimilado.value = 0 : fieldPorcentajeComision01Asimilado.value = nuevo[5] ;
            nuevo[6] === undefined || nuevo[6].length === 0 ? fieldPorcentajeComision01Normal.value = 0 : fieldPorcentajeComision01Normal.value = nuevo[6] ;
            nuevo[7] === undefined || nuevo[7].length === 0 ? fieldPorcentajeComision01Operacion.value = 0 : fieldPorcentajeComision01Operacion.value = nuevo[7] ;
    
            nuevo[8] === undefined || nuevo[8].length === 0 ? fieldComisionPesosPorcentaje.value = 0 : fieldComisionPesosPorcentaje.value = nuevo[8] ;
            nuevo[9] === undefined || nuevo[9].length === 0 ? fieldComisionPesosOperacion.value = 0 : fieldComisionPesosOperacion.value = nuevo[9] ;
    
            nuevo[10] === undefined || nuevo[10].length === 0 ? fieldComisionPesosExtraPorcentaje.value = 0 : fieldComisionPesosExtraPorcentaje.value = nuevo[10] ;
            nuevo[11] === undefined || nuevo[11].length === 0 ? fieldComisionPesosExtraOperacion.value = 0 : fieldComisionPesosExtraOperacion.value = nuevo[11] ;
    
            nuevo[12] === undefined || nuevo[12].length === 0 ? fieldIVAPorcentaje.value = 0 : fieldIVAPorcentaje.value = nuevo[12] ;
            nuevo[13] === undefined || nuevo[13].length === 0 ? fieldIVAOperacion.value = 0 : fieldIVAOperacion.value = nuevo[13] ;
    
            nuevo[14] === undefined || nuevo[14].length === 0 ? fieldDevolucionIVAPorcentaje.value = 0 : fieldDevolucionIVAPorcentaje.value = nuevo[14] ;
            nuevo[15] === undefined || nuevo[15].length === 0 ? fieldDevolucionIVAOperacion.value = 0 : fieldDevolucionIVAOperacion.value = nuevo[15] ;
    
            nuevo[16] === undefined || nuevo[16].length === 0 ? fieldRetornoPorcentaje.value = 0 : fieldRetornoPorcentaje.value = nuevo[16] ;
            nuevo[17] === undefined || nuevo[17].length === 0 ? fieldRetornoOperacion.value = 0 : fieldRetornoOperacion.value = nuevo[17] ;

            nuevo[18] === undefined || nuevo[18].length === 0 ? fieldOperacionTotalDisponibleCliente.value = 0 : fieldOperacionTotalDisponibleCliente.value = nuevo[18] ;
    
        });
    
        watch([fieldActivarComisionPesosExtra], (nuevo) => {
            if(!nuevo[0])
            {
                fieldComisionPesosExtraPorcentaje.value = '0';
                fieldComisionPesosExtraOperacion.value = '0';
            }
        } );

    //#endregion


    //#region Beneficiarios

        // Beneficiarios Modal grupos handle

        const handleModalGrupo = () => {
            if(modalGrupos.value)
            {
                modalGrupos.value = false;
                fieldNombreGrupo.value = '';
                fieldDescripcionGrupo.value = '';
                disableButtonGuardarGrupo.value = false;
                disableButtonEditarGuardarGrupo.value = true;
                disableButtonCancelarGuardarGrupo.value = true;
            }
            else
            {
                modalGrupos.value = true;
            }
        };
    
        const handleEditarDatosGrupo = async(accion, datos) => {
            
            
            if(accion === 'Guardar')
            {
                if(fieldNombreGrupo.value.length !== 0 && fieldDescripcionGrupo.value.length !== 0)
                {
                    if(jsonGrupos.value.filter( e => e.nombre.toUpperCase() == fieldNombreGrupo.value.toUpperCase() ).length <= 0)
                    {
                        
                        const data = await operacion(
                            {
                                numero : '51',
                                proceso : '5',
                                opcionTD : 'TODO',
                                parametros : true,
                                datosReceptor : 
                                {
                                    opcion: 'grabarGrupo',
                                    PidUnico: fieldSelectGrupoEmpresarial.value,
                                    PnombreGrupo: fieldNombreGrupo.value,
                                    PdescripcionGrupo: fieldDescripcionGrupo.value
                                },
                                mostrarMensaje: true
                            }
                        );
    
                        if(data.bandera === 0)
                        {
                            notify({
                                title: "Grupos",
                                text: data.mensaje
                            });
                            limpiarControlesGrupo(data.tablas.Table1);
                        }
                        else
                        {
                            notify({
                                title: "Grupos",
                                text: data.mensaje
                            });
                        }
    
                    } 
                    else
                    {
                        notify({
                            title: "Grupos",
                            text: `Ya existe un grupo con el nombre ${fieldNombreGrupo.value.toUpperCase()}.`
                        });    
                    }
                }
                else
                {
                    notify({
                        title: "Grupos",
                        text: 'No se puede guardar ya que hace falta informacion.'
                    });
                }
            }
            else if(accion === 'Eliminar')
            {
    
                if (confirm('Estas seguro de eliminar este grupo.') == true) {
                    
                    const data = await operacion(
                        {
                            numero : '51',
                            proceso : '5',
                            opcionTD : 'TODO',
                            parametros : true,
                            datosReceptor : 
                            {
                                opcion: 'eliminarGrupo',
                                PidUnico: fieldSelectGrupoEmpresarial.value,
                                PidUnicoGrupo: datos.idUnicoGrupo
                            },
                            mostrarMensaje: true
                        }
                    );
        
                    if(data.bandera === 0)
                    {
                        notify({
                            title: "Grupos",
                            text: data.mensaje
                        });
                        limpiarControlesGrupo(data.tablas.Table1);
                    }
                    else
                    {
                        notify({
                            title: "Grupos",
                            text: data.mensaje
                        });
                    }
                    
                }
    
            }
            else if(accion === 'Editar')
            {
                idUnicoGrupo.value = datos.idUnicoGrupo;
                fieldNombreGrupo.value = datos.nombre;
                fieldDescripcionGrupo.value = datos.descripcion;
    
                disableButtonGuardarGrupo.value = true;
                disableButtonEditarGuardarGrupo.value = false;
                disableButtonCancelarGuardarGrupo.value = false;
            }
            else if(accion === 'Actualizar'){
    
                if(fieldNombreGrupo.value.length !== 0 && fieldDescripcionGrupo.value.length !== 0)
                {
                    const data = await operacion(
                        {
                            numero : '51',
                            proceso : '5',
                            opcionTD : 'TODO',
                            parametros : true,
                            datosReceptor : 
                            {
                                opcion: 'actualizarGrupo',
                                PidUnico: fieldSelectGrupoEmpresarial.value,
                                PidUnicoGrupo: idUnicoGrupo.value,
                                PnombreGrupo: fieldNombreGrupo.value,
                                PdescripcionGrupo: fieldDescripcionGrupo.value
                            },
                            mostrarMensaje: true
                        }
                    );
    
                    if(data.bandera === 0)
                    {
                        notify({
                            title: "Grupos",
                            text: data.mensaje
                        });
                        disableButtonGuardarGrupo.value = false;
                        disableButtonEditarGuardarGrupo.value = true;
                        disableButtonCancelarGuardarGrupo.value = true;
                        limpiarControlesGrupo(data.tablas.Table1);
                    }
                    else
                    {
                        notify({
                            title: "Grupos",
                            text: data.mensaje
                        });
                    }
                }
                else
                {
                    notify({
                        title: "Grupos",
                        text: 'No se puede guardar ya que hace falta informacion.'
                    });
                }
    
            }
            else if(accion === 'Cancelar'){
                idUnicoGrupo.value = '';
                fieldNombreGrupo.value = '';
                fieldDescripcionGrupo.value = '';
    
                disableButtonGuardarGrupo.value = false;
                disableButtonEditarGuardarGrupo.value = true;
                disableButtonCancelarGuardarGrupo.value = true;
            }
        };
    
        const limpiarControlesGrupo = (datos) => {
            idUnicoGrupo.value = '';
            fieldNombreGrupo.value = '';
            fieldDescripcionGrupo.value = '';
            if(datos)
            {
                jsonGrupos.value = datos;
            }
            
        };

        // Beneficiarios Modal Beneficiarios handle

        const handleModalBeneficiarios = () => {
            if(modalBeneficiarios.value)
            {
                modalBeneficiarios.value = false;
                idUnicoBeneficiario.value = '';
                fieldBeneficiarioGrupo.value = 'S/D';
                fieldBeneficiarioUsoCFDI.value = 'P01';
                fieldBeneficiarioApellidoPaterno.value = '';
                fieldBeneficiarioApellidoMaterno.value = '';
                fieldBeneficiarioNombre.value = '';
                fieldBeneficiarioEmpresaRazonSocial.value = '';
                fieldBeneficiarioRFC.value = '';
                fieldBeneficiarioCURP.value = '';
                fieldBeneficiarioCuentaBanco.value = '';
                fieldBeneficiarioClabeBanco.value = '';
                fieldBeneficiarioBanco.value = 'S/D';
                fieldBeneficiarioNumeroInstitutoBancoSantander.value = '';
                fieldBeneficiarioClaveTransferenciaBancoSantander.value = '';
                fieldBeneficiarioNumeroInstitutoKuspit.value = '';
                fieldBeneficiarioActivo.value = 1;
                disableButtonBeneficiarioGuardar.value = true;
                fieldBeneficiarioTipoAlta.value = 'Persona';
            }
            else
            {
                modalBeneficiarios.value = true;
            }
        };

        const handleOperacionesBeneficiarios = async(tipo, datos) => {

            if(tipo === 'grabar' && idUnicoBeneficiario.value === '')
            {
                const data = await operacion(
                    {
                        numero : '51',
                        proceso : '5',
                        opcionTD : 'TODO',
                        parametros : true,
                        datosReceptor : 
                        {
                            opcion: 'grabarBeneficiario',
                            PidUnico: fieldSelectGrupoEmpresarial.value,

                            PidUnicoGrupo: fieldBeneficiarioGrupo.value,
                            PtipoAlta: fieldBeneficiarioTipoAlta.value,
                            PusoCFDI: fieldBeneficiarioUsoCFDI.value,
                            PapellidoPaterno: fieldBeneficiarioApellidoPaterno.value,
                            PapellidoMaterno: fieldBeneficiarioApellidoMaterno.value,
                            Pnombre: fieldBeneficiarioNombre.value,
                            PrazonSocial: fieldBeneficiarioEmpresaRazonSocial.value,
                            Prfc: fieldBeneficiarioRFC.value,
                            Pcurp: fieldBeneficiarioCURP.value,
                            PcuentaBanco: fieldBeneficiarioCuentaBanco.value,
                            PclabeBanco: fieldBeneficiarioClabeBanco.value,
                            PidCatBanco: fieldBeneficiarioBanco.value,
                            PnumeroInstitutoBancoSantander: fieldBeneficiarioNumeroInstitutoBancoSantander.value,
                            PclaveTransferenciaBancoSantander: fieldBeneficiarioClaveTransferenciaBancoSantander.value,
                            PnumeroInstitutoKuspit: fieldBeneficiarioNumeroInstitutoKuspit.value,
                            Pactivo: fieldBeneficiarioActivo.value
                        },
                        mostrarMensaje: true
                    }
                );
                if(data.bandera === 0)
                {
                    notify({
                        title: "Grupos",
                        text: data.mensaje
                    });
                    jsonBeneficiarios.value = data.tablas.Table1;
                    handleModalBeneficiarios();
                }
                else
                {
                    notify({
                        title: "Grupos",
                        text: data.mensaje
                    });
                }
                
            }

            else if(tipo === 'grabar' && idUnicoBeneficiario.value !== '')
            {
                const data = await operacion(
                    {
                        numero : '51',
                        proceso : '5',
                        opcionTD : 'TODO',
                        parametros : true,
                        datosReceptor : 
                        {
                            opcion: 'actualizarBeneficiario',
                            PidUnico: fieldSelectGrupoEmpresarial.value,

                            PtipoAlta: fieldBeneficiarioTipoAlta.value,
                            PidUnicoBeneficiario: idUnicoBeneficiario.value,
                            PidUnicoGrupo: fieldBeneficiarioGrupo.value,
                            PusoCFDI: fieldBeneficiarioUsoCFDI.value,
                            PapellidoPaterno: fieldBeneficiarioApellidoPaterno.value,
                            PapellidoMaterno: fieldBeneficiarioApellidoMaterno.value,
                            Pnombre: fieldBeneficiarioNombre.value,
                            PrazonSocial: fieldBeneficiarioEmpresaRazonSocial.value,
                            Prfc: fieldBeneficiarioRFC.value,
                            Pcurp: fieldBeneficiarioCURP.value,
                            PcuentaBanco: fieldBeneficiarioCuentaBanco.value,
                            PclabeBanco: fieldBeneficiarioClabeBanco.value,
                            PidCatBanco: fieldBeneficiarioBanco.value,
                            PnumeroInstitutoBancoSantander: fieldBeneficiarioNumeroInstitutoBancoSantander.value,
                            PclaveTransferenciaBancoSantander: fieldBeneficiarioClaveTransferenciaBancoSantander.value,
                            PnumeroInstitutoKuspit: fieldBeneficiarioNumeroInstitutoKuspit.value,
                            Pactivo: fieldBeneficiarioActivo.value
                        },
                        mostrarMensaje: true
                    }
                );
                if(data.bandera === 0)
                {
                    notify({
                        title: "Grupos",
                        text: data.mensaje
                    });
                    jsonBeneficiarios.value = data.tablas.Table1;
                    handleModalBeneficiarios();
                }
                else
                {
                    notify({
                        title: "Grupos",
                        text: data.mensaje
                    });
                }
            }

            else if(tipo === 'editar')
            {
                handleModalBeneficiarios();
                idUnicoBeneficiario.value = datos.idUnicoBeneficiario;
                fieldBeneficiarioTipoAlta.value = datos.tipoAlta;
                fieldBeneficiarioGrupo.value = datos.idUnicoGrupo;
                fieldBeneficiarioUsoCFDI.value = datos.usoCFDI;
                fieldBeneficiarioApellidoPaterno.value = datos.apellidoPaterno;
                fieldBeneficiarioApellidoMaterno.value = datos.apellidoMaterno;
                fieldBeneficiarioNombre.value = datos.nombre;
                fieldBeneficiarioEmpresaRazonSocial.value = datos.razonSocial;
                fieldBeneficiarioRFC.value = datos.rfc;
                fieldBeneficiarioCURP.value = datos.curp;
                fieldBeneficiarioCuentaBanco.value = datos.cuentaBanco;
                fieldBeneficiarioClabeBanco.value = datos.clabeBanco;
                fieldBeneficiarioBanco.value = datos.idCatBanco;
                fieldBeneficiarioNumeroInstitutoBancoSantander.value = datos.numeroInstitutoBancoSantander;
                fieldBeneficiarioClaveTransferenciaBancoSantander.value = datos.claveTransferenciaBancoSantander;
                fieldBeneficiarioNumeroInstitutoKuspit.value = datos.numeroInstitutoKuspit;
                fieldBeneficiarioActivo.value = datos.activo;
            }


        };

        const handleCuentasBancoEmpresa = async( idSysCPEmpresasRP ) => {
            const data = await operacion(
                {
                    numero : '51',
                    proceso : '5',
                    opcionTD : 'TODO',
                    parametros : true,
                    datosReceptor : 
                    {
                        opcion: 'bancosEmpresasInternas',
                        PidUnico: fieldSelectGrupoEmpresarial.value,
                        PidSysCPEmpresasRP: idSysCPEmpresasRP
                    },
                    mostrarMensaje: false
                }
            );
            if( data.bandera === 0 )
            {
                jsonBancosEmpresa.value = data.tablas.Table1;
            }
            else
            {
                jsonBancosEmpresa.value = [];
            }
        };

        // Beneficiarios Modal Beneficiarios watch

        watch( [
            fieldBeneficiarioGrupo, fieldBeneficiarioUsoCFDI, 
            fieldBeneficiarioApellidoPaterno, fieldBeneficiarioNombre,
            fieldBeneficiarioRFC, fieldBeneficiarioCURP,
            fieldBeneficiarioCuentaBanco, fieldBeneficiarioClabeBanco, fieldBeneficiarioBanco,

            fieldBeneficiarioEmpresaRazonSocial
            ], (dato) => {

        if( fieldBeneficiarioTipoAlta.value === 'Persona' )
        {

            const regexRFC = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
            const regexCURP = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
            const regexCuenta = /^\D*\d{10}|\D*\d{11}$/;
            const regexClabe = /^\D*\d{18}$/;

            let benGrupo = dato[0] === 'S/D' ? false : true ;
            let benUsoCFDI = dato[1] === 'S/D' ? false : true ;
            let apellidoPaterno = dato[2].length >= 2 ? true : false ;
            let nombre = dato[3].length >= 2 ? true : false ;
            let benRFC = false;
            let benCURP = false;
            let benCuentaBanco = false;
            let benClabeBanco = false;
            let benBanco = false;

            if(dato[4].length === 13)
            {
                if(regexRFC.test(dato[4]))
                {
                    benRFC = true;
                }
                else
                {
                    benRFC = false;
                }
            }
            else
            {
                benRFC = false;
            }

            // ====================================================================

            if(dato[5].length === 18)
            {
                if(regexCURP.test(dato[5]))
                {
                    benCURP = true;
                }
                else
                {
                    benCURP = false;
                }
            }
            else
            {
                benCURP = false;
            }

            // ====================================================================

            // if( dato[5].length === 0 )
            // {
            //     benCuentaBanco = true;
            // }
            if(dato[6].length > 9 && dato[6].length <= 11)
            {
                if(regexCuenta.test(dato[6]))
                {
                    benCuentaBanco = true;
                }
                else
                {
                    benCuentaBanco = false;
                }
            }
            else
            {
                benCuentaBanco = false;
            }

            // ====================================================================

            // if(jsonBancos.value.find( e => e.idCatBanco === dato[6].substring(0, 3)))
            if(regexClabe.test(dato[7]))
            {
                benClabeBanco = true;
            }
            else
            {
                benClabeBanco = false;
            }


            // ====================================================================

            if( jsonBancos.value.find( e => e.idCatBanco === dato[7].substring(0, 3)) )
            {
                benBanco = true;
                fieldBeneficiarioBanco.value = jsonBancos.value.find( e => e.idCatBanco === dato[7].substring(0, 3)).idCatBanco;
                const datosBancoSantander = jsonBancosSantander.value.find( e => e.idCatBanco === dato[7].substring(0, 3) );
                if( datosBancoSantander )
                {
                    fieldBeneficiarioNumeroInstitutoBancoSantander.value = datosBancoSantander.numInstituto;
                    fieldBeneficiarioClaveTransferenciaBancoSantander.value = datosBancoSantander.claveTransferencia;
                }
                else
                {
                    fieldBeneficiarioNumeroInstitutoBancoSantander.value = '';
                    fieldBeneficiarioClaveTransferenciaBancoSantander.value = '';
                }
            }
            else
            {
                benBanco = false;
                fieldBeneficiarioBanco.value = 'S/D';
                fieldBeneficiarioNumeroInstitutoBancoSantander.value = '';
                fieldBeneficiarioClaveTransferenciaBancoSantander.value = '';
            }

            // ====================================================================


            if(
                benGrupo && benUsoCFDI && apellidoPaterno && nombre && benRFC && benCURP && benCuentaBanco && benClabeBanco && benBanco
            )
            {
                disableButtonBeneficiarioGuardar.value = false;
            }
            else
            {
                disableButtonBeneficiarioGuardar.value = true;
            }

        }
        else if( fieldBeneficiarioTipoAlta.value === 'Empresa' )
        {

            const regexCuenta = /^\D*\d{10}|\D*\d{11}$/;
            const regexClabe = /^\D*\d{18}$/;

            let benGrupo = dato[0] === 'S/D' ? false : true ;
            let fieldBeneficiarioEmpresaRazonSocial = dato[9].length >= 2 ? true : false ;
            let benCuentaBanco = false;
            let benClabeBanco = false;
            let benBanco = false;


            // ====================================================================

            if(dato[6].length > 9 && dato[6].length <= 11)
            {
                if(regexCuenta.test(dato[6]))
                {
                    benCuentaBanco = true;
                }
                else
                {
                    benCuentaBanco = false;
                }
            }
            else
            {
                benCuentaBanco = false;
            }

            // ====================================================================

            if(regexClabe.test(dato[7]))
            {
                benClabeBanco = true;
            }
            else
            {
                benClabeBanco = false;
            }


            // ====================================================================

            if( jsonBancos.value.find( e => e.idCatBanco === dato[7].substring(0, 3)) )
            {
                benBanco = true;
                fieldBeneficiarioBanco.value = jsonBancos.value.find( e => e.idCatBanco === dato[7].substring(0, 3)).idCatBanco;
                const datosBancoSantander = jsonBancosSantander.value.find( e => e.idCatBanco === dato[7].substring(0, 3) );
                if( datosBancoSantander )
                {
                    fieldBeneficiarioNumeroInstitutoBancoSantander.value = datosBancoSantander.numInstituto;
                    fieldBeneficiarioClaveTransferenciaBancoSantander.value = datosBancoSantander.claveTransferencia;
                }
                else
                {
                    fieldBeneficiarioNumeroInstitutoBancoSantander.value = '';
                    fieldBeneficiarioClaveTransferenciaBancoSantander.value = '';
                }
            }
            else
            {
                benBanco = false;
                fieldBeneficiarioBanco.value = 'S/D';
                fieldBeneficiarioNumeroInstitutoBancoSantander.value = '';
                fieldBeneficiarioClaveTransferenciaBancoSantander.value = '';
            }

            // ====================================================================


            if(
                benGrupo && fieldBeneficiarioEmpresaRazonSocial && benCuentaBanco && benClabeBanco && benBanco
            )
            {
                disableButtonBeneficiarioGuardar.value = false;
            }
            else
            {
                disableButtonBeneficiarioGuardar.value = true;
            }

        }
        
        } );

        watch( [fieldEmpresaPagadora], (dato) => {
            handleCuentasBancoEmpresa( dato[0] );
        } );

    //#endregion


    //#region Facturacion

        //Facturacion modal conceptos handle

        const handleFacturaGuardar = async() => {
            try
            {
                if(fieldIdUnicoConcepto.value === '')
                {
                    jsonConceptosFactura.value = [...jsonConceptosFactura.value, {
                        idUnicoConcepto: shortid.generate().toLocaleUpperCase(),
                        fieldEmpresasConcepto: fieldEmpresasConcepto.value,
                        idUnicoGrupoEmpresarial: fieldSelectGrupoEmpresarial.value,
                        idUnicoPlantilla: fieldSelectPlantillaSeleccionada.value,
                        prodServ: fieldFacturaProdServ.value,
                        claveUnidad: fieldFacturaClaveUnidad.value.toLocaleUpperCase(),
                        metodoPago: fieldSelectFacturaMetodoPago.value,
                        formaPago: fieldSelectFacturaFormaPago.value,
                        descripcion: fieldFacturaDescripcion.value.toLocaleUpperCase()
                    }]

                    const data = await operacion(
                        {
                            numero : '51',
                            proceso : '11',
                            opcionTD : 'TODO',
                            parametros : true,
                            datosReceptor : 
                            {
                                opcion: 'guardarConceptosFactura',
                                PidUnicoGrupoEmpresarial: fieldSelectGrupoEmpresarial.value,
                                PjsonConceptosFactura: JSON.stringify( jsonConceptosFactura.value )
                            },
                            mostrarMensaje: true
                        }
                    );
                    
                    if(data.bandera === 0)
                    {
                        handleFacturaCancelar();
                        notify({
                            title: "Conceptos",
                            text: `El concepto se genero con exito.`
                        });
                    }
    
                }
                else
                {
                    const index = jsonConceptosFactura.value.findIndex( e => e.idUnicoConcepto === fieldIdUnicoConcepto.value);
                    jsonConceptosFactura.value[index].claveUnidad = fieldFacturaClaveUnidad.value.toLocaleUpperCase();
                    jsonConceptosFactura.value[index].descripcion = fieldFacturaDescripcion.value.toLocaleUpperCase();
                    jsonConceptosFactura.value[index].metodoPago = fieldSelectFacturaMetodoPago.value.toLocaleUpperCase();
                    jsonConceptosFactura.value[index].formaPago = fieldSelectFacturaFormaPago.value.toLocaleUpperCase();
                    
                    const data = await operacion(
                        {
                            numero : '51',
                            proceso : '11',
                            opcionTD : 'TODO',
                            parametros : true,
                            datosReceptor : 
                            {
                                opcion: 'guardarConceptosFactura',
                                PidUnicoGrupoEmpresarial: fieldSelectGrupoEmpresarial.value,
                                PjsonConceptosFactura: JSON.stringify( jsonConceptosFactura.value )
                            },
                            mostrarMensaje: true
                        }
                    );

                    if(data.bandera === 0)
                    {
                        handleFacturaCancelar();
                        notify({
                            title: "Conceptos",
                            text: `El concepto se actualizo con exito.`
                        });
                    }
                }
    
            }
            catch(error)
            {
                notify({
                    title: "Conceptos",
                    text: `Error: ${error}`
                });
            }
    
        };

        const handleFacturarQuitar = async(datos) => {
            try
            {
                jsonConceptosFactura.value = jsonConceptosFactura.value.filter( e => e.idUnicoConcepto !== datos.idUnicoConcepto);
                    
                const data = await operacion(
                    {
                        numero : '51',
                        proceso : '11',
                        opcionTD : 'TODO',
                        parametros : true,
                        datosReceptor : 
                        {
                            opcion: 'guardarConceptosFactura',
                            PidUnicoGrupoEmpresarial: fieldSelectGrupoEmpresarial.value,
                            PjsonConceptosFactura: JSON.stringify( jsonConceptosFactura.value )
                        },
                        mostrarMensaje: true
                    }
                );

                if(data.bandera === 0)
                {
                    notify({
                        title: "Conceptos",
                        text: `El concepto se quito con exito.`
                    });
                }

            }
            catch(error)
            {
                notify({
                    title: "Conceptos",
                    text: `Error: ${error}`
                });
            }
        };

        const handleFacturaCancelar = () => {
            fieldIdUnicoConcepto.value = '';
            fieldFacturaProdServ.value = '';
            fieldFacturaClaveUnidad.value = '';
            fieldSelectFacturaMetodoPago.value = 'S/D';
            fieldSelectFacturaFormaPago.value = 'S/D';
            fieldFacturaDescripcion.value = '';
            modalConceptos.value = modalConceptos.value ? false : true;
        }

        const handleFacturaValidationConceptos = (nuevo) => {
            let banderaProdServ = false;
            let banderaDescripcion = false;
            let banderaClaveUnidad = false;
            let banderaMetodoPago = false;
            let banderaFormaPago = false;
    
            const proserv = jsonProdServ.value.filter( e => e.claveProdServ === nuevo[0] ).length;
            if(proserv >= 1) 
            {
                banderaProdServ = true;
            }
            else
            {
                banderaProdServ = false;
            }
    
            if(nuevo[1].length >= 10)
            {
                banderaDescripcion = true;
            }
            else
            {
                banderaDescripcion = false;
            }
            const claveUnidad = jsonClaveUnidad.value.filter( e => e.idCatClaveUnidad === nuevo[2].toUpperCase() ).length;
            if(claveUnidad >= 1) 
            {
                banderaClaveUnidad = true;
            }
            else
            {
                banderaClaveUnidad = false;
            }
            if(nuevo[3] !== 'S/D')
            {
                banderaMetodoPago = true;
            }
            else
            {
                banderaMetodoPago = false;
            }
            if(nuevo[4] !== 'S/D')
            {
                banderaFormaPago = true;
            }
            else
            {
                banderaFormaPago = false;
            }
    
            if(banderaProdServ && banderaDescripcion && banderaClaveUnidad && banderaMetodoPago && banderaFormaPago)
            {
                disabledButtonGrabarFacturaConcepto.value = false;
            }
            else
            {
                disabledButtonGrabarFacturaConcepto.value = true;
            }
    
            
        };

        // Facturacion watch

        watch([fieldFacturaProdServ, fieldFacturaDescripcion, fieldFacturaClaveUnidad, fieldSelectFacturaMetodoPago, fieldSelectFacturaFormaPago], (nuevo) => {
            handleFacturaValidationConceptos(nuevo);
        });

        // Facturacion handle

        const handleChangeSerie = async(idUnicoGrupoEmpresarial, idSysCPEmpresasRP, {target}) => {
            const data = await operacion(
                {
                    numero : '51',
                    proceso : '4',
                    opcionTD : 'TODO',
                    parametros : true,
                    datosReceptor : 
                    {
                        opcion: 'actualizarSerie',
                        Pserie: target.value,
                        PidUnicoGrupoEmpresarial: idUnicoGrupoEmpresarial,
                        PidSysCPEmpresasRP: idSysCPEmpresasRP
                    },
                    mostrarMensaje: true
                }
            );
            if(data.bandera === 0)
            {
                const index = jsonEmpresasInternas.value.findIndex( e => e.idSysCPEmpresasRP === idSysCPEmpresasRP );
                jsonEmpresasInternas.value[index].serieFactura = target.value;
                notify({
                    title: "Empresas",
                    text: data.mensaje
                });
            }
            else
            {
                notify({
                    title: "Empresas",
                    text: data.mensaje
                });
            }
        };

        const handleSelectEmpresasConcepto = () => {
            fieldEmpresasConcepto.value !== 'S/D' ? disabledBotonAgregarConcepto.value = false : disabledBotonAgregarConcepto.value = true;
        };

        const handleModalConcepto = () => {
            modalConceptos.value = modalConceptos.value ? false : true;
        };

        const handleFacturarEditar = (datos) => {
            handleModalConcepto();
            fieldIdUnicoConcepto.value = datos.idUnicoConcepto;
            fieldFacturaProdServ.value = datos.prodServ;
            fieldFacturaDescripcion.value = datos.descripcion;
            fieldFacturaClaveUnidad.value = datos.claveUnidad;
            fieldSelectFacturaMetodoPago.value = datos.metodoPago;
            fieldSelectFacturaFormaPago.value = datos.formaPago;
        };

        const handleChangeUsoCFDI = async(idUnicoGrupoEmpresarial, idSysTSEmpresas, {target}) => {
            const data = await operacion(
                {
                    numero : '51',
                    proceso : '1',
                    opcionTD : 'TODO',
                    parametros : true,
                    datosReceptor : 
                    {
                        opcion: 'actualizarUsoCFDI',
                        PusoCFDI: target.value,
                        PidUnicoGrupoEmpresarial: idUnicoGrupoEmpresarial,
                        PidSysTSEmpresas: idSysTSEmpresas
                    },
                    mostrarMensaje: true
                }
            );
            if(data.bandera === 0)
            {
                const index = jsonTablaClientes.value.findIndex( e => e.idSysTSEmpresas === idSysTSEmpresas );
                jsonTablaClientes.value[index].usoCFDI = target.value;
                notify({
                    title: "Empresas",
                    text: data.mensaje
                });
            }
            else
            {
                notify({
                    title: "Empresas",
                    text: data.mensaje
                });
            }
        };

    //#endregion


    //#region Financiamiento

        //Financiamiento handle

        const handleActualizarFinanciamiento = async() => {
            const data = await operacion(
                {
                    numero : '51',
                    proceso : '5',
                    opcionTD : 'TODO',
                    parametros : true,
                    datosReceptor : 
                    {
                        opcion: 'guardarFinanciamiento',
                        PidUnico: fieldSelectGrupoEmpresarial.value,
                        PactivoFinanciamiento: fieldActivarFinanciamiento.value ? 1 : 0,
                        Pfinanciamiento: Number(formatNumero(fieldFinanciamiento.value))
                    },
                    mostrarMensaje: true
                }
            );
            if( data.bandera === 0 )
            {
                notify({
                    title: "Conceptos",
                    text: data.mensaje
                });
            }
            else
            {
                notify({
                    title: "Conceptos",
                    text: data.mensaje
                });
            }

        };

        //Financiamiento watch
        watch([fieldFinanciamiento], (nuevo) => {
            let valor = nuevo[0];
            try
            {
                fieldFinanciamiento.value = formatoMexico(Number(formatNumero(valor)));
            }
            catch(error)
            {
                fieldFinanciamiento.value = 0.00;
            }
        });

        watch([fieldActivarFinanciamiento], (nuevo) => {
            if(!nuevo[0])
            {
                fieldFinanciamiento.value = '0.00';
            }
        });

    //#endregion
    

    //#region Bancos LayOut

        //Bancos Layout handle
        const handleConfiguracionBancos = async() => {

            let jsonBancosEmpresaFinal = [];

            jsonBancosEmpresa.value.map( ( { idUnicoGrupoEmpresarial, idSysCPEmpresasRP, idCatBanco, descripcion, cuentaBancaria, clabeBancaria }, index ) => {

                jsonBancosEmpresaFinal = [ ...jsonBancosEmpresaFinal, {
                    idUnicoGrupoEmpresarial, idSysCPEmpresasRP, idCatBanco, descripcion, cuentaBancaria, clabeBancaria, orden: index + 1
                } ];

            } );

            const data = await operacion(
                {
                    numero : '51',
                    proceso : '5',
                    opcionTD : 'TODO',
                    parametros : true,
                    datosReceptor : 
                    {
                        opcion: 'actualizarOrdenBancos',
                        PidUnico: fieldSelectGrupoEmpresarial.value,
                        PjsonBancosOrden: JSON.stringify(jsonBancosEmpresaFinal)
                    },
                    mostrarMensaje: true
                }
            );
            if(data.bandera === 0)
            {
                notify({
                    title: "Bancos",
                    text: data.mensaje
                });
            }
            else
            {
                notify({
                    title: "Bancos",
                    text: data.mensaje
                });
            }

            // ∞

        };

    //#endregion


    //#region Correos
   
        //Correos handle

        const handleAgregarCorreo = async() => {
            if(fieldSelectCorreo.value !== 'S/D')
            {
                
                if(jsonCorreosAsignados.value.filter( e => e.idUnicoCorreos === fieldSelectCorreo.value ).length === 0)
                {
    
                    const datosCorreo = jsonCorreos.value.find( e => e.idUnicoCorreos == fieldSelectCorreo.value );
                    jsonCorreosAsignados.value = [...jsonCorreosAsignados.value, {
                        idUnicoCorreos: datosCorreo.idUnicoCorreos,
                        nombre: datosCorreo.nombre, 
                        correoElectronico: datosCorreo.correoElectronico
                    }];

                    const data = await operacion(
                        {
                            numero : '51',
                            proceso : '12',
                            opcionTD : 'TODO',
                            parametros : true,
                            datosReceptor : 
                            {
                                opcion: 'guardarCorreoAsignado',
                                PidUnicoGrupoEmpresarial: fieldSelectGrupoEmpresarial.value,
                                PjsonCorreoAsignado: JSON.stringify( jsonCorreosAsignados.value )
                            },
                            mostrarMensaje: true
                        }
                    );

                    if( data.bandera === 0 )
                    {   
                        notify({
                            title: "Asignar Correo",
                            text: 'El registro se agrego con exito.'
                        });
                    }
                    else
                    {
                        notify({
                            title: "Asignar Correo",
                            text: data.mensaje
                        });
                    }
                }
                else
                {
                    notify({
                        title: "Asignar Correo",
                        text: 'El correo ya esta agregado.'
                    });
                }
            }
            else
            {
                notify({
                    title: "Asignar Correo",
                    text: 'Debes de seleccionar un correo.'
                });
            }
        };

        const handleQuitarCorreo = async({idUnicoCorreos}) => {
            jsonCorreosAsignados.value = jsonCorreosAsignados.value.filter( e => e.idUnicoCorreos !== idUnicoCorreos );
            const data = await operacion(
                {
                    numero : '51',
                    proceso : '12',
                    opcionTD : 'TODO',
                    parametros : true,
                    datosReceptor : 
                    {
                        opcion: 'guardarCorreoAsignado',
                        PidUnicoGrupoEmpresarial: fieldSelectGrupoEmpresarial.value,
                        PjsonCorreoAsignado: JSON.stringify( jsonCorreosAsignados.value )
                    },
                    mostrarMensaje: true
                }
            );

            if( data.bandera === 0 )
            {
                notify({
                    title: "Asignar Correo",
                    text: 'El registro se quito con exito.'
                });
            }
            else
            {
                notify({
                    title: "Asignar Correo",
                    text: data.mensaje
                });
            }
        };

    //#endregion


    return{

        processSeleccionPest,
        jsonGrupoEmpresarial,
        fieldSelectGrupoEmpresarial,
        processBotonSeleccionarGrupoEmpresarial,
        fieldFormDatosGenerales,
        jsonTablaClientes,
        jsonCatalogosSATCFDI,
        jsonCatalogosSerieEmpresas,
        processModalPlantilla,
        fieldNombrePlantilla,
        fieldDescripcionPlantilla,
        disableButtonGuardarPlantilla,
        disableButtonEditarGuardarPlantilla,
        disableButtonCancelarGuardarPlantilla,
        jsonPlantillas,
        fieldSelectPlantillaSeleccionada,
        fieldNombrePlantillaSeleccionada,
        jsonPromotores,
        fieldSelectPromotores,
        fieldPorcentajePromotores,
        fieldOperacionPorcentajePromotores,
        fieldOperacionTotalDisponibleCliente,
        fieldComisionPesosPorcentaje,
        fieldComisionPesosOperacion,
        fieldActivarComisionPesosExtra,
        fieldComisionPesosExtraPorcentaje,
        fieldComisionPesosExtraOperacion,
        fieldIVAPorcentaje,
        fieldIVAOperacion,
        fieldDevolucionIVAPorcentaje,
        fieldDevolucionIVAOperacion,        
        fieldRetornoPorcentaje,
        fieldRetornoOperacion,
        jsonPromotoresComision,
        jsonEmpresasPagadoras,
        fieldEmpresaPagadora,
        fieldPorcentajeComision01Asimilado,
        fieldPorcentajeComision01Normal,
        fieldPorcentajeComision01Operacion,
        fieldPorcentajeComisionPorDepositoMovAsimilado,
        fieldPorcentajeComisionPorDepositoMovNormal,
        fieldPorcentajeComisionOperacion,
        jsonEmpresasInternas,
        fieldSelectCorreo,
        jsonCorreos,
        jsonCorreosAsignados,
        jsonMetodoPago,
        jsonFormaPago,
        jsonConceptosFactura,
        fieldIdUnicoConcepto,
        modalConceptos,
        fieldEmpresasConcepto,
        disabledBotonAgregarConcepto,
        fieldFacturaProdServ,
        fieldFacturaDescripcion,
        fieldFacturaClaveUnidad,
        fieldSelectFacturaMetodoPago,
        fieldSelectFacturaFormaPago,
        disabledButtonGrabarFacturaConcepto,
        modalGrupos,
        jsonGrupos,
        fieldNombreGrupo,
        fieldDescripcionGrupo,
        disableButtonGuardarGrupo,
        disableButtonEditarGuardarGrupo,
        disableButtonCancelarGuardarGrupo,
        modalBeneficiarios,
        jsonBeneficiarios,
        jsonBancos,
        idUnicoBeneficiario,
        fieldBeneficiarioGrupo,
        fieldBeneficiarioUsoCFDI,
        fieldBeneficiarioTipoAlta,
        fieldBeneficiarioApellidoPaterno,
        fieldBeneficiarioApellidoMaterno,
        fieldBeneficiarioNombre,
        fieldBeneficiarioEmpresaRazonSocial,
        fieldBeneficiarioRFC,
        fieldBeneficiarioCURP,
        fieldBeneficiarioCuentaBanco,
        fieldBeneficiarioClabeBanco,
        fieldBeneficiarioBanco,
        fieldBeneficiarioNumeroInstitutoBancoSantander,
        fieldBeneficiarioClaveTransferenciaBancoSantander,
        fieldBeneficiarioNumeroInstitutoKuspit,
        fieldBeneficiarioActivo,
        disableButtonBeneficiarioGuardar,
        jsonBancosEmpresa,
        fieldActivarFinanciamiento,
        fieldFinanciamiento,
        //===============================================================

        handleSelect,
        handleProcesoBotonSeleccionarGrupoEmpresarial,
        handleActivarDesactivarAcceso,
        handleUpdateClaveAcceso,
        handleUpdateLogoEmpresa,
        handleAbrirPlantilla,
        handleCerrarModalPlantilla,
        handleGuardarDatosPlantilla,
        handleEditarDatosPlantilla,
        handleCancelarDatosPlantilla,
        handleSeleccionPlantilla,
        handleDatosPlantillas,
        handleGrabarParametrosDatosPlantilla,
        handleValidadrPromotorComision,
        handleAgregarCorreo,
        handleQuitarCorreo,
        handleSelectEmpresasConcepto,
        handleModalConcepto,
        handleFacturaGuardar,
        handleFacturaCancelar,
        handleFacturarEditar,
        handleFacturarQuitar,
        handleChangeSerie,
        handleChangeUsoCFDI,
        handleModalGrupo,
        handleEditarDatosGrupo,
        handleModalBeneficiarios,
        handleOperacionesBeneficiarios,
        handleActualizarFinanciamiento,
        handleConfiguracionBancos,

        //===============================================================

        isNumber,
        isDecimal,
        isDecimal02
        
    }

};



export default useClientes;