<template>
  <nav
    class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl"
    v-bind="$attrs"
    id="navbarBlur"
    data-scroll="true"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs :currentPage="currentRouteName" :textWhite="textWhite" />
      <div class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4" id="navbar">
        
        <div class="pe-md-3 d-flex align-items-center ms-md-auto"></div>

        <ul class="navbar-nav justify-content-end">

          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a
              href="#"
              @click="toggleSidebar"
              class="p-0 nav-link text-body"
              id="iconNavbarSidenav"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
          </li>

          <li class="nav-item dropdown d-flex align-items-center pe-2">

            <a href="#" class="p-0 nav-link text-body" :class="[showMenu ? 'show' : '',]" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" @click="showMenu = !showMenu">
              &nbsp;<i class="fa fa-user-secret"></i>&nbsp;{{nombreCompleto}}
            </a>

            <ul class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4" :class="showMenu ? 'show' : ''" aria-labelledby="dropdownMenuButton">
              
              <li v-if="permiso === 'C' ? false : true " class="mb-2">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 row">
                    <div class="col my-auto">  
                      <i class="fa fa-user-secret">&nbsp;</i>
                    </div>
                    <div class="col d-flex justify-content-center">
                        <router-link :to="{ name: 'Perfil'}"><span class="font-weight-bold">Perfil</span></router-link>
                    </div>
                  </div>
                </a>
              </li>

              <!-- <li class="mb-2">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex row">
                    <div class="col my-auto">  
                      <i class="fa fa-history">&nbsp;</i>
                    </div>
                    <div class="col d-flex justify-content-center">
                        <router-link :to="{ name: 'Actividad'}"><span class="font-weight-bold">Actividad</span></router-link>
                    </div>
                  </div>
                </a>
              </li> -->

              <li v-if="permiso === 'C' ? false : true " class="mb-2">
                <a class="dropdown-item border-radius-md" @click="cerrarSesion" href="javascript:;">
                  <div class="py-1 d-flex row">
                    <div class="col my-auto">  
                      <i class="fa fa-sign-out">&nbsp;</i>
                    </div>
                    <div class="col d-flex justify-content-center">
                        <span class="font-weight-bold">Cerrar sesion</span>
                    </div>
                  </div>
                </a>
              </li>

              <li v-if="permiso === 'C' ? true : false " class="mb-2">
                <a class="dropdown-item border-radius-md" @click="cerrarSesionCliente" href="javascript:;">
                  <div class="py-1 d-flex row">
                    <div class="col my-auto">  
                      <i class="fa fa-sign-out">&nbsp;</i>
                    </div>
                    <div class="col d-flex justify-content-center">
                        <span class="font-weight-bold">Cerrar sesion cliente</span>
                    </div>
                  </div>
                </a>
              </li>

              
            </ul>
          </li>

        </ul>

      </div>

    </div>

  </nav>

</template>
<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapActions } from "vuex";

export default {
  name: "navbar",
  components: {Breadcrumbs},
  props: ["minNav", "textWhite"],

  data() {
    return {
      showMenu: false,
      nombreCompleto: localStorage.getItem('nombreCompleto'),
      permiso: localStorage.getItem('permiso')
    };
  },

  created() {
    this.minNav;
  },

  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),

    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },

    cerrarSesion(){
      localStorage.clear();
      this.$router.replace({name: 'Acceso'});
    },

    cerrarSesionCliente(){
      localStorage.clear();
      window.location.href = "https://google.com";
    }

  },
  
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },

  updated() {
    const navbar = document.getElementById("navbarBlur");
    window.addEventListener("scroll", () => {
      if (window.scrollY > 10 && this.$store.state.isNavFixed) {
        navbar.classList.add("blur");
        navbar.classList.add("position-sticky");
        navbar.classList.add("shadow-blur");
      } else {
        navbar.classList.remove("blur");
        navbar.classList.remove("position-sticky");
        navbar.classList.remove("shadow-blur");
      }
    });
  },
  
};
</script>
