import { ref } from 'vue';
import { operacion } from '@/helpers/axios';

import { notify } from "@kyvg/vue3-notification";

// fa-floppy-o
// @PidUnicoGrupoEmpresarial

const usePrincipal = () => {

    //#region Generales
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // statements
    const seleccionPest = ref({
        seleccion01: true,
        seleccion02: false,
        seleccion03: false
    });

    // handle
    const handleSelect = (selec) => {
        if(selec === 'C')
        {
            seleccionPest.value = {
                seleccion01: true,
                seleccion02: false,
                seleccion03: false
            };
        }
        else if(selec === 'I')
        {
            seleccionPest.value = {
                seleccion01: false,
                seleccion02: true,
                seleccion03: false
            };
        }
        else if(selec === 'U')
        {
            seleccionPest.value = {
                seleccion01: false,
                seleccion02: false,
                seleccion03: true
            };
        }
    };

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion Generales
    
    //#region Grupo Empresarial Modal
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // statements
    const processModalGrupoEmpresarial = ref({
        open: false,
        title: 'Grupo Empresarial'
    });
    const fieldGrupoEmpresarialIdUnico = ref('');
    const fieldGrupoEmpresarial = ref('');
    const fieldGrupoEmpresarialDescripcion = ref('');
    const fieldGrupoEmpresarialSiglas = ref('');
    const disableButtonGuardarGrupoEmpresarial = ref(false);
    const disableButtonEditarGuardarGrupoEmpresarial = ref(true);
    const disableButtonCancelarGuardarGrupoEmpresarial = ref(true);

    // handle

    const handleAbrirModalGrupoEmpresarial = () => {
        processModalGrupoEmpresarial.value = {
            open: true,
            title: 'Grupo Empresarial'
        };
    };

    const handleGuardarDatosGrupoEmpresarial = async() => {
        if(fieldGrupoEmpresarialIdUnico.value === '')
        {
            if(fieldGrupoEmpresarial.value === '' || fieldGrupoEmpresarialDescripcion.value === '' || fieldGrupoEmpresarialSiglas.value === '')
            {
                notify({
                    title: "Grupo Empresarial",
                    text: "Los campos de grupo empresarial y descripcion no pueden estar vacios."
                });
            }
            else
            {
                const data = await operacion(
                    {
                        numero : '51',
                        proceso : '5',
                        opcionTD : 'TODO',
                        parametros : true,
                        datosReceptor : 
                        {
                            opcion: 'guardarGrupoEmpresarial',
                            PgrupoEmpresarial: fieldGrupoEmpresarial.value,
                            Pdescripcion: fieldGrupoEmpresarialDescripcion.value,
                            Psiglas: fieldGrupoEmpresarialSiglas.value
                        },
                        mostrarMensaje: true
                    }
                );
                console.log(data);
                if(data.bandera === 0)
                {
                    notify({
                        title: "Grupo Empresarial",
                        text: data.mensaje
                    });
                    handleCancelarDatosGrupoEmpresarial();
                    handleDatosGrupoEmpresarial();
                }
                else
                {
                    notify({
                        title: "Grupo Empresarial",
                        text: data.mensaje
                    });
                }
            }
        }
        else
        {
            if(fieldGrupoEmpresarial.value === '' || fieldGrupoEmpresarialDescripcion.value === '' || fieldGrupoEmpresarialSiglas.value === '')
            {
                notify({
                    title: "Grupo Empresarial",
                    text: "Los campos de grupo empresarial y descripcion no pueden estar vacios."
                });
            }
            else
            {
                const data = await operacion(
                    {
                        numero : '51',
                        proceso : '5',
                        opcionTD : 'TODO',
                        parametros : true,
                        datosReceptor : 
                        {
                            opcion: 'actualizarGrupoEmpresarial',
                            PidUnico: fieldGrupoEmpresarialIdUnico.value,
                            PgrupoEmpresarial: fieldGrupoEmpresarial.value,
                            Pdescripcion: fieldGrupoEmpresarialDescripcion.value,
                            Psiglas: fieldGrupoEmpresarialSiglas.value
                        },
                        mostrarMensaje: true
                    }
                );
                console.log(data);
                if(data.bandera === 0)
                {
                    notify({
                        title: "Grupo Empresarial",
                        text: data.mensaje
                    });
                    handleCancelarDatosGrupoEmpresarial();
                    handleDatosGrupoEmpresarial();
                }
                else
                {
                    notify({
                        title: "Grupo Empresarial",
                        text: data.mensaje
                    });
                }
            }
        }
    };

    const handleCancelarDatosGrupoEmpresarial = () => {
        fieldGrupoEmpresarialIdUnico.value = '';
        fieldGrupoEmpresarial.value = '';
        fieldGrupoEmpresarialDescripcion.value = '';
        fieldGrupoEmpresarialSiglas.value = '';
        disableButtonGuardarGrupoEmpresarial.value = false;
        disableButtonEditarGuardarGrupoEmpresarial.value = true;
        disableButtonCancelarGuardarGrupoEmpresarial.value = true;
    };

    const handleCerrarModalGrupoEmpresarial = () => {
        processModalGrupoEmpresarial.value = {
            open: false,
            title: 'Grupo Empresarial'
        };
        handleCancelarDatosGrupoEmpresarial();
    };

    const handleEditarDatosGrupoEmpresarial = (data) => {
        fieldGrupoEmpresarialIdUnico.value = data.idUnico;
        fieldGrupoEmpresarial.value = data.grupoEmpresarial;
        fieldGrupoEmpresarialDescripcion.value = data.descripcion;
        fieldGrupoEmpresarialSiglas.value = data.siglas;
        disableButtonGuardarGrupoEmpresarial.value = true;
        disableButtonEditarGuardarGrupoEmpresarial.value = false;
        disableButtonCancelarGuardarGrupoEmpresarial.value = false;
    };

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion Grupo Empresarial
    
    //#region Clientes
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // statements
    const jsonData = ref({});
    const datosSeleccionados = ref([]);
    const fieldSelectGrupoEmpresarialClientes = ref(0);
    const disabledProcesoClientesGrupoEmpresarial = ref({
        textoBotonSeleccionar: 'Seleccionar',
        select: false,
        buttonSeleccionar: false,
        buttonActualizar: true,
        buttonGuardar: true,
        buttonQuitarSeleccion: true
    });
    const jsonGrupoEmpresarial = ref({});
    // handle
    const handleEmpresasCliente = async() => {
        datosSeleccionados.value = [];
        const data = await operacion(
            {
                numero : '51',
                proceso : '1',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'tablaEmpresas',
                    PidUnicoGrupoEmpresarial: fieldSelectGrupoEmpresarialClientes.value
                },
                mostrarMensaje: false
            }
        );
        jsonData.value = {
            encabezados: ['Nombre Corto', 'Razon Social', 'RFC', 'Siglas'],
            camposMostrar: ['nombreCorto', 'razonSocial', 'rfc', 'siglas'],
            registros: data.tablas.Table1,
            opcionesRegistro: false,
            opcionesRegistroActualizar: true,
            opcionesRegistroGuardar: true
        };
        data.tablas.Table2.map( (valor) => {
            datosSeleccionados.value = [...datosSeleccionados.value, valor.idSysTSEmpresas];
        });
    };

    const handleActualizarRegistros = async() => {
        await handleEmpresasCliente();
        notify({
            title: "Actualizacion",
            text: "Los datos fueron acturoizados con exito",
          });
    };

    const handleGuardarRegistros = async() => {
        const data = await operacion(
            {
                numero : '51',
                proceso : '1',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'guardarClientes',
                    PidSysTSEmpresas: JSON.stringify(datosSeleccionados.value).replace('[','').replace(']',''),
                    PidUnicoGrupoEmpresarial: fieldSelectGrupoEmpresarialClientes.value
                },
                mostrarMensaje: false
            }
        );
        if(data.bandera === 0)
        {
            notify({
                title: "Actualizacion",
                text: "Los datos fueron acturoizados con exito"
            });
        }
        else
        {
            notify({
                title: "Actualizacion",
                text: data.mensaje
            });
        }
        
    };

    const handleSeleccionarGrupoEmpresarialCliente = async() => {

        if(disabledProcesoClientesGrupoEmpresarial.value.textoBotonSeleccionar === 'Seleccionar')
        {
            if(fieldSelectGrupoEmpresarialClientes.value === 0)
            {
                notify({
                    title: "Clientes",
                    text: "Debes de elegir un grupo empresarial."
                });
            }
            else
            {
                disabledProcesoClientesGrupoEmpresarial.value = {
                    textoBotonSeleccionar: 'Editar',
                    select: true,
                    buttonSeleccionar: false,
                    buttonActualizar: false,
                    buttonGuardar: false,
                    buttonQuitarSeleccion: false
                };
                await handleEmpresasCliente();
            }
        }
        else
        {
            fieldSelectGrupoEmpresarialClientes.value = 0;
            disabledProcesoClientesGrupoEmpresarial.value = {
                textoBotonSeleccionar: 'Seleccionar',
                select: false,
                buttonSeleccionar: false,
                buttonActualizar: true,
                buttonGuardar: true,
                buttonQuitarSeleccion: true
            };
            jsonData.value = {};
            datosSeleccionados.value = [];
        }

    };

    const handleDatosGrupoEmpresarial = async() => {
        const data = await operacion(
            {
                numero : '51',
                proceso : '5',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'tablaGrupoEmpresarial'
                },
                mostrarMensaje: false
            }
        );
        jsonGrupoEmpresarial.value = {
            encabezados: ['Grupo Empresarial', 'Descripcion', 'Siglas'],
            camposMostrar: ['grupoEmpresarial', 'descripcion', 'siglas'],
            registros: data.tablas.Table1,
            opcionesRegistro: false,
            opcionesRegistroActualizar: true,
            opcionesRegistroGuardar: true
        };
    };
    handleDatosGrupoEmpresarial();

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion Clientes

    //#region Internas
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // statements
    const jsonDataInternas = ref({});
    const datosSeleccionadosInternas = ref([]);
    const fieldSelectGrupoEmpresarialInternas = ref(0);
    const disabledProcesoInternasGrupoEmpresarial = ref({
        textoBotonSeleccionar: 'Seleccionar',
        select: false,
        buttonSeleccionar: false,
        buttonActualizar: true,
        buttonGuardar: true,
        buttonQuitarSeleccion: true
    });
    // handle
    const handleEmpresasInternas = async() => {
        datosSeleccionadosInternas.value = [];
        const data = await operacion(
            {
                numero : '51',
                proceso : '4',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'tablaEmpresas',
                    PidUnicoGrupoEmpresarial: fieldSelectGrupoEmpresarialInternas.value
                },
                mostrarMensaje: false
            }
        );
        jsonDataInternas.value = {
            encabezados: ['Nombre Corto', 'Razon Social', 'RFC', 'Siglas'],
            camposMostrar: ['nombreCorto', 'razonSocial', 'rfc', 'siglas'],
            registros: data.tablas.Table1,
            opcionesRegistro: false,
            opcionesRegistroActualizar: true,
            opcionesRegistroGuardar: true
        };
        data.tablas.Table2.map( (valor) => {
            datosSeleccionadosInternas.value = [...datosSeleccionadosInternas.value, valor.idSysCPEmpresasRP];
        });
    };

    const handleActualizarRegistrosInternas = async() => {
        await handleEmpresasInternas();
        notify({
            title: "Actualizacion",
            text: "Los datos fueron acturoizados con exito",
          });
    };

    const handleGuardarRegistrosInternas = async() => {
        const data = await operacion(
            {
                numero : '51',
                proceso : '4',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'guardarInternas',
                    PidSysCPEmpresasRP: JSON.stringify(datosSeleccionadosInternas.value).replace('[','').replace(']',''),
                    PidUnicoGrupoEmpresarial: fieldSelectGrupoEmpresarialInternas.value
                },
                mostrarMensaje: false
            }
        );
        if(data.bandera === 0)
        {
            notify({
                title: "Actualizacion",
                text: "Los datos fueron acturoizados con exito"
            });
        }
        else
        {
            notify({
                title: "Actualizacion",
                text: data.mensaje
            });
        }
        
    };

    const handleSeleccionarGrupoEmpresarialInternas = async() => {

        if(disabledProcesoInternasGrupoEmpresarial.value.textoBotonSeleccionar === 'Seleccionar')
        {
            if(fieldSelectGrupoEmpresarialInternas.value === 0)
            {
                notify({
                    title: "Clientes",
                    text: "Debes de elegir un grupo empresarial."
                });
            }
            else
            {
                disabledProcesoInternasGrupoEmpresarial.value = {
                    textoBotonSeleccionar: 'Editar',
                    select: true,
                    buttonSeleccionar: false,
                    buttonActualizar: false,
                    buttonGuardar: false,
                    buttonQuitarSeleccion: false
                };
                await handleEmpresasInternas();
            }
        }
        else
        {
            fieldSelectGrupoEmpresarialInternas.value = 0;
            disabledProcesoInternasGrupoEmpresarial.value = {
                textoBotonSeleccionar: 'Seleccionar',
                select: false,
                buttonSeleccionar: false,
                buttonActualizar: true,
                buttonGuardar: true,
                buttonQuitarSeleccion: true
            };
            jsonDataInternas.value = {};
            datosSeleccionadosInternas.value = [];
        }

    };


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion Internas

    //#region Usuarios
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // statements
    const jsonDataUsuario = ref({});
    const datosSeleccionadosUsuario = ref([]);

    // handle
    const handleEmpresasUsuarios = async() => {
        datosSeleccionadosUsuario.value = [];
        const data = await operacion(
            {
                numero : '51',
                proceso : '2',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'tablaUsuarios'
                },
                mostrarMensaje: false
            }
        );
        jsonDataUsuario.value = {
            encabezados: ['Nombre Completo', 'Nombre Usuario', 'Correo', 'Estatus'],
            camposMostrar: ['nombreCompleto', 'nombreUsuario', 'correo', 'idEstatus'],
            registros: data.tablas.Table1,
            opcionesRegistro: false,
            opcionesRegistroActualizar: true,
            opcionesRegistroGuardar: true
        };
        data.tablas.Table2.map( (valor) => {
            datosSeleccionadosUsuario.value = [...datosSeleccionadosUsuario.value, valor.idUsuario];
        });
    };
    handleEmpresasUsuarios();

    const handleActualizarRegistrosUsuarios = async() => {
        await handleEmpresasUsuarios();
        notify({
            title: "Actualizacion",
            text: "Los datos fueron actualizados con exito.",
          });
    };

    const handleGuardarRegistrosUsuarios = async() => {
        const data = await operacion(
            {
                numero : '51',
                proceso : '2',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'guardarUsuario',
                    PidUsuario: JSON.stringify(datosSeleccionadosUsuario.value).replace('[','').replace(']','')
                },
                mostrarMensaje: false
            }
        );
        if(data.bandera === 0)
        {
            notify({
                title: "Actualizacion",
                text: "Los datos fueron acturoizados con exito."
            });
        }
        
    };

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion Usuarios

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return{
        seleccionPest,
        processModalGrupoEmpresarial,
        fieldGrupoEmpresarialIdUnico,
        fieldGrupoEmpresarial,
        fieldGrupoEmpresarialDescripcion,
        fieldGrupoEmpresarialSiglas,
        disableButtonGuardarGrupoEmpresarial,
        disableButtonEditarGuardarGrupoEmpresarial,
        disableButtonCancelarGuardarGrupoEmpresarial,
        jsonData,
        datosSeleccionados,
        fieldSelectGrupoEmpresarialClientes,
        disabledProcesoClientesGrupoEmpresarial,
        jsonGrupoEmpresarial,
        jsonDataInternas,
        datosSeleccionadosInternas,
        fieldSelectGrupoEmpresarialInternas,
        disabledProcesoInternasGrupoEmpresarial,
        jsonDataUsuario,
        datosSeleccionadosUsuario,

        handleSelect,
        handleAbrirModalGrupoEmpresarial,
        handleGuardarDatosGrupoEmpresarial,
        handleCancelarDatosGrupoEmpresarial,
        handleCerrarModalGrupoEmpresarial,
        handleEditarDatosGrupoEmpresarial,
        handleEmpresasCliente,
        handleActualizarRegistros,
        handleGuardarRegistros,
        handleSeleccionarGrupoEmpresarialCliente,
        handleEmpresasInternas,
        handleActualizarRegistrosInternas,
        handleGuardarRegistrosInternas,
        handleSeleccionarGrupoEmpresarialInternas,
        handleActualizarRegistrosUsuarios,
        handleGuardarRegistrosUsuarios
    }
};

export default usePrincipal;