import { ref } from 'vue';
import { layoutBancario } from '@/helpers/general';


let jsonData = [{
    "idUnicoBeneficiario": "E77AAC52F2354600A7394EB7F98F5549",
    "tipoAlta": "Persona",
    "idUnicoGrupo": "A31E37A7A4844D338AD30DA3CD172724",
    "nombreGrupo": "NORMAL",
    "usoCFDI": "P01",
    "codigoBeneficiario": "00002",
    "nombre": "CAMACHO CARREON ELSY GIOVANNA",
    "razonSocial": null,
    "rfc": "CACE970125JT9",
    "curp": "CACE970125MPLMRL00",
    "banco": "HSBC",
    "cuentaBanco": "6510094464",
    "clabeBanco": "021650065100944647",
    "idCatBanco": "021",
    "numeroInstitutoBancoSantander": 40021,
    "claveTransferenciaBancoSantander": "BITAL",
    "numeroInstitutoKuspit": "",
    "monto": "433.30",
    "retorno": "433.30",
    "idUnicoBancosLayOut": "ASD4654ASD",
    "clabeBancariaEmpresa": "014180655090659162",
    "cuentaBancariaEmpresa": "65509065916",
    "activo": 1,
    "fechaPago": "01062022"
  }, {
    "idUnicoBeneficiario": "E338EFA504884EACA58731269382553A",
    "tipoAlta": "Persona",
    "idUnicoGrupo": "A31E37A7A4844D338AD30DA3CD172724",
    "nombreGrupo": "NORMAL",
    "usoCFDI": "P01",
    "codigoBeneficiario": "00006",
    "nombre": "CASTILLA ORTEGA ABRAHAM",
    "razonSocial": null,
    "rfc": "CAOA8506103I9",
    "curp": "CAOA850610HPLSRB07",
    "banco": "BBVA BANCOMER",
    "cuentaBanco": "1599271498",
    "clabeBanco": "012650015992714989",
    "idCatBanco": "012",
    "numeroInstitutoBancoSantander": 40012,
    "claveTransferenciaBancoSantander": "BACOM",
    "numeroInstitutoKuspit": "",
    "monto": "7,433.30",
    "retorno": "7,433.30",
    "idUnicoBancosLayOut": "ASD4654ASD",
    "clabeBancariaEmpresa": "014180655090659162",
    "cuentaBancariaEmpresa": "65509065916",
    "activo": 1,
    "fechaPago": "01062022"
  }, {
    "idUnicoBeneficiario": "27968A58F7B841008F6AD3B5E2913D60",
    "tipoAlta": "Persona",
    "idUnicoGrupo": "A31E37A7A4844D338AD30DA3CD172724",
    "nombreGrupo": "NORMAL",
    "usoCFDI": "P01",
    "codigoBeneficiario": "00005",
    "nombre": "MEDINA ANDRES ELIDETH",
    "razonSocial": null,
    "rfc": "MEAE9809202M5",
    "curp": "MEAE980920MNEDNL06",
    "banco": "BANCOPPEL",
    "cuentaBanco": "10408676199",
    "clabeBanco": "137650104086761993",
    "idCatBanco": "137",
    "numeroInstitutoBancoSantander": 40137,
    "claveTransferenciaBancoSantander": "COPEL",
    "numeroInstitutoKuspit": "",
    "monto": "433.30",
    "retorno": "433.30",
    "idUnicoBancosLayOut": "ASD4654ASD",
    "clabeBancariaEmpresa": "014180655090659162",
    "cuentaBancariaEmpresa": "65509065916",
    "activo": 1,
    "fechaPago": "01062022"
  }, {
    "idUnicoBeneficiario": "5C078285F0374B51914FFBAEA976293B",
    "tipoAlta": "Persona",
    "idUnicoGrupo": "A31E37A7A4844D338AD30DA3CD172724",
    "nombreGrupo": "NORMAL",
    "usoCFDI": "P01",
    "codigoBeneficiario": "00001",
    "nombre": "ROSALES BRETON ELSA MARIA DEL CONSUELO",
    "razonSocial": null,
    "rfc": "ROBE591021HU1",
    "curp": "RXBE591021MPLSRL00",
    "banco": "BBVA BANCOMER",
    "cuentaBanco": "1583734679",
    "clabeBanco": "012650015837346797",
    "idCatBanco": "012",
    "numeroInstitutoBancoSantander": 40012,
    "claveTransferenciaBancoSantander": "BACOM",
    "numeroInstitutoKuspit": "",
    "monto": "10,500.00",
    "retorno": "10,500.00",
    "idUnicoBancosLayOut": "ASD4654ASD",
    "clabeBancariaEmpresa": "014180655090659162",
    "cuentaBancariaEmpresa": "65509065916",
    "activo": 1,
    "fechaPago": "01062022"
  }, {
    "idUnicoBeneficiario": "E7E5A4C28E9F4E02B95EEB82B2F8218C",
    "tipoAlta": "Persona",
    "idUnicoGrupo": "A31E37A7A4844D338AD30DA3CD172724",
    "nombreGrupo": "NORMAL",
    "usoCFDI": "P01",
    "codigoBeneficiario": "00004",
    "nombre": "SANCHEZ GUZMAN MARIA DEL CARMEN",
    "razonSocial": null,
    "rfc": "SAGC690610ND1",
    "curp": "SAGC690610MPLNZR08",
    "banco": "BBVA BANCOMER",
    "cuentaBanco": "1259215913",
    "clabeBanco": "012180012592159132",
    "idCatBanco": "012",
    "numeroInstitutoBancoSantander": 40012,
    "claveTransferenciaBancoSantander": "BACOM",
    "numeroInstitutoKuspit": "",
    "monto": "6,433.30",
    "retorno": "6,433.30",
    "idUnicoBancosLayOut": "ASD4654ASD",
    "clabeBancariaEmpresa": "014180655090659162",
    "cuentaBancariaEmpresa": "65509065916",
    "activo": 1,
    "fechaPago": "01062022"
  }, {
    "idUnicoBeneficiario": "F205570067B5446EBEC0889116443D43",
    "tipoAlta": "Persona",
    "idUnicoGrupo": "A31E37A7A4844D338AD30DA3CD172724",
    "nombreGrupo": "NORMAL",
    "usoCFDI": "P01",
    "codigoBeneficiario": "00003",
    "nombre": "VILLA v JORGE ALBERTO",
    "razonSocial": null,
    "rfc": "VIDJ791021S10",
    "curp": "VIDJ791021HDFLLR09",
    "banco": "BANAMEX",
    "cuentaBanco": "70120780628",
    "clabeBanco": "002180701207806282",
    "idCatBanco": "002",
    "numeroInstitutoBancoSantander": 40002,
    "claveTransferenciaBancoSantander": "BANAM",
    "numeroInstitutoKuspit": "",
    "monto": "12,633.30",
    "retorno": "12,633.30",
    "idUnicoBancosLayOut": "ASD4654ASD",
    "clabeBancariaEmpresa": "014180655090659162",
    "cuentaBancariaEmpresa": "65509065916",
    "activo": 1,
    "fechaPago": "01062022"
}];
  
  let jsonConfigSpeiTerceros = [
      {
        tipo: 'datos',
        datosConfig: {
          config: [
            {
              tipo: 'caracter',
              texto: 'LTX07',
              campoConfi: {
                tamaño: 5,
                caracterRellenoInicio: '',
                caracterRellenoFin: ' '
              }
            },
            {
              tipo: 'campo',
              campo: 'cuentaBancariaEmpresa',
              campoConfi: {
                tamaño: 18,
                caracterRellenoInicio: '',
                caracterRellenoFin: ' '
              }
            },
            {
              tipo: 'campo',
              campo: 'cuentaBancariaEmpresa',
              campoConfi: {
                tamaño: 20,
                caracterRellenoInicio: '',
                caracterRellenoFin: ' '
              }
            },
            {
              tipo: 'campoMonto',
              campo: 'retorno',
              campoConfi: {
                tamaño: 18,
                caracterRellenoInicio: '0',
                caracterRellenoFin: ''
              }
            },
            {
              tipo: 'caracter',
              texto: 'ASIMILADOS 06PAF01',
              campoConfi: {
                tamaño: 40,
                caracterRellenoInicio: '',
                caracterRellenoFin: ' '
              }
            },
            {
              tipo: 'campo',
              campo: 'fechaPago',
              campoConfi: {
                tamaño: 8,
                caracterRellenoInicio: '0',
                caracterRellenoFin: ''
              }
            },
            {
                tipo: 'caracter',
                texto: '',
                campoConfi: {
                    tamaño: 40,
                    caracterRellenoInicio: '',
                    caracterRellenoFin: ' '
                }
            }
          ]
        },
        
      }
];

const usePrincipal = () => {

    const titulo = ref('Hola como estas');

    const handleCorreo = async() => {
        let layOutBancario = await layoutBancario(jsonConfigSpeiTerceros, jsonData) ;

        console.log( JSON.stringify( jsonConfigSpeiTerceros ) )
        console.log({
            nombreArchivo: 'Hola.txt', 
            layOutBancario
        })
    }

    handleCorreo();

    return{
        titulo
    }

};

export default usePrincipal;