
export const convertirFecha = (fecha) => {

    const dia = new Date(fecha).toLocaleDateString('es-MX', { day: 'numeric' }).toString().padStart(2, 0);
    const mes = new Date(fecha).toLocaleDateString('es-MX', { month: 'numeric' }).toString().padStart(2, 0);
    const anio = new Date(fecha).toLocaleDateString('es-MX', { year: 'numeric' }).toString().padStart(4, 0);
    // const hora = new Date(fecha).toLocaleTimeString('es-MX');
    
    return `${anio}-${mes}-${dia}`;

};

export const convertirFechaMes = (fecha) => {

  // const dia = new Date(fecha).toLocaleDateString('es-MX', { day: 'numeric' }).toString().padStart(2, 0);
  const mes = new Date(fecha).toLocaleDateString('es-MX', { month: 'numeric' }).toString().padStart(2, 0);
  // const anio = new Date(fecha).toLocaleDateString('es-MX', { year: 'numeric' }).toString().padStart(4, 0);
  // const hora = new Date(fecha).toLocaleTimeString('es-MX');
  
  return `${mes}`;

};

export const isNumber = (evt) => {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
      evt.preventDefault();;
    } else {
      return true;
    }
}

export const isDecimal = (evt) => {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
      evt.preventDefault();;
    } else {
      return true;
    }
}

export const isDecimal02 = (evt) => {
  evt = (evt) ? evt : window.event;
  var charCode = (evt.which) ? evt.which : evt.keyCode;
  if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 44) {
    evt.preventDefault();;
  } else {
    return true;
  }
}

export const imagenToBase64 = async(archivo) => {
  return new Promise(resolve => {
      if (archivo !== null || archivo !== '') {
          if (archivo.type === 'image/jpeg' || archivo.type === 'image/jpg' || archivo.type === 'image/png' || archivo.type === 'application/pdf') {
              const leer = new FileReader();
              leer.readAsDataURL(archivo);
              leer.onload = () => resolve({
                  bandera: true,
                  base64: leer.result,
                  name: archivo.name
              });
          } else {
              resolve({
                  bandera: false,
                  base64: '',
                  name: ''
              });
          }
      } else {
          resolve({
              bandera: false,
              base64: '',
              name: ''
          });
      }



  });

};

export const formatNumero = (value) => {
  value = value.toString().replace(/,/g, '').replace('$','')
  let val = (value/1).toFixed(2);
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "");
}

export const formatoMexico = (value) => {
  value = value.toString().replace(/,/g, '')

  let val = (value/1).toFixed(2);
  const exp = /(\d)(?=(\d{3})+(?!\d))/g;
  const rep = '$1,';
  return val.toString().replace(exp,rep);
}



export const layoutBancario = async(jsonLayOut, jsonDatos) => {

  // let encabezados = '';
    let contenidos = '';
    
    jsonLayOut.map( (dato) => {

        // if( dato.tipo === 'encabezado' )
        // {
        //   encabezados += dato;
        //   encabezados += '\n';
        // }

        if( dato.tipo === 'datos' )
        {

          jsonDatos.map( (datoDatos, index) => {

            dato.datosConfig.config.map( (datoConfig) => {
              
              if( datoConfig.tipo === 'autonumerico' )
              {
                contenidos += (datoConfig.inicariEn + index).toString().substring(0, datoConfig.campoConfi.tamaño).padStart(datoConfig.campoConfi.tamaño, datoConfig.campoConfi.caracterRellenoInicio).padEnd(datoConfig.campoConfi.tamaño, datoConfig.campoConfi.caracterRellenoFin);
              }

              else if( datoConfig.tipo === 'caracter' )
              {
                contenidos += datoConfig.texto.substring(0, datoConfig.campoConfi.tamaño).padStart(datoConfig.campoConfi.tamaño, datoConfig.campoConfi.caracterRellenoInicio).padEnd(datoConfig.campoConfi.tamaño, datoConfig.campoConfi.caracterRellenoFin);
              }

              else if( datoConfig.tipo === 'campo' )
              {
                contenidos += datoDatos[datoConfig.campo].toString().substring(0, datoConfig.campoConfi.tamaño).padStart(datoConfig.campoConfi.tamaño, datoConfig.campoConfi.caracterRellenoInicio).padEnd(datoConfig.campoConfi.tamaño, datoConfig.campoConfi.caracterRellenoFin);
              }

              else if( datoConfig.tipo === 'campoMonto' )
              {
                contenidos += datoDatos[datoConfig.campo].toString().replace(',','').replace('.','').substring(0, datoConfig.campoConfi.tamaño).padStart(datoConfig.campoConfi.tamaño, datoConfig.campoConfi.caracterRellenoInicio).padEnd(datoConfig.campoConfi.tamaño, datoConfig.campoConfi.caracterRellenoFin);
              }

            } );

            contenidos += '\n';

          } );

        }

      } 
    );

    // console.log(contenidos);
    const archivo = new Blob([ (contenidos) ], { type: 'text/plain' });

    return await blobToBase64(archivo);
};

const blobToBase64 = blob => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});


