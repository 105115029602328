import { ref, watch } from 'vue';
import { operacion } from '@/helpers/axios';
import { notify } from '@kyvg/vue3-notification';

import { isDecimal02, convertirFecha, convertirFechaMes, formatoMexico, formatNumero, imagenToBase64 } from '@/helpers/general';

const shortid =  require('shortid');

const useNuevaSolicitud = () => {

    // Nueva solicitud Declaraciones

    const dataParametros = ref({});

    const jsonGrupoEmpresarial = ref([]);
    const fieldSelectGrupoEmpresarial = ref('0');
    const processBotonSeleccionarGrupoEmpresarial = ref({
        texto: 'Nueva solicitud',
        seleccionado: false
    });
    const fieldFolioSolicitud = ref('');
    const fieldFechaSolicitud = ref('');
    const fieldSaldoAFavor = ref('0.00')
    const fieldSaldoAFavorUtilizado = ref('0.00')
    const fieldActivarFinanciamiento = ref(false);
    const fieldFinanciamiento = ref('0.00');
    const fieldFinanciamientoUtilizado = ref('0.00');
    const fieldOperarCon = ref('S/D');
    const jsonTablaClientes = ref([]);
    const jsonEmpresasInternas = ref([]);
    
    const processModalDepositos = ref({
        open: false,
        title: 'Deposito'
    });
    const fieldComprobanteFile = ref({});
    const fieldRazonSocialDeposita = ref('S/D');
    const fieldRazonSocialRecibeDeposito = ref('S/D');
    const fieldDatosFacturacion = ref('S/D');
    const fieldTipoMovimiento = ref('S/D');
    const fieldMontoDeposito = ref('0.00');
    const fieldFactura = ref('Pendiente');
    const jsonConceptosFactura = ref([]);
    const fieldValidacion = ref('');

     const processModalSaldoAFavor = ref({
        open: false,
        title: 'Saldo a favor'
    });
    const processModalFinanciamiento = ref({
        open: false,
        title: 'Financiamiento'
    });

    const jsonDepositos = ref([]);
    const fieldTotalDeposito = ref('0.00');
    const fieldSubTotalDeposito = ref('0.00');
    const fieldIVADeposito = ref('0.00');
    const fieldComisionDeposito = ref('0.00');
    const jsonBeneficiarios = ref([]);
    const jsonRegistrosExcelAsimilados = ref([]);
    const modalRegistrosExcelAsimilados = ref(false);
    const jsonLayOutBancariosNomina = ref([]);
    const fieldDisponibleCliente = ref('0.00');
    const fieldDisponibleClienteAsimilado = ref('0.00');
    const fieldSaldoClienteAsimilado = ref('0.00');
    const fieldDisponibleClienteEfectivoSinRecibo = ref('0.00');
    const fieldSaldoClienteEfectivoSinRecibo = ref('0.00');
    const fieldUtilizadoClienteEfectivoSinRecibo = ref('0.00');
    const fieldMontoRetornar = ref('0.00');
    const jsonRegistrosExcelSinRecibo = ref([]);
    const modalRegistrosExcelSinRecibo = ref(false);
    const jsonLayOutBancariosSinRecibo = ref([]);
    const jsonRegistrosExcelEfectivo = ref([]);
    const modalRegistrosExcelEfectivo = ref(false);
    const fieldUtilizadoClienteAsimilado = ref('0.00');
    const fieldFechaLayoutPago = ref(new Date());
    const fieldCorreoBeneficiario = ref('');
    const fieldComisionPromotor = ref('0.00');
    const fieldComisionPesos = ref('0.00');
    const fieldActivarComisionPesosExtra = ref(false);
    const fieldIVA = ref('0.00');
    const fieldDevolucionIVA = ref('0.00');
    const fieldRetorno = ref('0.00');
    const fieldSaldo = ref('0.00');
    const buttonDisabledPrecalculoGuardar = ref(false);
    const fieldComisionPesosExtra = ref('0.00')
    const solicitud = ref(true);

    // Nueva solicitud Handle

    const handleDatosGrupoEmpresarial = async() => {
        const data = await operacion(
            {
                numero : '51',
                proceso : '5',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'tablaGrupoEmpresarial'
                },
                mostrarMensaje: true
            }
        );
        jsonGrupoEmpresarial.value = data.tablas.Table1;
    };
    handleDatosGrupoEmpresarial();

    const handleProcesoBotonSeleccionarGrupoEmpresarial = async() => {

        if(fieldSelectGrupoEmpresarial.value !== '0')
        {
            if(processBotonSeleccionarGrupoEmpresarial.value.seleccionado)
            {
                processBotonSeleccionarGrupoEmpresarial.value = {
                    texto: 'Nueva solicitud',
                    seleccionado: false
                };
                fieldSelectGrupoEmpresarial.value = '0';
                fieldFolioSolicitud.value = '';
                fieldFechaSolicitud.value = '';
                fieldActivarFinanciamiento.value = false;
                fieldSaldoAFavor.value = '0.00';
                fieldSaldoAFavorUtilizado.value = '0.00';
                fieldFinanciamiento.value = '0.00';
                fieldFinanciamientoUtilizado.value = '0.00';
                fieldOperarCon.value = 'S/D';
                fieldFechaLayoutPago.value = new Date();
                fieldCorreoBeneficiario.value = '';
                jsonDepositos.value = [];
                fieldTotalDeposito.value = '0.00';
                fieldSubTotalDeposito.value = '0.00';
                fieldIVADeposito.value = '0.00';
                fieldDisponibleCliente.value = '0.00';

                fieldComisionDeposito.value = '0.00';

                fieldDisponibleClienteAsimilado.value = '0.00';
                fieldUtilizadoClienteAsimilado.value = '0.00';
                fieldSaldoClienteAsimilado.value = '0.00';

                fieldDisponibleClienteEfectivoSinRecibo.value = '0.00';
                fieldUtilizadoClienteEfectivoSinRecibo.value = '0.00';
                fieldSaldoClienteEfectivoSinRecibo.value = '0.00';

                fieldMontoRetornar.value = '0.00';
                fieldComisionPromotor.value = '0.00';
                fieldComisionPesos.value = '0.00';
                fieldComisionPesosExtra.value = '0.00';
                fieldIVA.value = '0.00';
                fieldDevolucionIVA.value = '0.00';
                fieldRetorno.value = '0.00';
                fieldSaldo.value = '0.00';

                jsonLayOutBancariosNomina.value = [];
                jsonRegistrosExcelAsimilados.value = [];

                jsonLayOutBancariosSinRecibo.value = [];
                jsonRegistrosExcelSinRecibo.value = [];

                jsonRegistrosExcelEfectivo.value = [];

                fieldActivarComisionPesosExtra.value = false;

                solicitud.value = true;
            }
            else
            {
                processBotonSeleccionarGrupoEmpresarial.value = {
                    texto: 'Cancelar solicitud',
                    seleccionado: true
                };

                const data = await operacion(
                    {
                        numero : '51',
                        proceso : '5',
                        opcionTD : 'TODO',
                        parametros : true,
                        datosReceptor : 
                        {
                            opcion: 'tablaParametrosNuevaSolicitud',
                            PidUnico: fieldSelectGrupoEmpresarial.value
                        },
                        mostrarMensaje: true
                    }
                );

                // let activar =  false;
                // if(data.tablas.Table5.length > 0)
                // {
                //     console.log('Si')
                //     console.log(data.tablas.Table5[0].jsonGenerales);
                //     activar = JSON.parse( data.tablas.Table5[0].jsonGenerales ).fieldActivarComisionPesosExtra === 1 ? true : false;
                // }
                // else
                // {
                //     console.log('No')
                //     activar = false;
                // }
                


                dataParametros.value = {
                    datosGenerales: data.tablas.Table1,
                    empresasCliente: data.tablas.Table2,
                    empresasInternas: data.tablas.Table3,
                    conceptosFactura: data.tablas.Table4.length > 0 ? JSON.parse( data.tablas.Table4[0].jsonConceptosFactura ) : [],
                    parametrosCalculo: {
                        parametros: {
                            fieldSelectPromotores: data.tablas.Table5.length > 0 ? JSON.parse( data.tablas.Table5[0].jsonGenerales ).fieldSelectPromotores.toString() : 'S/D',
                            fieldPorcentajePromotores: data.tablas.Table5.length > 0 ? JSON.parse( data.tablas.Table5[0].jsonGenerales ).fieldPorcentajePromotores.toString() : '0',
                            fieldOperacionPorcentajePromotores: data.tablas.Table5.length > 0 ? JSON.parse( data.tablas.Table5[0].jsonGenerales ).fieldOperacionPorcentajePromotores.toString() : '0',
                            
                            fieldPorcentajeComisionPorDepositoMovAsimilado: data.tablas.Table5.length > 0 ? JSON.parse( data.tablas.Table5[0].jsonGenerales ).fieldPorcentajeComisionPorDepositoMovAsimilado.toString() : '0',
                            fieldPorcentajeComisionPorDepositoMovNormal: data.tablas.Table5.length > 0 ? JSON.parse( data.tablas.Table5[0].jsonGenerales ).fieldPorcentajeComisionPorDepositoMovNormal.toString() : '0',
                            fieldPorcentajeComisionOperacion: data.tablas.Table5.length > 0 ? JSON.parse( data.tablas.Table5[0].jsonGenerales ).fieldPorcentajeComisionOperacion.toString() : '0',
                            
                            fieldPorcentajeComision01Asimilado: data.tablas.Table5.length > 0 ? JSON.parse( data.tablas.Table5[0].jsonGenerales ).fieldPorcentajeComision01Asimilado.toString() : '0',
                            fieldPorcentajeComision01Normal: data.tablas.Table5.length > 0 ? JSON.parse( data.tablas.Table5[0].jsonGenerales ).fieldPorcentajeComision01Normal.toString() : '0',
                            fieldPorcentajeComision01Operacion: data.tablas.Table5.length > 0 ? JSON.parse( data.tablas.Table5[0].jsonGenerales ).fieldPorcentajeComision01Operacion.toString() : '0',
                            
                            fieldOperacionTotalDisponibleCliente: data.tablas.Table5.length > 0 ? JSON.parse( data.tablas.Table5[0].jsonGenerales ).fieldOperacionTotalDisponibleCliente.toString() : '0',
                            fieldComisionPesosPorcentaje: data.tablas.Table5.length > 0 ? JSON.parse( data.tablas.Table5[0].jsonGenerales ).fieldComisionPesosPorcentaje.toString() : '0',
                            fieldComisionPesosOperacion: data.tablas.Table5.length > 0 ? JSON.parse( data.tablas.Table5[0].jsonGenerales ).fieldComisionPesosOperacion.toString() : '0',
                            
                            fieldActivarComisionPesosExtra: data.tablas.Table5.length > 0 ? JSON.parse( data.tablas.Table5[0].jsonGenerales ).fieldActivarComisionPesosExtra : false,
                            fieldComisionPesosExtraPorcentaje: data.tablas.Table5.length > 0 ? JSON.parse( data.tablas.Table5[0].jsonGenerales ).fieldComisionPesosExtraPorcentaje.toString() : '0',
                            fieldComisionPesosExtraOperacion: data.tablas.Table5.length > 0 ? JSON.parse( data.tablas.Table5[0].jsonGenerales ).fieldComisionPesosExtraOperacion.toString() : '0',
                            
                            fieldIVAPorcentaje: data.tablas.Table5.length > 0 ? JSON.parse( data.tablas.Table5[0].jsonGenerales ).fieldIVAPorcentaje.toString() : '0',
                            fieldIVAOperacion: data.tablas.Table5.length > 0 ? JSON.parse( data.tablas.Table5[0].jsonGenerales ).fieldIVAOperacion.toString() : '0',
                            
                            fieldDevolucionIVAPorcentaje: data.tablas.Table5.length > 0 ? JSON.parse( data.tablas.Table5[0].jsonGenerales ).fieldDevolucionIVAPorcentaje.toString() : '0',
                            fieldDevolucionIVAOperacion: data.tablas.Table5.length > 0 ? JSON.parse( data.tablas.Table5[0].jsonGenerales ).fieldDevolucionIVAOperacion.toString() : '0',

                            fieldRetornoPorcentaje: data.tablas.Table5.length > 0 ? JSON.parse( data.tablas.Table5[0].jsonGenerales ).fieldRetornoPorcentaje.toString() : '0',
                            fieldRetornoOperacion: data.tablas.Table5.length > 0 ? JSON.parse( data.tablas.Table5[0].jsonGenerales ).fieldRetornoOperacion.toString() : '0'
                        },
                        idUnicoPlantilla: data.tablas.Table5.length > 0 ? data.tablas.Table5[0].idUnicoPlantilla : 'Sin plantilla de calculo',
                        nombrePlantilla: data.tablas.Table5.length > 0 ? data.tablas.Table5[0].nombrePlantilla : 'Sin plantilla de calculo'
                    },
                    beneficiarios: data.tablas.Table6,
                    correosAsignados: data.tablas.Table7.length > 0 ? JSON.parse( data.tablas.Table7[0].jsonCorreoAsignado ) : [],
                    empresaPagadora: data.tablas.Table8.length > 0 ? data.tablas.Table8[0].nombreCorto : '',
                };               
                const { siglas, saldoAFavor, activoFinanciamiento, financiamiento } = dataParametros.value.datosGenerales.find( e => e.idUnico === fieldSelectGrupoEmpresarial.value);
                fieldFolioSolicitud.value = `${convertirFechaMes(new Date())}${siglas}---`;
                fieldFechaSolicitud.value = convertirFecha(new Date());
                fieldSaldoAFavor.value = formatoMexico(Number(saldoAFavor));
                fieldActivarFinanciamiento.value = activoFinanciamiento === 1 ? true : false;
                fieldFinanciamiento.value = formatoMexico(Number(financiamiento));
                jsonTablaClientes.value = dataParametros.value.empresasCliente;
                jsonEmpresasInternas.value = dataParametros.value.empresasInternas;
                jsonConceptosFactura.value = dataParametros.value.conceptosFactura;
                jsonBeneficiarios.value = dataParametros.value.beneficiarios;
                solicitud.value = false;
                fieldActivarComisionPesosExtra.value = dataParametros.value.parametrosCalculo.parametros.fieldActivarComisionPesosExtra;
            }
        }
        else
        {
            notify({
                title: "Clientes",
                text: "Debes de elegir un grupo empresarial.",
            });
        }
    };

    const handleValidarFile = async({target}) => {
        const { files } = target;
        if(files.length > 0)
        {
            fieldComprobanteFile.value = await imagenToBase64(files[0]);
        }
        else
        {
            fieldComprobanteFile.value = {
                bandera: false,
                base64: '',
                name: ''
            };
        }
    };

    const handleOpenModalDepSalFin = () => {
        if(fieldOperarCon.value  === 'Deposito')
        {
            processModalDepositos.value = {
                open: true,
                title: 'Deposito'
            }
        }
        else if(fieldOperarCon.value  === 'Saldo a favor')
        {
            if(formatNumero(fieldSaldoAFavor.value) > 0)
            {
                processModalSaldoAFavor.value = {
                    open: true,
                    title: 'Saldo a favor'
                };
            }
            else
            {
                alert(`No cuentas con saldo a favor.`);
            }
        }
        else if(fieldOperarCon.value  === 'Financiamiento')
        {
            processModalFinanciamiento.value = {
                open: true,
                title: 'Financiamiento'
            };
        }
    };

    const handleValidarDatosDepSalFin = () => {

        if(fieldOperarCon.value  === 'Deposito')
        {
            const comparacionMoneda = /^(?!0\.00)\d{1,3}(,\d{3})*(\.\d\d)?$/;
            
            const bandera01 = fieldRazonSocialDeposita.value !== 'S/D' ? true : false;
            const bandera01Texto = 'Falta elegir una razon social cliente. ';

            const bandera02 = fieldRazonSocialRecibeDeposito.value !== 'S/D' ? true : false;
            const bandera02Texto = 'Falta elegir una razon social que recibe el deposito. ';

            const bandera03 = fieldDatosFacturacion.value !== 'S/D' ? true : false;
            const bandera03Texto = 'Falta elegir una concepto de factura. ';

            const bandera04 = fieldTipoMovimiento.value !== 'S/D' ? true : false;
            const bandera04Texto = 'Falta elegir una tipo de movimiento. ';

            const bandera05 = comparacionMoneda.test(fieldMontoDeposito.value);
            const bandera05Texto = 'El monto introducido no tiene un formato valido, Eje.: (1,000,000.00). ';

            const bandera06 = fieldComprobanteFile.value.bandera;
            const bandera06Texto = 'Falta informacion del comprobante. ';

            fieldValidacion.value = '';
            if(!bandera01)
            {
                fieldValidacion.value = fieldValidacion.value + bandera01Texto
            }
            else
            {
                fieldValidacion.value = fieldValidacion.value + '';
            }

            if(!bandera02)
            {
                fieldValidacion.value = fieldValidacion.value + bandera02Texto
            }
            else
            {
                fieldValidacion.value = fieldValidacion.value + '';
            }

            if(!bandera03)
            {
                fieldValidacion.value = fieldValidacion.value + bandera03Texto
            }
            else
            {
                fieldValidacion.value = fieldValidacion.value + '';
            }

            if(!bandera04)
            {
                fieldValidacion.value = fieldValidacion.value + bandera04Texto
            }
            else
            {
                fieldValidacion.value = fieldValidacion.value + '';
            }

            if(!bandera05)
            {
                fieldValidacion.value = fieldValidacion.value + bandera05Texto
            }
            else
            {
                fieldValidacion.value = fieldValidacion.value + '';
            }

            if(!bandera06)
            {
                fieldValidacion.value = fieldValidacion.value + bandera06Texto
            }
            else
            {
                fieldValidacion.value = fieldValidacion.value + '';
            }

            if(bandera01 && bandera02 && bandera03 && bandera04 && bandera05 && bandera06)
            {
                fieldValidacion.value = 'Correcto!.';
                // disabledButtomGrabarDesposito.value = false;
            }
        }

        else if(fieldOperarCon.value  === 'Saldo a favor')
        {
            const comparacionMoneda = /^(?!0\.00)\d{1,3}(,\d{3})*(\.\d\d)?$/;

            let sumaSubTotalSaldo = 0;

            jsonDepositos.value.map( (valor) => {
                if( valor.tipo === 'Saldo' )
                {
                    sumaSubTotalSaldo += Number(formatNumero(valor.subTotal));
                }
                else
                {
                    sumaSubTotalSaldo += Number(formatNumero(0));
                }
            } );

            
            const bandera03 = Number(formatNumero(fieldSaldoAFavor.value)) - (sumaSubTotalSaldo + Number(formatNumero(fieldMontoDeposito.value))) >= 0 ? true : false;
            const bandera03Texto = `El monto ha sobrepasado el saldo a favor por: ${ formatoMexico( -1 * (Number(formatNumero(fieldSaldoAFavor.value)) - (sumaSubTotalSaldo + Number(formatNumero(fieldMontoDeposito.value)))) ) }`;

            const bandera04 = fieldTipoMovimiento.value !== 'S/D' ? true : false;
            const bandera04Texto = 'Falta elegir una tipo de movimiento. ';

            const bandera05 = comparacionMoneda.test(fieldMontoDeposito.value);
            const bandera05Texto = 'El monto introducido no tiene un formato valido, Eje.: (1,000,000.00). ';
            fieldValidacion.value = '';


            if(!bandera03)
            {
                fieldValidacion.value = fieldValidacion.value + bandera03Texto
            }
            else
            {
                fieldValidacion.value = fieldValidacion.value + '';
                fieldSaldoAFavorUtilizado.value = formatoMexico( (sumaSubTotalSaldo + Number(formatNumero(fieldMontoDeposito.value))));
            }

            if(!bandera04)
            {
                fieldValidacion.value = fieldValidacion.value + bandera04Texto
            }
            else
            {
                fieldValidacion.value = fieldValidacion.value + '';
            }

            if(!bandera05)
            {
                fieldValidacion.value = fieldValidacion.value + bandera05Texto
            }
            else
            {
                fieldValidacion.value = fieldValidacion.value + '';
            }

            if(bandera03 && bandera04 && bandera05)
            {
                fieldValidacion.value = 'Correcto!.';
            }

        }

        else if(fieldOperarCon.value  === 'Financiamiento')
        {
            const comparacionMoneda = /^(?!0\.00)\d{1,3}(,\d{3})*(\.\d\d)?$/;
            
            const bandera01 = fieldRazonSocialDeposita.value !== 'S/D' ? true : false;
            const bandera01Texto = 'Falta elegir una razon social cliente. ';

            const bandera02 = fieldRazonSocialRecibeDeposito.value !== 'S/D' ? true : false;
            const bandera02Texto = 'Falta elegir una razon social que recibe el deposito. ';

            const bandera03 = fieldDatosFacturacion.value !== 'S/D' ? true : false;
            const bandera03Texto = 'Falta elegir una concepto de factura. ';

            const bandera04 = fieldTipoMovimiento.value !== 'S/D' ? true : false;
            const bandera04Texto = 'Falta elegir una tipo de movimiento. ';

            const bandera05 = comparacionMoneda.test(fieldMontoDeposito.value);
            const bandera05Texto = 'El monto introducido no tiene un formato valido, Eje.: (1,000,000.00). ';

            

            let bandera06 = false;
            let bandera06Texto = '';
            let sumaSubTotalFinanciamiento = 0;

            if(fieldFinanciamiento.value === '0.00')
            {
                bandera06 = true;
                bandera06Texto = '';
            }
            else
            {
                
                jsonDepositos.value.map( (valor) => {
                    if( valor.tipo === 'Financiamiento' )
                    {
                        sumaSubTotalFinanciamiento += Number(formatNumero(valor.montoDeposito));
                    }
                    else
                    {
                        sumaSubTotalFinanciamiento += Number(formatNumero(0));
                    }
                } );

                bandera06 = (sumaSubTotalFinanciamiento + Number(formatNumero(fieldMontoDeposito.value))) <= Number(formatNumero(fieldFinanciamiento.value)) ? true : false;
                bandera06Texto = `El monto ha sobrepasado el financiamiento permitido ${fieldFinanciamiento.value} por: ${ formatoMexico( (( (sumaSubTotalFinanciamiento + Number(formatNumero(fieldMontoDeposito.value)))) - Number(formatNumero(fieldFinanciamiento.value))) ) }`;

            }


            fieldValidacion.value = '';
            if(!bandera01)
            {
                fieldValidacion.value = fieldValidacion.value + bandera01Texto
            }
            else
            {
                fieldValidacion.value = fieldValidacion.value + '';
            }

            if(!bandera02)
            {
                fieldValidacion.value = fieldValidacion.value + bandera02Texto
            }
            else
            {
                fieldValidacion.value = fieldValidacion.value + '';
            }

            if(!bandera03)
            {
                fieldValidacion.value = fieldValidacion.value + bandera03Texto
            }
            else
            {
                fieldValidacion.value = fieldValidacion.value + '';
            }

            if(!bandera04)
            {
                fieldValidacion.value = fieldValidacion.value + bandera04Texto
            }
            else
            {
                fieldValidacion.value = fieldValidacion.value + '';
            }

            if(!bandera05)
            {
                fieldValidacion.value = fieldValidacion.value + bandera05Texto
            }
            else
            {
                fieldValidacion.value = fieldValidacion.value + '';
            }

            if(!bandera06)
            {
                fieldValidacion.value = fieldValidacion.value + bandera06Texto
            }
            else
            {
                fieldValidacion.value = fieldValidacion.value + '';
                fieldFinanciamientoUtilizado.value = formatoMexico((sumaSubTotalFinanciamiento + Number(formatNumero(fieldMontoDeposito.value))));
            }

            if(bandera01 && bandera02 && bandera03 && bandera04 && bandera05 && bandera06)
            {
                fieldValidacion.value = 'Correcto!.';
                // disabledButtomGrabarDesposito.value = false;
            }
        }

    };

    const handleGrabarDepositoDepSalFin = () => {
        handleValidarDatosDepSalFin();

        if(fieldOperarCon.value  === 'Deposito')
        {
            if(fieldValidacion.value === 'Correcto!.')
            {
                let comision = 0;
                let porcentajeComision = 0;

                const empresaCliente = jsonTablaClientes.value.find( e => e.idSysTSEmpresas === fieldRazonSocialDeposita.value );

                const empresaInterna = jsonEmpresasInternas.value.find( e => e.idSysCPEmpresasRP === fieldRazonSocialRecibeDeposito.value );

                if(fieldTipoMovimiento.value === 'Asimilado')
                {
                    porcentajeComision = Number(dataParametros.value.parametrosCalculo.parametros.fieldPorcentajeComisionPorDepositoMovAsimilado);
                }
                else if(fieldTipoMovimiento.value === 'Efectivo / Sin recibo')
                {
                    porcentajeComision = Number(dataParametros.value.parametrosCalculo.parametros.fieldPorcentajeComisionPorDepositoMovNormal);
                }

                if(dataParametros.value.parametrosCalculo.parametros.fieldPorcentajeComisionOperacion !== '0')
                {
                    comision = dataParametros.value.parametrosCalculo.parametros.fieldPorcentajeComisionOperacion.toString().replaceAll('monto', formatNumero(fieldMontoDeposito.value)).replaceAll('%', porcentajeComision);
                }            

                const datosFactura = jsonConceptosFactura.value.find( e => e.idUnicoConcepto = fieldDatosFacturacion.value );

                jsonDepositos.value = [...jsonDepositos.value, {
                    idUnicoDeposito: shortid.generate(),

                    tipo: 'Deposito',

                    empresaCliente: fieldRazonSocialDeposita.value,
                    razonSocialCliente: empresaCliente.razonSocial,
                    rfcCliente: empresaCliente.rfc,
                    usoCFDICliente: empresaCliente.usoCFDI,

                    empresaInterna: fieldRazonSocialRecibeDeposito.value,
                    razonSocialInterna: empresaInterna.razonSocial,
                    rfcInterna: empresaInterna.rfc,
                    regimenFiscalInterna: empresaInterna.regimenFiscal,
                    serieFacturaInterna: empresaInterna.serieFactura,
                    codigoPostalInterna: empresaInterna.codigoPostal,

                    datosFacturacion: fieldDatosFacturacion.value,
                    
                    prodServ: datosFactura.prodServ,
                    descripcion: datosFactura.descripcion,
                    formaPago: datosFactura.formaPago,
                    metodoPago: datosFactura.metodoPago,
                    claveUnidad: datosFactura.claveUnidad,

                    tipoMovimiento: fieldTipoMovimiento.value,

                    montoDeposito: fieldMontoDeposito.value,
                    subTotal: formatoMexico( Number(formatNumero(fieldMontoDeposito.value)) - (Number(formatNumero(fieldMontoDeposito.value)) - (Number(formatNumero(fieldMontoDeposito.value))/1.16 )) ),
                    iva: formatoMexico( Number(formatNumero(fieldMontoDeposito.value)) - (Number(formatNumero(fieldMontoDeposito.value))/1.16 ) ),

                    comision: formatoMexico(eval(comision)),

                    comprobanteFileName: fieldComprobanteFile.value.name,
                    comprobanteFileBase64: fieldComprobanteFile.value.base64

                }];
                
                let suma = 0;
                let sumaSubTotal = 0;
                let sumaIVA = 0;
                let sumaComi = 0;
                jsonDepositos.value.map( (valor) => {
                    suma += Number(formatNumero(valor.montoDeposito));
                    
                    sumaSubTotal += Number(formatNumero(valor.subTotal));
                    sumaIVA += Number(formatNumero(valor.iva));

                    sumaComi += Number(formatNumero(valor.comision));
                } );
                fieldTotalDeposito.value = formatoMexico(Number(suma));
                fieldSubTotalDeposito.value = formatoMexico(Number(sumaSubTotal));
                fieldIVADeposito.value = formatoMexico(Number(sumaIVA));
                fieldComisionDeposito.value = formatoMexico(Number(sumaComi));
                handleCloseModalDepSalFin();
                fieldOperarCon.value = 'S/D';
            }
        }

        else if(fieldOperarCon.value  === 'Saldo a favor')
        {
            if(fieldValidacion.value === 'Correcto!.')
            {
                jsonDepositos.value = [...jsonDepositos.value, {
                    idUnicoDeposito: shortid.generate(),

                    tipo: 'Saldo',

                    empresaCliente: '',
                    razonSocialCliente: '',
                    rfcCliente: '',
                    usoCFDICliente: '',

                    empresaInterna: '',
                    razonSocialInterna: '',
                    rfcInterna: '',
                    regimenFiscalInterna: '',
                    serieFacturaInterna: '',
                    codigoPostalInterna: '',

                    datosFacturacion: '',
                    
                    prodServ: '',
                    descripcion: '',
                    formaPago: '',
                    metodoPago: '',
                    claveUnidad: '',

                    tipoMovimiento: fieldTipoMovimiento.value,

                    montoDeposito: fieldMontoDeposito.value,
                    subTotal: fieldMontoDeposito.value,
                    iva: '0.00',

                    comision: '0.00',

                    comprobanteFileName: '',
                    comprobanteFileBase64: ''

                }];
                let suma = 0;
                let sumaSubTotal = 0;
                let sumaIVA = 0;
                let sumaComi = 0;
                jsonDepositos.value.map( (valor) => {
                    suma += Number(formatNumero(valor.montoDeposito));
                    
                    sumaSubTotal += Number(formatNumero(valor.subTotal));
                    sumaIVA += Number(formatNumero(valor.iva));

                    sumaComi += Number(formatNumero(valor.comision));
                } );
                fieldTotalDeposito.value = formatoMexico(Number(suma));
                fieldSubTotalDeposito.value = formatoMexico(Number(sumaSubTotal));
                fieldIVADeposito.value = formatoMexico(Number(sumaIVA));
                fieldComisionDeposito.value = formatoMexico(Number(sumaComi));
                handleCloseModalDepSalFin();
                fieldOperarCon.value = 'S/D';
            }
        }

        else if(fieldOperarCon.value  === 'Financiamiento')
        {
            if(fieldValidacion.value === 'Correcto!.')
            {
                let comision = 0;
                let porcentajeComision = 0;

                const empresaCliente = jsonTablaClientes.value.find( e => e.idSysTSEmpresas === fieldRazonSocialDeposita.value );

                const empresaInterna = jsonEmpresasInternas.value.find( e => e.idSysCPEmpresasRP === fieldRazonSocialRecibeDeposito.value );

                if(fieldTipoMovimiento.value === 'Asimilado')
                {
                    porcentajeComision = Number(dataParametros.value.parametrosCalculo.parametros.fieldPorcentajeComisionPorDepositoMovAsimilado);
                }
                else if(fieldTipoMovimiento.value === 'Efectivo / Sin recibo')
                {
                    porcentajeComision = Number(dataParametros.value.parametrosCalculo.parametros.fieldPorcentajeComisionPorDepositoMovNormal);
                }

                if(dataParametros.value.parametrosCalculo.parametros.fieldPorcentajeComisionOperacion !== '')
                {
                    comision = dataParametros.value.parametrosCalculo.parametros.fieldPorcentajeComisionOperacion.toString().replaceAll('monto', formatNumero(fieldMontoDeposito.value)).replaceAll('%', porcentajeComision);
                }            

                const datosFactura = jsonConceptosFactura.value.find( e => e.idUnicoConcepto = fieldDatosFacturacion.value );

                jsonDepositos.value = [...jsonDepositos.value, {
                    idUnicoDeposito: shortid.generate(),

                    tipo: 'Financiamiento',

                    empresaCliente: fieldRazonSocialDeposita.value,
                    razonSocialCliente: empresaCliente.razonSocial,
                    rfcCliente: empresaCliente.rfc,
                    usoCFDICliente: empresaCliente.usoCFDI,

                    empresaInterna: fieldRazonSocialRecibeDeposito.value,
                    razonSocialInterna: empresaInterna.razonSocial,
                    rfcInterna: empresaInterna.rfc,
                    regimenFiscalInterna: empresaInterna.regimenFiscal,
                    serieFacturaInterna: empresaInterna.serieFactura,
                    codigoPostalInterna: empresaInterna.codigoPostal,

                    datosFacturacion: fieldDatosFacturacion.value,
                    
                    prodServ: datosFactura.prodServ,
                    descripcion: datosFactura.descripcion,
                    formaPago: datosFactura.formaPago,
                    metodoPago: datosFactura.metodoPago,
                    claveUnidad: datosFactura.claveUnidad,

                    tipoMovimiento: fieldTipoMovimiento.value,

                    montoDeposito: fieldMontoDeposito.value,
                    subTotal: formatoMexico( Number(formatNumero(fieldMontoDeposito.value)) - (Number(formatNumero(fieldMontoDeposito.value)) - (Number(formatNumero(fieldMontoDeposito.value))/1.16 )) ),
                    iva: formatoMexico( Number(formatNumero(fieldMontoDeposito.value)) - (Number(formatNumero(fieldMontoDeposito.value))/1.16 ) ),
                    comision: formatoMexico(eval(comision)),

                    comprobanteFileName: fieldComprobanteFile.value.name,
                    comprobanteFileBase64: fieldComprobanteFile.value.base64

                }];
                
                let suma = 0;
                let sumaSubTotal = 0;
                let sumaIVA = 0;
                let sumaComi = 0;
                jsonDepositos.value.map( (valor) => {
                    suma += Number(formatNumero(valor.montoDeposito));
                    
                    sumaSubTotal += Number(formatNumero(valor.subTotal));
                    sumaIVA += Number(formatNumero(valor.iva));

                    sumaComi += Number(formatNumero(valor.comision));
                } );
                fieldTotalDeposito.value = formatoMexico(Number(suma));
                fieldSubTotalDeposito.value = formatoMexico(Number(sumaSubTotal));
                fieldIVADeposito.value = formatoMexico(Number(sumaIVA));
                fieldComisionDeposito.value = formatoMexico(Number(sumaComi));
                handleCloseModalDepSalFin();
                fieldOperarCon.value = 'S/D';
            }
        }

        let suma = 0;
        let sumaSubTotal = 0;
        let sumaIVA = 0;
        let sumaComi = 0;
        
        jsonDepositos.value.map( (valor) => {
            suma += Number(formatNumero(valor.montoDeposito));
            sumaSubTotal += Number(formatNumero(valor.subTotal));
            sumaIVA += Number(formatNumero(valor.iva));
            sumaComi += Number(formatNumero(valor.comision));
        } );

        fieldTotalDeposito.value = formatoMexico(Number(suma));
        fieldSubTotalDeposito.value = formatoMexico(Number(sumaSubTotal));
        fieldIVADeposito.value = formatoMexico(Number(sumaIVA));
        fieldComisionDeposito.value = formatoMexico(Number(sumaComi));

        //Operacion Retorno
        let sumaTotalRetAsi = 0;
        let sumaSubTotalRetAsi = 0;
        let sumaIvaRetAsi = 0;
        let sumaComiRetAsi = 0;

        let sumaTotalRetEfeSinRec = 0;
        let sumaSubTotalRetEfeSinRec = 0;
        let sumaIvaRetEfeSinRec = 0;
        let sumaComiRetEfeSinRec = 0;

        
        if( jsonDepositos.value.length > 0 )
        {

            jsonDepositos.value.map( (valor) => {
                if( valor.tipoMovimiento === 'Asimilado' )
                {
                    sumaTotalRetAsi += Number(formatNumero(valor.montoDeposito));
                    sumaSubTotalRetAsi += Number(formatNumero(valor.subTotal));
                    sumaIvaRetAsi += Number(formatNumero(valor.iva));
                    sumaComiRetAsi += Number(formatNumero(valor.comision));
                }
                else
                {
                    sumaTotalRetAsi += Number(formatNumero(0));
                    sumaSubTotalRetAsi += Number(formatNumero(0));
                    sumaIvaRetAsi += Number(formatNumero(0));
                    sumaComiRetAsi += Number(formatNumero(0));
                }

                if( valor.tipoMovimiento === 'Efectivo / Sin recibo' )
                {
                    sumaTotalRetEfeSinRec += Number(formatNumero(valor.montoDeposito));
                    sumaSubTotalRetEfeSinRec += Number(formatNumero(valor.subTotal));
                    sumaIvaRetEfeSinRec += Number(formatNumero(valor.iva));
                    sumaComiRetEfeSinRec += Number(formatNumero(valor.comision));
                }
                else
                {
                    sumaTotalRetEfeSinRec += Number(formatNumero(0));
                    sumaSubTotalRetEfeSinRec += Number(formatNumero(0));
                    sumaIvaRetEfeSinRec += Number(formatNumero(0));
                    sumaComiRetEfeSinRec += Number(formatNumero(0));
                }

            } );
        }
        else
        {
            sumaTotalRetAsi = 0;
            sumaSubTotalRetAsi = 0;
            sumaIvaRetAsi = 0;
            sumaComiRetAsi = 0;

            sumaTotalRetEfeSinRec = 0;
            sumaSubTotalRetEfeSinRec = 0;
            sumaIvaRetEfeSinRec = 0;
            sumaComiRetEfeSinRec = 0;
        }

        if(dataParametros.value.parametrosCalculo.parametros.fieldOperacionTotalDisponibleCliente.toString() !== '0')
        {
            let stringDisponibleCliente = ''
            stringDisponibleCliente = dataParametros.value.parametrosCalculo.parametros.fieldOperacionTotalDisponibleCliente
                .replaceAll( 'totalDepositos', Number(formatNumero(fieldTotalDeposito.value)) )
                .replaceAll( 'subTotalDepositos', Number(formatNumero(fieldSubTotalDeposito.value)) )
                .replaceAll( 'ivaDepositos', Number(formatNumero(fieldIVADeposito.value)) )
                .replaceAll( 'comisionDepositos', Number(formatNumero(fieldComisionDeposito.value)) )
            fieldDisponibleCliente.value = formatoMexico(eval(stringDisponibleCliente));
        }
        else
        {
            fieldDisponibleCliente.value = formatoMexico('0.00');
        }

        
        if(dataParametros.value.parametrosCalculo.parametros.fieldOperacionTotalDisponibleCliente.toString() !== '0')
        {
            let stringDisponibleCliente = ''
            stringDisponibleCliente = dataParametros.value.parametrosCalculo.parametros.fieldOperacionTotalDisponibleCliente
                .replaceAll( 'totalDepositos', Number(formatNumero(sumaTotalRetAsi)) )
                .replaceAll( 'subTotalDepositos', Number(formatNumero(sumaSubTotalRetAsi)) )
                .replaceAll( 'ivaDepositos', Number(formatNumero(sumaIvaRetAsi)) )
                .replaceAll( 'comisionDepositos', Number(formatNumero(sumaComiRetAsi)) )
                fieldDisponibleClienteAsimilado.value = formatoMexico(eval(stringDisponibleCliente));
                fieldSaldoClienteAsimilado.value = formatoMexico(eval(stringDisponibleCliente));
        }
        else
        {
            fieldDisponibleClienteAsimilado.value = formatoMexico('0.00');
            fieldSaldoClienteAsimilado.value = formatoMexico('0.00');
        }

        if(dataParametros.value.parametrosCalculo.parametros.fieldOperacionTotalDisponibleCliente.toString() !== '0')
        {
            let stringDisponibleCliente = ''
            stringDisponibleCliente = dataParametros.value.parametrosCalculo.parametros.fieldOperacionTotalDisponibleCliente
                .replaceAll( 'totalDepositos', Number(formatNumero(sumaTotalRetEfeSinRec)) )
                .replaceAll( 'subTotalDepositos', Number(formatNumero(sumaSubTotalRetEfeSinRec)) )
                .replaceAll( 'ivaDepositos', Number(formatNumero(sumaIvaRetEfeSinRec)) )
                .replaceAll( 'comisionDepositos', Number(formatNumero(sumaComiRetEfeSinRec)) )
                console.log(stringDisponibleCliente)
                fieldDisponibleClienteEfectivoSinRecibo.value = formatoMexico(eval(stringDisponibleCliente));
                fieldSaldoClienteEfectivoSinRecibo.value = formatoMexico(eval(stringDisponibleCliente));
        }
        else
        {
            fieldDisponibleClienteEfectivoSinRecibo.value = formatoMexico('0.00');
            fieldSaldoClienteEfectivoSinRecibo.value = formatoMexico('0.00');
        }
       
    };

    const handleQuitarDepSalFin = async(registro) => {
        jsonDepositos.value = jsonDepositos.value.filter( e => e.idUnicoDeposito !== registro.idUnicoDeposito );

        let suma = 0;
        let sumaSubTotal = 0;
        let sumaIVA = 0;
        let sumaComi = 0;
        
        jsonDepositos.value.map( (valor) => {
            suma += Number(formatNumero(valor.montoDeposito));
            sumaSubTotal += Number(formatNumero(valor.subTotal));
            sumaIVA += Number(formatNumero(valor.iva));
            sumaComi += Number(formatNumero(valor.comision));
        } );

        fieldTotalDeposito.value = formatoMexico(Number(suma));
        fieldSubTotalDeposito.value = formatoMexico(Number(sumaSubTotal));
        fieldIVADeposito.value = formatoMexico(Number(sumaIVA));
        fieldComisionDeposito.value = formatoMexico(Number(sumaComi));

        let sumaTotalRetAsi = 0;
        let sumaSubTotalRetAsi = 0;
        let sumaIvaRetAsi = 0;
        let sumaComiRetAsi = 0;

        let sumaTotalRetEfeSinRec = 0;
        let sumaSubTotalRetEfeSinRec = 0;
        let sumaIvaRetEfeSinRec = 0;
        let sumaComiRetEfeSinRec = 0;

        
        if( jsonDepositos.value.length > 0 )
        {

            jsonDepositos.value.map( (valor) => {
                if( valor.tipoMovimiento === 'Asimilado' )
                {
                    sumaTotalRetAsi += Number(formatNumero(valor.montoDeposito));
                    sumaSubTotalRetAsi += Number(formatNumero(valor.subTotal));
                    sumaIvaRetAsi += Number(formatNumero(valor.iva));
                    sumaComiRetAsi += Number(formatNumero(valor.comision));
                }
                else
                {
                    sumaTotalRetAsi += Number(formatNumero(0));
                    sumaSubTotalRetAsi += Number(formatNumero(0));
                    sumaIvaRetAsi += Number(formatNumero(0));
                    sumaComiRetAsi += Number(formatNumero(0));
                }

                if( valor.tipoMovimiento === 'Efectivo / Sin recibo' )
                {
                    sumaTotalRetEfeSinRec += Number(formatNumero(valor.montoDeposito));
                    sumaSubTotalRetEfeSinRec += Number(formatNumero(valor.subTotal));
                    sumaIvaRetEfeSinRec += Number(formatNumero(valor.iva));
                    sumaComiRetEfeSinRec += Number(formatNumero(valor.comision));
                }
                else
                {
                    sumaTotalRetEfeSinRec += Number(formatNumero(0));
                    sumaSubTotalRetEfeSinRec += Number(formatNumero(0));
                    sumaIvaRetEfeSinRec += Number(formatNumero(0));
                    sumaComiRetEfeSinRec += Number(formatNumero(0));
                }

            } );
        }
        else
        {
            sumaTotalRetAsi = 0;
            sumaSubTotalRetAsi = 0;
            sumaIvaRetAsi = 0;
            sumaComiRetAsi = 0;

            sumaTotalRetEfeSinRec = 0;
            sumaSubTotalRetEfeSinRec = 0;
            sumaIvaRetEfeSinRec = 0;
            sumaComiRetEfeSinRec = 0;
        }

        if(dataParametros.value.parametrosCalculo.parametros.fieldOperacionTotalDisponibleCliente.toString() !== '0')
        {
            let stringDisponibleCliente = ''
            stringDisponibleCliente = dataParametros.value.parametrosCalculo.parametros.fieldOperacionTotalDisponibleCliente
                .replaceAll( 'totalDepositos', Number(formatNumero(fieldTotalDeposito.value)) )
                .replaceAll( 'subTotalDepositos', Number(formatNumero(fieldSubTotalDeposito.value)) )
                .replaceAll( 'ivaDepositos', Number(formatNumero(fieldIVADeposito.value)) )
                .replaceAll( 'comisionDepositos', Number(formatNumero(fieldComisionDeposito.value)) )
            fieldDisponibleCliente.value = formatoMexico(eval(stringDisponibleCliente));
        }
        else
        {
            fieldDisponibleCliente.value = formatoMexico('0.00');
        }

        
        if(dataParametros.value.parametrosCalculo.parametros.fieldOperacionTotalDisponibleCliente.toString() !== '0')
        {
            let stringDisponibleCliente = ''
            stringDisponibleCliente = dataParametros.value.parametrosCalculo.parametros.fieldOperacionTotalDisponibleCliente
                .replaceAll( 'totalDepositos', Number(formatNumero(sumaTotalRetAsi)) )
                .replaceAll( 'subTotalDepositos', Number(formatNumero(sumaSubTotalRetAsi)) )
                .replaceAll( 'ivaDepositos', Number(formatNumero(sumaIvaRetAsi)) )
                .replaceAll( 'comisionDepositos', Number(formatNumero(sumaComiRetAsi)) )
                fieldDisponibleClienteAsimilado.value = formatoMexico(eval(stringDisponibleCliente));
                fieldSaldoClienteAsimilado.value = formatoMexico(eval(stringDisponibleCliente));
        }
        else
        {
            fieldDisponibleClienteAsimilado.value = formatoMexico('0.00');
            fieldSaldoClienteAsimilado.value = formatoMexico('0.00');
        }

        if(dataParametros.value.parametrosCalculo.parametros.fieldOperacionTotalDisponibleCliente.toString() !== '0')
        {
            let stringDisponibleCliente = ''
            stringDisponibleCliente = dataParametros.value.parametrosCalculo.parametros.fieldOperacionTotalDisponibleCliente
                .replaceAll( 'totalDepositos', Number(formatNumero(sumaTotalRetEfeSinRec)) )
                .replaceAll( 'subTotalDepositos', Number(formatNumero(sumaSubTotalRetEfeSinRec)) )
                .replaceAll( 'ivaDepositos', Number(formatNumero(sumaIvaRetEfeSinRec)) )
                .replaceAll( 'comisionDepositos', Number(formatNumero(sumaComiRetEfeSinRec)) )
                fieldDisponibleClienteEfectivoSinRecibo.value = formatoMexico(eval(stringDisponibleCliente));
                fieldSaldoClienteEfectivoSinRecibo.value = formatoMexico(eval(stringDisponibleCliente));
        }
        else
        {
            fieldDisponibleClienteEfectivoSinRecibo.value = formatoMexico('0.00');
            fieldSaldoClienteEfectivoSinRecibo.value = formatoMexico('0.00');
        }

        let sumaSubTotalSaldo = 0;

        jsonDepositos.value.map( (valor) => {
            if( valor.tipo === 'Saldo' )
            {
                sumaSubTotalSaldo += Number(formatNumero(valor.subTotal));
            }
            else
            {
                sumaSubTotalSaldo += Number(formatNumero(0));
            }
        } );

        fieldSaldoAFavorUtilizado.value = formatoMexico((sumaSubTotalSaldo + Number(formatNumero(fieldMontoDeposito.value))));

        let sumaSubTotalFinanciamiento = 0;
        jsonDepositos.value.map( (valor) => {
            if( valor.tipo === 'Financiamiento' )
            {
                sumaSubTotalFinanciamiento += Number(formatNumero(valor.montoDeposito));
            }
            else
            {
                sumaSubTotalFinanciamiento += Number(formatNumero(0));
            }
        } );
        fieldFinanciamientoUtilizado.value = formatoMexico((sumaSubTotalFinanciamiento + Number(formatNumero(fieldMontoDeposito.value))));


    };

    const handleCloseModalDepSalFin = () => {
    
        if(fieldOperarCon.value  === 'Deposito')
        {
            fieldRazonSocialDeposita.value = 'S/D';
            fieldRazonSocialRecibeDeposito.value = 'S/D';
            fieldDatosFacturacion.value = 'S/D';
            fieldTipoMovimiento.value = 'S/D';
            fieldMontoDeposito.value = '0.00';
            fieldComprobanteFile.value = {};
            fieldValidacion.value = '';
            processModalDepositos.value = {
                open: false,
                title: 'Deposito'
            }
        }
        else if(fieldOperarCon.value  === 'Saldo a favor')
        {
            fieldTipoMovimiento.value = 'S/D';
            fieldMontoDeposito.value = '0.00';
            fieldValidacion.value = '';
            processModalSaldoAFavor.value = {
                open: false,
                title: 'Saldo a favor'
            };
        }
        else if(fieldOperarCon.value  === 'Financiamiento')
        {
            fieldRazonSocialDeposita.value = 'S/D';
            fieldRazonSocialRecibeDeposito.value = 'S/D';
            fieldDatosFacturacion.value = 'S/D';
            fieldTipoMovimiento.value = 'S/D';
            fieldMontoDeposito.value = '0.00';
            fieldComprobanteFile.value = {};
            fieldValidacion.value = '';
            processModalFinanciamiento.value = {
                open: false,
                title: 'Financiamiento'
            }
        }

    };

    const handleModalMontoAsimiladosBene = async() => {
        if(modalRegistrosExcelAsimilados.value)
        {
            modalRegistrosExcelAsimilados.value = false;
        }
        else
        {
            modalRegistrosExcelAsimilados.value = true;

            const data = await operacion(
                {
                    numero : '51',
                    proceso : '5',
                    opcionTD : 'TODO',
                    parametros : true,
                    datosReceptor : 
                    {
                        opcion: 'layOutBancariosNomina',
                        PidUnico: fieldSelectGrupoEmpresarial.value
                    },
                    mostrarMensaje: false
                }
            );
            

            if( data.bandera === 0 )
            {
                jsonLayOutBancariosNomina.value = data.tablas.Table1;
            }

            jsonBeneficiarios.value.map( ( {
                idUnicoBeneficiario, tipoAlta, idUnicoGrupo, nombreGrupo, usoCFDI, codigoBeneficiario, apellidoPaterno, apellidoMaterno, nombre, razonSocial, rfc, curp,
                banco, cuentaBanco, clabeBanco, idCatBanco, numeroInstitutoBancoSantander, claveTransferenciaBancoSantander,
                numeroInstitutoKuspit, activo
            } ) => {

                if(tipoAlta === 'Persona')
                {

                    if(activo === 1)
                    {

                        const existeIndex = jsonRegistrosExcelAsimilados.value.findIndex( e => e.idUnicoBeneficiario === idUnicoBeneficiario );
                        
                        if(existeIndex === -1)
                        {
                            let layOut = '';

                            //Nomina Mismo Banco
                            if( jsonLayOutBancariosNomina.value.find( e => e.idCatBanco === idCatBanco && e.tipo === 'mbn' ) && cuentaBanco.length >= 10 && clabeBanco.length === 18 )
                            {
                                layOut = jsonLayOutBancariosNomina.value.find( e => e.idCatBanco === idCatBanco && e.tipo === 'mbn' && e.orden === 1 );
                            }
                            else if( jsonLayOutBancariosNomina.value.find( e => e.idCatBanco === idCatBanco && e.tipo === 'mbs') && cuentaBanco.length >= 10 && clabeBanco.length === 18 )
                            {
                                layOut = jsonLayOutBancariosNomina.value.find( e => e.idCatBanco === idCatBanco && e.tipo === 'mbs');
                            }
                            else if( jsonLayOutBancariosNomina.value.find( e => e.idCatBanco !== idCatBanco && e.tipo === 'is') && cuentaBanco.length >= 10 && clabeBanco.length === 18 )
                            {
                                layOut = jsonLayOutBancariosNomina.value.find( e => e.idCatBanco !== idCatBanco && e.tipo === 'is');
                            }

                            jsonRegistrosExcelAsimilados.value = [ ...jsonRegistrosExcelAsimilados.value, {
                                    idUnicoBeneficiario,
                                    tipoAlta,
                                    idUnicoGrupo,
                                    nombreGrupo,
                                    usoCFDI,
                                    codigoBeneficiario,
                                    apellidoPaterno,
                                    apellidoMaterno,
                                    nombre,
                                    razonSocial,
                                    rfc,
                                    curp,
                                    banco,
                                    cuentaBanco,
                                    clabeBanco,
                                    idCatBanco,
                                    numeroInstitutoBancoSantander,
                                    claveTransferenciaBancoSantander,
                                    numeroInstitutoKuspit,
                                    monto: '0.00',
                                    retorno: '0.00',
                                    idUnicoBancosLayOut: layOut.idUnicoBancosLayOut,
                                    clabeBancariaEmpresa: layOut.clabeBancaria,
                                    cuentaBancariaEmpresa: layOut.cuentaBancaria,
                                    bancoEmpresa: layOut.banco,
                                    tipoLayoutEmpresa: layOut.tipoLayout,
                                    empresaPagadora: dataParametros.value.empresaPagadora,
                                    activo
                                } 
                            ];
                        }
                        else
                        {

                            jsonRegistrosExcelAsimilados.value[existeIndex].idUnicoBeneficiario = idUnicoBeneficiario;
                            jsonRegistrosExcelAsimilados.value[existeIndex].tipoAlta = tipoAlta;
                            jsonRegistrosExcelAsimilados.value[existeIndex].idUnicoGrupo = idUnicoGrupo;
                            jsonRegistrosExcelAsimilados.value[existeIndex].nombreGrupo = nombreGrupo;
                            jsonRegistrosExcelAsimilados.value[existeIndex].usoCFDI = usoCFDI;
                            jsonRegistrosExcelAsimilados.value[existeIndex].codigoBeneficiario = codigoBeneficiario;
                            jsonRegistrosExcelAsimilados.value[existeIndex].apellidoPaterno = apellidoPaterno;
                            jsonRegistrosExcelAsimilados.value[existeIndex].apellidoMaterno = apellidoMaterno;
                            jsonRegistrosExcelAsimilados.value[existeIndex].nombre = nombre;
                            jsonRegistrosExcelAsimilados.value[existeIndex].razonSocial = razonSocial;
                            jsonRegistrosExcelAsimilados.value[existeIndex].rfc = rfc;
                            jsonRegistrosExcelAsimilados.value[existeIndex].curp = curp;
                            jsonRegistrosExcelAsimilados.value[existeIndex].banco = banco;
                            jsonRegistrosExcelAsimilados.value[existeIndex].cuentaBanco = cuentaBanco;
                            jsonRegistrosExcelAsimilados.value[existeIndex].clabeBanco = clabeBanco;
                            jsonRegistrosExcelAsimilados.value[existeIndex].idCatBanco = idCatBanco;
                            jsonRegistrosExcelAsimilados.value[existeIndex].numeroInstitutoBancoSantander = numeroInstitutoBancoSantander;
                            jsonRegistrosExcelAsimilados.value[existeIndex].claveTransferenciaBancoSantander = claveTransferenciaBancoSantander;
                            jsonRegistrosExcelAsimilados.value[existeIndex].numeroInstitutoKuspit = numeroInstitutoKuspit;
                        }

                    }
                    else
                    {
                        const index = jsonRegistrosExcelAsimilados.value.findIndex( e => e.idUnicoBeneficiario === idUnicoBeneficiario );
                        delete jsonRegistrosExcelAsimilados.value[index];
                    }

                }

            } );
        }
    };

    const handleMontoAsimiladosBene = ({target}, index, tipo) => {

        if( tipo === 'monto' )
        {
            let retornoAsimilado = '0.00';
            let sumaRetorno = 0;
            let valorMonto = target.value === '' ? '0.00' : formatoMexico(Number(formatNumero(target.value)));

            if(Number(formatNumero(dataParametros.value.parametrosCalculo.parametros.fieldPorcentajeComision01Operacion)) === 0)
            {
                retornoAsimilado = Number( formatNumero( valorMonto ) ) ;
            }
            else
            {
                retornoAsimilado = eval( dataParametros.value.parametrosCalculo.parametros.fieldPorcentajeComision01Operacion.toString()
                            .replaceAll('monto', valorMonto === undefined ? 0 :Number(formatNumero( valorMonto )) )
                            .replaceAll('%', Number(formatNumero(dataParametros.value.parametrosCalculo.parametros.fieldPorcentajeComision01Asimilado))) ).toFixed(2) ;
            }

            jsonRegistrosExcelAsimilados.value[index].monto = valorMonto;
            jsonRegistrosExcelAsimilados.value[index].retorno = formatoMexico( Number( formatNumero(retornoAsimilado) ) );

            jsonRegistrosExcelAsimilados.value.map( (valor) => {
                sumaRetorno += Number(formatNumero(valor.retorno));
            } );

            if( sumaRetorno > Number(formatNumero( fieldDisponibleClienteAsimilado.value )) )
            {
                jsonRegistrosExcelAsimilados.value[index].monto = '0.00';
                jsonRegistrosExcelAsimilados.value[index].retorno = '0.00';
                alert('Ya no cuentas con saldo asimilado.');
                sumaRetorno = 0;
                jsonRegistrosExcelAsimilados.value.map( (valor) => {
                    sumaRetorno += Number(formatNumero(valor.retorno));
                } );
            }

            fieldUtilizadoClienteAsimilado.value = formatoMexico( Number( sumaRetorno));
            fieldSaldoClienteAsimilado.value = formatoMexico( Number( Number(formatNumero( fieldDisponibleClienteAsimilado.value ) ) - Number(formatNumero( fieldUtilizadoClienteAsimilado.value ) ) ) );

            fieldMontoRetornar.value = formatoMexico(Number(formatNumero(fieldUtilizadoClienteAsimilado.value)) + Number(formatNumero(fieldUtilizadoClienteEfectivoSinRecibo.value)));
        }

        else if( tipo === 'layout' )
        {
            
            const layOut = jsonLayOutBancariosNomina.value.find( e => e.clabeBancaria === target.value );
            
            jsonRegistrosExcelAsimilados.value[index].idUnicoBancosLayOut = layOut.idUnicoBancosLayOut;
            jsonRegistrosExcelAsimilados.value[index].clabeBancariaEmpresa = layOut.clabeBancaria;
            jsonRegistrosExcelAsimilados.value[index].cuentaBancariaEmpresa = layOut.cuentaBancaria;
            jsonRegistrosExcelAsimilados.value[index].bancoEmpresa = layOut.banco;
            jsonRegistrosExcelAsimilados.value[index].tipoLayoutEmpresa = layOut.tipoLayout;

        }

    };

    const handleModalMontoSinReciboBene = async() => {
        if(modalRegistrosExcelSinRecibo.value)
        {
            modalRegistrosExcelSinRecibo.value = false;
        }
        else
        {
            modalRegistrosExcelSinRecibo.value = true;

            const data = await operacion(
                {
                    numero : '51',
                    proceso : '5',
                    opcionTD : 'TODO',
                    parametros : true,
                    datosReceptor : 
                    {
                        opcion: 'layOutBancariosNomina',
                        PidUnico: fieldSelectGrupoEmpresarial.value
                    },
                    mostrarMensaje: false
                }
            );

            if( data.bandera === 0 )
            {
                jsonLayOutBancariosSinRecibo.value = data.tablas.Table1;
            }

            jsonBeneficiarios.value.map( ( {
                idUnicoBeneficiario, tipoAlta, idUnicoGrupo, nombreGrupo, usoCFDI, codigoBeneficiario, apellidoPaterno, apellidoMaterno, nombre, razonSocial, rfc, curp,
                banco, cuentaBanco, clabeBanco, idCatBanco, numeroInstitutoBancoSantander, claveTransferenciaBancoSantander,
                numeroInstitutoKuspit, activo
            } ) => {

                if(activo === 1)
                {

                    const existeIndex = jsonRegistrosExcelSinRecibo.value.findIndex( e => e.idUnicoBeneficiario === idUnicoBeneficiario );
                    
                    if(existeIndex === -1)
                    {
                        let layOut = '';

                        //Nomina Mismo Banco
                        if( jsonLayOutBancariosSinRecibo.value.find( e => e.idCatBanco === idCatBanco && e.tipo === 'mbs') && cuentaBanco.length >= 10 && clabeBanco.length === 18 )
                        {
                            layOut = jsonLayOutBancariosSinRecibo.value.find( e => e.idCatBanco === idCatBanco && e.tipo === 'mbs');
                        }
                        else if( jsonLayOutBancariosSinRecibo.value.find( e => e.idCatBanco !== idCatBanco && e.tipo === 'is') && cuentaBanco.length >= 10 && clabeBanco.length === 18 )
                        {
                            layOut = jsonLayOutBancariosSinRecibo.value.find( e => e.idCatBanco !== idCatBanco && e.tipo === 'is');
                        }

                        jsonRegistrosExcelSinRecibo.value = [ ...jsonRegistrosExcelSinRecibo.value, {
                                idUnicoBeneficiario,
                                tipoAlta,
                                idUnicoGrupo,
                                nombreGrupo,
                                usoCFDI,
                                codigoBeneficiario,
                                apellidoPaterno,
                                apellidoMaterno,
                                nombre,
                                razonSocial,
                                rfc,
                                curp,
                                banco,
                                cuentaBanco,
                                clabeBanco,
                                idCatBanco,
                                numeroInstitutoBancoSantander,
                                claveTransferenciaBancoSantander,
                                numeroInstitutoKuspit,
                                monto: '0.00',
                                retorno: '0.00',
                                idUnicoBancosLayOut: layOut.idUnicoBancosLayOut,
                                clabeBancariaEmpresa: layOut.clabeBancaria,
                                cuentaBancariaEmpresa: layOut.cuentaBancaria,
                                bancoEmpresa: layOut.banco,
                                tipoLayoutEmpresa: layOut.tipoLayout,
                                empresaPagadora: dataParametros.value.empresaPagadora,
                                activo
                            } 
                        ];
                    }
                    else
                    {

                        jsonRegistrosExcelSinRecibo.value[existeIndex].idUnicoBeneficiario = idUnicoBeneficiario;
                        jsonRegistrosExcelSinRecibo.value[existeIndex].tipoAlta = tipoAlta;
                        jsonRegistrosExcelSinRecibo.value[existeIndex].idUnicoGrupo = idUnicoGrupo;
                        jsonRegistrosExcelSinRecibo.value[existeIndex].nombreGrupo = nombreGrupo;
                        jsonRegistrosExcelSinRecibo.value[existeIndex].usoCFDI = usoCFDI;
                        jsonRegistrosExcelSinRecibo.value[existeIndex].codigoBeneficiario = codigoBeneficiario;
                        jsonRegistrosExcelSinRecibo.value[existeIndex].apellidoPaterno = apellidoPaterno;
                        jsonRegistrosExcelSinRecibo.value[existeIndex].apellidoMaterno = apellidoMaterno;
                        jsonRegistrosExcelSinRecibo.value[existeIndex].nombre = nombre;
                        jsonRegistrosExcelSinRecibo.value[existeIndex].razonSocial = razonSocial;
                        jsonRegistrosExcelSinRecibo.value[existeIndex].rfc = rfc;
                        jsonRegistrosExcelSinRecibo.value[existeIndex].curp = curp;
                        jsonRegistrosExcelSinRecibo.value[existeIndex].banco = banco;
                        jsonRegistrosExcelSinRecibo.value[existeIndex].cuentaBanco = cuentaBanco;
                        jsonRegistrosExcelSinRecibo.value[existeIndex].clabeBanco = clabeBanco;
                        jsonRegistrosExcelSinRecibo.value[existeIndex].idCatBanco = idCatBanco;
                        jsonRegistrosExcelSinRecibo.value[existeIndex].numeroInstitutoBancoSantander = numeroInstitutoBancoSantander;
                        jsonRegistrosExcelSinRecibo.value[existeIndex].claveTransferenciaBancoSantander = claveTransferenciaBancoSantander;
                        jsonRegistrosExcelSinRecibo.value[existeIndex].numeroInstitutoKuspit = numeroInstitutoKuspit;
                    }

                }
                else
                {
                    const index = jsonRegistrosExcelSinRecibo.value.findIndex( e => e.idUnicoBeneficiario === idUnicoBeneficiario );
                    delete jsonRegistrosExcelSinRecibo.value[index];
                }


            } );
        }
    };

    const handleMontoSinReciboBene = ({target}, index, tipo) => {

        if( tipo === 'monto' )
        {
            let retornoSinRecibo = '0.00';
            
            let sumaRetornoSinRecibo = 0;
            let sumaRetornoEfectivo = 0;

            let valorMonto = target.value === '' ? '0.00' : formatoMexico(Number(formatNumero(target.value)));

            if( dataParametros.value.parametrosCalculo.parametros.fieldPorcentajeComision01Operacion === '0' )
            {
                retornoSinRecibo = valorMonto ;
            }
            else
            {
                retornoSinRecibo = eval( dataParametros.value.parametrosCalculo.parametros.fieldPorcentajeComision01Operacion.toString()
                            .replaceAll('monto', valorMonto === undefined ? 0 :Number(formatNumero( valorMonto )) )
                            .replaceAll('%', Number(formatNumero(dataParametros.value.parametrosCalculo.parametros.fieldPorcentajeComision01Normal))) ).toFixed(2) ;
            }

            jsonRegistrosExcelSinRecibo.value[index].monto = valorMonto;
            jsonRegistrosExcelSinRecibo.value[index].retorno = formatoMexico( Number( formatNumero(retornoSinRecibo) ) );

            jsonRegistrosExcelSinRecibo.value.map( (valor) => {
                sumaRetornoSinRecibo += Number(formatNumero(valor.retorno));
            } );

            jsonRegistrosExcelEfectivo.value.map( (valor) => {
                sumaRetornoEfectivo += Number(formatNumero(valor.retorno));
            } );

            if( (sumaRetornoSinRecibo + sumaRetornoEfectivo) > Number(formatNumero( fieldDisponibleClienteEfectivoSinRecibo.value )) )
            {
                jsonRegistrosExcelSinRecibo.value[index].monto = '0.00';
                jsonRegistrosExcelSinRecibo.value[index].retorno = '0.00';
                alert('Ya no cuentas con saldo sin recibo / efectivo.');
                
                sumaRetornoSinRecibo = 0;
                sumaRetornoEfectivo = 0;

                jsonRegistrosExcelSinRecibo.value.map( (valor) => {
                    sumaRetornoSinRecibo += Number(formatNumero(valor.retorno));
                } );

                jsonRegistrosExcelEfectivo.value.map( (valor) => {
                    sumaRetornoEfectivo += Number(formatNumero(valor.retorno));
                } );
            }

            fieldUtilizadoClienteEfectivoSinRecibo.value = formatoMexico( Number( (sumaRetornoEfectivo + sumaRetornoSinRecibo) ));
            fieldSaldoClienteEfectivoSinRecibo.value = formatoMexico( Number( Number(formatNumero( fieldDisponibleClienteEfectivoSinRecibo.value ) ) - Number(formatNumero( fieldUtilizadoClienteEfectivoSinRecibo.value ) ) ) );
            fieldMontoRetornar.value = formatoMexico(Number(formatNumero(fieldUtilizadoClienteAsimilado.value)) + Number(formatNumero(fieldUtilizadoClienteEfectivoSinRecibo.value)));
        }

        else if( tipo === 'layout' )
        {
            
            const layOut = jsonLayOutBancariosSinRecibo.value.find( e => e.clabeBancaria === target.value );
            
            jsonRegistrosExcelSinRecibo.value[index].idUnicoBancosLayOut = layOut.idUnicoBancosLayOut;
            jsonRegistrosExcelSinRecibo.value[index].clabeBancariaEmpresa = layOut.clabeBancaria;
            jsonRegistrosExcelSinRecibo.value[index].cuentaBancariaEmpresa = layOut.cuentaBancaria;
            jsonRegistrosExcelAsimilados.value[index].bancoEmpresa = layOut.banco;
            jsonRegistrosExcelAsimilados.value[index].tipoLayoutEmpresa = layOut.tipoLayout;

        }

    };

    const handleModalMontoEfectivoBene = async() => {
        if(modalRegistrosExcelEfectivo.value)
        {
            modalRegistrosExcelEfectivo.value = false;
        }
        else
        {
            modalRegistrosExcelEfectivo.value = true;


            jsonBeneficiarios.value.map( ( {
                idUnicoBeneficiario, tipoAlta, idUnicoGrupo, nombreGrupo, usoCFDI, codigoBeneficiario, apellidoPaterno, apellidoMaterno, nombre, razonSocial, rfc, curp,
                banco, cuentaBanco, clabeBanco, idCatBanco, numeroInstitutoBancoSantander, claveTransferenciaBancoSantander,
                numeroInstitutoKuspit, activo
            } ) => {

                if(activo === 1)
                {

                    const existeIndex = jsonRegistrosExcelEfectivo.value.findIndex( e => e.idUnicoBeneficiario === idUnicoBeneficiario );
                    
                    if(existeIndex === -1)
                    {
                        let layOut = '';

                        jsonRegistrosExcelEfectivo.value = [ ...jsonRegistrosExcelEfectivo.value, {
                                idUnicoBeneficiario,
                                tipoAlta,
                                idUnicoGrupo,
                                nombreGrupo,
                                usoCFDI,
                                codigoBeneficiario,
                                apellidoPaterno,
                                apellidoMaterno,
                                nombre,
                                razonSocial,
                                rfc,
                                curp,
                                banco,
                                cuentaBanco,
                                clabeBanco,
                                idCatBanco,
                                numeroInstitutoBancoSantander,
                                claveTransferenciaBancoSantander,
                                numeroInstitutoKuspit,
                                monto: '0.00',
                                retorno: '0.00',
                                idUnicoBancosLayOut: layOut.idUnicoBancosLayOut,
                                clabeBancariaEmpresa: layOut.clabeBancaria,
                                cuentaBancariaEmpresa: layOut.cuentaBancaria,
                                activo
                            } 
                        ];
                    }
                    else
                    {

                        jsonRegistrosExcelEfectivo.value[existeIndex].idUnicoBeneficiario = idUnicoBeneficiario;
                        jsonRegistrosExcelEfectivo.value[existeIndex].tipoAlta = tipoAlta;
                        jsonRegistrosExcelEfectivo.value[existeIndex].idUnicoGrupo = idUnicoGrupo;
                        jsonRegistrosExcelEfectivo.value[existeIndex].nombreGrupo = nombreGrupo;
                        jsonRegistrosExcelEfectivo.value[existeIndex].usoCFDI = usoCFDI;
                        jsonRegistrosExcelEfectivo.value[existeIndex].codigoBeneficiario = codigoBeneficiario;
                        jsonRegistrosExcelEfectivo.value[existeIndex].apellidoPaterno = apellidoPaterno;
                        jsonRegistrosExcelEfectivo.value[existeIndex].apellidoMaterno = apellidoMaterno;
                        jsonRegistrosExcelEfectivo.value[existeIndex].nombre = nombre;
                        jsonRegistrosExcelEfectivo.value[existeIndex].razonSocial = razonSocial;
                        jsonRegistrosExcelEfectivo.value[existeIndex].rfc = rfc;
                        jsonRegistrosExcelEfectivo.value[existeIndex].curp = curp;
                        jsonRegistrosExcelEfectivo.value[existeIndex].banco = banco;
                        jsonRegistrosExcelEfectivo.value[existeIndex].cuentaBanco = cuentaBanco;
                        jsonRegistrosExcelEfectivo.value[existeIndex].clabeBanco = clabeBanco;
                        jsonRegistrosExcelEfectivo.value[existeIndex].idCatBanco = idCatBanco;
                        jsonRegistrosExcelEfectivo.value[existeIndex].numeroInstitutoBancoSantander = numeroInstitutoBancoSantander;
                        jsonRegistrosExcelEfectivo.value[existeIndex].claveTransferenciaBancoSantander = claveTransferenciaBancoSantander;
                        jsonRegistrosExcelEfectivo.value[existeIndex].numeroInstitutoKuspit = numeroInstitutoKuspit;
                    }

                }
                else
                {
                    const index = jsonRegistrosExcelEfectivo.value.findIndex( e => e.idUnicoBeneficiario === idUnicoBeneficiario );
                    delete jsonRegistrosExcelEfectivo.value[index];
                }


            } );
        }
    };

    const handleMontoEfectivoBene = ({target}, index, tipo) => {

        if( tipo === 'monto' )
        {
            let retornoEfectivo = '0.00';
            let sumaRetornoSinRecibo = 0;
            let sumaRetornoEfectivo = 0;
            let valorMonto = target.value === '' ? '0.00' : formatoMexico(Number(formatNumero(target.value)));

            if(dataParametros.value.parametrosCalculo.parametros.fieldPorcentajeComision01Operacion === '0')
            {
                retornoEfectivo = valorMonto ;
            }
            else
            {
                retornoEfectivo = eval( dataParametros.value.parametrosCalculo.parametros.fieldPorcentajeComision01Operacion.toString()
                            .replaceAll('monto', valorMonto === undefined ? 0 :Number(formatNumero( valorMonto )) )
                            .replaceAll('%', Number(formatNumero(dataParametros.value.parametrosCalculo.parametros.fieldPorcentajeComision01Normal))) ).toFixed(2) ;
            }

            jsonRegistrosExcelEfectivo.value[index].monto = valorMonto;
            jsonRegistrosExcelEfectivo.value[index].retorno = formatoMexico( Number( formatNumero(retornoEfectivo) ) );

            jsonRegistrosExcelSinRecibo.value.map( (valor) => {
                sumaRetornoSinRecibo += Number(formatNumero(valor.retorno));
            } );

            jsonRegistrosExcelEfectivo.value.map( (valor) => {
                sumaRetornoEfectivo += Number(formatNumero(valor.retorno));
            } );

            if( (sumaRetornoSinRecibo + sumaRetornoEfectivo) > Number(formatNumero( fieldDisponibleClienteEfectivoSinRecibo.value )) )
            {
                jsonRegistrosExcelEfectivo.value[index].monto = '0.00';
                jsonRegistrosExcelEfectivo.value[index].retorno = '0.00';
                alert('Ya no cuentas con saldo sin recibo / efectivo.');

                sumaRetornoSinRecibo = 0;
                sumaRetornoEfectivo = 0;

                jsonRegistrosExcelSinRecibo.value.map( (valor) => {
                    sumaRetornoSinRecibo += Number(formatNumero(valor.retorno));
                } );

                jsonRegistrosExcelEfectivo.value.map( (valor) => {
                    sumaRetornoEfectivo += Number(formatNumero(valor.retorno));
                } );
            }

            fieldUtilizadoClienteEfectivoSinRecibo.value = formatoMexico( Number( (sumaRetornoEfectivo + sumaRetornoSinRecibo) ));
            fieldSaldoClienteEfectivoSinRecibo.value = formatoMexico( Number( Number(formatNumero( fieldDisponibleClienteEfectivoSinRecibo.value ) ) - Number(formatNumero( fieldUtilizadoClienteEfectivoSinRecibo.value ) ) ) );
            fieldMontoRetornar.value = formatoMexico(Number(formatNumero(fieldUtilizadoClienteAsimilado.value)) + Number(formatNumero(fieldUtilizadoClienteEfectivoSinRecibo.value)));
        }

    };

    const handlePrecalculo = () => {

        ////////////////////////////////////////

        // totalDepositos [fieldTotalDeposito]
        // subTotalDepositos [fieldSubTotalDeposito]
        // ivaDepositos [fieldIVADeposito]
        // comisionDepositos [fieldComisionDeposito]

        // montoRetornar [fieldMontoRetornar]

        ///////////////////////////////////////

        //Promotor
        //Parametro fieldPorcentajePromotores
        //Parametros fieldOperacionPorcentajePromotores
        //Resultado fieldComisionPromotor

        if(dataParametros.value.parametrosCalculo.parametros.fieldOperacionPorcentajePromotores.toString() !== '0')
        {
            let stringOperacionPromotor = ''
            stringOperacionPromotor = dataParametros.value.parametrosCalculo.parametros.fieldOperacionPorcentajePromotores
                .replaceAll( '%', Number(formatNumero(dataParametros.value.parametrosCalculo.parametros.fieldPorcentajePromotores)) )
                .replaceAll( 'totalDepositos', Number(formatNumero(fieldTotalDeposito.value)) )
                .replaceAll( 'subTotalDepositos', Number(formatNumero(fieldSubTotalDeposito.value)) )
                .replaceAll( 'ivaDepositos', Number(formatNumero(fieldIVADeposito.value)) )
                .replaceAll( 'comisionDepositos', Number(formatNumero(fieldComisionDeposito.value)) )
                .replaceAll( 'montoRetornar', Number(formatNumero(fieldMontoRetornar.value)) )

            fieldComisionPromotor.value = formatoMexico(eval(stringOperacionPromotor));
        }
        else
        {
            fieldComisionPromotor.value = formatoMexico('0.00');
        }

        ///////////////////////////////////////

        //Comision en pesos
        //Parametro fieldComisionPesosPorcentaje
        //Parametros fieldComisionPesosOperacion
        //Resultado fieldComisionPesos

        if(dataParametros.value.parametrosCalculo.parametros.fieldComisionPesosOperacion.toString() !== '0')
        {
            let stringOperacionComisionPesos = ''
            stringOperacionComisionPesos = dataParametros.value.parametrosCalculo.parametros.fieldComisionPesosOperacion
                .replaceAll( '%', Number(formatNumero(dataParametros.value.parametrosCalculo.parametros.fieldComisionPesosPorcentaje)) )
                .replaceAll( 'totalDepositos', Number(formatNumero(fieldTotalDeposito.value)) )
                .replaceAll( 'subTotalDepositos', Number(formatNumero(fieldSubTotalDeposito.value)) )
                .replaceAll( 'ivaDepositos', Number(formatNumero(fieldIVADeposito.value)) )
                .replaceAll( 'comisionDepositos', Number(formatNumero(fieldComisionDeposito.value)) )
                .replaceAll( 'montoRetornar', Number(formatNumero(fieldMontoRetornar.value)) )
            
            fieldComisionPesos.value = formatoMexico(eval(stringOperacionComisionPesos));
        }
        else
        {
            fieldComisionPesos.value = formatoMexico('0.00');
        }

        ///////////////////////////////////////

        //Comision en pesos extra
        //Parametro fieldComisionPesosExtraPorcentaje
        //Parametros fieldComisionPesosExtraOperacion
        //Resultado fieldComisionPesosExtra

        if(dataParametros.value.parametrosCalculo.parametros.fieldComisionPesosExtraOperacion.toString() !== '0')
        {
            let stringOperacionComisionPesosExtra = ''
            stringOperacionComisionPesosExtra = dataParametros.value.parametrosCalculo.parametros.fieldComisionPesosExtraOperacion
                .replaceAll( '%', Number(formatNumero(dataParametros.value.parametrosCalculo.parametros.fieldComisionPesosExtraPorcentaje)) )
                .replaceAll( 'totalDepositos', Number(formatNumero(fieldTotalDeposito.value)) )
                .replaceAll( 'subTotalDepositos', Number(formatNumero(fieldSubTotalDeposito.value)) )
                .replaceAll( 'ivaDepositos', Number(formatNumero(fieldIVADeposito.value)) )
                .replaceAll( 'comisionDepositos', Number(formatNumero(fieldComisionDeposito.value)) )
                .replaceAll( 'montoRetornar', Number(formatNumero(fieldMontoRetornar.value)) )
                
            fieldComisionPesosExtra.value = formatoMexico(eval(stringOperacionComisionPesosExtra));
        }
        else
        {
            fieldComisionPesosExtra.value = formatoMexico('0.00');
        }

        ///////////////////////////////////////

        //I.V.A.
        //Parametro fieldIVAPorcentaje
        //Parametros fieldIVAOperacion
        //Resultado fieldIVA

        if(dataParametros.value.parametrosCalculo.parametros.fieldIVAOperacion.toString() !== '0')
        {
            let stringOperacionIVA = ''
            stringOperacionIVA = dataParametros.value.parametrosCalculo.parametros.fieldIVAOperacion
                .replaceAll( '%', Number(formatNumero(dataParametros.value.parametrosCalculo.parametros.fieldIVAPorcentaje)) )
                .replaceAll( 'totalDepositos', Number(formatNumero(fieldTotalDeposito.value)) )
                .replaceAll( 'subTotalDepositos', Number(formatNumero(fieldSubTotalDeposito.value)) )
                .replaceAll( 'ivaDepositos', Number(formatNumero(fieldIVADeposito.value)) )
                .replaceAll( 'comisionDepositos', Number(formatNumero(fieldComisionDeposito.value)) )
                .replaceAll( 'montoRetornar', Number(formatNumero(fieldMontoRetornar.value)) )
                
                fieldIVA.value = formatoMexico(eval(stringOperacionIVA));
        }
        else
        {
            fieldIVA.value = formatoMexico('0.00');
        }

        ///////////////////////////////////////

        //Devolucion I.V.A.
        //Parametro fieldDevolucionIVAPorcentaje
        //Parametros fieldDevolucionIVAOperacion
        //Resultado fieldDevolucionIVA

        if(dataParametros.value.parametrosCalculo.parametros.fieldDevolucionIVAOperacion.toString() !== '0')
        {
            let stringOperacionDevIVA = ''
            stringOperacionDevIVA = dataParametros.value.parametrosCalculo.parametros.fieldDevolucionIVAOperacion
                .replaceAll( '%', Number(formatNumero(dataParametros.value.parametrosCalculo.parametros.fieldDevolucionIVAPorcentaje)) )
                .replaceAll( 'totalDepositos', Number(formatNumero(fieldTotalDeposito.value)) )
                .replaceAll( 'subTotalDepositos', Number(formatNumero(fieldSubTotalDeposito.value)) )
                .replaceAll( 'ivaDepositos', Number(formatNumero(fieldIVADeposito.value)) )
                .replaceAll( 'comisionDepositos', Number(formatNumero(fieldComisionDeposito.value)) )
                .replaceAll( 'montoRetornar', Number(formatNumero(fieldMontoRetornar.value)) )
                
                fieldDevolucionIVA.value = formatoMexico(eval(stringOperacionDevIVA));
        }
        else
        {
            fieldDevolucionIVA.value = formatoMexico('0.00');
        }

        ///////////////////////////////////////

        //Retorno
        //Parametro fieldRetornoPorcentaje
        //Parametros fieldRetornoOperacion
        //Resultado fieldRetorno

        if(dataParametros.value.parametrosCalculo.parametros.fieldRetornoOperacion.toString() !== '0')
        {
            let stringOperacionRetorno = ''
            stringOperacionRetorno = dataParametros.value.parametrosCalculo.parametros.fieldRetornoOperacion
                .replaceAll( '%', Number(formatNumero(dataParametros.value.parametrosCalculo.parametros.fieldRetornoPorcentaje)) )
                .replaceAll( 'totalDepositos', Number(formatNumero(fieldTotalDeposito.value)) )
                .replaceAll( 'subTotalDepositos', Number(formatNumero(fieldSubTotalDeposito.value)) )
                .replaceAll( 'ivaDepositos', Number(formatNumero(fieldIVADeposito.value)) )
                .replaceAll( 'comisionDepositos', Number(formatNumero(fieldComisionDeposito.value)) )
                .replaceAll( 'montoRetornar', Number(formatNumero(fieldMontoRetornar.value)) )
                
                fieldRetorno.value = formatoMexico(eval(stringOperacionRetorno));
        }
        else
        {
            fieldRetorno.value = formatoMexico('0.00');
        }

        fieldSaldo.value = formatoMexico(Number(formatNumero(fieldSaldoClienteAsimilado.value)) + Number(formatNumero(fieldSaldoClienteEfectivoSinRecibo.value)));


    }

    const handlePrecalculoGuardar = async() => {

        if(confirm('Estas seguro de guardar la solicitud?') === true)
        {
            buttonDisabledPrecalculoGuardar.value = true;
            handlePrecalculo();

            let sumaRetorno = 0;
            let sumaRetornoSinRecibo = 0;

            jsonRegistrosExcelAsimilados.value.map( (valor) => {
                sumaRetorno += Number(formatNumero(valor.retorno));
            } );

            jsonRegistrosExcelSinRecibo.value.map( (valor) => {
                sumaRetornoSinRecibo += Number(formatNumero(valor.retorno));
            } );

            if( ( sumaRetorno + sumaRetornoSinRecibo) > 0)
            {
                if(fieldFechaLayoutPago.value.length <= 0 || fieldCorreoBeneficiario.value.length <= 0)
                {
                    notify({
                        title: 'Solicitud',
                        text: 'Faltan los datos de fecha aplicacion pago banco y/o correo beneficiario.'
                    });
                    buttonDisabledPrecalculoGuardar.value = false;
                }
                else
                {
                    let jsonDatosGuardado = {

                        fieldSelectGrupoEmpresarial: fieldSelectGrupoEmpresarial.value,
                        idUnicoPlantilla: dataParametros.value.parametrosCalculo.idUnicoPlantilla,
    
                        fieldFolioSolicitud: fieldFolioSolicitud.value,
                        fieldFechaSolicitud: fieldFechaSolicitud.value,
                        fieldSaldoAFavor: fieldSaldoAFavor.value,
                        fieldSaldoAFavorUtilizado: fieldSaldoAFavorUtilizado.value,
                        fieldFinanciamiento: fieldFinanciamiento.value,
                        fieldFinanciamientoUtilizado: fieldFinanciamientoUtilizado.value,

    
                        jsonDepositos: JSON.stringify( jsonDepositos.value ),
                        fieldTotalDeposito: fieldTotalDeposito.value,
                        fieldSubTotalDeposito: fieldSubTotalDeposito.value,
                        fieldIVADeposito: fieldIVADeposito.value,
                        fieldComisionDeposito: fieldComisionDeposito.value,
    
                        fieldDisponibleCliente: fieldDisponibleCliente.value,
                        fieldDisponibleClienteAsimilado: fieldDisponibleClienteAsimilado.value,
                        fieldUtilizadoClienteAsimilado: fieldUtilizadoClienteAsimilado.value,
                        fieldSaldoClienteAsimilado: fieldSaldoClienteAsimilado.value,
                        jsonRegistrosExcelAsimilados: JSON.stringify(jsonRegistrosExcelAsimilados.value),
    
                        fieldDisponibleClienteEfectivoSinRecibo: fieldDisponibleClienteEfectivoSinRecibo.value,
                        fieldUtilizadoClienteEfectivoSinRecibo: fieldUtilizadoClienteEfectivoSinRecibo.value,
                        fieldSaldoClienteEfectivoSinRecibo: fieldSaldoClienteEfectivoSinRecibo.value,
                        jsonRegistrosExcelSinRecibo: JSON.stringify(jsonRegistrosExcelSinRecibo.value),
                        jsonRegistrosExcelEfectivo: JSON.stringify(jsonRegistrosExcelEfectivo.value),
    
                        fieldFechaLayoutPago: fieldFechaLayoutPago.value,
                        fieldCorreoBeneficiario: fieldCorreoBeneficiario.value,
    
                        fieldMontoRetornar: fieldMontoRetornar.value,
    
                        fieldComisionPromotor: fieldComisionPromotor.value,
                        fieldComisionPesos: fieldComisionPesos.value,
                        fieldComisionPesosExtra: fieldComisionPesosExtra.value,
                        fieldIVA: fieldIVA.value,
                        fieldDevolucionIVA: fieldDevolucionIVA.value,
                        fieldRetorno: fieldRetorno.value,
                        fieldSaldo: fieldSaldo.value,
    
                        fieldSelectPromotores: dataParametros.value.parametrosCalculo.parametros.fieldSelectPromotores,
                        fieldPorcentajePromotores: dataParametros.value.parametrosCalculo.parametros.fieldPorcentajePromotores,
                        fieldOperacionPorcentajePromotores: dataParametros.value.parametrosCalculo.parametros.fieldOperacionPorcentajePromotores,
    
                        jsonCorreosAsignados: dataParametros.value.correosAsignados
    
                    };
    
                    const data = await operacion(
                        {
                            numero : '51',
                            proceso : '9',
                            opcionTD : 'TODO',
                            parametros : true,
                            datosReceptor : 
                            {
                                opcion: 'grabarSolicitud',
                                PidUnicoGrupoEmpresarial: fieldSelectGrupoEmpresarial.value,
                                PidUnicoPlantilla: dataParametros.value.parametrosCalculo.idUnicoPlantilla,
                                PfolioSolicitud: fieldFolioSolicitud.value,
                                PfechaSolicitud: fieldFechaSolicitud.value,
                                PsaldoAFavor: Number(formatNumero( fieldSaldoAFavor.value )),
                                PsaldoAFavorUtilizado: Number(formatNumero( fieldSaldoAFavorUtilizado.value )),
                                Pfinanciamiento: Number(formatNumero( fieldFinanciamiento.value )),
                                PfinanciamientoUtilizado: Number(formatNumero( fieldFinanciamientoUtilizado.value )),
    
                                PtotalDepositos: Number(formatNumero( fieldTotalDeposito.value )),
                                PsubTotalDepositos: Number(formatNumero( fieldSubTotalDeposito.value )),
                                PivaDepositos: Number(formatNumero( fieldIVADeposito.value )),
                                PcomisionDeposito: Number(formatNumero( fieldComisionDeposito.value )),
                                PmontoAsimilado: Number(formatNumero( fieldDisponibleClienteAsimilado.value )),
                                PmontoEfectivoSinRecibo: Number(formatNumero( fieldDisponibleClienteEfectivoSinRecibo.value )),
    
                                PfechaLayoutPago: fieldFechaLayoutPago.value,
                                PcorreoBeneficiario: fieldCorreoBeneficiario.value,
    
                                PcomisionPromotor: Number(formatNumero( fieldComisionPromotor.value )),
                                PcomisionPesos: Number(formatNumero( fieldComisionPesos.value )),
                                PcomisionPesosExtra: Number(formatNumero( fieldComisionPesosExtra.value )),
                                Piva: Number(formatNumero( fieldIVA.value )),
                                PdevIva: Number(formatNumero( fieldDevolucionIVA.value )),
                                Pretorno: Number(formatNumero( fieldRetorno.value )),
                                Psaldo: Number(formatNumero( fieldSaldo.value )),
    
                                PjsonData: JSON.stringify( jsonDatosGuardado )
                            },
                            mostrarMensaje: true
                        }
                    );
                    if(data.bandera === 0)
                    {
                        jsonDatosGuardado = {};
                        await limpiarDatos();
                        notify({
                            title: 'Solicitud',
                            text: data.mensaje
                        });
                        buttonDisabledPrecalculoGuardar.value = false;
                    }
                    else
                    {
                        notify({
                            title: 'Solicitud',
                            text: data.mensaje
                        });
                        buttonDisabledPrecalculoGuardar.value = false;
                    }
                }
            }
            else
            {
                let jsonDatosGuardado = {

                    fieldSelectGrupoEmpresarial: fieldSelectGrupoEmpresarial.value,
                    idUnicoPlantilla: dataParametros.value.parametrosCalculo.idUnicoPlantilla,

                    fieldFolioSolicitud: fieldFolioSolicitud.value,
                    fieldFechaSolicitud: fieldFechaSolicitud.value,
                    fieldSaldoAFavor: fieldSaldoAFavor.value,
                    fieldSaldoAFavorUtilizado: fieldSaldoAFavorUtilizado.value,
                    fieldFinanciamiento: fieldFinanciamiento.value,
                    fieldFinanciamientoUtilizado: fieldFinanciamientoUtilizado.value,


                    jsonDepositos: JSON.stringify( jsonDepositos.value ),
                    fieldTotalDeposito: fieldTotalDeposito.value,
                    fieldSubTotalDeposito: fieldSubTotalDeposito.value,
                    fieldIVADeposito: fieldIVADeposito.value,
                    fieldComisionDeposito: fieldComisionDeposito.value,

                    fieldDisponibleCliente: fieldDisponibleCliente.value,
                    fieldDisponibleClienteAsimilado: fieldDisponibleClienteAsimilado.value,
                    fieldUtilizadoClienteAsimilado: fieldUtilizadoClienteAsimilado.value,
                    fieldSaldoClienteAsimilado: fieldSaldoClienteAsimilado.value,
                    jsonRegistrosExcelAsimilados: JSON.stringify(jsonRegistrosExcelAsimilados.value),

                    fieldDisponibleClienteEfectivoSinRecibo: fieldDisponibleClienteEfectivoSinRecibo.value,
                    fieldUtilizadoClienteEfectivoSinRecibo: fieldUtilizadoClienteEfectivoSinRecibo.value,
                    fieldSaldoClienteEfectivoSinRecibo: fieldSaldoClienteEfectivoSinRecibo.value,
                    jsonRegistrosExcelSinRecibo: JSON.stringify(jsonRegistrosExcelSinRecibo.value),
                    jsonRegistrosExcelEfectivo: JSON.stringify(jsonRegistrosExcelEfectivo.value),

                    fieldFechaLayoutPago: fieldFechaLayoutPago.value,
                    fieldCorreoBeneficiario: fieldCorreoBeneficiario.value,

                    fieldMontoRetornar: fieldMontoRetornar.value,

                    fieldComisionPromotor: fieldComisionPromotor.value,
                    fieldComisionPesos: fieldComisionPesos.value,
                    fieldComisionPesosExtra: fieldComisionPesosExtra.value,
                    fieldIVA: fieldIVA.value,
                    fieldDevolucionIVA: fieldDevolucionIVA.value,
                    fieldRetorno: fieldRetorno.value,
                    fieldSaldo: fieldSaldo.value,

                    fieldSelectPromotores: dataParametros.value.parametrosCalculo.parametros.fieldSelectPromotores,
                    fieldPorcentajePromotores: dataParametros.value.parametrosCalculo.parametros.fieldPorcentajePromotores,
                    fieldOperacionPorcentajePromotores: dataParametros.value.parametrosCalculo.parametros.fieldOperacionPorcentajePromotores,

                    jsonCorreosAsignados: dataParametros.value.correosAsignados

                };

                const data = await operacion(
                    {
                        numero : '51',
                        proceso : '9',
                        opcionTD : 'TODO',
                        parametros : true,
                        datosReceptor : 
                        {
                            opcion: 'grabarSolicitud',
                            PidUnicoGrupoEmpresarial: fieldSelectGrupoEmpresarial.value,
                            PidUnicoPlantilla: dataParametros.value.parametrosCalculo.idUnicoPlantilla,
                            PfolioSolicitud: fieldFolioSolicitud.value,
                            PfechaSolicitud: fieldFechaSolicitud.value,
                            PsaldoAFavor: Number(formatNumero( fieldSaldoAFavor.value )),
                            PsaldoAFavorUtilizado: Number(formatNumero( fieldSaldoAFavorUtilizado.value )),
                            Pfinanciamiento: Number(formatNumero( fieldFinanciamiento.value )),
                            PfinanciamientoUtilizado: Number(formatNumero( fieldFinanciamientoUtilizado.value )),

                            PtotalDepositos: Number(formatNumero( fieldTotalDeposito.value )),
                            PsubTotalDepositos: Number(formatNumero( fieldSubTotalDeposito.value )),
                            PivaDepositos: Number(formatNumero( fieldIVADeposito.value )),
                            PcomisionDeposito: Number(formatNumero( fieldComisionDeposito.value )),
                            PmontoAsimilado: Number(formatNumero( fieldDisponibleClienteAsimilado.value )),
                            PmontoEfectivoSinRecibo: Number(formatNumero( fieldDisponibleClienteEfectivoSinRecibo.value )),

                            PfechaLayoutPago: fieldFechaLayoutPago.value,
                            PcorreoBeneficiario: fieldCorreoBeneficiario.value,

                            PcomisionPromotor: Number(formatNumero( fieldComisionPromotor.value )),
                            PcomisionPesos: Number(formatNumero( fieldComisionPesos.value )),
                            PcomisionPesosExtra: Number(formatNumero( fieldComisionPesosExtra.value )),
                            Piva: Number(formatNumero( fieldIVA.value )),
                            PdevIva: Number(formatNumero( fieldDevolucionIVA.value )),
                            Pretorno: Number(formatNumero( fieldRetorno.value )),
                            Psaldo: Number(formatNumero( fieldSaldo.value )),

                            PjsonData: JSON.stringify( jsonDatosGuardado )
                        },
                        mostrarMensaje: true
                    }
                );
                if(data.bandera === 0)
                {
                    jsonDatosGuardado = {};
                    await limpiarDatos();
                    notify({
                        title: 'Solicitud',
                        text: data.mensaje
                    });
                    buttonDisabledPrecalculoGuardar.value = false;
                }
                else
                {
                    notify({
                        title: 'Solicitud',
                        text: data.mensaje
                    });
                    buttonDisabledPrecalculoGuardar.value = false;
                }
        
            }
        }
        
    };

    const limpiarDatos = async() => {
        processBotonSeleccionarGrupoEmpresarial.value = {
            texto: 'Nueva solicitud',
            seleccionado: false
        };
        fieldSelectGrupoEmpresarial.value = '0';
        fieldFolioSolicitud.value = '';
        fieldFechaSolicitud.value = '';
        fieldActivarFinanciamiento.value = false;
        fieldSaldoAFavor.value = '0.00';
        fieldSaldoAFavorUtilizado.value = '0.00';
        fieldFinanciamiento.value = '0.00';
        fieldFinanciamientoUtilizado.value = '0.00';
        fieldOperarCon.value = 'S/D';
        fieldFechaLayoutPago.value = new Date();
        fieldCorreoBeneficiario.value = '';
        jsonDepositos.value = [];
        fieldTotalDeposito.value = '0.00';
        fieldSubTotalDeposito.value = '0.00';
        fieldIVADeposito.value = '0.00';
        fieldDisponibleCliente.value = '0.00';

        fieldComisionDeposito.value = '0.00';

        fieldDisponibleClienteAsimilado.value = '0.00';
        fieldUtilizadoClienteAsimilado.value = '0.00';
        fieldSaldoClienteAsimilado.value = '0.00';

        fieldDisponibleClienteEfectivoSinRecibo.value = '0.00';
        fieldUtilizadoClienteEfectivoSinRecibo.value = '0.00';
        fieldSaldoClienteEfectivoSinRecibo.value = '0.00';

        fieldMontoRetornar.value = '0.00';
        fieldComisionPromotor.value = '0.00';
        fieldComisionPesos.value = '0.00';
        fieldComisionPesosExtra.value = '0.00';
        fieldIVA.value = '0.00';
        fieldDevolucionIVA.value = '0.00';
        fieldRetorno.value = '0.00';
        fieldSaldo.value = '0.00';

        jsonLayOutBancariosNomina.value = [];
        jsonRegistrosExcelAsimilados.value = [];

        jsonLayOutBancariosSinRecibo.value = [];
        jsonRegistrosExcelSinRecibo.value = [];

        jsonRegistrosExcelEfectivo.value = [];

        solicitud.value = true;
    };

    watch([fieldMontoDeposito], (nuevo) => {
        let valor = nuevo[0];
        try
        {
            fieldMontoDeposito.value = formatoMexico(Number(formatNumero(valor)));
        }
        catch(error)
        {
            fieldMontoDeposito.value = 0.00;
        }
    });
        
    //#endregion

    return {

        jsonGrupoEmpresarial,
        fieldSelectGrupoEmpresarial,
        processBotonSeleccionarGrupoEmpresarial,
        fieldFolioSolicitud,
        fieldFechaSolicitud,
        fieldSaldoAFavor,
        fieldSaldoAFavorUtilizado,
        fieldActivarFinanciamiento,
        fieldFinanciamiento,
        fieldFinanciamientoUtilizado,
        fieldOperarCon,
        jsonTablaClientes,
        jsonEmpresasInternas,
        processModalDepositos,
        fieldRazonSocialDeposita,
        fieldRazonSocialRecibeDeposito,
        fieldDatosFacturacion,
        fieldTipoMovimiento,
        fieldMontoDeposito,
        fieldFactura,
        jsonConceptosFactura,
        fieldValidacion,

        processModalSaldoAFavor,
        processModalFinanciamiento,
        
        jsonDepositos,
        fieldTotalDeposito,
        fieldSubTotalDeposito,
        fieldIVADeposito,
        fieldComisionDeposito,

        jsonRegistrosExcelAsimilados,
        jsonBeneficiarios,
        modalRegistrosExcelAsimilados,
        jsonLayOutBancariosNomina,
        fieldDisponibleCliente,
        fieldDisponibleClienteAsimilado,
        fieldSaldoClienteAsimilado,

        fieldUtilizadoClienteEfectivoSinRecibo,
        fieldDisponibleClienteEfectivoSinRecibo,
        fieldSaldoClienteEfectivoSinRecibo,
        jsonRegistrosExcelSinRecibo,

        fieldUtilizadoClienteAsimilado,

        fieldMontoRetornar,
        modalRegistrosExcelEfectivo,
        modalRegistrosExcelSinRecibo,

        fieldFechaLayoutPago,

        fieldCorreoBeneficiario,
        fieldComisionPromotor,
        fieldComisionPesos,
        fieldActivarComisionPesosExtra,
        fieldIVA,
        fieldDevolucionIVA,
        fieldRetorno,
        fieldSaldo,
        buttonDisabledPrecalculoGuardar,
        jsonLayOutBancariosSinRecibo,
        jsonRegistrosExcelEfectivo,
        fieldComisionPesosExtra,

        solicitud,

        //==============================================================================

        handleProcesoBotonSeleccionarGrupoEmpresarial,
        handleOpenModalDepSalFin,
        handleCloseModalDepSalFin,
        handleValidarFile,
        handleGrabarDepositoDepSalFin,
        handleQuitarDepSalFin,

        handleModalMontoAsimiladosBene,
        handleMontoAsimiladosBene,

        handleModalMontoSinReciboBene,
        handleMontoSinReciboBene,

        handleModalMontoEfectivoBene,
        handleMontoEfectivoBene,

        handlePrecalculo,
        handlePrecalculoGuardar,

        isDecimal02
    }

};

export default useNuevaSolicitud;