<template>

    <div class="bg-white">
    <main class="mt-0 main-content">
      <section>
        <div class="page-header min-vh-100">
          <div class="container">


            <div v-if=" activoAcceso === 0 ? false : true " class="row">
              
              <div class="col-6 d-lg-flex d-none h-100 my-auto pe-0 ps-0 position-absolute top-0 start-0 text-center justify-content-center flex-column">
                <div class="position-relative h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center"><img :src="logo"></div>
                
              </div>

              <div class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column ms-auto me-auto ms-lg-auto me-lg-5">
                
                <div class="card card-plain">
                  
                  <div class="pb-0 card-header bg-transparent mb-4">
                    <h4 class="font-weight-bolder">Acceso Cliente: {{nombreGrupoEmpresarial}} </h4>
                    <p class="mb-0">
                      Introduce la clave de acceso
                    </p>
                  </div>
                  
                  <div class="card-body">
                    <form role="form" @submit.prevent="iniciarSesion">

                      <div class="form-group">
                        <label for="exampleInputPassword1">Clave Acceso</label>
                        <input type="password" v-model="claveAcceso" class="form-control" placeholder="Clave Acceso" autocomplete="off" required>
                      </div>


                      <button type="submit" class="btn btn-primary">Iniciar Sesion Cliente</button>


                    </form>

                  </div>

                </div>

              </div>

            </div>

            <div v-if=" activoAcceso === 1 ? false : true " class="row">
                <div class="col">
                    <p style="font-size:40px">No hay datos de acceso.</p>
                    <p style="font-size:20px">Favor de solicitarlo con tu accesor.</p>
                    <br>
                    <br>
                    <p style="font-size:20px">SAMSuite - Cliente.</p>
                </div>
            </div>



          </div>
        </div>
      </section>
    </main>
  </div>
  
</template>

<script>
import proceso from './js/proceso';
export default {
    name: 'cliente',
    components:{},
    setup(){

        return{
            ...proceso()
        }
    }

}
</script>

<style scoped>
    @import './css/estilos.css';
</style>