<template>

    <div class="row">

        <div class="col">

            <div class="form-group">
                <label>Grupo Empresarial:</label>
                <select v-model="fieldSelectGrupoEmpresarial" class="form-control" :disabled="processBotonSeleccionarGrupoEmpresarial.seleccionado">
                    <option value="0">Selecciona una opcion.</option>
                    <option v-for=" ({idUnico, grupoEmpresarial}) in jsonGrupoEmpresarial" :key="idUnico" :value="idUnico">{{grupoEmpresarial}}</option>
                </select>
            </div>

        </div>
        <div class="col">
            <div class="form-group">
                <br>
                <button type="button" class="btn btn-info mb-2" @click="handleProcesoBotonSeleccionarGrupoEmpresarial">{{processBotonSeleccionarGrupoEmpresarial.texto}}</button>
            </div>
        </div>
        <div class="col">

        </div>

        <div class="col">
            
        </div>

    </div>

    <div class="card">

        <div class="card-body">

           
            <h5 class="card-title">Nueva Solicitud</h5>

            <div class="row">

                <div class="col">

                    <div class="form-group">
                        <label>Folio Solicitud:</label>
                        <input type="text" class="form-control" v-model="fieldFolioSolicitud" disabled>
                    </div>

                </div>

                <div class="col">

                    <div class="form-group">
                        <label>Fecha Solicitud:</label>
                        <input type="text" class="form-control" v-model="fieldFechaSolicitud" disabled>
                    </div>

                </div>

                

                <div class="col">

                    <div class="form-group" >
                        <label>Saldo a favor actual:</label>
                        <input type="text" class="form-control" v-model="fieldSaldoAFavor" disabled>
                    </div>
                    
                </div>

                <div class="col">

                    <div class="form-group" >
                        <label>Saldo a favor utilizado:</label>
                        <input type="text" class="form-control" v-model="fieldSaldoAFavorUtilizado" disabled>
                    </div>

                </div>

                <div class="col">
                    
                    <div class="form-group" v-if="fieldActivarFinanciamiento">
                        <label>Financiamiento actual:</label>
                        <input type="text" class="form-control" v-model="fieldFinanciamiento" disabled>
                    </div>

                </div>

                <div class="col">
                    
                    <div class="form-group" v-if="fieldActivarFinanciamiento">
                        <label>Financiamiento utilizado:</label>
                        <input type="text" class="form-control" v-model="fieldFinanciamientoUtilizado" disabled>
                    </div>

                </div>

                <div class="col">

                </div>

                
                <div class="col"></div>

            </div>

            <div class="row">

                <div class="col">
                    <label style="font-size: 18px;">Operar con:</label>
                </div>
                <div class="col">
                    <select class="form-control" v-model="fieldOperarCon" :disabled="solicitud">
                        <option value="S/D">Selecciona una opcion.</option>
                        <option value="Deposito">Deposito</option>
                        <option v-if="fieldSaldoAFavor !== '0.00'" value="Saldo a favor">Saldo a favor</option>
                        <option v-if="fieldActivarFinanciamiento" value="Financiamiento">Financiamiento</option>
                    </select>
                </div>
                <div class="col">
                    <button type="button" class="btn btn-info" @click="handleOpenModalDepSalFin" :disabled="fieldOperarCon === 'S/D' ? true : false">
                        <i class="fa fa-plus-circle" aria-hidden="true" style="color: white; font-size: 15px;"></i>&nbsp;&nbsp;
                        <i class="fa fa-credit-card-alt" aria-hidden="true" style="color: white; font-size: 15px;"></i>
                    </button>
                </div>
                <div class="col"></div>
                <div class="col"></div>
                <div class="col"></div>

            </div>

            <div class="row">

                    <div class="col tableBorder">
                        <table class="table mb-0">

                            <thead>
                                <tr>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Tipo</th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Razon Social Deposita</th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Empresa Recibe Deposito</th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Monto Deposito</th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Subtotal</th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">I.V.A.</th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Tipo Movimiento</th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Comision</th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Opciones</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(registro, index) in jsonDepositos" :key="index">
                                    <td>
                                        <h6 class="mb-0 text-xs">{{ registro.tipo }}</h6>
                                    </td>
                                    <td>
                                        <h6 class="mb-0 text-xs">{{ registro.razonSocialCliente }}</h6>
                                    </td>

                                    <td>
                                        <h6 class="mb-0 text-xs">{{ registro.razonSocialInterna }}</h6>
                                    </td>

                                    <td>
                                        <h6 class="mb-0 text-xs">{{ registro.montoDeposito }}</h6>
                                    </td>

                                    <td>
                                        <h6 class="mb-0 text-xs">{{ registro.subTotal }}</h6>
                                    </td>

                                    <td>
                                        <h6 class="mb-0 text-xs">{{ registro.iva }}</h6>
                                    </td>

                                    <td>
                                        <h6 class="mb-0 text-xs">{{ registro.tipoMovimiento }}</h6>
                                    </td>

                                    <td>
                                        <h6 class="mb-0 text-xs">{{ registro.comision }}</h6>
                                    </td>

                                    <td>
                                        <button type="button" class="btn btn-info btn-sm" @click="handleQuitarDepSalFin(registro)">Quitar</button>
                                    </td>
                                </tr>
                            </tbody>

                        </table>

                    </div>

            </div>

            <br>

            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Total ($):</label>
                        <input type="text" class="form-control" v-model="fieldTotalDeposito" disabled>
                    </div>
                </div>

                <div class="col">
                    <div class="form-group">
                        <label>Sub Total ($):</label>
                        <input type="text" class="form-control" v-model="fieldSubTotalDeposito" disabled>
                    </div>
                </div>

                <div class="col">
                    <div class="form-group">
                        <label>I.V.A. ($):</label>
                        <input type="text" class="form-control" v-model="fieldIVADeposito" disabled>
                    </div>
                </div>

                <div class="col">
                    <div class="form-group">
                        <label>Comision ($):</label>
                        <input type="text" class="form-control" v-model="fieldComisionDeposito" disabled>
                    </div>
                </div>

            </div>

            <br>

            <div class="row p-4" style="border: 1px solid gray; border-radius: 5px; display: flex; justify-content: center; align-items: center;">
                <div class="row">

                    <div class="col">

                        <div class="row">
                            <div class="form-group">
                                <label>Total Disponible Cliente:</label>
                                <input type="text" class="form-control" v-model="fieldDisponibleCliente" @keypress="isDecimal02($event)" disabled>
                            </div>
                        </div>

                        <div class="row">
                            
                        </div>
                        
                    </div>

                    <div class="col">

                        <div class="row">
                            <div class="form-group">
                                <label>Total disponible asimilado cliente:</label>
                                <input type="text" class="form-control" v-model="fieldDisponibleClienteAsimilado" @keypress="isDecimal02($event)" disabled>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group">
                                <label>Total utilizado asimilado cliente:</label>
                                <input type="text" class="form-control" v-model="fieldUtilizadoClienteAsimilado" @keypress="isDecimal02($event)" disabled>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group">
                                <label>Total saldo asimilado cliente:</label>
                                <input type="text" class="form-control" v-model="fieldSaldoClienteAsimilado" @keypress="isDecimal02($event)" disabled>
                            </div>
                        </div>

                        <div class="row">
                            <div class="input-group-append">
                                <button class="btn btn-outline-info" type="button" :disabled="fieldDisponibleClienteAsimilado !== '0.00' ? false : true" @click="handleModalMontoAsimiladosBene">beneficiarios</button>
                            </div>
                        </div>
                        
                    </div>

                    <div class="col">

                        <div class="row">
                            <div class="form-group">
                                <label>Total disponible efectivo / sin recibo cliente:</label>
                                <input type="text" class="form-control" v-model="fieldDisponibleClienteEfectivoSinRecibo" @keypress="isDecimal02($event)" disabled>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group">
                                <label>Total utlizado efectivo / sin recibo cliente:</label>
                                <input type="text" class="form-control" v-model="fieldUtilizadoClienteEfectivoSinRecibo" @keypress="isDecimal02($event)" disabled>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group">
                                <label>Total saldo efectivo / sin recibo cliente:</label>
                                <input type="text" class="form-control" v-model="fieldSaldoClienteEfectivoSinRecibo" @keypress="isDecimal02($event)" disabled>
                            </div>
                        </div>

                        <div class="row">
                            
                            <div class="col">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-info" type="button" :disabled="fieldDisponibleClienteEfectivoSinRecibo !== '0.00' ? false : true" @click="handleModalMontoSinReciboBene">benef. Sin recibo</button>
                                </div>
                            </div>

                            <div class="col">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-info" type="button" :disabled="fieldDisponibleClienteEfectivoSinRecibo !== '0.00' ? false : true" @click="handleModalMontoEfectivoBene">benef. Efectivo</button>
                                </div>
                            </div>

                        </div>
                        
                    </div>

                </div>

                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Fecha aplicacion pago banco layout:</label>
                            <Datepicker class="form-control" v-model="fieldFechaLayoutPago" :disabled="solicitud"/>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label>Correo beneficiario:</label>
                            <input type="text" class="form-control" v-model="fieldCorreoBeneficiario" :disabled="solicitud"/>
                        </div>
                    </div>
                    <div class="col"></div>
                    <div class="col"></div>
                </div>

            </div>

            <br>

            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Monto a retornar ($):</label>
                        <input type="text" class="form-control" v-model="fieldMontoRetornar" disabled>
                    </div>
                </div>

                <div class="col">

                </div>

                <div class="col">

                </div>
            </div>

            <div id="divPadre">
                <div id="divHijo">
                    
                    <div class="row">

                        <div class="col">
                            <div class="form-group">
                                <label>Comision promotor ($):</label>
                                <input type="text" class="form-control" v-model="fieldComisionPromotor" disabled>
                            </div>
                        </div>

                        <div class="col">
                            <div class="form-group">
                                <label>Comision en pesos ($):</label>
                                <input type="text" class="form-control" v-model="fieldComisionPesos" disabled>
                            </div>
                        </div>

                        <div class="col" v-if="fieldActivarComisionPesosExtra">
                            <div class="form-group">
                                <label>Comision en pesos extras ($):</label>
                                <input type="text" class="form-control" v-model="fieldComisionPesosExtra" disabled>
                            </div>
                        </div>

                    </div>

                    <div class="row">

                        <div class="col">
                            <div class="form-group">
                                <label>I.V.A. ($):</label>
                                <input type="text" class="form-control" v-model="fieldIVA" disabled>
                            </div>
                        </div>

                        <div class="col">
                            <div class="form-group">
                                <label>Dev. I.V.A. ($):</label>
                                <input type="text" class="form-control" v-model="fieldDevolucionIVA" disabled>
                            </div>
                        </div>

                    </div>

                    <div class="row">

                        <div class="col">
                            <div class="form-group">
                                <label>Retorno ($):</label>
                                <input type="text" class="form-control" v-model="fieldRetorno" disabled>
                            </div>
                        </div>

                        <div class="col">
                            <div class="form-group">
                                <label>Saldo a favor final ($):</label>
                                <input type="text" class="form-control" v-model="fieldSaldo" disabled>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <div class="row">
                <div class="col">
                    
                </div>

                <div class="col">
                    <div class="form-group">
                        <button type="button" class="btn btn-warning btn-lg btn-block" @click="handlePrecalculo" :disabled="solicitud">Precalculo</button>
                    </div>
                </div>

                <div class="col">
                    <div class="form-group">
                        <button type="button" :disabled="solicitud" class="btn btn-info btn-lg btn-block" @click="handlePrecalculoGuardar">Precalculo / Grabar</button>
                    </div>
                </div>

                <div class="col">

                </div>
            </div>
            

        </div>

    </div>








    <comp-modal v-if="processModalDepositos.open" size="width: 900px; height: 690px;" sizeCuerpo="height:550px">
        <template v-slot:encabezado>
        <label><h5>{{processModalDepositos.title}}</h5></label>
      </template>
      <template v-slot:cuerpo>
          
          <div class="classContenedor">

            <div class="row">

                
                <div class="col">
                    <p>Razón Social que depositó:</p>
                </div>

                <div class="col">
                    <div class="form-group" style="width: 550px;">
                        <select class="form-control" v-model="fieldRazonSocialDeposita">
                            <option value="S/D">Selecciona una opcion.</option>
                            <option v-for="{idSysTSEmpresas, razonSocial} in jsonTablaClientes.filter( e => e.usoCFDI !== 'S/D' )" :key="idSysTSEmpresas" :value="idSysTSEmpresas">{{razonSocial}}</option>
                        </select>
                    </div>
                </div>

            </div>

            <div class="row">

                <div class="col">
                    <p>Empresa que recibió el depósito:</p>
                </div>

                <div class="col">
                    <div class="form-group" style="width: 550px;">
                        <select class="form-control" v-model="fieldRazonSocialRecibeDeposito" @change="fieldSelectEmpresaRecibeDeposito">
                            <option value="S/D">Selecciona una opcion.</option>
                            <option v-for="{idSysCPEmpresasRP, razonSocial} in jsonEmpresasInternas.filter( e => e.serie !== 'S/D' )" :key="idSysCPEmpresasRP" :value="idSysCPEmpresasRP">{{razonSocial}}</option>
                        </select>
                    </div>
                </div>

            </div>

            <div class="row">

                <div class="col">
                    <p>Datos Factura:</p>
                </div>

                <div class="col">
                    <div class="form-group" style="width: 600px;">
                        <select class="form-control" v-model="fieldDatosFacturacion">
                            <option value="S/D">Selecciona una opcion.</option>
                            <option v-for="{idUnicoConcepto, prodServ, claveUnidad, metodoPago, formaPago, descripcion} in jsonConceptosFactura.filter( e => e.fieldEmpresasConcepto === fieldRazonSocialRecibeDeposito )" :key="idUnicoConcepto" :value="idUnicoConcepto">{{prodServ}}, {{claveUnidad}}, {{metodoPago}}, {{formaPago}}, {{descripcion}}</option>
                        </select>
                    </div>
                </div>

            </div>

            <div class="row">

                <div class="col">
                    <p>Tipo Movimiento:</p>
                </div>

                <div class="col">
                    <select class="form-control" v-model="fieldTipoMovimiento">
                        <option value="S/D">Selecciona una opcion.</option>
                        <option value="Asimilado">Asimilado</option>
                        <option value="Efectivo / Sin recibo">Efectivo / Sin recibo</option>
                    </select>
                    <br>
                </div>
                
            </div>

            <div class="row">

                <div class="col">
                    <p>Monto Deposito (I.V.A. Incluido):</p>
                </div>

                <div class="col">
                    <div class="form-group">
                        <input type="text" @keypress="isDecimal02($event)" style="text-align: right; font-size: 20px;" class="form-control" v-model.lazy="fieldMontoDeposito">
                    </div>
                </div>

            </div>

            <div class="row">
                <br>
                <div class="col">
                    <p>Factura:</p>
                </div>

                <div class="col">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="fieldFactura" disabled>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col">
                    <div class="custom-file">
                        <input type="file" class="form-control" accept=".jpg, .png, .pdf" lang="es" @change="handleValidarFile" />
                    </div>
                </div>
            </div>

            <br>

            <div class="classNoti">
                <p>Validacion: {{fieldValidacion}}</p>
            </div>
        </div>
          
      </template>

      <template v-slot:pie>

        <button type="button" class="btn btn-info" @click="handleGrabarDepositoDepSalFin">
            <i class="fa fa-floppy-o" aria-hidden="true" style="color: white; font-size: 15px;"></i>
        </button>
        &nbsp;&nbsp;
        <button type="button" class="btn btn-danger" @click="handleCloseModalDepSalFin">
            <i class="fa fa-window-close" aria-hidden="true" style="color: white; font-size: 15px;"></i>
        </button>

      </template>

    </comp-modal>

    <comp-modal v-if="processModalSaldoAFavor.open" size="width: 900px; height: 420px;" sizeCuerpo="height:280px">
        <template v-slot:encabezado>
        <label><h5>{{processModalSaldoAFavor.title}}</h5></label>
      </template>
      <template v-slot:cuerpo>
          
          <div class="classContenedor02">

            <div class="row">

                <div class="col">
                    <p>Tipo Movimiento:</p>
                </div>

                <div class="col">
                    <select class="form-control" v-model="fieldTipoMovimiento">
                        <option value="S/D">Selecciona una opcion.</option>
                        <option value="Asimilado">Asimilado</option>
                        <option value="Efectivo / Sin recibo">Efectivo / Sin recibo</option>
                    </select>
                    <br>
                </div>
                
            </div>

            <div class="row">

                <div class="col">
                    <p>Monto:</p>
                </div>

                <div class="col">
                    <div class="form-group">
                        <input type="text" @keypress="isDecimal02($event)" style="text-align: right; font-size: 20px;" class="form-control" v-model.lazy="fieldMontoDeposito">
                    </div>
                </div>

            </div>

            <div class="classNoti02">
                <p>Validacion: {{fieldValidacion}}</p>
            </div>
        </div>
          
      </template>

      <template v-slot:pie>

        <button type="button" class="btn btn-info" @click="handleGrabarDepositoDepSalFin">
            <i class="fa fa-floppy-o" aria-hidden="true" style="color: white; font-size: 15px;"></i>
        </button>
        &nbsp;&nbsp;
        <button type="button" class="btn btn-danger" @click="handleCloseModalDepSalFin">
            <i class="fa fa-window-close" aria-hidden="true" style="color: white; font-size: 15px;"></i>
        </button>

      </template>

    </comp-modal>

    <comp-modal v-if="processModalFinanciamiento.open" size="width: 900px; height: 690px;" sizeCuerpo="height:550px">
        <template v-slot:encabezado>
        <label><h5>{{processModalFinanciamiento.title}}</h5></label>
      </template>
      <template v-slot:cuerpo>
          
          <div class="classContenedor">

            <div class="row">

                
                <div class="col">
                    <p>Razón Social que depositó:</p>
                </div>

                <div class="col">
                    <div class="form-group" style="width: 550px;">
                        <select class="form-control" v-model="fieldRazonSocialDeposita">
                            <option value="S/D">Selecciona una opcion.</option>
                            <option v-for="{idSysTSEmpresas, razonSocial} in jsonTablaClientes.filter( e => e.usoCFDI !== 'S/D' )" :key="idSysTSEmpresas" :value="idSysTSEmpresas">{{razonSocial}}</option>
                        </select>
                    </div>
                </div>

            </div>

            <div class="row">

                <div class="col">
                    <p>Empresa que recibió el depósito:</p>
                </div>

                <div class="col">
                    <div class="form-group" style="width: 550px;">
                        <select class="form-control" v-model="fieldRazonSocialRecibeDeposito" @change="fieldSelectEmpresaRecibeDeposito">
                            <option value="S/D">Selecciona una opcion.</option>
                            <option v-for="{idSysCPEmpresasRP, razonSocial} in jsonEmpresasInternas.filter( e => e.serieFactura !== 'S/D' )" :key="idSysCPEmpresasRP" :value="idSysCPEmpresasRP">{{razonSocial}}</option>
                        </select>
                    </div>
                </div>

            </div>

            <div class="row">

                <div class="col">
                    <p>Datos Factura:</p>
                </div>

                <div class="col">
                    <div class="form-group" style="width: 600px;">
                        <select class="form-control" v-model="fieldDatosFacturacion">
                            <option value="S/D">Selecciona una opcion.</option>
                            <option v-for="{idUnicoConcepto, prodServ, claveUnidad, metodoPago, formaPago, descripcion} in jsonConceptosFactura.filter( e => e.fieldEmpresasConcepto === fieldRazonSocialRecibeDeposito )" :key="idUnicoConcepto" :value="idUnicoConcepto">{{prodServ}}, {{claveUnidad}}, {{metodoPago}}, {{formaPago}}, {{descripcion}}</option>
                        </select>
                    </div>
                </div>

            </div>

            <div class="row">

                <div class="col">
                    <p>Tipo Movimiento:</p>
                </div>

                <div class="col">
                    <select class="form-control" v-model="fieldTipoMovimiento">
                        <option value="S/D">Selecciona una opcion.</option>
                        <option value="Asimilado">Asimilado</option>
                        <option value="Efectivo / Sin recibo">Efectivo / Sin recibo</option>
                    </select>
                    <br>
                </div>
                
            </div>

            <div class="row">

                <div class="col">
                    <p>Monto a financiar (I.V.A. Incluido):</p>
                </div>

                <div class="col">
                    <div class="form-group">
                        <input type="text" @keypress="isDecimal02($event)" style="text-align: right; font-size: 20px;" class="form-control" v-model.lazy="fieldMontoDeposito">
                    </div>
                </div>

            </div>

            <div class="row">
                <br>
                <div class="col">
                    <p>Factura:</p>
                </div>

                <div class="col">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="fieldFactura" disabled>
                    </div>
                </div>

            </div>

            <br>

            <div class="classNoti">
                <p>Validacion: {{fieldValidacion}}</p>
            </div>
        </div>
          
      </template>

      <template v-slot:pie>

        <button type="button" class="btn btn-info" @click="handleGrabarDepositoDepSalFin">
            <i class="fa fa-floppy-o" aria-hidden="true" style="color: white; font-size: 15px;"></i>
        </button>
        &nbsp;&nbsp;
        <button type="button" class="btn btn-danger" @click="handleCloseModalDepSalFin">
            <i class="fa fa-window-close" aria-hidden="true" style="color: white; font-size: 15px;"></i>
        </button>

      </template>

    </comp-modal>

    <comp-modal v-if="modalRegistrosExcelAsimilados" size="width: 1400px; height: 690px;" sizeCuerpo="height:520px">
        <template v-slot:encabezado>
        <label><h5>Retorno Asimilado</h5></label>
        <div class="row">
            <div class="col">
                <label>Disponible Asimilado: {{fieldDisponibleClienteAsimilado}}</label>
            </div>
            <div class="col">
                <label>Utilizado Asimilado: {{fieldUtilizadoClienteAsimilado}}</label>
            </div>
            <div class="col">
                <label>Saldo Asimilado: {{fieldSaldoClienteAsimilado}}</label>
            </div>
        </div>
      </template>
      <template v-slot:cuerpo>
          
          <div class="classContenedor">

            <div class="row mb-3 p-3">

                <table class="table" style="font-size: 9px">

                    <thead>
                        <tr>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"></th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nombre Completo</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">curp</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">rfc</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Banco</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Clabe Banco</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Cuenta Banco</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Tipo LayOut</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Monto</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Retorno</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(registro, index) in jsonRegistrosExcelAsimilados" :key="index" style="border-bottom: 1px grey solid">
                            <td>
                            </td>

                            <td>
                                {{ registro.apellidoPaterno }} {{ registro.apellidoMaterno }} {{ registro.nombre }}
                            </td>

                            <td>
                                {{ registro.curp }}
                            </td>

                            <td>
                                {{ registro.rfc }}
                            </td>

                            <td>
                                {{ registro.banco }}
                            </td>

                            <td>
                                {{ registro.clabeBanco }}
                            </td>

                            <td>
                                {{ registro.cuentaBanco }}
                            </td>

                            <td>
                                <select class="form-control p-1" style="width:250px;" @change="handleMontoAsimiladosBene($event, index, 'layout');">
                                    <option>Selecciona una opcion</option>
                                    <option v-for="( {idUnicoBancosLayOut, clabeBancaria, descripcion} ) in jsonLayOutBancariosNomina" :key="clabeBancaria" :value="clabeBancaria" :selected="idUnicoBancosLayOut === registro.idUnicoBancosLayOut && clabeBancaria === registro.clabeBancariaEmpresa ? true : false">{{descripcion}}</option>
                                </select>
                            </td>

                            <td>
                                <input type="text" @keypress="isDecimal02($event)" @change="handleMontoAsimiladosBene($event, index, 'monto');" class="form-control p-1" style="width:100px;" :value="registro.monto" >
                            </td>

                            <td>
                                <input type="text" class="form-control p-1" style="width:100px;" :value="registro.retorno" readonly="true" disabled>
                            </td>

                            <td></td>
                        </tr>
                    </tbody>

                </table>


            </div>


        </div>
          
      </template>

      <template v-slot:pie>

        <button type="button" class="btn btn-danger" @click="modalRegistrosExcelAsimilados = false">
            <i class="fa fa-window-close" aria-hidden="true" style="color: white; font-size: 15px;"></i>
        </button>

      </template>

    </comp-modal>

    <comp-modal v-if="modalRegistrosExcelSinRecibo" size="width: 1400px; height: 690px;" sizeCuerpo="height:520px">
        <template v-slot:encabezado>
        <label><h5>Retorno sin recibo</h5></label>
        <div class="row">
            <div class="col">
                <label>Disponible sin recibo: {{fieldDisponibleClienteEfectivoSinRecibo}}</label>
            </div>
            <div class="col">
                <label>Utilizado sin recibo: {{fieldUtilizadoClienteEfectivoSinRecibo}}</label>
            </div>
            <div class="col">
                <label>Saldo sin recibo: {{fieldSaldoClienteEfectivoSinRecibo}}</label>
            </div>
        </div>
      </template>
      <template v-slot:cuerpo>
          
          <div class="classContenedor">

            <div class="row mb-3 p-3">

                <table class="table" style="font-size: 9px">

                    <thead>
                        <tr>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"></th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nombre Completo / Razon Social</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">curp</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">rfc</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Banco</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Clabe Banco</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Cuenta Banco</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Tipo LayOut</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Monto</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Retorno</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(registro, index) in jsonRegistrosExcelSinRecibo" :key="index" style="border-bottom: 1px grey solid">
                            <td>
                            </td>

                            <td v-if="registro.tipoAlta === 'Persona'">
                                {{ registro.apellidoPaterno }} {{ registro.apellidoMaterno }} {{ registro.nombre }}
                            </td>
                            <td v-if="registro.tipoAlta === 'Empresa'">
                                {{ registro.razonSocial }}
                            </td>

                            <td>
                                {{ registro.curp }}
                            </td>

                            <td>
                                {{ registro.rfc }}
                            </td>

                            <td>
                                {{ registro.banco }}
                            </td>

                            <td>
                                {{ registro.clabeBanco }}
                            </td>

                            <td>
                                {{ registro.cuentaBanco }}
                            </td>

                            <td>
                                <select class="form-control p-1" style="width:250px;" @change="handleMontoSinReciboBene($event, index, 'layout');">
                                    <option>Selecciona una opcion</option>
                                    <option v-for="( {idUnicoBancosLayOut, clabeBancaria, descripcion} ) in jsonLayOutBancariosSinRecibo" :key="clabeBancaria" :value="clabeBancaria" :selected="idUnicoBancosLayOut === registro.idUnicoBancosLayOut && clabeBancaria === registro.clabeBancariaEmpresa ? true : false">{{descripcion}}</option>
                                </select>
                            </td>

                            <td>
                                <input type="text" @keypress="isDecimal02($event)" @change="handleMontoSinReciboBene($event, index, 'monto');" class="form-control p-1" style="width:100px;" :value="registro.monto" >
                            </td>

                            <td>
                                <input type="text" class="form-control p-1" style="width:100px;" :value="registro.retorno" readonly="true" disabled>
                            </td>

                            <td></td>
                        </tr>
                    </tbody>

                </table>

            </div>


        </div>
          
      </template>

      <template v-slot:pie>

        <button type="button" class="btn btn-danger" @click="handleModalMontoSinReciboBene">
            <i class="fa fa-window-close" aria-hidden="true" style="color: white; font-size: 15px;"></i>
        </button>

      </template>

    </comp-modal>

    <comp-modal v-if="modalRegistrosExcelEfectivo" size="width: 1400px; height: 690px;" sizeCuerpo="height:520px">
        <template v-slot:encabezado>
        <label><h5>Retorno Efectivo</h5></label>
        <div class="row">
            <div class="col">
                <label>Disponible efectivo: {{fieldDisponibleClienteEfectivoSinRecibo}}</label>
            </div>
            <div class="col">
                <label>Utilizado efectivo: {{fieldUtilizadoClienteEfectivoSinRecibo}}</label>
            </div>
            <div class="col">
                <label>Saldo efectivo: {{fieldSaldoClienteEfectivoSinRecibo}}</label>
            </div>
        </div>
      </template>
      <template v-slot:cuerpo>
          
          <div class="classContenedor">

            <div class="row mb-3 p-3">

                <table class="table" style="font-size: 9px">

                    <thead>
                        <tr>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"></th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Tipo</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nombre Completo / Razon Social</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Monto</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Retorno</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(registro, index) in jsonRegistrosExcelEfectivo" :key="index" style="border-bottom: 1px grey solid">
                            <td>
                            </td>

                            <td>
                                {{ registro.tipoAlta }}
                            </td>
                            <td v-if="registro.tipoAlta === 'Persona'">
                                {{ registro.apellidoPaterno }} {{ registro.apellidoMaterno }} {{ registro.nombre }}
                            </td>
                            <td v-if="registro.tipoAlta === 'Empresa'">
                                {{ registro.razonSocial }}
                            </td>

                            <td>
                                <input type="text" @keypress="isDecimal02($event)" @change="handleMontoEfectivoBene($event, index, 'monto');" class="form-control p-1" style="width:100px;" :value="registro.monto" >
                            </td>

                            <td>
                                <input type="text" class="form-control p-1" style="width:100px;" :value="registro.retorno" readonly="true" disabled>
                            </td>

                            <td></td>
                        </tr>
                    </tbody>

                </table>

            </div>


        </div>
          
      </template>

      <template v-slot:pie>

        <button type="button" class="btn btn-danger" @click="handleModalMontoEfectivoBene">
            <i class="fa fa-window-close" aria-hidden="true" style="color: white; font-size: 15px;"></i>
        </button>

      </template>

    </comp-modal>
  
</template>

<script>
import proceso from './js/proceso';
import Datepicker from 'vue3-datepicker';
import CompModal from '../../componentes/modal/modal.vue';
export default {
    name: "Nueva-Solicitud",
    components: { CompModal, Datepicker },
    setup(){
    return{
      ...proceso()
    }
  }
}
</script>

<style scoped>
    @import './css/estilo.css';
</style>