import { ref, onBeforeMount, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { notify } from "@kyvg/vue3-notification";
import { acceso, verificarIdUnico } from '@/helpers/axios.js';

const body = document.getElementsByTagName("body")[0];

const useAcceso = () => {
    const store = useStore();
    const router = useRouter();

    const usuario = ref('');
    const contrasena = ref('');
    const invalido = ref(false);

    

    onBeforeMount( () => {
        store.commit('toggleEveryDisplay');
        store.commit('toggleHideConfig');
        body.classList.remove("bg-gray-100");
    } );

    onBeforeUnmount( () => {
        store.commit('toggleEveryDisplay');
        store.commit('toggleHideConfig');
        body.classList.add("bg-gray-100");
    } );

    const verificar = async() => {
        if(localStorage.getItem('usuario') === null || localStorage.getItem('usuario') === '')
        {
            router.replace({name: 'Acceso'}); 
        }
        else
        {
            const dataUsuario = JSON.parse(localStorage.getItem('usuario'));
            const data = await verificarIdUnico(dataUsuario.idUnico);
            if(data.tablas.Table1.length > 0)
            {
                router.replace({name: 'Principal'}); 
            }
        }
    };

    verificar();

    const iniciarSesion = async() => {
        const data = await acceso(usuario.value, contrasena.value);
        
        if(data.data.tablas.Table1.length === 0)
        {
            invalido.value = true;
            notify({
                title: "Acceso",
                text: "Los datos del usuario estan mal escritos",
              });
        }
        else
        {
            localStorage.setItem('usuario', JSON.stringify(data.data.tablas.Table1[0]))
            router.replace({name: 'Principal'});            
        }
    };

    return{

        usuario,
        contrasena,
        invalido,

        iniciarSesion

    }

};

export default useAcceso;