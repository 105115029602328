<template>

    <!-- <div class="row">

        <div class="col">

            <div class="form-group">
                <label>Grupo Empresarial:</label>
                <select v-model="fieldSelectGrupoEmpresarial" class="form-control" :disabled="processBotonSeleccionarGrupoEmpresarial.seleccionado">
                    <option v-for=" ({idUnico, grupoEmpresarial}) in jsonGrupoEmpresarial" :key="idUnico" :value="idUnico">{{grupoEmpresarial}}</option>
                </select>
            </div>

        </div>
        <div class="col">
            <div class="form-group">
                <br>
                <button type="button" class="btn btn-info mb-2" @click="handleProcesoBotonSeleccionarGrupoEmpresarial">{{processBotonSeleccionarGrupoEmpresarial.texto}}</button>
            </div>
        </div>
        <div class="col"></div>

    </div> -->

    <div class="contenedor">

        <ul class="nav nav-tabs" disabled>

            <li class="nav-item">
                <span class="nav-link" style="cursor:pointer;" :class="processSeleccionPest.seleccion01 ? 'active' : ''" @click="handleSelect('01')"><i class="fa fa-caret-square-o-down" style="color: blue; font-size: 15px;"></i> Informacion General</span>
            </li>

            <!-- <li class="nav-item">
                <span class="nav-link" style="cursor:pointer;" :class="processSeleccionPest.seleccion02 ? 'active' : ''" @click="handleSelect('02')"><i class="fa fa-caret-square-o-down" style="color: blue; font-size: 15px;"></i> Parametros</span>
            </li> -->

            <li class="nav-item">
                <span class="nav-link" style="cursor:pointer;" :class="processSeleccionPest.seleccion03 ? 'active' : ''" @click="handleSelect('03')"><i class="fa fa-caret-square-o-down" style="color: blue; font-size: 15px;"></i> Nueva Solicitud</span>
            </li>

            <li class="nav-item">
                <span class="nav-link" style="cursor:pointer;" :class="processSeleccionPest.seleccion04 ? 'active' : ''" @click="handleSelect('04')"><i class="fa fa-caret-square-o-down" style="color: blue; font-size: 15px;"></i> Solicitudes</span>
            </li>

            <!-- <li class="nav-item">
                <span class="nav-link" style="cursor:pointer;" :class="processSeleccionPest.seleccion05 ? 'active' : ''" @click="handleSelect('05')"><i class="fa fa-caret-square-o-down" style="color: blue; font-size: 15px;"></i> Movimientos</span>
            </li> -->

        </ul>

        <div v-if="processSeleccionPest.seleccion01" class="divInformacionGeneral">

            <!-- DATOS GENERALES GRUPO EMPRESARIAL -->
            <div class="card cardPersonalizado">
                <div class="card-body">
                    <h5 class="card-title">Datos del grupo empresarial</h5>
                    <br>

                    <div class="row">
                        <div class="col">

                            <div class="form-group">
                                <label>idUnico:</label>
                                <input type="text" class="form-control" v-model="fieldFormDatosGenerales.idUnico" disabled>
                            </div>

                        </div>

                        <div class="col">

                            <div class="form-group">
                                <label>Grupo Empresarial:</label>
                                <input type="text" class="form-control" v-model="fieldFormDatosGenerales.grupoEmpresarial" disabled>
                            </div>

                        </div>

                        <div class="col">

                            <div class="form-group">
                                <label>Descripcion:</label>
                                <input type="text" class="form-control" v-model="fieldFormDatosGenerales.descripcion" disabled>
                            </div>

                        </div>

                    </div>

                    <div class="row">
                        <div class="col">

                            <div class="form-group">
                                <label>Ultimo Movimiento Parametros:</label>
                                <input type="text" class="form-control" v-model="fieldFormDatosGenerales.ultimoMovimientoParametros" disabled>
                            </div>

                        </div>

                        <div class="col">

                            <div class="form-group">
                                <label>Ultima Solicitud:</label>
                                <input type="text" class="form-control" v-model="fieldFormDatosGenerales.ultimaSolicitud" disabled>
                            </div>

                        </div>

                        <div class="col">



                        </div>

                        <div class="col">



                        </div>

                    </div>

                    <div class="row">

                        <div class="col">


                        </div>

                        <div class="col">


                        </div>

                        <div class="col">


                        </div>

                    </div>

                </div>
            </div>

            <!-- TABLA EMPRESAS CLIENTE -->
            <div class="card cardPersonalizado">
                <div class="card-body">
                    <label><h6>Empresas Cliente</h6></label>
                    <table class="table mb-0">

                        <thead>
                            <tr>
                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Razon Social</th>
                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">R.F.C.</th>
                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Siglas</th>
                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nombre Corto</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="(registro, index) in fieldFormDatosGeneralesTablaClientes" :key="index">
                                <td>
                                    <h6 class="mb-0 text-xs">{{ registro.razonSocial }}</h6>
                                </td>

                                <td>
                                    <h6 class="mb-0 text-xs">{{ registro.rfc }}</h6>
                                </td>

                                <td>
                                    <h6 class="mb-0 text-xs">{{ registro.siglas }}</h6>
                                </td>

                                <td>
                                    <h6 class="mb-0 text-xs">{{ registro.nombreCorto }}</h6>
                                </td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>


        </div>

        <div v-if="processSeleccionPest.seleccion02" class="divEstadoCuenta">

            <div class="card cardPersonalizadoFiltros">
                
                <!-- Filtro plantilla -->
                <div class="card" style="padding:5px">
                    <div class="row">
                        <div class="col">

                            <div class="form-group">
                                <label>Plantilla ( Seleccionada: {{fieldNombrePlantillaSeleccionada}}):</label>
                                <select class="form-control" v-model="fieldSelectPlantillaSeleccionada" @change="handleParametrosDatosPlantilla">
                                    <option value="0">Selecciona una opcion.</option>
                                    <option v-for="({idUnicoPlantilla, nombrePlantilla}) in jsonPlantillas.registros" :key="idUnicoPlantilla" :value="idUnicoPlantilla">{{nombrePlantilla}}</option>
                                </select>
                            </div>

                        </div>
                        <div class="col">

                            <div class="row">
                                <div class="col">
                                    <br>
                                    <button type="button" class="btn btn-info" @click="handleSeleccionPlantilla">Seleccionar</button>
                                </div>
                                <div class="col">
                                    <br>
                                    <button type="button" class="btn btn-success" @click="handleGrabarParametrosDatosPlantilla"><i class="fa fa-floppy-o" style="font-size: 15px;"></i> Guardar Parametros</button>
                                </div>
                            </div>
                        </div>
                        <div class="col"></div>
                        <div class="col">
                            <br>
                            <button type="button" class="btn btn-info" @click="handleAbrirPlantilla">Catalogo plantillas</button>
                        </div>
                    </div>
                </div>

                <!-- Pestañas -->
                <ul class="nav nav-tabs">

                    <li class="nav-item">
                        <span class="nav-link" style="cursor:pointer;" :class="processSeleccionPestParam.seleccion01 ? 'active' : ''" @click="handleSelectParam('01')"><i class="fa fa-id-card-o" style="color: black; font-size: 15px;"></i> Generales</span>
                    </li>

                    <li class="nav-item">
                        <span class="nav-link" style="cursor:pointer;" :class="processSeleccionPestParam.seleccion02 ? 'active' : ''" @click="handleSelectParam('02')"><i class="fa fa-users" style="color: black; font-size: 15px;"></i> Facturacion</span>
                    </li>

                    <li class="nav-item">
                        <span class="nav-link" style="cursor:pointer;" :class="processSeleccionPestParam.seleccion03 ? 'active' : ''" @click="handleSelectParam('03')"><i class="fa fa-users" style="color: black; font-size: 15px;"></i> Nomina</span>
                    </li>

                    <!-- <li class="nav-item"> -->
                        <!-- <span class="nav-link" style="cursor:pointer;" :class="processSeleccionPestParam.seleccion04 ? 'active' : ''" @click="handleSelectParam('04')"><i class="fa fa-credit-card" style="color: black; font-size: 15px;"></i> Calculos</span> -->
                    <!-- </li> -->

                    <!-- <li class="nav-item">
                        <span class="nav-link" style="cursor:pointer;" :class="processSeleccionPestParam.seleccion05 ? 'active' : ''" @click="handleSelectParam('05')"><i class="fa fa-credit-card" style="color: black; font-size: 15px;"></i> Operaciones</span>
                    </li> -->

                </ul>

                <!-- Generales -->
                <div class="card subOpciones" v-if="processSeleccionPestParam.seleccion01">
                    
                    <!-- promotores -->
                    <div class="row">

                        <div class="col">
                            
                            <div class="form-group">
                                <label>Promotores:</label>
                                <select class="form-control" @change="handleValidadrPromotorComision" v-model="fieldSelectPromotores">
                                    <option value="0">Selecciona una opcion.</option>
                                    <option v-for="{idUnicoPromotor, nombreCompleto} in jsonPromotores" :key="idUnicoPromotor" :value="idUnicoPromotor">{{nombreCompleto}}</option>
                                </select>
                            </div>

                        </div>

                        <div class="col">
                            <div class="form-group">
                                <label>Porcentaje de comision (%):</label>
                                <input type="text" v-model="fieldPorcentajePromotores" @keypress="isNumber($event)" class="form-control">
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label>Operacion comision promotor:</label>
                                <input type="text" v-model="fieldOperacionPorcentajePromotores" class="form-control">
                            </div>
                        </div>
                        <div class="col"></div>
                        <hr style="border: 10px solid green; border-radius: 5px;">
                    </div>

                    <!-- Empresa por la que se paga -->
                    <div class="row">

                        <div class="col">
                            
                            <div class="form-group">
                                <label>Empresa por la que vamos a pagar :</label>
                                <select class="form-control" v-model="fieldEmpresaPagadora" >
                                    <option value="0">Selecciona una opcion.</option>
                                    <option v-for="{idSysCPEmpresasRP, razonSocial} in jsonEmpresasPagadoras" :key="idSysCPEmpresasRP" :value="idSysCPEmpresasRP">{{razonSocial}}</option>
                                </select>
                            </div>

                        </div>
                        <div class="col"></div>
                        <hr style="border: 10px solid green; border-radius: 5px;">
                    </div>


                    <!-- Tabla comisiones por deposito -->
                    <div class="row">
                        <div class="col">
                            
                            <div class="form-group">
                                <label>Empresa interna:</label>
                                <select class="form-control" v-model="fieldSelectNombreEmpresaInterna" >
                                    <option value="0">Selecciona una opcion.</option>
                                    <option v-for="{idSysCPEmpresasRP, razonSocial} in jsonEmpresasInternas" :key="idSysCPEmpresasRP" :value="idSysCPEmpresasRP">{{razonSocial}}</option>
                                </select>
                            </div>

                        </div>

                        <div class="col">

                            <div class="form-group">
                                <label>Comsion por deposito (Asimilado %):</label>
                                <input type="text" @keypress="isDecimal($event)" v-model="fieldPorcentajeComisionPorDepositoMovAsimilado" class="form-control">
                            </div>

                        </div>

                        <div class="col">

                            <div class="form-group">
                                <label>Comsion por deposito (Normal %):</label>
                                <input type="text" @keypress="isDecimal($event)" v-model="fieldPorcentajeComisionPorDepositoMovNormal" class="form-control">
                            </div>

                        </div>

                        <div class="col">

                            <div class="form-group">
                                <label>Operacion Comision:</label>
                                <input type="text" v-model="fieldPorcentajeComisionOperacion" class="form-control">
                            </div>

                        </div>

                        <div class="col">

                            <div class="form-group">
                                <br>
                                <button type="button" class="btn btn-info" @click="handleAgregarComisionPorDepositoEmpresa">Agregar / Actualizar</button>
                            </div>

                        </div>
                        
                    </div>

                    <div class="row">
                        <div class="col tableBorder">
                            <table class="table mb-0">

                                <thead>
                                    <tr>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Razon Social</th>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Comision Asimilado</th>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Comision Normal</th>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Operacion</th>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Eliminar</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="(registro, index) in jsonComisionPorDepositoEmpresa" :key="index">
                                        <td>
                                            <h6 class="mb-0 text-xs">{{ registro.razonSocial }}</h6>
                                        </td>

                                        <td>
                                            <h6 class="mb-0 text-xs">{{ registro.porcentajeComisionPorDepositoMovAsimilado }} %</h6>
                                        </td>

                                        <td>
                                            <h6 class="mb-0 text-xs">{{ registro.porcentajeComisionPorDepositoMovNomral }} %</h6>
                                        </td>

                                        <td>
                                            <h6 class="mb-0 text-xs">{{ registro.porcentajeComisionOperacion }}</h6>
                                        </td>

                                        <td>
                                            <button type="button" class="btn btn-info btn-sm" @click="handleQuitarComisionPorDepositoEmpresa(registro)">Quitar</button>
                                        </td>
                                    </tr>
                                </tbody>

                            </table>

                        </div>
                        <hr style="border: 10px solid green; border-radius: 5px;">
                    </div>

                    <div class="row">

                        <div class="col">
                            <div class="form-check">
                                <label>
                                    Activar comision retorno
                                    <input class="form-check-input" v-model="fieldNombreComision01Check" type="checkbox">
                                </label>
                            </div>
                        </div>
                        
                        <div class="col">

                            <div class="form-group">
                                <label>Comision Asimilado (%):</label>
                                <input type="text" @keypress="isDecimal($event)" v-model="fieldPorcentajeComision01Asimilado" class="form-control" :disabled="!fieldNombreComision01Check">
                            </div>

                        </div>

                        <div class="col">

                            <div class="form-group">
                                <label>Comision Normal (%):</label>
                                <input type="text" @keypress="isDecimal($event)" v-model="fieldPorcentajeComision01Normal" class="form-control" :disabled="!fieldNombreComision01Check">
                            </div>

                        </div>

                        <div class="col">

                            <div class="form-group" style="width: 700px;">
                                <label>Operacion comision:</label>
                                <input type="text" v-model="fieldPorcentajeComision01Operacion" class="form-control" :disabled="!fieldNombreComision01Check">
                            </div>

                        </div>
                        <hr style="border: 10px solid green; border-radius: 5px;">
                    </div>

                    <div class="row">

                        <div class="row">
                            <div class="col">

                            </div>
                            
                            <div class="col">

                                <div class="form-group">
                                    <label>Comision en pesos (%):</label>
                                    <input type="text" @keypress="isDecimal($event)" v-model="fieldComisionPesosPorcentaje" class="form-control">
                                </div>

                            </div>

                            <div class="col">

                                <div class="form-group">
                                    <label>Comision en pesos operacion:</label>
                                    <input type="text" v-model="fieldComisionPesosOperacion" class="form-control" >
                                </div>

                            </div>

                            <div class="col">

                            </div>
                        </div>



                        <div class="row">

                            <div class="col">
                                <div class="form-check">
                                    <label>
                                        Activar comision en pesos extra
                                        <input class="form-check-input" v-model="fieldActivarComisionPesosExtra" type="checkbox">
                                    </label>
                                </div>
                            </div>
                            
                            <div class="col">

                                <div class="form-group">
                                    <label>Comision en pesos extra (%):</label>
                                    <input type="text" @keypress="isDecimal($event)" v-model="fieldComisionPesosExtraPorcentaje" class="form-control" :disabled="!fieldActivarComisionPesosExtra">
                                </div>

                            </div>

                            <div class="col">

                                <div class="form-group" style="width: 700px;">
                                    <label>Operacion en pesos extra comision:</label>
                                    <input type="text" class="form-control" v-model="fieldComisionPesosExtraOperacion" :disabled="!fieldActivarComisionPesosExtra">
                                </div>

                            </div>

                            <div class="col">

                            </div>
                        </div>

                        <div class="row">
                            <div class="col">

                            </div>
                            
                            <div class="col">

                                <div class="form-group">
                                    <label>I.V.A. (%):</label>
                                    <input type="text" @keypress="isDecimal($event)" v-model="fieldIVAPorcentaje" class="form-control">
                                </div>

                            </div>

                            <div class="col">

                                <div class="form-group">
                                    <label>I.V.A. Operacion:</label>
                                    <input type="text" v-model="fieldIVAOperacion" class="form-control" >
                                </div>

                            </div>

                            <div class="col">

                            </div>
                        </div>

                        <div class="row">
                            <div class="col">

                            </div>
                            
                            <div class="col">

                                <div class="form-group">
                                    <label>Devolucion I.V.A. (%):</label>
                                    <input type="text" @keypress="isDecimal($event)" v-model="fieldDevolucionIVAPorcentaje" class="form-control">
                                </div>

                            </div>

                            <div class="col">

                                <div class="form-group">
                                    <label>Devolucion I.V.A. Operacion:</label>
                                    <input type="text" v-model="fieldDevolucionIVAOperacion" class="form-control" >
                                </div>

                            </div>

                            <div class="col">

                            </div>
                        </div>

                        <div class="row">
                            <div class="col">

                            </div>
                            
                            <div class="col">

                                <div class="form-group">
                                    <label>Retorno (%):</label>
                                    <input type="text" @keypress="isDecimal($event)" v-model="fieldRetornoPorcentaje" class="form-control">
                                </div>

                            </div>

                            <div class="col">

                                <div class="form-group">
                                    <label>Retorno Operacion:</label>
                                    <input type="text" v-model="fieldRetornoOperacion" class="form-control" >
                                </div>

                            </div>

                            <div class="col">

                            </div>
                        </div>

                        
                        <hr style="border: 10px solid green; border-radius: 5px;">
                    </div>

                    <!-- Tabla Correos-->
                    <div class="row">
                        <div class="col">
                            
                            <div class="form-group">
                                <label>Correo:</label>
                                <select class="form-control" v-model="fieldSelectCorreo">
                                    <option value="S/D">Selecciona una opcion.</option>
                                    <option v-for="{idUnicoCorreos, nombre, correoElectronico} in jsonCorreos" :key="idUnicoCorreos" :value="idUnicoCorreos">{{nombre}} - {{correoElectronico}} </option>
                                </select>
                            </div>

                        </div>



                        <div class="col">

                            <div class="form-group">
                                <br>
                                <button type="button" class="btn btn-info" @click="handleAgregarCorreo">Agregar</button>
                            </div>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col tableBorder">
                            <table class="table mb-0">

                                <thead>
                                    <tr>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nombre</th>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Correo Electronico</th>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Eliminar</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="(registro, index) in jsonCorreosAsignados" :key="index">
                                        <td>
                                            <h6 class="mb-0 text-xs">{{ registro.nombre }}</h6>
                                        </td>

                                        <td>
                                            <h6 class="mb-0 text-xs">{{ registro.correoElectronico }}</h6>
                                        </td>

                                        <td>
                                            <button type="button" class="btn btn-info btn-sm" @click="handleQuitarCorreo(registro)">Quitar</button>
                                        </td>
                                    </tr>
                                </tbody>

                            </table>

                        </div>
                        <hr>
                    </div>
                    
                </div>

                <!-- Facturacion -->
                <div class="card subOpciones" v-if="processSeleccionPestParam.seleccion02">

                    <div class="row">

                        <div class="col">

                            <div class="form-group">
                                <label>Concepto a facturar:</label>
                                <input type="text" class="form-control" v-model.lazy="fieldFacturaProdServ" @keypress="isNumber($event)" maxlength="8" :disabled="fieldIdUnicoConcepto === '' ? false : true">
                            </div>

                        </div>

                        <div class="col">

                            <div class="form-group" style="width: 1200px;">
                                <label>Decripcio de concepto:</label>
                                <input type="text" class="form-control" v-model.lazy="fieldFacturaDescripcion" style="text-transform: uppercase;" maxlength="90" :disabled="fieldIdUnicoConcepto === '' ? false : true">
                            </div>

                        </div>

                    </div>

                    <div class="row">

                        <div class="col">
                            <div class="form-group" style="width: 150px;">
                                <label>Clave unidad:</label>
                                <input type="text" class="form-control" v-model.lazy="fieldFacturaClaveUnidad" style="text-transform: uppercase;" maxlength="3">
                            </div>
                        </div>

                        <div class="col">
                            <div class="form-group" style="width: 320px;">
                                <label>Metodo de pago:</label>
                                <select class="form-control" v-model="fieldSelectFacturaMetodoPago">
                                    <option value="S/D">Selecciona una opcion.</option>
                                    <option v-for="{idCatMetodoPago, descripcion} in jsonMetodoPago" :key="idCatMetodoPago" :value="idCatMetodoPago">({{idCatMetodoPago}}) {{descripcion}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="col">
                            <div class="form-group" style="width: 350px;">
                                <label>Forma de pago:</label>
                                <select class="form-control" v-model="fieldSelectFacturaFormaPago">
                                    <option value="S/D">Selecciona una opcion.</option>
                                    <option v-for="{idCatFormaPago, descripcion} in jsonFormaPago" :key="idCatFormaPago" :value="idCatFormaPago">({{idCatFormaPago}}) {{descripcion}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="col">
                            <div class="row">
                                <div class="col">
                                    <br>
                                    <button type="button" class="btn btn-info" @click="handleFacturaGuardar" :disabled="disabledButtonGrabarFacturaConcepto" ><i class="fa fa-floppy-o" aria-hidden="true"></i></button>
                                </div>
                                <div class="col">
                                    <br>
                                    <button type="button" class="btn btn-warning" @click="handleFacturaCancelar" :disabled="disabledButtonGrabarFacturaConcepto" ><i class="fa fa-times-circle" aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </div>
                        <hr>
                    </div>

                    <div class="row">

                        <div class="col">

                            <div class="col tableBorder">
                                <table class="table mb-0">

                                    <thead>
                                        <tr>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Prod / Serv</th>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Clave Unidad</th>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Metodo Pago</th>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Forma Pago</th>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Descripcion</th>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Opciones</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="(registro, index) in jsonDataGruporEmpresarialPlantillaConceptos" :key="index">
                                            <td>
                                                <h6 class="mb-0 text-xs">{{ registro.prodServ }}</h6>
                                            </td>

                                            <td>
                                                <h6 class="mb-0 text-xs">{{ registro.claveUnidad }}</h6>
                                            </td>

                                            <td>
                                                <h6 class="mb-0 text-xs">{{ registro.metodoPago }}</h6>
                                            </td>

                                            <td>
                                                <h6 class="mb-0 text-xs">{{ registro.formaPago }}</h6>
                                            </td>

                                            <td>
                                                <h6 class="mb-0 text-xs">{{ registro.descripcion }}</h6>
                                            </td>

                                            <td>
                                                <button type="button" class="btn btn-info btn-sm" @click="handleFacturarEditar(registro)" :disabled="fieldIdUnicoConcepto === '' ? false : true">Editar</button>
                                                <button type="button" class="btn btn-info btn-sm" @click="handleFacturarQuitar(registro)" :disabled="fieldIdUnicoConcepto === '' ? false : true">Quitar</button>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>

                            </div>

                        </div>

                    </div>
                    
                    <!-- trabajando -->
                    <hr style="border: 10px solid green; border-radius: 5px;">

                    <div class="row">

                        
                        <h4><label>Empresas Internas (Folios)</label></h4>
                        <div class="row">
                            <div class="col">
                                <div class="col tableBorder">

                                    <ul class="list-group" style="font-size: 11px;">
                                        <li v-for="({idSysCPEmpresasRP, razonSocial, serieFactura}) in jsonEmpresasInternas" :key="idSysCPEmpresasRP" class="list-group-item d-flex justify-content-between align-items-center">
                                            {{razonSocial}}
                                            <span class="badge badge-primary badge-pill" style="color:red">
                                                <select class="form-control" @change="handleChangeSerie(fieldSelectGrupoEmpresarial, idSysCPEmpresasRP, $event)" >
                                                    <option value="S/D">Sin Dato</option>
                                                    <option v-for="({serie}) in jsonCatalogosSATFolios.folios.filter( e => e.idSysCPEmpresasRP === idSysCPEmpresasRP )" :key="serie" :value="serie" :selected="serieFactura === serie ? true : false ">{{serie}}</option>
                                                </select>
                                            </span>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>
<!-- fieldFormDatosGeneralesTablaClientes -->
                        <h4><label>Empresas Cliente (Uso CFDI)</label></h4>
                        <div class="row">
                            <div class="col">
                                <div class="col tableBorder">
                                    
                                    <ul class="list-group" style="font-size: 11px;">
                                        <li v-for="({idSysTSEmpresas, razonSocial, usoCFDI}) in fieldFormDatosGeneralesTablaClientes" :key="idSysTSEmpresas" class="list-group-item d-flex justify-content-between align-items-center">
                                            {{razonSocial}}
                                            <span class="badge badge-primary badge-pill" style="color:red">
                                                <select class="form-control" @change="handleChangeUsoCFDI(fieldSelectGrupoEmpresarial, idSysTSEmpresas, $event)" >
                                                    <option value="S/D">Sin Dato</option>
                                                    <option v-for="({idCatUsoCFDI, descripcion}) in jsonCatalogosSATFolios.usosCFDI" :key="idCatUsoCFDI" :value="idCatUsoCFDI" :selected="usoCFDI === idCatUsoCFDI ? true : false">[{{idCatUsoCFDI}}] {{descripcion}}</option>
                                                </select>
                                            </span>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <!-- Nomina -->
                <div class="card subOpciones" v-if="processSeleccionPestParam.seleccion03">
                            
                    <div class="row">

                        <div class="col">
                            
                            <div class="form-group">
                                <label>Bases Nomina:</label>
                                <select class="form-control" v-model="fieldBaseNomina" @change="handleEmpresaAgrupadora">
                                    <option value="0">Selecciona una opcion.</option>
                                    <option v-for="{idBaseERP, nombre} in jsonBasesNomina" :key="idBaseERP" :value="idBaseERP">{{nombre}}</option>
                                </select>
                            </div>

                        </div>

                        <div class="col">
                            
                            <div class="form-group">
                                <label>Empresa Agrupadora:</label>
                                <select class="form-control" v-model="fieldEmpresaAgrupadora" @change="handleTipoPeriodoPuestoConceptos">
                                    <option value="0">Selecciona una opcion.</option>
                                    <option v-for="{idSysCPEmpresas, nombreEmpresa} in jsonEmpresasAgrupadoras" :key="idSysCPEmpresas" :value="idSysCPEmpresas">{{nombreEmpresa}}</option>
                                </select>
                            </div>

                        </div>

                    </div>

                    <div class="row">

                        <div class="col">
                            
                            <div class="form-group">
                                <label>Tipo Periodo:</label>
                                <select class="form-control" v-model="fieldTipoPeriodo" >
                                    <option value="0">Selecciona una opcion.</option>
                                    <option v-for="{idSysCPTipoPeriodos, descripcion} in jsonTipoPeriodo" :key="idSysCPTipoPeriodos" :value="idSysCPTipoPeriodos">{{descripcion}}</option>
                                </select>
                            </div>

                        </div>

                        <div class="col">
                            
                            <div class="form-group">
                                <label>Puesto:</label>
                                <select class="form-control" v-model="fieldPuesto" >
                                    <option value="0">Selecciona una opcion.</option>
                                    <option v-for="{idSysCPPuestos, descripcion} in jsonPuesto" :key="idSysCPPuestos" :value="idSysCPPuestos">{{descripcion}}</option>
                                </select>
                            </div>

                        </div>

                    </div>

                    <div class="row">

                        <div class="col">
                            
                            <div class="form-group">
                                <label>Departamento:</label>
                                <select class="form-control" v-model="fieldDepartamentos" >
                                    <option value="0">Selecciona una opcion.</option>
                                    <option v-for="{idSysCPCentroCosto, descripcion} in jsonDepartamentos" :key="idSysCPCentroCosto" :value="idSysCPCentroCosto">{{descripcion}}</option>
                                </select>
                            </div>

                        </div>
                        <div class="col"></div>

                    </div>

                    <div class="row">

                        <div class="col">
                            <label><h6>Lista Conceptos Asimilados</h6></label>
                            <ul class="list-group listaConceptos">
                                <li v-for=" {idConcepto, nombre} in jsonConceptos " :key="idConcepto" class="list-group-item d-flex justify-content-between align-items-center">
                                    {{nombre}}
                                    <span>
                                        <div class="form-check">
                                            <label class="form-check-label" :for="nombre">Seleccionar</label>
                                            <input class="form-check-input" v-model="fieldCheckConceptos" type="checkbox" :value="idConcepto" :id="nombre">
                                        </div>
                                    </span>
                                </li>
                            </ul>

                        </div>

                        <div class="col">
                            <label><h6>Lista Conceptos Dispersion</h6></label>
                            <ul class="list-group listaConceptos">
                                <li v-for=" {idConcepto, nombre} in jsonConceptosDispersion " :key="idConcepto" class="list-group-item d-flex justify-content-between align-items-center">
                                    {{nombre}}
                                    <span>
                                        <div class="form-check">
                                            <label class="form-check-label" :for="nombre">Seleccionar</label>
                                            <input class="form-check-input" v-model="fieldCheckConceptosDispersion" type="checkbox" :value="idConcepto" :id="nombre">
                                        </div>
                                    </span>
                                </li>
                            </ul>

                        </div>

                    </div>

                </div>

            </div>


        </div>

        <div v-if="processSeleccionPest.seleccion03" class="divParametros">

            <div class="card cardPersonalizado">
                <div class="card-body">
                    <h5 class="card-title">Nueva Solicitud</h5>
            
                    <div class="row">

                        <div class="col">

                            <div class="form-group">
                                <label>Folio Solicitud:</label>
                                <input type="text" class="form-control" v-model="fieldFolioSolicitud" disabled>
                            </div>

                        </div>

                        <div class="col">

                            <div class="form-group">
                                <label>Fecha Solicitud:</label>
                                <input type="text" class="form-control" v-model="fieldFechaSolicitud" disabled>
                            </div>

                        </div>

                        

                        <div class="col">
                            
                        </div>

                        <div class="col">

                        </div>

                        <div class="col">

                        </div>

                        <div class="col">

                        </div>

                        
                        <div class="col"></div>

                    </div>

                    <div class="row">

                        <div class="col">
                            <label style="font-size: 18px;">Depositos</label>
                        </div>
                        <div class="col">
                            <button type="button" class="btn btn-info" @click="handleOpenModalDepositos">
                                <i class="fa fa-plus-circle" aria-hidden="true" style="color: white; font-size: 15px;"></i>&nbsp;&nbsp;
                                <i class="fa fa-credit-card-alt" aria-hidden="true" style="color: white; font-size: 15px;"></i>
                            </button>
                        </div>
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="col"></div>

                    </div>

                    <div class="row">

                            <div class="col tableBorder">
                                <table class="table mb-0">

                                    <thead>
                                        <tr>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Razon Social Deposita</th>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Empresa Recibe Deposito</th>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Monto Deposito</th>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Subtotal</th>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">I.V.A.</th>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Tipo Movimiento</th>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Comision</th>
                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Opciones</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="(registro, index) in jsonDepositos" :key="index">
                                            <td>
                                                <h6 class="mb-0 text-xs">{{ registro.razonSocialCliente }}</h6>
                                            </td>

                                            <td>
                                                <h6 class="mb-0 text-xs">{{ registro.razonSocialInterna }}</h6>
                                            </td>

                                            <td>
                                                <h6 class="mb-0 text-xs">{{ registro.montoDeposito }}</h6>
                                            </td>

                                            <td>
                                                <h6 class="mb-0 text-xs">{{ registro.subTotal }}</h6>
                                            </td>

                                            <td>
                                                <h6 class="mb-0 text-xs">{{ registro.iva }}</h6>
                                            </td>

                                            <td>
                                                <h6 class="mb-0 text-xs">{{ registro.tipoMovimiento }}</h6>
                                            </td>

                                            <td>
                                                <h6 class="mb-0 text-xs">{{ registro.comision }}</h6>
                                            </td>

                                            <td>
                                                <button type="button" class="btn btn-info btn-sm" @click="handleQuitarDeposito(registro)">Quitar</button>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>

                            </div>

                    </div>

                    <br>

                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label>Total Despositos ($):</label>
                                <input type="text" class="form-control" v-model="fieldTotalDeposito" disabled>
                            </div>
                        </div>

                        <div class="col">
                            <div class="form-group">
                                <label>Sub Total Deposito ($):</label>
                                <input type="text" class="form-control" v-model="fieldSubTotalDeposito" disabled>
                            </div>
                        </div>

                        <div class="col">
                            <div class="form-group">
                                <label>I.V.A. Deposito ($):</label>
                                <input type="text" class="form-control" v-model="fieldIVADeposito" disabled>
                            </div>
                        </div>

                        <div class="col">
                            <div class="form-group">
                                <label>Comision Deposito ($):</label>
                                <input type="text" class="form-control" v-model="fieldComisionDeposito" disabled>
                            </div>
                        </div>

                    </div>

                    <br>

                    <div class="row">

                            <div class="classOpcionesDispersionAsimilado">

                                <div class="row">
                                    <div class="col">
                                    
                                            <button type="button" class="btn btn-info" @click="handleDescargaLayout">
                                                <i class="fa fa-cloud-download" aria-hidden="true" style="color: white; font-size: 15px;"></i>
                                                LayOut
                                            </button>
                                            &nbsp;

                                            <label class="btn btn-info" for="file-input">
                                                <i class="fa fa-cloud-upload" aria-hidden="true" style="color: white; font-size: 15px;"></i>
                                                <input type="file" id="file-input" accept=".xlsx" lang="es" class="mt-1" style="position: fixed; top: -100em" @change="handleCargaLayout($event)" />
                                                Cargar
                                            </label>

                                    </div>

                                    <div class="col">

                                        <div class="row">
                                            
                                            <div class="col">
                                                <div class="form-group" style="width: 250px;">
                                                    <label>Monto Asimilado:</label>
                                                    <input type="text" class="form-control" v-model="fieldExcelAsimiladoMontoTotal" @keypress="isDecimal02($event)" style="text-align: right;" disabled>
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-info" type="button" :disabled="jsonRegistrosExcelAsimilados.length > 0 ? false : true" @click="modalRegistrosExcelAsimilados = true">Mostrar Registros</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="form-group" >
                                                    <label>Concepto a pagar (Asimilado):</label>
                                                    <select class="form-control" :disabled="jsonRegistrosExcelAsimilados.length > 0 ? false : true" v-model="fieldSelectConceptoAsimiladoMonto">
                                                        <option value="S/D">Selecciona una opcion.</option>
                                                        <option v-for="{idConcepto, nombre} in jsonSelectConceptosAsimilados" :key="idConcepto" :value="idConcepto">{{nombre}}</option>
                                                    </select>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="row">
                                            
                                            <div class="col">
                                                <div class="form-group" style="width: 250px;">
                                                    <label>Monto Dispersion:</label>
                                                    <input type="text" class="form-control" v-model="fieldExcelDispersionMontoTotal" @keypress="isDecimal02($event)" style="text-align: right;" disabled>
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-info" type="button" :disabled="jsonRegistrosExcelDispersion.length > 0 ? false : true" @click="modalRegistrosExcelDispersion = true">Mostrar Registros</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="form-group" >
                                                    <label>Concepto a pagar (Dispersion):</label>
                                                    <select class="form-control" :disabled="jsonRegistrosExcelDispersion.length > 0 ? false : true" v-model="fieldSelectConceptoDispersionMonto">
                                                        <option value="S/D">Selecciona una opcion.</option>
                                                        <option v-for="{idConcepto, nombre} in jsonSelectConceptosDispersion" :key="idConcepto" :value="idConcepto">{{nombre}}</option>
                                                    </select>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="row">

                                            <div class="col">
                                                <div class="form-group" style="width: 250px;">
                                                    <label>Monto Efectivo:</label>
                                                    <input type="text" class="form-control" v-model="fieldExcelEfectivoMontoTotal" @keypress="isDecimal02($event)" style="text-align: right;" disabled>
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-info" type="button" :disabled="jsonRegistrosExcelDispersion.length > 0 ? false : true" @click="modalRegistrosExcelEfectivo = true">Mostrar Registros</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>


                                </div>

                            </div>

                    </div>

                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label>Monto a retornar ($):</label>
                                <input type="text" class="form-control" v-model="fieldMontoRetornar" disabled>
                            </div>
                        </div>

                        <div class="col">

                        </div>

                        <div class="col">

                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            
                        </div>

                        <div class="col">
                            <div class="form-group">
                                <label>Comision promotor ($):</label>
                                <input type="text" class="form-control" v-model="fieldComisionPromotor" disabled>
                            </div>
                        </div>

                        <div class="col">

                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            
                        </div>

                        <div class="col">
                            <div class="form-group">
                                <label>Comision en pesos ($):</label>
                                <input type="text" class="form-control" v-model="fieldComisionPesos" disabled>
                            </div>
                        </div>

                        <div class="col">

                        </div>
                    </div>

                    <div class="row" v-if="fieldActivarComisionPesosExtra">
                        <div class="col">
                            
                        </div>

                        <div class="col">
                            <div class="form-group">
                                <label>Comision en pesos extras ($):</label>
                                <input type="text" class="form-control" v-model="fieldComisionPesosExtra" disabled>
                            </div>
                        </div>

                        <div class="col">

                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            
                        </div>

                        <div class="col">
                            <div class="form-group">
                                <label>I.V.A. ($):</label>
                                <input type="text" class="form-control" v-model="fieldIVA" disabled>
                            </div>
                        </div>

                        <div class="col">

                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            
                        </div>

                        <div class="col">
                            <div class="form-group">
                                <label>Dev. I.V.A. ($):</label>
                                <input type="text" class="form-control" v-model="fieldDevolucionIVA" disabled>
                            </div>
                        </div>

                        <div class="col">

                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            
                        </div>

                        <div class="col">
                            <div class="form-group">
                                <label>Retorno ($):</label>
                                <input type="text" class="form-control" v-model="fieldRetorno" disabled>
                            </div>
                        </div>

                        <div class="col">

                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            
                        </div>

                        <div class="col">
                            <div class="form-group">
                                <button type="button" class="btn btn-warning btn-lg btn-block" @click="handlePrecalculo">Precalculo</button>
                            </div>
                        </div>

                        <div class="col">
                            <div class="form-group">
                                <button type="button" :disabled="buttonDisabledPrecalculoGuardar" class="btn btn-info btn-lg btn-block" @click="handlePrecalculoGuardar">Precalculo / Grabar</button>
                            </div>
                        </div>

                        <div class="col">

                        </div>
                    </div>



                </div>
            </div>

        </div>

        <div v-if="processSeleccionPest.seleccion04" class="divParametros">
            <div class="card cardPersonalizado">
                <div class="card-body">
                    <h5 class="card-title">Solicitudes</h5>

                    <div class="row">
                        <div class="col">

                            <div class="form-group">
                                <label>Fecha:</label>
                                <Datepicker class="form-control" v-model="fieldFechaConsultaSolicitudes" />
                            </div>

                        </div>
                        <div class="col">

                            <div class="form-group">
                                <label>Estatus:</label>
                                <select class="form-control" v-model="fieldEstatusConsultaSolicitudes">
                                    <option value="S/D">Selecciona una opcion.</option>
                                    <option value="Pendiente">Pendiente</option>
                                    <option value="Aprobado">Aprobado</option>
                                    <option value="Cancelado">Cancelado</option>
                                </select>
                            </div>

                        </div>
                        <div class="col">

                            <div class="form-group">
                                <br>
                                <button type="button" class="btn btn-primary" @click="handleConsultarSolicitudes">Consultar</button>
                            </div>
                        </div>
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="col"></div>
                    </div>

                    <div class="row">
                        <div class="tablaSolicitudes">
                            <table class="table mb-0">

                                <thead>
                                    <tr>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Fecha Solicitud</th>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Estatus</th>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Total Depositos</th>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Total Retorno</th>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Opciones</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="(registro, index) in jsonSolicitudesConsulta" :key="index">

                                        <td>
                                            <h6 class="mb-0 text-xs">{{ registro.fechaSolicitud }}</h6>
                                        </td>

                                        <td>
                                            <h6 class="mb-0 text-xs">{{ registro.estatus }}</h6>
                                        </td>

                                        <td>
                                            <h6 class="mb-0 text-xs">{{ registro.totalDepositos }}</h6>
                                        </td>

                                        <td>
                                            <h6 class="mb-0 text-xs">{{ registro.totalRetorno }}</h6>
                                        </td>

                                        <td v-if="registro.estatus === 'Aprobado' ? true : false ">
                                            <button type="button" class="btn btn-success btn-sm" @click="handleMostrarFacturas(registro)">Mostrar Registros Fact.</button>
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>


    <!-- Modal plantillas -->
    <comp-modal v-if="processModalPlantilla.open" size="width: 1100px; height: 670px;" sizeCuerpo="height:600px">
        <template v-slot:encabezado>
        <label><h5>Catalogo de plantillas</h5></label>
      </template>
      <template v-slot:cuerpo>
          
          <div class="row">

            <div class="col">

                <div class="form-group">
                <label>Nombre de la plantilla:</label>
                <input type="text" class="form-control" style="text-transform:uppercase;" v-model="fieldNombrePlantilla" />
                </div>

            </div>

            <div class="col">
                
                <div class="form-group">
                <label>Descripcion de la plantilla:</label>
                <input type="text" class="form-control" style="text-transform:uppercase;" v-model="fieldDescripcionPlantilla" />
                </div>

            </div>

            <div class="col">

                <div class="form-group">
                <br />
                <button type="button" class="btn btn-dark mb-2" :disabled="disableButtonGuardarPlantilla" @click="handleGuardarDatosPlantilla" ><i class="fa fa-floppy-o" style="color: white; font-size: 15px;"></i></button>
                &nbsp;
                <button type="button" class="btn btn-dark mb-2" :disabled="disableButtonEditarGuardarPlantilla" @click="handleGuardarDatosPlantilla"><i class="fa fa-pencil" style="color: white; font-size: 15px;"></i>&nbsp;&nbsp;&nbsp;<i class="fa fa-floppy-o" style="color: white; font-size: 15px;"></i></button>
                &nbsp;
                <button type="button" class="btn btn-dark mb-2" :disabled="disableButtonCancelarGuardarPlantilla" @click="handleCancelarDatosPlantilla"><i class="fa fa-times" style="color: white; font-size: 15px;"></i></button>
                &nbsp;
                <button type="button" class="btn btn-dark mb-2" @click="handleCerrarModalPlantilla"><i class="fa fa-sign-out" style="color: white; font-size: 15px;"></i></button>
                </div>

            </div>
            
        </div>

        <div class="tablaGrupoEmpresas">
          <table class="table mb-0">
            <thead>
                <tr>
                    <th v-for="(encabezado, index) in jsonPlantillas.encabezados" :key="index" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">{{encabezado}}</th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Opcion</th>
                </tr>
            </thead>
            <tbody>
              <tr v-for="(registro, index) in jsonPlantillas.registros" :key="index">
                  <td v-for="( dato, index ) in jsonPlantillas.camposMostrar" :key="index">
                      <h6 class="mb-0 text-xs">{{ registro[dato] }}</h6>
                  </td>
                  <td>
                    <button type="button" class="btn btn-dark btn-sm" @click="handleEditarDatosPlantilla(registro)">Editar</button>
                  </td>
              </tr>
            </tbody>
          </table>
        </div>
          
      </template>
    </comp-modal>


    <!-- Depositos -->
    <comp-modal v-if="processModalDepositos.open" size="width: 900px; height: 690px;" sizeCuerpo="height:550px">
        <template v-slot:encabezado>
        <label><h5>{{processModalDepositos.title}}</h5></label>
      </template>
      <template v-slot:cuerpo>
          
          <div class="classContenedor">

            <div class="row">

                
                <div class="col">
                    <p>Razón Social que depositó:</p>
                </div>

                <div class="col">
                    <div class="form-group" style="width: 550px;">
                        <select class="form-control" v-model="fieldRazonSocialDeposita">
                            <option value="S/D">Selecciona una opcion.</option>
                            <option v-for="{idSysTSEmpresas, razonSocial} in fieldFormDatosGeneralesTablaClientes.filter( e => e.usoCFDI !== 'S/D' )" :key="idSysTSEmpresas" :value="idSysTSEmpresas">{{razonSocial}}</option>
                        </select>
                    </div>
                </div>

            </div>

            <div class="row">

                <div class="col">
                    <p>Empresa que recibió el depósito:</p>
                </div>

                <div class="col">
                    <div class="form-group" style="width: 550px;">
                        <select class="form-control" v-model="fieldRazonSocialRecibeDeposito">
                            <option value="S/D">Selecciona una opcion.</option>
                            <option v-for="{idSysCPEmpresasRP, razonSocial} in jsonEmpresasInternas.filter( e => e.serieFactura !== 'S/D' )" :key="idSysCPEmpresasRP" :value="idSysCPEmpresasRP">{{razonSocial}}</option>
                        </select>
                    </div>
                </div>

            </div>

            <div class="row">

                <div class="col">
                    <p>Datos Factura:</p>
                </div>

                <div class="col">
                    <div class="form-group" style="width: 600px;">
                        <select class="form-control" v-model="fieldDatosFacturacion">
                            <option value="S/D">Selecciona una opcion.</option>
                            <option v-for="{idUnicoConcepto, prodServ, claveUnidad, metodoPago, formaPago, descripcion} in jsonDataGruporEmpresarialPlantillaConceptos" :key="idUnicoConcepto" :value="idUnicoConcepto">{{prodServ}}, {{claveUnidad}}, {{metodoPago}}, {{formaPago}}, {{descripcion}}</option>
                        </select>
                    </div>
                </div>

            </div>

            <div class="row">

                <div class="col">
                    <p>Tipo Movimiento:</p>
                </div>

                <div class="col">
                    <select class="form-control" v-model="fieldTipoMovimiento">
                        <option value="S/D">Selecciona una opcion.</option>
                        <option value="Normal">Normal</option>
                        <option value="Asimilado">Asimilado</option>
                    </select>
                    <br>
                </div>
                
            </div>

            <div class="row">

                <div class="col">
                    <p>Monto Deposito (I.V.A. Incluido):</p>
                </div>

                <div class="col">
                    <div class="form-group">
                        <input type="text" @keypress="isDecimal02($event)" style="text-align: right; font-size: 20px;" class="form-control" v-model.lazy="fieldMontoDeposito">
                    </div>
                </div>

            </div>

            <div class="row">
                <br>
                <div class="col">
                    <p>Factura:</p>
                </div>

                <div class="col">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="fieldFactura" disabled>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col">
                    <div class="custom-file">
                        <input type="file" class="form-control" accept=".jpg, .png, .pdf" lang="es" @change="handleValidarFile" />
                    </div>
                </div>
            </div>

            <br>

            <div class="classNoti">
                <p>Validacion: {{fieldValidacion}}</p>
            </div>
        </div>
          
      </template>

      <template v-slot:pie>

        <button type="button" class="btn btn-danger" @click="handleCloseModalDepositos">
            <i class="fa fa-window-close" aria-hidden="true" style="color: white; font-size: 15px;"></i>
        </button>
        &nbsp;&nbsp;
        <button type="button" class="btn btn-info" @click="handleGrabarDeposito">
            <i class="fa fa-floppy-o" aria-hidden="true" style="color: white; font-size: 15px;"></i>
        </button>

      </template>

    </comp-modal>

    
    <!-- Registros Excel Asimilados -->
    <comp-modal v-if="modalRegistrosExcelAsimilados" size="width: 900px; height: 690px;" sizeCuerpo="height:550px">
        <template v-slot:encabezado>
        <label><h5>Registros Excel Asimilados</h5></label>
      </template>
      <template v-slot:cuerpo>
          
          <div class="classContenedor">

            <div class="row">

                <table class="table mb-0">

                    <thead>
                        <tr>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Apellido Paterno</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Apellido Materno</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nombre</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">curp</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">rfc</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Clabe Banco</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Cuenta Banco</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Monto</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Retorno</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(registro, index) in jsonRegistrosExcelAsimilados" :key="index">
                            <td>
                                <h6 class="mb-0 text-xs">{{ registro.apellidoPaterno }}</h6>
                            </td>

                            <td>
                                <h6 class="mb-0 text-xs">{{ registro.apellidoMaterno }}</h6>
                            </td>

                            <td>
                                <h6 class="mb-0 text-xs">{{ registro.nombre }}</h6>
                            </td>

                            <td>
                                <h6 class="mb-0 text-xs">{{ registro.curp }}</h6>
                            </td>

                            <td>
                                <h6 class="mb-0 text-xs">{{ registro.rfc }}</h6>
                            </td>

                            <td>
                                <h6 class="mb-0 text-xs">{{ registro.clabeBanco }}</h6>
                            </td>

                            <td>
                                <h6 class="mb-0 text-xs">{{ registro.cuentaBanco }}</h6>
                            </td>

                            <td>
                                <h6 class="mb-0 text-xs">{{ registro.monto }}</h6>
                            </td>

                            <td>
                                <h6 class="mb-0 text-xs">{{ registro.retorno }}</h6>
                            </td>

                        </tr>
                    </tbody>

                </table>


            </div>


        </div>
          
      </template>

      <template v-slot:pie>

        <button type="button" class="btn btn-danger" @click="modalRegistrosExcelAsimilados = false">
            <i class="fa fa-window-close" aria-hidden="true" style="color: white; font-size: 15px;"></i>
        </button>

      </template>

    </comp-modal>

    <!-- Registros Excel Dispersion -->
    <comp-modal v-if="modalRegistrosExcelDispersion" size="width: 900px; height: 690px;" sizeCuerpo="height:550px">
        <template v-slot:encabezado>
        <label><h5>Registros Excel Dispersion</h5></label>
      </template>
      <template v-slot:cuerpo>
          
          <div class="classContenedor">

            <div class="row">

                <table class="table mb-0">

                    <thead>
                        <tr>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Apellido Paterno</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Apellido Materno</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nombre</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Clabe Banco</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Cuenta Banco</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Monto</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Retorno</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(registro, index) in jsonRegistrosExcelDispersion" :key="index">
                            <td>
                                <h6 class="mb-0 text-xs">{{ registro.apellidoPaterno }}</h6>
                            </td>

                            <td>
                                <h6 class="mb-0 text-xs">{{ registro.apellidoMaterno }}</h6>
                            </td>

                            <td>
                                <h6 class="mb-0 text-xs">{{ registro.nombre }}</h6>
                            </td>

                            <td>
                                <h6 class="mb-0 text-xs">{{ registro.clabeBanco }}</h6>
                            </td>

                            <td>
                                <h6 class="mb-0 text-xs">{{ registro.cuentaBanco }}</h6>
                            </td>

                            <td>
                                <h6 class="mb-0 text-xs">{{ registro.monto }}</h6>
                            </td>

                            <td>
                                <h6 class="mb-0 text-xs">{{ registro.retorno }}</h6>
                            </td>


                        </tr>
                    </tbody>

                </table>


            </div>


        </div>
          
      </template>

      <template v-slot:pie>

        <button type="button" class="btn btn-danger" @click="modalRegistrosExcelDispersion = false">
            <i class="fa fa-window-close" aria-hidden="true" style="color: white; font-size: 15px;"></i>
        </button>

      </template>

    </comp-modal>

    <!-- Registros Excel Dispersion -->
    <comp-modal v-if="modalRegistrosExcelEfectivo" size="width: 900px; height: 690px;" sizeCuerpo="height:550px">
        <template v-slot:encabezado>
        <label><h5>Registros Excel Efectivo</h5></label>
      </template>
      <template v-slot:cuerpo>
          
          <div class="classContenedor">

            <div class="row">

                <table class="table mb-0">

                    <thead>
                        <tr>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Apellido Paterno</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Apellido Materno</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nombre</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Monto</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Retorno</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(registro, index) in jsonRegistrosExcelEfectivo" :key="index">
                            <td>
                                <h6 class="mb-0 text-xs">{{ registro.apellidoPaterno }}</h6>
                            </td>

                            <td>
                                <h6 class="mb-0 text-xs">{{ registro.apellidoMaterno }}</h6>
                            </td>

                            <td>
                                <h6 class="mb-0 text-xs">{{ registro.nombre }}</h6>
                            </td>

                            <td>
                                <h6 class="mb-0 text-xs">{{ registro.monto }}</h6>
                            </td>

                            <td>
                                <h6 class="mb-0 text-xs">{{ registro.retorno }}</h6>
                            </td>

                        </tr>
                    </tbody>

                </table>


            </div>


        </div>
          
      </template>

      <template v-slot:pie>

        <button type="button" class="btn btn-danger" @click="modalRegistrosExcelEfectivo = false">
            <i class="fa fa-window-close" aria-hidden="true" style="color: white; font-size: 15px;"></i>
        </button>

      </template>

    </comp-modal>


    <comp-modal v-if="modalFacturas" size="width: 1200px; height: 690px;" sizeCuerpo="height:550px">
        <template v-slot:encabezado>
        <label><h5>Registros Facturas</h5></label>
      </template>
      <template v-slot:cuerpo>
          
          <div class="classContenedor">

            <div class="row">

                <table class="table mb-0">

                    <thead>
                        <tr>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Razon Social</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Cliente Razon Social</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Sub Total</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">I.V.A.</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Total</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Estatus</th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Opciones</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(registro, index) in jsonRegistrosFactura" :key="index">
                            <td>
                                <h6 class="mb-0 text-xs">{{ registro.empresa_razon_social }}</h6>
                            </td>

                            <td>
                                <h6 class="mb-0 text-xs">{{ registro.cliente_razon_social }}</h6>
                            </td>

                            <td>
                                <h6 class="mb-0 text-xs">{{ registro.sub_total }}</h6>
                            </td>

                            <td>
                                <h6 class="mb-0 text-xs">{{ registro.iva }}</h6>
                            </td>

                            <td>
                                <h6 class="mb-0 text-xs">{{ registro.total }}</h6>
                            </td>

                            <td>
                                <h6 class="mb-0 text-xs">{{ registro.estatus }}</h6>
                            </td>

                            <td>
                              <button type="button" class="btn btn-success btn-sm" @click="handleDescargarComprobantePago(registro.comprobanteFileBase64, registro.comprobanteFileName)">Comprobante</button>
                              &nbsp;
                              <button type="button" class="btn btn-success btn-sm" @click="handleDescargarXml(registro.stringXML, registro.serie, registro.folio )">XML Sin Timbrar</button>
                              &nbsp;
                              <button :disabled="registro.Pendiente === 'Pendiente' ? false : true " type="button" class="btn btn-success btn-sm" @click="handleDescargarXml(registro.stringXML, registro.serie, registro.folio )">XML Timbrar</button>
                            </td>

                        </tr>
                    </tbody>

                </table>


            </div>


        </div>
          
      </template>

      <template v-slot:pie>

        <button type="button" class="btn btn-danger" @click="modalFacturas = false">
            <i class="fa fa-window-close" aria-hidden="true" style="color: white; font-size: 15px;"></i>
        </button>

      </template>

    </comp-modal>

</template>

<script>
import proceso from './js/proceso';
import CompModal from '../../componentes/modal/modal.vue';
import Datepicker from 'vue3-datepicker';
// Datepicker
export default {
  name: "cliente",
  components: {Datepicker, CompModal},
  setup(){
    return{
      ...proceso()
    }
  }
}
</script>

<style scoped>
  @import './css/estilos.css';
</style>

