import { ref, watch } from 'vue';
import { isNumber, isDecimal, isDecimal02, convertirFecha, convertirFechaMes, imagenToBase64, formatNumero, formatoMexico } from '@/helpers/general';
import { operacion, archivosApi } from '@/helpers/axios';
import { notify } from '@kyvg/vue3-notification';
import sin_logo from '../../../../assets/img/sin_logo.png';
import xlsxParser from 'xlsx-parse-json';

const shortid =  require('shortid');

const useClientes = () => {

    const idUnicoUsuario = ref(localStorage.getItem('idUnico'));

    const processSeleccionPest = ref({
        seleccion01: true,
        seleccion02: false,
        seleccion03: false,
        seleccion04: false,
        seleccion05: false,
        seleccion06: false
    });
    const fieldSelectGrupoEmpresarial = ref('0');
    const processBotonSeleccionarGrupoEmpresarial = ref({
        texto: 'Seleccionar',
        seleccionado: false
    });
    const jsonGrupoEmpresarial = ref([]);
    const jsonDatosGeneralesGrupoEmpresarial = ref({
        generales: [],
        empresasCliente: []
    });
    const fieldFormDatosGenerales = ref({
        idUnico: '',
        grupoEmpresarial: '',
        descripcion: '',
        ultimoMovimientoParametros: '',
        ultimaSolicitud: '',
        saldoAFavor: '',
        credito: '',
        claveAcceso: '',
        activoAcceso: 0,
        logo: sin_logo
    });
    const fieldFormDatosGeneralesTablaClientes = ref([]);
    const jsonCatalogosSATFolios = ref({});
    const jsonEmpresasDatosFacturas = ref({});

    const processModalPlantilla = ref({
        open: false,
        title: 'Catalogo de plantillas'
    });
    const fieldPlantillaIdUnico = ref('');
    const fieldNombrePlantilla = ref('');
    const fieldDescripcionPlantilla = ref('');
    const disableButtonGuardarPlantilla = ref(false);
    const disableButtonEditarGuardarPlantilla = ref(true);
    const disableButtonCancelarGuardarPlantilla = ref(true);
    const jsonPlantillas = ref({});
    const jsonParametrosDatosPlantilla = ref({});
    const fieldSelectPlantillaSeleccionada = ref('0');
    const fieldNombrePlantillaSeleccionada = ref('');

    const processSeleccionPestParam = ref({
        seleccion01: true,
        seleccion02: false,
        seleccion03: false,
        seleccion04: false,
        seleccion05: false
    });

    const fieldCheckTipoOperacion = ref([]);
    const disabledParametrosNomina = ref(true);
    const jsonBasesNomina = ref([]);
    const fieldBaseNomina = ref(0);
    const jsonEmpresasAgrupadoras = ref([]);
    const fieldEmpresaAgrupadora = ref(0);
    const jsonTipoPeriodo = ref([]);
    const fieldTipoPeriodo = ref(0);
    const jsonPuesto = ref([]);
    const fieldPuesto = ref('0');

    const fieldDepartamentos = ref('0');
    const jsonDepartamentos = ref([]);

    const jsonConceptos = ref([]);
    const fieldCheckConceptos = ref([]);
    const jsonConceptosDispersion = ref([]);
    const fieldCheckConceptosDispersion = ref([]);
    

    const jsonPromotores = ref([]);
    const fieldSelectPromotores = ref('0');
    const fieldPorcentajePromotores = ref('0');
    const fieldOperacionPorcentajePromotores = ref('0');
    const jsonPromotoresComision = ref([]);

    const fieldComisionPesosPorcentaje = ref(0);
    const fieldComisionPesosOperacion = ref('');
    const fieldActivarComisionPesosExtra = ref(false);
    const fieldComisionPesosExtraPorcentaje = ref(0);
    const fieldComisionPesosExtraOperacion = ref('');
    const fieldIVAPorcentaje = ref(0);
    const fieldIVAOperacion = ref('');
    const fieldDevolucionIVAPorcentaje = ref(0);
    const fieldDevolucionIVAOperacion = ref('');
    const fieldRetornoPorcentaje = ref(0);
    const fieldRetornoOperacion = ref('');

    const jsonEmpresasPagadoras = ref([]);
    const fieldEmpresaPagadora = ref('0');

    const fieldNombreComision01Check = ref(false);
    const fieldNombreComision01 = ref('');
    const fieldPorcentajeComision01Asimilado = ref('0');
    const fieldPorcentajeComision01Normal = ref('0');
    const fieldPorcentajeComision01Operacion = ref('');

    const fieldNombreComision02Check = ref(false);
    const fieldNombreComision02 = ref('');
    const fieldPorcentajeComision02Asimilado = ref('0');
    const fieldPorcentajeComision02Normal = ref('0');
    const fieldPorcentajeComision02Operacion = ref('');
    

    const fieldSelectNombreEmpresaInterna = ref('0');
    const fieldPorcentajeComisionPorDepositoMovAsimilado = ref('0');
    const fieldPorcentajeComisionPorDepositoMovNormal = ref('0');
    const fieldPorcentajeComisionOperacion = ref('');
    const jsonEmpresasInternas = ref([]);
    const jsonComisionPorDepositoEmpresa = ref([]);

    const fieldSelectCorreo = ref('S/D');
    const jsonCorreos = ref([]);
    const jsonCorreosAsignados = ref([]);
    
    const jsonMetodoPago = ref([]);
    const jsonFormaPago = ref([]);
    const jsonProdServ = ref([])
    const jsonClaveUnidad = ref([]);
    const jsonDataGruporEmpresarialPlantillaConceptos = ref([]);
    const fieldIdUnicoConcepto = ref('');
    const fieldFacturaProdServ = ref('');
    const fieldFacturaDescripcion = ref('');
    const fieldFacturaClaveUnidad = ref('');
    const fieldSelectFacturaMetodoPago = ref('S/D');
    const fieldSelectFacturaFormaPago = ref('S/D');
    const disabledButtonGrabarFacturaConcepto = ref(true);

    const fieldFolioSolicitud = ref('');
    const fieldFechaSolicitud = ref(convertirFecha(new Date()));
    
    const fieldTotalDeposito = ref(0.00);
    const fieldSubTotalDeposito = ref(0.00);
    const fieldIVADeposito = ref(0.00);
    const fieldComisionDeposito = ref(0.00);

    const fieldMontoRetornar = ref('0.00');
    const fieldComisionMontoRetornar = ref('0.00');

    const fieldTotalComision = ref('0.00');
    const fieldTotalCredito = ref(0.00);
    const fieldTotalSaldoFavor = ref(0.00);
    const processModalDepositos = ref({
        open: false,
        title: 'Deposito'
    });
    const fieldRazonSocialDeposita = ref('S/D');
    const fieldRazonSocialRecibeDeposito = ref('S/D');
    const fieldDatosFacturacion = ref('S/D');
    const fieldTipoMovimiento = ref('S/D');
    const fieldMontoDeposito = ref('0.00');
    const fieldFactura = ref('Pendiente');
    const fieldComprobanteFile = ref({});
    const fieldValidacion = ref('');
    const jsonDepositos = ref([]);

    const jsonRegistrosExcelAsimilados = ref([]);
    const jsonRegistrosExcelDispersion = ref([]);
    const jsonRegistrosExcelEfectivo = ref([]);
    const fieldExcelAsimiladoMontoTotal = ref('0.00');
    const fieldExcelDispersionMontoTotal = ref('0.00');
    const fieldExcelEfectivoMontoTotal = ref('0.00');
    const fieldSelectConceptoAsimiladoMonto = ref('S/D');
    const fieldSelectConceptoDispersionMonto = ref('S/D');

    const jsonSelectConceptosAsimilados = ref([]);
    const jsonSelectConceptosDispersion = ref([]);
    // const disabledButtomGrabarDesposito = ref(true);

    const modalRegistrosExcelAsimilados = ref(false);
    const modalRegistrosExcelDispersion = ref(false);
    const modalRegistrosExcelEfectivo = ref(false);

    const fieldComisionPromotor = ref('0.00');
    const fieldComisionPesos = ref('0.00');
    const fieldComisionPesosExtra = ref('0.00');
    const fieldIVA = ref('0.00');
    const fieldDevolucionIVA = ref('0.00');
    const fieldRetorno = ref('0.00');

    const fieldFechaConsultaSolicitudes = ref(new Date());
    const fieldEstatusConsultaSolicitudes = ref('S/D');
    const jsonSolicitudesConsulta = ref([]);

    const buttonDisabledPrecalculoGuardar = ref(false);

    const modalFacturas = ref(false);
    const jsonRegistrosFactura = ref([]);

    //#region Pestañana 01
    //=====================================================================================================================================================
    const handleSelect = (selec) => {

        if(fieldSelectGrupoEmpresarial.value !== 0 && processBotonSeleccionarGrupoEmpresarial.value.seleccionado)
        {
            if(selec === '01')
            {
                processSeleccionPest.value = {
                    seleccion01: true,
                    seleccion02: false,
                    seleccion03: false,
                    seleccion04: false,
                    seleccion05: false,
                    seleccion06: false
                };
            }
            else if(selec === '02')
            {
                processSeleccionPest.value = {
                    seleccion01: false,
                    seleccion02: true,
                    seleccion03: false,
                    seleccion04: false,
                    seleccion05: false,
                    seleccion06: false
                };
            }
            else if(selec === '03')
            {
                processSeleccionPest.value = {
                    seleccion01: false,
                    seleccion02: false,
                    seleccion03: true,
                    seleccion04: false,
                    seleccion05: false,
                    seleccion06: false
                };
            }
            else if(selec === '04')
            {
                processSeleccionPest.value = {
                    seleccion01: false,
                    seleccion02: false,
                    seleccion03: false,
                    seleccion04: true,
                    seleccion05: false,
                    seleccion06: false
                };
            }
            else if(selec === '05')
            {
                processSeleccionPest.value = {
                    seleccion01: false,
                    seleccion02: false,
                    seleccion03: false,
                    seleccion04: false,
                    seleccion05: true,
                    seleccion06: false
                };
            }
            else if(selec === '06')
            {
                processSeleccionPest.value = {
                    seleccion01: false,
                    seleccion02: false,
                    seleccion03: false,
                    seleccion04: false,
                    seleccion05: false,
                    seleccion06: true
                };
            }
        }
        else
        {
            notify({
                title: "Clientes",
                text: "Debes de seleccionar una empresa primero",
              });
        }
    };

    const handleDatosGrupoEmpresarial = async() => {
        const data = await operacion(
            {
                numero : '51',
                proceso : '5',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'tablaGrupoEmpresarial'
                },
                mostrarMensaje: true
            }
        );
        jsonGrupoEmpresarial.value = data.tablas.Table1.filter( e => e.idUnico === idUnicoUsuario.value );
        fieldSelectGrupoEmpresarial.value = idUnicoUsuario.value;
    };
    

    const handleActivarDesactivarAcceso = async() => {
        const data = await operacion(
            {
                numero : '51',
                proceso : '5',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'activarDesactivar',
                    PidUnico: fieldSelectGrupoEmpresarial.value
                },
                mostrarMensaje: true
            }
        );

        if(data.bandera === 0)
        {
            notify({
                title: "Empresa General",
                text: data.mensaje
            });
            fieldFormDatosGenerales.value.activoAcceso = data.tablas.Table1[0].activoAcceso
        }
        else
        {
            notify({
                title: "Empresa General",
                text: data.mensaje
            });
        }
    };

    const handleUpdateClaveAcceso = async() => {
        const data = await operacion(
            {
                numero : '51',
                proceso : '5',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'claveAcceso',
                    PidUnico: fieldSelectGrupoEmpresarial.value,
                    PclaveAcceso: fieldFormDatosGenerales.value.claveAcceso
                },
                mostrarMensaje: true
            }
        );

        if(data.bandera === 0)
        {
            notify({
                title: "Empresa General",
                text: data.mensaje
            });
        }
        else
        {
            notify({
                title: "Empresa General",
                text: data.mensaje
            });
        }
    };

    const handleUpdateLogoEmpresa = async({target}) => {
        const base64 = await imagenToBase64(target.files[0]);
        const data = await operacion(
            {
                numero : '51',
                proceso : '5',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'logoEmpresa',
                    PidUnico: fieldSelectGrupoEmpresarial.value,
                    PlogoEmpresa: base64.base64
                },
                mostrarMensaje: true
            }
        );

        if(data.bandera === 0)
        {
            notify({
                title: "Empresa General",
                text: data.mensaje
            });
            fieldFormDatosGenerales.value.logo = data.tablas.Table1[0].logoEmpresa;
        }
        else
        {
            notify({
                title: "Empresa General",
                text: data.mensaje
            });
        }

        // console.log(base64.base64);
    };

    const handleProcesoBotonSeleccionarGrupoEmpresarial = async() => {

        processSeleccionPest.value = {
            seleccion01: true,
            seleccion02: false,
            seleccion03: false,
            seleccion04: false,
            seleccion05: false,
            seleccion06: false
        };
        fieldFormDatosGeneralesTablaClientes.value = [];
        jsonCatalogosSATFolios.value = {};
        jsonEmpresasDatosFacturas.value = {};
        fieldCheckTipoOperacion.value = [];
        if(fieldSelectGrupoEmpresarial.value !== '0')
        {
            if(processBotonSeleccionarGrupoEmpresarial.value.seleccionado)
            {
                processBotonSeleccionarGrupoEmpresarial.value = {
                    texto: 'Seleccionar',
                    seleccionado: false
                };
                fieldSelectGrupoEmpresarial.value = '0';
                fieldFormDatosGenerales.value = {
                    idUnico: '',
                    grupoEmpresarial: '',
                    descripcion: '',
                    ultimoMovimientoParametros: '',
                    ultimaSolicitud: '',
                    saldoAFavor: '',
                    credito: '',
                    claveAcceso: '',
                    activoAcceso: 0,
                    logo: sin_logo
                }
            }
            else
            {
                processBotonSeleccionarGrupoEmpresarial.value = {
                    texto: 'Editar',
                    seleccionado: true
                };
                const { siglas } = jsonGrupoEmpresarial.value.find( e => e.idUnico === idUnicoUsuario.value);
                fieldFolioSolicitud.value = `${convertirFechaMes(new Date())}${siglas}???`;

                
                await handleDatosGeneralesGrupoEmpresarial(fieldSelectGrupoEmpresarial.value);
                await handleDatosPlantillas();
                await handleEmpresasInternas();
                await handleCorreos();
            }
        }
        else
        {
            notify({
                title: "Clientes",
                text: "Debes de elegir un grupo empresarial.",
              });
        }
    };

    const handleInicio = async() => {
        await handleDatosGrupoEmpresarial();
        await handleProcesoBotonSeleccionarGrupoEmpresarial();
    }
    handleInicio();

    const handleDatosGeneralesGrupoEmpresarial = async(idUnico) => {
        console.log(idUnico)
        const data = await operacion(
            {
                numero : '51',
                proceso : '5',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'seleccionarDatosGrupoempresarial',
                    PidUnico: idUnico
                },
                mostrarMensaje: true
            }
        );

        if(data.bandera === 0)
        {
            const ifLogo = data.tablas.Table1[0].logoEmpresa === '' || data.tablas.Table1[0].logoEmpresa === null ? sin_logo : data.tablas.Table1[0].logoEmpresa;
            fieldFormDatosGenerales.value = {
                idUnico: data.tablas.Table1[0].idUnico,
                grupoEmpresarial: data.tablas.Table1[0].grupoEmpresarial,
                descripcion: data.tablas.Table1[0].descripcion,
                ultimoMovimientoParametros: data.tablas.Table1[0].ultimoMovimientoParametros,
                ultimaSolicitud: data.tablas.Table1[0].ultimaSolicitud,
                saldoAFavor: data.tablas.Table1[0].saldoAFavor,
                credito: data.tablas.Table1[0].credito,
                claveAcceso: data.tablas.Table1[0].claveAcceso,
                activoAcceso: data.tablas.Table1[0].activoAcceso,
                logo: ifLogo
            };
            fieldFormDatosGeneralesTablaClientes.value = data.tablas.Table2;

            jsonCatalogosSATFolios.value = {
                folios: data.tablas.Table3,
                usosCFDI: data.tablas.Table4
            };

        }
        else
        {
            fieldFormDatosGenerales.value = {
                idUnico: '',
                grupoEmpresarial: '',
                descripcion: '',
                ultimoMovimientoParametros: '',
                ultimaSolicitud: '',
                saldoAFavor: '',
                credito: '',
                claveAcceso: '',
                activoAcceso: '',
                logo: sin_logo
            };
            fieldFormDatosGeneralesTablaClientes.value = [];
            jsonEmpresasDatosFacturas.value = {}
        }
    };
    //#endregion

    //#region Pestañana 02
    //=====================================================================================================================================================

    const handleDatosPlantillas = async() => {
        jsonPlantillas.value = [];
        const data = await operacion(
            {
                numero : '51',
                proceso : '7',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'tablaGrupoEmpresarialPlantilla',
                    PidUnicoGrupoEmpresarial: fieldSelectGrupoEmpresarial.value
                },
                mostrarMensaje: true
            }
        );

        jsonParametrosDatosPlantilla.value = data.tablas.Table1;

        if(data.tablas.Table1.find( e => e.seleccionado === 'Activo'))
        {
            const { idUnicoPlantilla, nombrePlantilla } = data.tablas.Table1.find( e => e.seleccionado === 'Activo');
            fieldSelectPlantillaSeleccionada.value = idUnicoPlantilla;
            fieldNombrePlantillaSeleccionada.value = nombrePlantilla;
            handleParametrosDatosPlantilla();
        }
        else
        {
            fieldSelectPlantillaSeleccionada.value = '0';
            fieldNombrePlantillaSeleccionada.value = '';
            handleParametrosDatosPlantilla();
        }

        jsonPlantillas.value = {
            encabezados: ['Nombre Plantilla', 'Descripcion Plantilla', 'Seleccionado'],
            camposMostrar: ['nombrePlantilla', 'descripcionPlantilla', 'seleccionado'],
            registros: data.tablas.Table1,
            opcionesRegistro: false,
            opcionesRegistroActualizar: true,
            opcionesRegistroGuardar: true
        };
    };

    const handleAbrirPlantilla = () => {
        processModalPlantilla.value = {
            open: true,
            title: 'Catalogo de Plantillas'
        };
        handleDatosPlantillas();
    };

    const handleCerrarModalPlantilla = () => {
        processModalPlantilla.value = {
            open: false,
            title: 'Catalogo de Plantillas'
        };
        handleCancelarDatosPlantilla();
    };

    const handleGuardarDatosPlantilla = async() => {
        if(fieldPlantillaIdUnico.value === '')
        {
            if(fieldNombrePlantilla.value === '' || fieldDescripcionPlantilla.value === '')
            {
                notify({
                    title: "Plantilla",
                    text: "Los campos de nombre plnatilla y descripcion no pueden estar vacios."
                });
            }
            else
            {
                const data = await operacion(
                    {
                        numero : '51',
                        proceso : '7',
                        opcionTD : 'TODO',
                        parametros : true,
                        datosReceptor : 
                        {
                            opcion: 'guardarPlantilla',
                            PidUnicoGrupoEmpresarial: fieldSelectGrupoEmpresarial.value,
                            PnombrePlantilla: fieldNombrePlantilla.value,
                            PdescripcionPlantilla: fieldDescripcionPlantilla.value
                        },
                        mostrarMensaje: true
                    }
                );
                if(data.bandera === 0)
                {
                    notify({
                        title: "Plantillas",
                        text: data.mensaje
                    });
                    handleCancelarDatosPlantilla();
                    handleDatosPlantillas();
                }
                else
                {
                    notify({
                        title: "Plantillas",
                        text: data.mensaje
                    });
                }
            }
        }
        else
        {
            if(fieldNombrePlantilla.value === '' || fieldDescripcionPlantilla.value === '')
            {
                notify({
                    title: "Plantilla",
                    text: "Los campos de nombre plnatilla y descripcion no pueden estar vacios."
                });
            }
            else
            {
                const data = await operacion(
                    {
                        numero : '51',
                        proceso : '7',
                        opcionTD : 'TODO',
                        parametros : true,
                        datosReceptor : 
                        {
                            opcion: 'actualizarPlantilla',
                            PidUnicoPlantilla: fieldPlantillaIdUnico.value,
                            PidUnicoGrupoEmpresarial: fieldSelectGrupoEmpresarial.value,
                            PnombrePlantilla: fieldNombrePlantilla.value,
                            PdescripcionPlantilla: fieldDescripcionPlantilla.value
                        },
                        mostrarMensaje: true
                    }
                );
                if(data.bandera === 0)
                {
                    notify({
                        title: "Plantillas",
                        text: data.mensaje
                    });
                    handleCancelarDatosPlantilla();
                    handleDatosPlantillas();
                }
                else
                {
                    notify({
                        title: "Plantillas",
                        text: data.mensaje
                    });
                }
            }
        }
    };

    const handleCancelarDatosPlantilla = () => {
        fieldPlantillaIdUnico.value = '';
        fieldNombrePlantilla.value = '';
        fieldDescripcionPlantilla.value = '';
        disableButtonGuardarPlantilla.value = false;
        disableButtonEditarGuardarPlantilla.value = true;
        disableButtonCancelarGuardarPlantilla.value = true;
    };

    const handleEditarDatosPlantilla = (data) => {
        fieldPlantillaIdUnico.value = data.idUnicoPlantilla;
        fieldNombrePlantilla.value = data.nombrePlantilla;
        fieldDescripcionPlantilla.value = data.descripcionPlantilla;
        disableButtonGuardarPlantilla.value = true;
        disableButtonEditarGuardarPlantilla.value = false;
        disableButtonCancelarGuardarPlantilla.value = false;
    };

    const handleSeleccionPlantilla = async() => {
        const data = await operacion(
            {
                numero : '51',
                proceso : '7',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'actualizarSeleccionPlantilla',
                    PidUnicoGrupoEmpresarial: fieldSelectGrupoEmpresarial.value,
                    PidUnicoPlantilla: fieldSelectPlantillaSeleccionada.value
                },
                mostrarMensaje: true
            }
        );
        if(data.bandera === 0)
        {
            await handleDatosPlantillas();
            notify({
                title: "Plantillas",
                text: data.mensaje
            });
        }
        else
        {
            notify({
                title: "Plantillas",
                text: data.mensaje
            });
        }
    };

    const handleSelectParam = (selec) => {
        if(fieldSelectPlantillaSeleccionada.value === 0 || fieldSelectPlantillaSeleccionada.value === '0')
        {
            notify({
                title: "Clientes",
                text: "Debes de seleccionar una plantilla.",
            });
            processSeleccionPestParam.value = {
                seleccion01: true,
                seleccion02: false,
                seleccion03: false,
                seleccion04: false,
                seleccion05: false
            };
        }
        else
        {
            if(selec === '01')
            {
                processSeleccionPestParam.value = {
                    seleccion01: true,
                    seleccion02: false,
                    seleccion03: false,
                    seleccion04: false,
                    seleccion05: false
                };
            }
            else if(selec === '02')
            {
                processSeleccionPestParam.value = {
                    seleccion01: false,
                    seleccion02: true,
                    seleccion03: false,
                    seleccion04: false,
                    seleccion05: false
                };
            }
            else if(selec === '03')
            {
                processSeleccionPestParam.value = {
                    seleccion01: false,
                    seleccion02: false,
                    seleccion03: true,
                    seleccion04: false,
                    seleccion05: false
                };
            }
            else if(selec === '04')
            {
                processSeleccionPestParam.value = {
                    seleccion01: false,
                    seleccion02: false,
                    seleccion03: false,
                    seleccion04: true,
                    seleccion05: false
                };
            }
            else if(selec === '05')
            {
                processSeleccionPestParam.value = {
                    seleccion01: false,
                    seleccion02: false,
                    seleccion03: false,
                    seleccion04: false,
                    seleccion05: true
                };
            }
        }
    };

    const handleParametrosDatosPlantilla = async() => {
        
        if(fieldSelectPlantillaSeleccionada.value !== '0')
        {
            const { jsonGenerales, jsonFacturacion, jsonNomina } = jsonParametrosDatosPlantilla.value.find( e => e.idUnicoPlantilla === fieldSelectPlantillaSeleccionada.value);

            if(jsonGenerales !== null)
            {
                const dataG = JSON.parse(jsonGenerales);
                fieldSelectPromotores.value = dataG.fieldSelectPromotores;
                fieldPorcentajePromotores.value = dataG.fieldPorcentajePromotores;
                fieldOperacionPorcentajePromotores.value = dataG.fieldOperacionPorcentajePromotores;

                fieldComisionPesosPorcentaje.value = dataG.fieldComisionPesosPorcentaje;
                fieldComisionPesosOperacion.value = dataG.fieldComisionPesosOperacion;
                fieldActivarComisionPesosExtra.value = dataG.fieldActivarComisionPesosExtra;
                fieldComisionPesosExtraPorcentaje.value = dataG.fieldComisionPesosExtraPorcentaje;
                fieldComisionPesosExtraOperacion.value = dataG.fieldComisionPesosExtraOperacion;
                fieldIVAPorcentaje.value = dataG.fieldIVAPorcentaje;
                fieldIVAOperacion.value = dataG.fieldIVAOperacion;
                fieldDevolucionIVAPorcentaje.value = dataG.fieldDevolucionIVAPorcentaje
                fieldDevolucionIVAOperacion.value = dataG.fieldDevolucionIVAOperacion

                fieldRetornoPorcentaje.value = dataG.fieldRetornoPorcentaje;
                fieldRetornoOperacion.value = dataG.fieldRetornoOperacion;


                fieldEmpresaPagadora.value = dataG.fieldEmpresaPagadora;
                // jsonComision.value = dataG.jsonComision;//Checar
    
                fieldNombreComision01Check.value = dataG.fieldNombreComision01Check;
                fieldNombreComision01.value = dataG.fieldNombreComision01;
                fieldPorcentajeComision01Asimilado.value = dataG.fieldPorcentajeComision01Asimilado;
                fieldPorcentajeComision01Normal.value = dataG.fieldPorcentajeComision01Normal;
                fieldPorcentajeComision01Operacion.value = dataG.fieldPorcentajeComision01Operacion;

                fieldNombreComision02Check.value = dataG.fieldNombreComision02Check;
                fieldNombreComision02.value = dataG.fieldNombreComision02;
                fieldPorcentajeComision02Asimilado.value = dataG.fieldPorcentajeComision02Asimilado;
                fieldPorcentajeComision02Normal.value = dataG.fieldPorcentajeComision02Normal;
                fieldPorcentajeComision02Operacion.value = dataG.fieldPorcentajeComision02Operacion;

                jsonComisionPorDepositoEmpresa.value = dataG.jsonComisionPorDepositoEmpresa;
                jsonCorreosAsignados.value = dataG.jsonCorreosAsignados;
            }
            else
            {
                fieldSelectPromotores.value = '0';
                fieldPorcentajePromotores.value = '0';
                fieldOperacionPorcentajePromotores.value = '';

                fieldComisionPesosPorcentaje.value = '';
                fieldComisionPesosOperacion.value = '';
                fieldActivarComisionPesosExtra.value = '';
                fieldComisionPesosExtraPorcentaje.value = '';
                fieldComisionPesosExtraOperacion.value = '';
                fieldIVAPorcentaje.value = '';
                fieldIVAOperacion.value = '';
                
                fieldDevolucionIVAPorcentaje.value = '';
                fieldDevolucionIVAOperacion.value = '';
                
                fieldRetornoPorcentaje.value = '';
                fieldRetornoOperacion.value = '';

                fieldEmpresaPagadora.value = '0';
                
                fieldNombreComision01Check.value = false;
                fieldNombreComision01.value = '';
                fieldPorcentajeComision01Asimilado.value = '0';
                fieldPorcentajeComision01Normal.value = '0';
                fieldPorcentajeComision01Operacion.value = '';

                fieldNombreComision02Check.value = false;
                fieldNombreComision02.value = '';
                fieldPorcentajeComision02Asimilado.value = '0';
                fieldPorcentajeComision02Normal.value = '0';
                fieldPorcentajeComision02Operacion.value = '';

                jsonComisionPorDepositoEmpresa.value = [];
                jsonCorreosAsignados.value = [];
            }

            if(jsonFacturacion !== null)
            {
                const dataF = JSON.parse(jsonFacturacion);
                jsonDataGruporEmpresarialPlantillaConceptos.value = dataF.jsonDataGruporEmpresarialPlantillaConceptos;
            }
            else
            {
                jsonDataGruporEmpresarialPlantillaConceptos.value = [];
            }

            if(jsonNomina !== null)
            {
                const dataN = JSON.parse(jsonNomina);
                fieldBaseNomina.value = dataN.fieldBaseNomina;

                await handleEmpresaAgrupadora();
                
                fieldEmpresaAgrupadora.value = dataN.fieldEmpresaAgrupadora;
                await handleTipoPeriodoPuestoConceptos();
                fieldTipoPeriodo.value = dataN.fieldTipoPeriodo;
                fieldPuesto.value = dataN.fieldPuesto;
                fieldCheckConceptos.value = JSON.parse(dataN.fieldCheckConceptos);
                fieldCheckConceptosDispersion.value = JSON.parse(dataN.fieldCheckConceptosDispersion);
                fieldDepartamentos.value = dataN.fieldDepartamentos;
            }
            else
            {

                fieldBaseNomina.value = '0';

                jsonEmpresasAgrupadoras.value = [];
                fieldEmpresaAgrupadora.value = '0';
                
                jsonTipoPeriodo.value = [];
                fieldTipoPeriodo.value = 0;

                jsonPuesto.value = [];
                fieldPuesto.value = 0;

                jsonConceptos.value = [];
                fieldCheckConceptos.value = [];

                jsonDepartamentos.value = [];
                fieldDepartamentos.value = 0;

                jsonConceptosDispersion.value = [];
                fieldCheckConceptosDispersion.value = [];
            }
        }
        else
        {
            fieldSelectPromotores.value = '0';
            fieldPorcentajePromotores.value = '0';
            fieldOperacionPorcentajePromotores.value = '';

            fieldComisionPesosPorcentaje.value= '';
            fieldComisionPesosOperacion.value= '';
            fieldActivarComisionPesosExtra.value= '';
            fieldComisionPesosExtraPorcentaje.value= '';
            fieldComisionPesosExtraOperacion.value= '';
            fieldIVAPorcentaje.value= '';
            fieldIVAOperacion.value= '';
            fieldRetornoPorcentaje.value= '';
            fieldRetornoOperacion.value= '';

            fieldEmpresaPagadora.value = '0'
            fieldNombreComision01Check.value = false;
            fieldNombreComision02Check.value = false;
            jsonComisionPorDepositoEmpresa.value = [];
            jsonCorreosAsignados.value = [];
        }
    };

    const handleGrabarParametrosDatosPlantilla = async() => {
        let parametrosGenerales = {
            fieldSelectPromotores: fieldSelectPromotores.value,
            fieldPorcentajePromotores: fieldPorcentajePromotores.value,
            fieldOperacionPorcentajePromotores: fieldOperacionPorcentajePromotores.value,

            fieldComisionPesosPorcentaje: fieldComisionPesosPorcentaje.value,
            fieldComisionPesosOperacion: fieldComisionPesosOperacion.value,
            fieldActivarComisionPesosExtra: fieldActivarComisionPesosExtra.value,
            fieldComisionPesosExtraPorcentaje: fieldComisionPesosExtraPorcentaje.value,
            fieldComisionPesosExtraOperacion: fieldComisionPesosExtraOperacion.value,
            fieldIVAPorcentaje: fieldIVAPorcentaje.value,
            fieldIVAOperacion: fieldIVAOperacion.value,
            fieldDevolucionIVAPorcentaje: fieldDevolucionIVAPorcentaje.value,
            fieldDevolucionIVAOperacion: fieldDevolucionIVAOperacion.value,

            fieldRetornoPorcentaje: fieldRetornoPorcentaje.value,
            fieldRetornoOperacion: fieldRetornoOperacion.value,


            fieldEmpresaPagadora: fieldEmpresaPagadora.value,
            
            fieldNombreComision01Check: fieldNombreComision01Check.value,
            fieldNombreComision01: fieldNombreComision01.value,
            fieldPorcentajeComision01Asimilado: fieldPorcentajeComision01Asimilado.value,
            fieldPorcentajeComision01Normal: fieldPorcentajeComision01Normal.value,
            fieldPorcentajeComision01Operacion: fieldPorcentajeComision01Operacion.value,

            fieldNombreComision02Check: fieldNombreComision02Check.value,
            fieldNombreComision02: fieldNombreComision02.value,
            fieldPorcentajeComision02Asimilado: fieldPorcentajeComision02Asimilado.value,
            fieldPorcentajeComision02Normal: fieldPorcentajeComision02Normal.value,
            fieldPorcentajeComision02Operacion: fieldPorcentajeComision02Operacion.value,

            jsonComisionPorDepositoEmpresa: jsonComisionPorDepositoEmpresa.value,
            jsonCorreosAsignados: jsonCorreosAsignados.value
        };

        let parametrosFacturacion = {
            jsonDataGruporEmpresarialPlantillaConceptos: jsonDataGruporEmpresarialPlantillaConceptos.value
        };


        let parametrosNomina = {
            fieldBaseNomina: fieldBaseNomina.value,
            fieldEmpresaAgrupadora: fieldEmpresaAgrupadora.value,
            fieldTipoPeriodo: fieldTipoPeriodo.value,
            fieldPuesto: fieldPuesto.value,
            fieldCheckConceptos: JSON.stringify(fieldCheckConceptos.value),
            fieldCheckConceptosDispersion: JSON.stringify(fieldCheckConceptosDispersion.value),
            fieldDepartamentos: fieldDepartamentos.value
        };

        const data = await operacion(
            {
                numero : '51',
                proceso : '7',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'guardarParametros',
                    PidUnicoGrupoEmpresarial: fieldSelectGrupoEmpresarial.value,
                    PidUnicoPlantilla: fieldSelectPlantillaSeleccionada.value,
                    PparametrosGenerales: JSON.stringify(parametrosGenerales),
                    PparametrosFacturacion: JSON.stringify(parametrosFacturacion),
                    PparametrosNomina: JSON.stringify(parametrosNomina)
                },
                mostrarMensaje: true
            }
        );

        jsonParametrosDatosPlantilla.value = data.tablas.Table1;

        if(data.bandera === 0)
        {
            notify({
                title: "Plantilla parametros",
                text: data.mensaje
            });
        }
        else
        {
            notify({
                title: "Plantillas parametros",
                text: data.mensaje
            });
        }

    };

    watch([fieldNombreComision01Check, fieldNombreComision02Check, fieldActivarComisionPesosExtra], (nuevo) => {
        if(!nuevo[0])
        {
            fieldNombreComision01.value = '';
            fieldPorcentajeComision01Asimilado.value = '0';
            fieldPorcentajeComision01Normal.value = '0';
            fieldPorcentajeComision01Operacion.value = '';
        }
        if(!nuevo[1])
        {
            fieldNombreComision02.value = '';
            fieldPorcentajeComision02Asimilado.value = '0';
            fieldPorcentajeComision02Normal.value = '0';
            fieldPorcentajeComision02Operacion.value = '';
        }
        if(!nuevo[2])
        {
            fieldComisionPesosExtraPorcentaje.value = '0';
            fieldComisionPesosExtraOperacion.value = '';
            fieldComisionPesosExtra.value = '0.00';
        }
    } );

    watch([fieldCheckTipoOperacion], (nuevo) => {
        handleCheckTipoOperacion(nuevo[0]);
    });

    const handleCheckTipoOperacion = (opcion) => {
        if( opcion.find( e => e === '02') )
        {
            disabledParametrosNomina.value = false;
        }
        else
        {
            disabledParametrosNomina.value = true;
            fieldBaseNomina.value = 0;
            fieldEmpresaAgrupadora.value = 0;
            fieldTipoPeriodo.value = 0;
            fieldPuesto.value = 0;
            jsonConceptos.value = [];
            jsonConceptosDispersion.value = [];
            fieldCheckConceptos.value = [];
            fieldCheckConceptosDispersion.value = [];
            fieldDepartamentos.value = 0;
        }
    };

    const handleBasesNomina = async() => {

        const data = await operacion(
            {
                numero : '51',
                proceso : '6',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'basesNomina',
                    idUnicoUsuario: idUnicoUsuario.value
                },
                mostrarMensaje: true
            }
        );
        if(data.bandera === 0)
        {
            jsonBasesNomina.value = data.tablas.Table1;
        }
        else
        {
            notify({
                title: "Clientes",
                text: data.mensaje
            });
            jsonBasesNomina.value = [];
        }
    };
    handleBasesNomina();

    const handleEmpresaAgrupadora = async() => {
        fieldEmpresaAgrupadora.value = 0;
        const data = await operacion(
            {
                numero : '51',
                proceso : '6',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'empresasAgrupadoras',
                    PidBaseERP: fieldBaseNomina.value
                },
                mostrarMensaje: true
            }
        );
        if(data.bandera === 0)
        {
            jsonEmpresasAgrupadoras.value = data.tablas.Table1;
        }
        else
        {
            notify({
                title: "Clientes",
                text: data.mensaje
            });
            jsonEmpresasAgrupadoras.value = [];
        }
    };

    const handleTipoPeriodoPuestoConceptos = async() => {
        jsonTipoPeriodo.value = [];
        jsonPuesto.value = [];
        jsonConceptos.value = [];
        jsonConceptosDispersion.value = [];
        jsonDepartamentos.value = [];

        

        if(fieldBaseNomina.value !== '0')
        {
            let { baseSQL } = await jsonBasesNomina?.value.find( e => e.idBaseERP === fieldBaseNomina.value);
            const data = await operacion(
                {
                    numero : '51',
                    proceso : '6',
                    opcionTD : 'TODO',
                    parametros : true,
                    datosReceptor : 
                    {
                        opcion: 'tipoPeriodo',
                        PSQL: baseSQL,
                        PidSysCPEmpresas: fieldEmpresaAgrupadora.value
                    },
                    mostrarMensaje: true
                }
            );

            if(data.bandera === 0)
            {
                jsonTipoPeriodo.value = data.tablas.Table1;
                jsonPuesto.value = data.tablas.Table2;
                jsonConceptos.value = data.tablas.Table3;
                jsonConceptosDispersion.value = data.tablas.Table4;
                jsonDepartamentos.value = data.tablas.Table5;
            }
            else
            {
                notify({
                    title: "Clientes",
                    text: data.mensaje
                });
                jsonTipoPeriodo.value = [];
                jsonPuesto.value = [];
                jsonConceptos.value = [];
                jsonConceptosDispersion.value = [];
                jsonDepartamentos.value = [];
            }
        }
        

    };

    const handleListaPromotores = async() => {
        const data = await operacion(
            {
                numero : '51',
                proceso : '6',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'promotores'
                },
                mostrarMensaje: true
            }
        );
        if(data.bandera === 0)
        {
            jsonPromotores.value = data.tablas.Table1;
        }
        else
        {
            notify({
                title: "Clientes",
                text: data.mensaje
            });
        }
    };
    handleListaPromotores();

    const handleEmpresasPagadoras = async() => {
        const data = await operacion(
            {
                numero : '51',
                proceso : '6',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'listaEmpresasInternas'
                },
                mostrarMensaje: true
            }
        );
        if(data.bandera === 0)
        {
            jsonEmpresasPagadoras.value = data.tablas.Table1;
        }
        else
        {
            notify({
                title: "Clientes",
                text: data.mensaje
            });
        }
    };
    handleEmpresasPagadoras();

    const handleAgregarPromotor = () => {
        

        if(fieldSelectPromotores.value === '0'|| fieldPorcentajePromotores.value === '0' || fieldOperacionPorcentajePromotores.value === '' )
        {
            notify({
                title: 'Clientes',
                text: 'Debes de seleccionar un promotoro y un porcentaje de comision mayor a 0.'
            });
        }
        else
        {
            if(jsonPromotoresComision.value.find(e => e.idUnicoPromotor === fieldSelectPromotores.value))
            {
                let index = jsonPromotoresComision.value.findIndex(e => e.idUnicoPromotor === fieldSelectPromotores.value)
                jsonPromotoresComision.value[index].porcentajePromotores = fieldPorcentajePromotores.value;
            }
            else
            {
                let nombrePromotor = jsonPromotores.value.find(e => e.idUnicoPromotor === fieldSelectPromotores.value).nombreCompleto;
                jsonPromotoresComision.value = [...jsonPromotoresComision.value, {
                    idUnicoGrupoEmpresarial: fieldSelectGrupoEmpresarial.value,
                    idUnicoPromotor: fieldSelectPromotores.value,
                    nombrePromotor,
                    porcentajePromotores: fieldPorcentajePromotores.value * 1
                }];
            }
            fieldSelectPromotores.value = '0';
            fieldPorcentajePromotores.value = '0';
        }
    }

    const hanldeQuitarPromotor = (datos) => {
        const { idUnicoPromotor } = datos;
        jsonPromotoresComision.value = jsonPromotoresComision.value.filter( e => e.idUnicoPromotor !== idUnicoPromotor);
    }

    const handleChangeSerie = async(idUnicoGrupoEmpresarial, idSysCPEmpresasRP, {target}) => {
        const data = await operacion(
            {
                numero : '51',
                proceso : '4',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'actualizarSerie',
                    Pserie: target.value,
                    PidUnicoGrupoEmpresarial: idUnicoGrupoEmpresarial,
                    PidSysCPEmpresasRP: idSysCPEmpresasRP
                },
                mostrarMensaje: true
            }
        );
        if(data.bandera === 0)
        {
            const index = jsonEmpresasInternas.value.findIndex( e => e.idSysCPEmpresasRP === idSysCPEmpresasRP );
            jsonEmpresasInternas.value[index].serieFactura = target.value;
            notify({
                title: "Empresas",
                text: data.mensaje
            });
        }
        else
        {
            notify({
                title: "Empresas",
                text: data.mensaje
            });
        }
    };

    const handleChangeUsoCFDI = async(idUnicoGrupoEmpresarial, idSysTSEmpresas, {target}) => {
        const data = await operacion(
            {
                numero : '51',
                proceso : '1',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'actualizarUsoCFDI',
                    PusoCFDI: target.value,
                    PidUnicoGrupoEmpresarial: idUnicoGrupoEmpresarial,
                    PidSysTSEmpresas: idSysTSEmpresas
                },
                mostrarMensaje: true
            }
        );
        if(data.bandera === 0)
        {
            const index = fieldFormDatosGeneralesTablaClientes.value.findIndex( e => e.idSysTSEmpresas === idSysTSEmpresas );
            fieldFormDatosGeneralesTablaClientes.value[index].usoCFDI = target.value;
            notify({
                title: "Empresas",
                text: data.mensaje
            });
        }
        else
        {
            notify({
                title: "Empresas",
                text: data.mensaje
            });
        }
    };

    // const handleAgregarComision = () => {
        

        // if(fieldNombreComision.value === ''|| fieldPorcentajeComision.value === '0' || fieldPorcentajeComision.value === '' )
        // {
        //     notify({
        //         title: 'Clientes',
        //         text: 'Los datos no pueden ir vacios o con valor 0.'
        //     });
        // }
        // else
        // {
        //     if(jsonComision.value.find(e => e.nombreComision === fieldNombreComision.value))
        //     {
        //         let index = jsonComision.value.findIndex(e => e.nombreComision === fieldNombreComision.value)
        //         jsonComision.value[index].porcentajeComision = fieldPorcentajeComision.value;
        //     }
        //     else
        //     {
        //         jsonComision.value = [...jsonComision.value, {
        //             idUnicoGrupoEmpresarial: fieldSelectGrupoEmpresarial.value,
        //             nombreComision: fieldNombreComision.value.toUpperCase(),
        //             porcentajeComision: fieldPorcentajeComision.value * 1
        //         }];
        //     }
        //     fieldNombreComision.value = '';
        //     fieldPorcentajeComision.value = '0';
        // }
    // }
    // const hanldeQuitarComision = (datos) => {
    //     const { nombreComision } = datos;
    //     jsonComision.value = jsonComision.value.filter( e => e.nombreComision !== nombreComision);
    // }
    const handleValidadrPromotorComision = () => {
        if(fieldSelectPromotores.value === '0')
        {
            fieldPorcentajePromotores.value = 0;
        }
    };

    
    const handleEmpresasInternas = async() => {
        const data = await operacion(
            {
                numero : '51',
                proceso : '7',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'listaEmpresasInternas',
                    PidUnicoGrupoEmpresarial: fieldSelectGrupoEmpresarial.value
                },
                mostrarMensaje: true
            }
        );
        if(data.bandera === 0)
        {
            jsonEmpresasInternas.value = data.tablas.Table1;
        }
        else
        {
            jsonEmpresasInternas.value = [];
        }
    };
    const handleAgregarComisionPorDepositoEmpresa = async() => {
        if(fieldSelectNombreEmpresaInterna.value !== 0 && fieldPorcentajeComisionPorDepositoMovAsimilado.value >= 0 && fieldPorcentajeComisionPorDepositoMovNormal.value >= 0 && fieldPorcentajeComisionOperacion.value !== '') 
        {
            
            const conteo = jsonComisionPorDepositoEmpresa?.value.filter( e => e.idSysCPEmpresasRP === fieldSelectNombreEmpresaInterna.value ).length

            if(conteo === 0)
            {
                const { razonSocial } = jsonEmpresasInternas.value.find( e => e.idSysCPEmpresasRP === fieldSelectNombreEmpresaInterna.value );
                jsonComisionPorDepositoEmpresa.value = [...jsonComisionPorDepositoEmpresa.value, {
                    idSysCPEmpresasRP: fieldSelectNombreEmpresaInterna.value,
                    razonSocial: razonSocial,
                    porcentajeComisionPorDepositoMovAsimilado: fieldPorcentajeComisionPorDepositoMovAsimilado.value,
                    porcentajeComisionPorDepositoMovNomral: fieldPorcentajeComisionPorDepositoMovNormal.value,
                    porcentajeComisionOperacion: fieldPorcentajeComisionOperacion.value
                }];
            }
            else
            {
                const index = jsonComisionPorDepositoEmpresa.value.findIndex( e => e.idSysCPEmpresasRP === fieldSelectNombreEmpresaInterna.value );
                jsonComisionPorDepositoEmpresa.value[index].porcentajeComisionPorDepositoMovAsimilado = fieldPorcentajeComisionPorDepositoMovAsimilado.value;
                jsonComisionPorDepositoEmpresa.value[index].porcentajeComisionPorDepositoMovNomral = fieldPorcentajeComisionPorDepositoMovNormal.value;
                jsonComisionPorDepositoEmpresa.value[index].porcentajeComisionOperacion = fieldPorcentajeComisionOperacion.value;
            }

            fieldSelectNombreEmpresaInterna.value = 0;
            fieldPorcentajeComisionPorDepositoMovAsimilado.value = 0;
            fieldPorcentajeComisionPorDepositoMovNormal.value = 0;
            fieldPorcentajeComisionOperacion.value = '';

            notify({
                title: "Empresas Internas",
                text: 'El registro se agrego con exito.'
            });

        } 
        else
        {
            notify({
                title: "Empresas Internas",
                text: 'Falta informacion para agregar el registro.'
            });
        }
    };
    const handleQuitarComisionPorDepositoEmpresa = (registro) => {
        jsonComisionPorDepositoEmpresa.value = jsonComisionPorDepositoEmpresa.value.filter( e => e.idSysCPEmpresasRP !== registro.idSysCPEmpresasRP );
        notify({
            title: "Empresas Internas",
            text: 'El registro se quito con exito.'
        });
    };

    const handleCorreos = async() => {
        const data = await operacion(
            {
                numero : '51',
                proceso : '7',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'listaCorreos'
                },
                mostrarMensaje: true
            }
        );
        if(data.bandera === 0)
        {
            jsonCorreos.value = data.tablas.Table1;
        }
        else
        {
            jsonCorreos.value = [];
        }
    };
    const handleAgregarCorreo = () => {
        if(fieldSelectCorreo.value !== 'S/D')
        {
            
            if(jsonCorreosAsignados.value.filter( e => e.idUnicoCorreos === fieldSelectCorreo.value ).length === 0)
            {

                const datosCorreo = jsonCorreos.value.find( e => e.idUnicoCorreos == fieldSelectCorreo.value );
                jsonCorreosAsignados.value = [...jsonCorreosAsignados.value, {
                    idUnicoCorreos: datosCorreo.idUnicoCorreos,
                    nombre: datosCorreo.nombre, 
                    correoElectronico: datosCorreo.correoElectronico
                }];
                fieldSelectCorreo.value = 'S/D';
                notify({
                    title: "Asignar Correo",
                    text: 'El registro se agrego con exito.'
                });
            }
            else
            {
                notify({
                    title: "Asignar Correo",
                    text: 'El correo ya esta agregado.'
                });
            }

        }
        else
        {
            notify({
                title: "Asignar Correo",
                text: 'Debes de seleccionar un correo.'
            });
        }
    };
    const handleQuitarCorreo = ({idUnicoCorreos}) => {
        jsonCorreosAsignados.value = jsonCorreosAsignados.value.filter( e => e.idUnicoCorreos !== idUnicoCorreos );
        notify({
            title: "Asignar Correo",
            text: 'El registro se quito con exito.'
        });
    };


    const handleDataCatalogosFacturacion = async() => {
        const data = await operacion(
            {
                numero : '51',
                proceso : '6',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'catalogosSat'
                },
                mostrarMensaje: true
            }
        );
        if(data.bandera === 0)
        {
            jsonMetodoPago.value = data.tablas.Table1;
            jsonFormaPago.value = data.tablas.Table2;
            jsonProdServ.value = data.tablas.Table3;
            jsonClaveUnidad.value = data.tablas.Table4;
        }
        else
        {
            notify({
                title: "Clientes",
                text: data.mensaje
            });
        }
    };
    handleDataCatalogosFacturacion();

    watch([fieldFacturaProdServ, fieldFacturaDescripcion, fieldFacturaClaveUnidad, fieldSelectFacturaMetodoPago, fieldSelectFacturaFormaPago], (nuevo) => {
        handleFacturaValidationConceptos(nuevo);
    });
    const handleFacturaValidationConceptos = (nuevo) => {
        let banderaProdServ = false;
        let banderaDescripcion = false;
        let banderaClaveUnidad = false;
        let banderaMetodoPago = false;
        let banderaFormaPago = false;

        const proserv = jsonProdServ.value.filter( e => e.claveProdServ === nuevo[0] ).length;
        if(proserv >= 1) 
        {
            banderaProdServ = true;
        }
        else
        {
            banderaProdServ = false;
        }

        if(nuevo[1].length >= 10)
        {
            banderaDescripcion = true;
        }
        else
        {
            banderaDescripcion = false;
        }
        const claveUnidad = jsonClaveUnidad.value.filter( e => e.idCatClaveUnidad === nuevo[2].toUpperCase() ).length;
        if(claveUnidad >= 1) 
        {
            banderaClaveUnidad = true;
        }
        else
        {
            banderaClaveUnidad = false;
        }
        if(nuevo[3] !== 'S/D')
        {
            banderaMetodoPago = true;
        }
        else
        {
            banderaMetodoPago = false;
        }
        if(nuevo[4] !== 'S/D')
        {
            banderaFormaPago = true;
        }
        else
        {
            banderaFormaPago = false;
        }

        if(banderaProdServ && banderaDescripcion && banderaClaveUnidad && banderaMetodoPago && banderaFormaPago)
        {
            disabledButtonGrabarFacturaConcepto.value = false;
        }
        else
        {
            disabledButtonGrabarFacturaConcepto.value = true;
        }

        
    };

    const handleFacturaGuardar = () => {
        try
        {
            if(fieldIdUnicoConcepto.value === '')
            {
                jsonDataGruporEmpresarialPlantillaConceptos.value = [...jsonDataGruporEmpresarialPlantillaConceptos.value, {
                    idUnicoConcepto: shortid.generate().toLocaleUpperCase(),
                    idUnicoGrupoEmpresarial: fieldSelectGrupoEmpresarial.value,
                    idUnicoPlantilla: fieldSelectPlantillaSeleccionada.value,
                    prodServ: fieldFacturaProdServ.value,
                    claveUnidad: fieldFacturaClaveUnidad.value.toLocaleUpperCase(),
                    metodoPago: fieldSelectFacturaMetodoPago.value,
                    formaPago: fieldSelectFacturaFormaPago.value,
                    descripcion: fieldFacturaDescripcion.value.toLocaleUpperCase()
                }]

                handleFacturaCancelar();

                notify({
                    title: "Conceptos",
                    text: `El concepto se genero con exito.`
                });
            }
            else
            {
                const index = jsonDataGruporEmpresarialPlantillaConceptos.value.findIndex( e => e.idUnicoConcepto === fieldIdUnicoConcepto.value);
                jsonDataGruporEmpresarialPlantillaConceptos.value[index].claveUnidad = fieldFacturaClaveUnidad.value.toLocaleUpperCase();
                jsonDataGruporEmpresarialPlantillaConceptos.value[index].claveUnidad = fieldFacturaClaveUnidad.value.toLocaleUpperCase();
                jsonDataGruporEmpresarialPlantillaConceptos.value[index].claveUnidad = fieldFacturaClaveUnidad.value.toLocaleUpperCase();
                handleFacturaCancelar();

                notify({
                    title: "Conceptos",
                    text: `El concepto se actualizo con exito.`
                });
            }

        }
        catch(error)
        {
            notify({
                title: "Conceptos",
                text: `Error: ${error}`
            });
        }

    };

    const handleFacturaCancelar = () => {
        fieldIdUnicoConcepto.value = '';
        fieldFacturaProdServ.value = '';
        fieldFacturaClaveUnidad.value = '';
        fieldSelectFacturaMetodoPago.value = 'S/D';
        fieldSelectFacturaFormaPago.value = 'S/D';
        fieldFacturaDescripcion.value = '';
    }

    const handleFacturarEditar = (datos) => {
        fieldIdUnicoConcepto.value = datos.idUnicoConcepto;
        fieldFacturaProdServ.value = datos.prodServ;
        fieldFacturaDescripcion.value = datos.descripcion;
        fieldFacturaClaveUnidad.value = datos.claveUnidad;
        fieldSelectFacturaMetodoPago.value = datos.metodoPago;
        fieldSelectFacturaFormaPago.value = datos.formaPago;
    };

    const handleFacturarQuitar = (datos) => {
        try
        {
            jsonDataGruporEmpresarialPlantillaConceptos.value = jsonDataGruporEmpresarialPlantillaConceptos.value.filter( e => e.idUnicoConcepto !== datos.idUnicoConcepto);
            notify({
                title: "Conceptos",
                text: `El concepto se quito con exito.`
            });
        }
        catch(error)
        {
            notify({
                title: "Conceptos",
                text: `Error: ${error}`
            });
        }
    };
    

    //#endregion

    //#region Pestañana 03

    const handleOpenModalDepositos = () => {
        processModalDepositos.value = {
            open: true,
            title: 'Deposito'
        }
    };

    const handleCloseModalDepositos = () => {
        
        fieldRazonSocialDeposita.value = 'S/D';
        fieldRazonSocialRecibeDeposito.value = 'S/D';
        fieldDatosFacturacion.value = 'S/D';
        fieldTipoMovimiento.value = 'S/D';
        fieldMontoDeposito.value = '0.00';
        fieldComprobanteFile.value = {};
        fieldValidacion.value = '';
        processModalDepositos.value = {
            open: false,
            title: 'Deposito'
        }
    };

    watch([fieldMontoDeposito], (nuevo) => {
        let valor = nuevo[0];
        try
        {
            fieldMontoDeposito.value = formatoMexico(Number(formatNumero(valor)));
        }
        catch(error)
        {
            fieldMontoDeposito.value = 0.00;
        }
    });

    const handleValidarFile = async({target}) => {
        const { files } = target;
        if(files.length > 0)
        {
            fieldComprobanteFile.value = await imagenToBase64(files[0]);
        }
        else
        {
            fieldComprobanteFile.value = {
                bandera: false,
                base64: '',
                name: ''
            };
        }
    };

    const handleValidarDatosDeposito = () => {
        const comparacionMoneda = /^(?!0\.00)\d{1,3}(,\d{3})*(\.\d\d)?$/;
        
        const bandera01 = fieldRazonSocialDeposita.value !== 'S/D' ? true : false;
        const bandera01Texto = 'Falta elegir una razon social cliente. ';

        const bandera02 = fieldRazonSocialRecibeDeposito.value !== 'S/D' ? true : false;
        const bandera02Texto = 'Falta elegir una razon social que recibe el deposito. ';

        const bandera03 = fieldDatosFacturacion.value !== 'S/D' ? true : false;
        const bandera03Texto = 'Falta elegir una concepto de factura. ';

        const bandera04 = fieldTipoMovimiento.value !== 'S/D' ? true : false;
        const bandera04Texto = 'Falta elegir una tipo de movimiento. ';

        const bandera05 = comparacionMoneda.test(fieldMontoDeposito.value);
        const bandera05Texto = 'El monto introducido no tiene un formato valido, Eje.: (1,000,000.00). ';

        const bandera06 = fieldComprobanteFile.value.bandera;
        const bandera06Texto = 'Falta informacion del comprobante. ';

        fieldValidacion.value = '';
        if(!bandera01)
        {
            fieldValidacion.value = fieldValidacion.value + bandera01Texto
        }
        else
        {
            fieldValidacion.value = fieldValidacion.value + '';
        }

        if(!bandera02)
        {
            fieldValidacion.value = fieldValidacion.value + bandera02Texto
        }
        else
        {
            fieldValidacion.value = fieldValidacion.value + '';
        }

        if(!bandera03)
        {
            fieldValidacion.value = fieldValidacion.value + bandera03Texto
        }
        else
        {
            fieldValidacion.value = fieldValidacion.value + '';
        }

        if(!bandera04)
        {
            fieldValidacion.value = fieldValidacion.value + bandera04Texto
        }
        else
        {
            fieldValidacion.value = fieldValidacion.value + '';
        }

        if(!bandera05)
        {
            fieldValidacion.value = fieldValidacion.value + bandera05Texto
        }
        else
        {
            fieldValidacion.value = fieldValidacion.value + '';
        }

        if(!bandera06)
        {
            fieldValidacion.value = fieldValidacion.value + bandera06Texto
        }
        else
        {
            fieldValidacion.value = fieldValidacion.value + '';
        }

        if(bandera01 && bandera02 && bandera03 && bandera04 && bandera05 && bandera06)
        {
            fieldValidacion.value = 'Correcto!.';
            // disabledButtomGrabarDesposito.value = false;
        }

    };

    const handleGrabarDeposito = () => {
        handleValidarDatosDeposito();

        if(fieldValidacion.value === 'Correcto!.')
        {
            let comision = 0;
            let porcentajeComision = 0;

            const empresaCliente = fieldFormDatosGeneralesTablaClientes.value.find( e => e.idSysTSEmpresas === fieldRazonSocialDeposita.value );
            // { razonSocial, rfc, usoCFDI }

            const empresaInterna = jsonEmpresasInternas.value.find( e => e.idSysCPEmpresasRP === fieldRazonSocialRecibeDeposito.value );
            // { razonSocial, rfc, serieFactura }

            if(jsonComisionPorDepositoEmpresa.value.filter( e => e.idSysCPEmpresasRP === fieldRazonSocialRecibeDeposito.value).length > 0)
            {
                const descuentosPartidas = jsonComisionPorDepositoEmpresa.value.find( e => e.idSysCPEmpresasRP === fieldRazonSocialRecibeDeposito.value);

                if(fieldTipoMovimiento.value === 'Normal')
                {
                    porcentajeComision = Number(descuentosPartidas.porcentajeComisionPorDepositoMovNomral);
                }
                else if(fieldTipoMovimiento.value === 'Asimilado')
                {
                    porcentajeComision = Number(descuentosPartidas.porcentajeComisionPorDepositoMovAsimilado);
                }

                comision = descuentosPartidas.porcentajeComisionOperacion.toString().replaceAll('monto', formatNumero(fieldMontoDeposito.value)).replaceAll('%', porcentajeComision);
            }

            if(porcentajeComision <= 0)
            {
                comision = 0;
            }
            const datosFactura = jsonDataGruporEmpresarialPlantillaConceptos.value.find( e => e.idUnicoConcepto = fieldDatosFacturacion.value );

            jsonDepositos.value = [...jsonDepositos.value, {
                idUnicoDeposito: shortid.generate(),

                empresaCliente: fieldRazonSocialDeposita.value,
                razonSocialCliente: empresaCliente.razonSocial,
                rfcCliente: empresaCliente.rfc,
                usoCFDICliente: empresaCliente.usoCFDI,

                empresaInterna: fieldRazonSocialRecibeDeposito.value,
                razonSocialInterna: empresaInterna.razonSocial,
                rfcInterna: empresaInterna.rfc,
                regimenFiscalInterna: empresaInterna.regimenFiscal,
                serieFacturaInterna: empresaInterna.serieFactura,
                codigoPostalInterna: empresaInterna.codigoPostal,

                datosFacturacion: fieldDatosFacturacion.value,
                
                prodServ: datosFactura.prodServ,
                descripcion: datosFactura.descripcion,
                formaPago: datosFactura.formaPago,
                metodoPago: datosFactura.metodoPago,
                claveUnidad: datosFactura.claveUnidad,

                tipoMovimiento: fieldTipoMovimiento.value,

                montoDeposito: fieldMontoDeposito.value,
                subTotal: formatoMexico( Number(formatNumero(fieldMontoDeposito.value)) - (Number(formatNumero(fieldMontoDeposito.value)) - (Number(formatNumero(fieldMontoDeposito.value))/1.16 )) ),
                iva: formatoMexico( Number(formatNumero(fieldMontoDeposito.value)) - (Number(formatNumero(fieldMontoDeposito.value))/1.16 ) ),

                comision: formatoMexico(eval(comision)),

                comprobanteFileName: fieldComprobanteFile.value.name,
                comprobanteFileBase64: fieldComprobanteFile.value.base64

            }];

            // console.log( JSON.stringify( jsonDepositos.value ) );
            
            let suma = 0;
            let sumaSubTotal = 0;
            let sumaIVA = 0;
            let sumaComi = 0;
            jsonDepositos.value.map( (valor) => {
                suma += Number(formatNumero(valor.montoDeposito));
                
                sumaSubTotal += Number(formatNumero(valor.subTotal));
                sumaIVA += Number(formatNumero(valor.iva));

                sumaComi += Number(formatNumero(valor.comision));
            } );
            fieldTotalDeposito.value = formatoMexico(Number(suma));
            fieldSubTotalDeposito.value = formatoMexico(Number(sumaSubTotal));
            fieldIVADeposito.value = formatoMexico(Number(sumaIVA));
            fieldComisionDeposito.value = formatoMexico(Number(sumaComi));
            handleCloseModalDepositos();
        }
    };

    const handleQuitarDeposito = (registro) => {
        jsonDepositos.value = jsonDepositos.value.filter( e => e.idUnicoDeposito !== registro.idUnicoDeposito );
        let suma = 0;
        let sumaSubTotal = 0;
        let sumaIVA = 0;
        let sumaComi = 0;
        jsonDepositos.value.map( (valor) => {
            suma += suma + Number(formatNumero(valor.montoDeposito));
            sumaSubTotal += Number(formatNumero(valor.subTotal));
            sumaIVA += Number(formatNumero(valor.iva));
            sumaComi += sumaComi + Number(formatNumero(valor.comision));
        } );
        fieldTotalDeposito.value = formatoMexico(Number(suma));
        fieldSubTotalDeposito.value = formatoMexico(Number(sumaSubTotal));
        fieldIVADeposito.value = formatoMexico(Number(sumaIVA));
        fieldComisionDeposito.value = formatoMexico(Number(sumaComi));
    };

    const handleDescargaLayout = async() => {
        if(fieldBaseNomina.value !== '0')
        {
            let { baseSQL } = await jsonBasesNomina?.value.find( e => e.idBaseERP === fieldBaseNomina.value);
            let parametros = {
                numero : '51',
                proceso : '6',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'listaEmpleados',
                    excel: shortid.generate(),
                    PSQL: baseSQL,
                    PidBaseERP: fieldBaseNomina.value,
                    PidSysCPEmpresas: fieldEmpresaAgrupadora.value,
                    PidSysCPTipoPeriodos: fieldTipoPeriodo.value,
                    PidSysCPPuestos: fieldPuesto.value,
                    PidSysCPCentroCosto: fieldDepartamentos.value,
                    PconceptosAsimiladosConteo: fieldCheckConceptos.value.length,
                    PconceptosDispersionConteo: fieldCheckConceptosDispersion.value.length
                },
                mostrarMensaje: true
            };
    
            // fieldCheckConceptos
            // fieldCheckConceptosDispersion
            await archivosApi('layOutExcelFile', parametros, `Empleados_${shortid.generate()}.xlsx`);
        }
    };

    const handleCargaLayout = async({target}) => {
        let layOutExcelCarga = {};
        jsonRegistrosExcelAsimilados.value = [];
        jsonRegistrosExcelDispersion.value = [];
        jsonRegistrosExcelEfectivo.value = [];

        let extension = (/[.]/.exec(event.target.files[0]['name'])) ? /[^.]+$/.exec(target.files[0]['name']) : undefined;
        if(extension[0] == 'xlsx')
        {
            const excelJson = target.files[0];
            await xlsxParser
                .onFileSelection(excelJson)
                .then(data => {
                    layOutExcelCarga = data;
            });

            if((layOutExcelCarga.Asimilados) && (layOutExcelCarga.Dispersion) && (layOutExcelCarga.Efectivo) && (layOutExcelCarga.Informacion))
            {

                
                
                 // if(Number(formatNumero(fieldMontoRetornar.value)) > 0)
                // {

                //     if(fieldNombreComision01Check.value && Number(formatNumero(fieldExcelAsimiladoMontoTotal.value)) > 0 && Number(formatNumero(fieldPorcentajeComision01Asimilado.value)) > 0 )
                //     {
                //         fieldComisionMontoRetornar.value = formatoMexico(eval(fieldPorcentajeComision01Operacion.value.toString().replaceAll('montoretornar', Number(formatNumero(fieldExcelAsimiladoMontoTotal.value)) ).replaceAll('comision', Number(formatNumero(fieldPorcentajeComision01Asimilado.value)) )).toFixed(2));
                //     }

                // }

                // ================================================================================================================================
                if(layOutExcelCarga.Asimilados.length > 0)
                {
                    let sumatoriaMontoAsimilados = 0;

                    layOutExcelCarga.Asimilados.map( async(valor) => {
                        let retornoAsimilado = '0.00';

                        if(fieldNombreComision01Check.value && Number(formatNumero(fieldPorcentajeComision01Asimilado.value)) > 0 )
                        {
                            retornoAsimilado = eval( fieldPorcentajeComision01Operacion.value.toString()
                                .replaceAll('monto', valor.monto === undefined ? 0 :Number(formatNumero(valor.monto)) )
                                .replaceAll('comision', Number(formatNumero(fieldPorcentajeComision01Asimilado.value))) ).toFixed(2) ;
                        }
                        else
                        {
                            retornoAsimilado = '0.00'
                        }

                        jsonRegistrosExcelAsimilados.value = [...jsonRegistrosExcelAsimilados.value, {
                            apellidoPaterno: valor.apellidoPaterno === undefined ? '' : valor.apellidoPaterno,
                            apellidoMaterno: valor.apellidoMaterno === undefined ? '' : valor.apellidoMaterno,
                            nombre: valor.nombre === undefined ? '' : valor.nombre,
                            curp: valor.curp === undefined ? '' : valor.curp,
                            rfc: valor.rfc === undefined ? '' : valor.rfc,
                            clabeBanco: valor.clabeBanco === undefined ? '' : valor.clabeBanco,
                            cuentaBanco: valor.cuentaBanco === undefined ? '' : valor.cuentaBanco,
                            monto: formatoMexico(valor.monto === undefined ? 0 :Number(formatNumero(valor.monto))),
                            retorno: formatoMexico(retornoAsimilado === '0.00' ? (valor.monto === undefined ? 0 :Number(formatNumero(valor.monto))) : retornoAsimilado)
                        }];
                        sumatoriaMontoAsimilados = sumatoriaMontoAsimilados + Number(formatNumero(retornoAsimilado === '0.00' ? ( valor.monto === undefined ? 0 : Number(formatNumero(valor.monto.toString())) ) : retornoAsimilado));
                    } 

                    );
                    fieldExcelAsimiladoMontoTotal.value = formatoMexico(sumatoriaMontoAsimilados);


                    fieldCheckConceptos.value.map( (valor) => {
                        const {idConcepto, nombre} = jsonConceptos.value.find( e => e.idConcepto === valor );
                        jsonSelectConceptosAsimilados.value = [...jsonSelectConceptosAsimilados.value, {
                            idConcepto, nombre
                        }];
                    } );

                }
                else
                {
                    fieldExcelAsimiladoMontoTotal.value = 0.00;
                }

                // ================================================================================================================================
                if(layOutExcelCarga.Dispersion.length > 0)
                {
                    let sumatoriaMontoDispersion = 0;
                    layOutExcelCarga.Dispersion.map( async(valor) => {
                        let retornoDispersion = '0.00';

                        if(fieldNombreComision01Check.value && Number(formatNumero(fieldPorcentajeComision01Normal.value)) > 0 )
                        {
                            retornoDispersion = eval( fieldPorcentajeComision01Operacion.value.toString()
                                .replaceAll('monto', valor.monto === undefined ? 0 :Number(formatNumero(valor.monto)) )
                                .replaceAll('comision', Number(formatNumero(fieldPorcentajeComision01Normal.value))) ).toFixed(2) ;
                        }
                        else
                        {
                            retornoDispersion = '0.00'
                        }

                        jsonRegistrosExcelDispersion.value = [...jsonRegistrosExcelDispersion.value, {
                            apellidoPaterno: valor.apellidoPaterno === undefined ? '' : valor.apellidoPaterno,
                            apellidoMaterno: valor.apellidoMaterno === undefined ? '' : valor.apellidoMaterno,
                            nombre: valor.nombre === undefined ? '' : valor.nombre,
                            clabeBanco: valor.clabeBanco === undefined ? '' : valor.clabeBanco,
                            cuentaBanco: valor.cuentaBanco === undefined ? '' : valor.cuentaBanco,
                            monto: formatoMexico(valor.monto === undefined ? 0 :Number(formatNumero(valor.monto))),
                            retorno: formatoMexico(retornoDispersion === '0.00' ? valor.monto === undefined ? 0 :Number(formatNumero(valor.monto)) : retornoDispersion)
                        }];
                        sumatoriaMontoDispersion = sumatoriaMontoDispersion + Number(formatNumero(retornoDispersion === '0.00' ? valor.monto === undefined ? 0 :Number(formatNumero(valor.monto)) : retornoDispersion));
                        } 
                    );
                    fieldExcelDispersionMontoTotal.value = formatoMexico(sumatoriaMontoDispersion);
                    fieldCheckConceptosDispersion.value.map( (valor) => {
                        const {idConcepto, nombre} = jsonConceptosDispersion.value.find( e => e.idConcepto === valor );
                        jsonSelectConceptosDispersion.value = [...jsonSelectConceptosDispersion.value, {
                            idConcepto, nombre
                        }];
                    } );
                }
                else
                {
                    fieldExcelDispersionMontoTotal.value = 0.00;
                }

                // ================================================================================================================================
                if(layOutExcelCarga.Efectivo.length > 0)
                {
                    let sumatoriaMontoEfectivo = 0;
                    layOutExcelCarga.Efectivo.map( async(valor) => {
                        let retornoEfectivo = '0.00';

                        if(fieldNombreComision01Check.value && Number(formatNumero(fieldPorcentajeComision01Normal.value)) > 0 )
                        {
                            retornoEfectivo = eval( fieldPorcentajeComision01Operacion.value.toString()
                                .replaceAll('monto', valor.monto === undefined ? 0 :Number(formatNumero(valor.monto)) )
                                .replaceAll('comision', Number(formatNumero(fieldPorcentajeComision01Normal.value))) ).toFixed(2) ;
                        }
                        else
                        {
                            retornoEfectivo = '0.00'
                        }

                        jsonRegistrosExcelEfectivo.value = [...jsonRegistrosExcelEfectivo.value, {
                            apellidoPaterno: valor.apellidoPaterno === undefined ? '' : valor.apellidoPaterno,
                            apellidoMaterno: valor.apellidoMaterno === undefined ? '' : valor.apellidoMaterno,
                            nombre: valor.nombre === undefined ? '' : valor.nombre,
                            monto: formatoMexico(valor.monto === undefined ? 0 :Number(formatNumero(valor.monto))),
                            retorno: formatoMexico(retornoEfectivo === '0.00' ? valor.monto === undefined ? 0 :Number(formatNumero(valor.monto)) : retornoEfectivo)
                        }];
                        sumatoriaMontoEfectivo = sumatoriaMontoEfectivo + Number(formatNumero(retornoEfectivo === '0.00' ? valor.monto === undefined ? 0 :Number(formatNumero(valor.monto)) : retornoEfectivo));
                        } 
                    );
                    fieldExcelEfectivoMontoTotal.value = formatoMexico(sumatoriaMontoEfectivo);

                }
                else
                {
                    fieldExcelEfectivoMontoTotal.value = 0.00;
                }
                

            }
            else
            {
                notify({
                    title: "LayOut carga",
                    text: "La extension no corresponde.",
                });    
            }

        }
    }

    watch([fieldExcelAsimiladoMontoTotal, fieldExcelDispersionMontoTotal, fieldExcelEfectivoMontoTotal], (nuevo) => {
        let valor01 = nuevo[0];
        let valor02 = nuevo[1];
        let valor03 = nuevo[2];
        try
        {
            fieldExcelAsimiladoMontoTotal.value = formatoMexico(Number(formatNumero(valor01.toString())));
            fieldExcelDispersionMontoTotal.value = formatoMexico(Number(formatNumero(valor02.toString())));
            fieldExcelEfectivoMontoTotal.value = formatoMexico(Number(formatNumero(valor03.toString())));

            fieldMontoRetornar.value = formatoMexico((Number(formatNumero(valor01.toString())) + Number(formatNumero(valor02.toString())) + Number(formatNumero(valor03.toString())) ).toFixed(2));

        }
        catch(error)
        {
            fieldExcelAsimiladoMontoTotal.value = '0.00';
            fieldExcelDispersionMontoTotal.value = '0.00';
            fieldExcelEfectivoMontoTotal.value = '0.00';
        }
    });

    const handlePrecalculo = () => {

        ////////////////////////////////////////

        // totalDepositos [fieldTotalDeposito]
        // subTotalDepositos [fieldSubTotalDeposito]
        // ivaDepositos [fieldIVADeposito]
        // comisionDepositos [fieldComisionDeposito]

        // montoRetornar [fieldMontoRetornar]

        ///////////////////////////////////////

        //Promotor
        //Parametro fieldPorcentajePromotores
        //Parametros fieldOperacionPorcentajePromotores
        //Resultado fieldComisionPromotor

        if(fieldOperacionPorcentajePromotores.value !== '')
        {
            
            let stringOperacionPromotor = ''
            stringOperacionPromotor = fieldOperacionPorcentajePromotores.value
                .replaceAll( '%', Number(formatNumero(fieldPorcentajePromotores.value)) )
                .replaceAll( 'totalDepositos', Number(formatNumero(fieldTotalDeposito.value)) )
                .replaceAll( 'subTotalDepositos', Number(formatNumero(fieldSubTotalDeposito.value)) )
                .replaceAll( 'ivaDepositos', Number(formatNumero(fieldIVADeposito.value)) )
                .replaceAll( 'comisionDepositos', Number(formatNumero(fieldComisionDeposito.value)) )
                .replaceAll( 'montoRetornar', Number(formatNumero(fieldMontoRetornar.value)) )

            fieldComisionPromotor.value = formatoMexico(eval(stringOperacionPromotor));
        }
        else
        {
            fieldComisionPromotor.value = formatoMexico('0.00');
        }

        ///////////////////////////////////////

        //Comision en pesos
        //Parametro fieldComisionPesosPorcentaje
        //Parametros fieldComisionPesosOperacion
        //Resultado fieldComisionPesos

        if(fieldComisionPesosOperacion.value.value !== '')
        {
            let stringOperacionComisionPesos = ''
            stringOperacionComisionPesos = fieldComisionPesosOperacion.value
                .replaceAll( '%', Number(formatNumero(fieldComisionPesosPorcentaje.value)) )
                .replaceAll( 'totalDepositos', Number(formatNumero(fieldTotalDeposito.value)) )
                .replaceAll( 'subTotalDepositos', Number(formatNumero(fieldSubTotalDeposito.value)) )
                .replaceAll( 'ivaDepositos', Number(formatNumero(fieldIVADeposito.value)) )
                .replaceAll( 'comisionDepositos', Number(formatNumero(fieldComisionDeposito.value)) )
                .replaceAll( 'montoRetornar', Number(formatNumero(fieldMontoRetornar.value)) )
            
            console.log(stringOperacionComisionPesos);
            fieldComisionPesos.value = formatoMexico(eval(stringOperacionComisionPesos));
        }
        else
        {
            fieldComisionPesos.value = formatoMexico('0.00');
        }

        ///////////////////////////////////////

        //Comision en pesos extra
        //Parametro fieldComisionPesosExtraPorcentaje
        //Parametros fieldComisionPesosExtraOperacion
        //Resultado fieldComisionPesosExtra

        if(fieldComisionPesosExtraOperacion.value !== '')
        {
            let stringOperacionComisionPesosExtra = ''
            stringOperacionComisionPesosExtra = fieldComisionPesosExtraOperacion.value
                .replaceAll( '%', Number(formatNumero(fieldComisionPesosExtraPorcentaje.value)) )
                .replaceAll( 'totalDepositos', Number(formatNumero(fieldTotalDeposito.value)) )
                .replaceAll( 'subTotalDepositos', Number(formatNumero(fieldSubTotalDeposito.value)) )
                .replaceAll( 'ivaDepositos', Number(formatNumero(fieldIVADeposito.value)) )
                .replaceAll( 'comisionDepositos', Number(formatNumero(fieldComisionDeposito.value)) )
                .replaceAll( 'montoRetornar', Number(formatNumero(fieldMontoRetornar.value)) )
                
            fieldComisionPesosExtra.value = formatoMexico(eval(stringOperacionComisionPesosExtra));
        }
        else
        {
            fieldComisionPesosExtra.value = formatoMexico('0.00');
        }

        ///////////////////////////////////////

        //I.V.A.
        //Parametro fieldIVAPorcentaje
        //Parametros fieldIVAOperacion
        //Resultado fieldIVA

        if(fieldIVAOperacion.value.value !== '')
        {
            let stringOperacionIVA = ''
            stringOperacionIVA = fieldIVAOperacion.value
                .replaceAll( '%', Number(formatNumero(fieldIVAPorcentaje.value)) )
                .replaceAll( 'totalDepositos', Number(formatNumero(fieldTotalDeposito.value)) )
                .replaceAll( 'subTotalDepositos', Number(formatNumero(fieldSubTotalDeposito.value)) )
                .replaceAll( 'ivaDepositos', Number(formatNumero(fieldIVADeposito.value)) )
                .replaceAll( 'comisionDepositos', Number(formatNumero(fieldComisionDeposito.value)) )
                .replaceAll( 'montoRetornar', Number(formatNumero(fieldMontoRetornar.value)) )
                
                fieldIVA.value = formatoMexico(eval(stringOperacionIVA));
        }
        else
        {
            fieldIVA.value = formatoMexico('0.00');
        }

        ///////////////////////////////////////

        //Devolucion I.V.A.
        //Parametro fieldDevolucionIVAPorcentaje
        //Parametros fieldDevolucionIVAOperacion
        //Resultado fieldDevolucionIVA

        if(fieldDevolucionIVAOperacion.value !== '')
        {
            let stringOperacionDevIVA = ''
            stringOperacionDevIVA = fieldDevolucionIVAOperacion.value
                .replaceAll( '%', Number(formatNumero(fieldDevolucionIVAPorcentaje.value)) )
                .replaceAll( 'totalDepositos', Number(formatNumero(fieldTotalDeposito.value)) )
                .replaceAll( 'subTotalDepositos', Number(formatNumero(fieldSubTotalDeposito.value)) )
                .replaceAll( 'ivaDepositos', Number(formatNumero(fieldIVADeposito.value)) )
                .replaceAll( 'comisionDepositos', Number(formatNumero(fieldComisionDeposito.value)) )
                .replaceAll( 'montoRetornar', Number(formatNumero(fieldMontoRetornar.value)) )
                
                fieldDevolucionIVA.value = formatoMexico(eval(stringOperacionDevIVA));
        }
        else
        {
            fieldDevolucionIVA.value = formatoMexico('0.00');
        }

        ///////////////////////////////////////

        //Retorno
        //Parametro fieldRetornoPorcentaje
        //Parametros fieldRetornoOperacion
        //Resultado fieldRetorno

        if(fieldRetornoOperacion.value.value !== '')
        {
            let stringOperacionRetorno = ''
            stringOperacionRetorno = fieldRetornoOperacion.value
                .replaceAll( '%', Number(formatNumero(fieldRetornoPorcentaje.value)) )
                .replaceAll( 'totalDepositos', Number(formatNumero(fieldTotalDeposito.value)) )
                .replaceAll( 'subTotalDepositos', Number(formatNumero(fieldSubTotalDeposito.value)) )
                .replaceAll( 'ivaDepositos', Number(formatNumero(fieldIVADeposito.value)) )
                .replaceAll( 'comisionDepositos', Number(formatNumero(fieldComisionDeposito.value)) )
                .replaceAll( 'montoRetornar', Number(formatNumero(fieldMontoRetornar.value)) )
                
                fieldRetorno.value = formatoMexico(eval(stringOperacionRetorno));
        }
        else
        {
            fieldRetorno.value = formatoMexico('0.00');
        }


    }

    const handlePrecalculoGuardar = async() => {

        if(confirm('Estas seguro de guardar la solicitud?') === true)
        {
            buttonDisabledPrecalculoGuardar.value = true;
            handlePrecalculo();

            if(
                (Number(formatNumero(fieldExcelAsimiladoMontoTotal.value)) > 0  && fieldSelectConceptoAsimiladoMonto.value === 'S/D' ) ||
                (Number(formatNumero(fieldExcelDispersionMontoTotal.value)) > 0  && fieldSelectConceptoDispersionMonto.value === 'S/D') ||
                Number(formatNumero(fieldTotalDeposito.value)) === 0 ||
                Number(formatNumero(fieldMontoRetornar.value)) === 0
                )
            {
                notify({
                    title: 'Solicitud',
                    text: 'Faltan datos.'
                });
                buttonDisabledPrecalculoGuardar.value = false;
            }
            else
            {
                
                let jsonDatosGuardado = {

                    fieldSelectGrupoEmpresarial: fieldSelectGrupoEmpresarial.value,
                    fieldSelectPlantillaSeleccionada: fieldSelectPlantillaSeleccionada.value,
                    fieldFolioSolicitud: fieldFolioSolicitud.value,
                    fieldFechaSolicitud: fieldFechaSolicitud.value,
                    jsonDepositos: JSON.stringify( jsonDepositos.value ),
                    fieldTotalDeposito: fieldTotalDeposito.value,
                    fieldSubTotalDeposito: fieldSubTotalDeposito.value,
                    fieldIVADeposito: fieldIVADeposito.value,
                    fieldComisionDeposito: fieldComisionDeposito.value,

                    fieldBaseNomina: fieldBaseNomina.value,
                    fieldEmpresaAgrupadora: fieldEmpresaAgrupadora.value,
                    fieldTipoPeriodo: fieldTipoPeriodo.value,
                    fieldPuesto: fieldPuesto.value,
                    fieldDepartamentos: fieldDepartamentos.value,

                    fieldCheckConceptos: JSON.stringify( fieldCheckConceptos.value ),
                    fieldCheckConceptosDispersion: JSON.stringify( fieldCheckConceptosDispersion.value ),
        
                    jsonRegistrosExcelAsimilados: JSON.stringify( jsonRegistrosExcelAsimilados.value ),
                    fieldExcelAsimiladoMontoTotal: fieldExcelAsimiladoMontoTotal.value,
                    jsonSelectConceptosAsimilados: JSON.stringify( jsonSelectConceptosAsimilados.value ),
                    fieldSelectConceptoAsimiladoMonto: fieldSelectConceptoAsimiladoMonto.value,
        
                    jsonRegistrosExcelDispersion: JSON.stringify( jsonRegistrosExcelDispersion.value ),
                    fieldExcelDispersionMontoTotal: fieldExcelDispersionMontoTotal.value,
                    jsonSelectConceptosDispersion: JSON.stringify( jsonSelectConceptosDispersion.value ),
                    fieldSelectConceptoDispersionMonto: fieldSelectConceptoDispersionMonto.value,
        
                    jsonRegistrosExcelEfectivo: JSON.stringify( jsonRegistrosExcelEfectivo.value ),
                    fieldExcelEfectivoMontoTotal: fieldExcelEfectivoMontoTotal.value,
        
                    fieldMontoRetornar: fieldMontoRetornar.value,

                    jsonCorreosAsignados: JSON.stringify( jsonCorreosAsignados.value ),
        
                    fieldSelectPromotores: fieldSelectPromotores.value,
                    fieldPorcentajePromotores: fieldPorcentajePromotores.value,
                    fieldOperacionPorcentajePromotores: fieldOperacionPorcentajePromotores.value,

                    fieldComisionPromotor: fieldComisionPromotor.value,
                    fieldComisionPesos: fieldComisionPesos.value,
                    fieldComisionPesosExtra: fieldComisionPesosExtra.value,
                    fieldIVA: fieldIVA.value,
                    fieldDevolucionIVA: fieldDevolucionIVA.value,
                    fieldRetorno: fieldRetorno.value
        
                };

                const data = await operacion(
                    {
                        numero : '51',
                        proceso : '9',
                        opcionTD : 'TODO',
                        parametros : true,
                        datosReceptor : 
                        {
                            opcion: 'grabarSolicitud',
                            PidUnicoGrupoEmpresarial: fieldSelectGrupoEmpresarial.value,
                            PidUnicoPlantilla: fieldSelectPlantillaSeleccionada.value,
                            PfolioSolicitud: fieldFolioSolicitud.value,
                            PfechaSolicitud: fieldFechaSolicitud.value,
                            PtotalDepositos: Number(formatNumero( fieldTotalDeposito.value )),
                            PsubTotalDepositos: Number(formatNumero( fieldSubTotalDeposito.value )),
                            PivaDepositos: Number(formatNumero( fieldIVADeposito.value )),
                            PcomisionDeposito: Number(formatNumero( fieldComisionDeposito.value )),
                            PmontoAsimilado: Number(formatNumero( fieldExcelAsimiladoMontoTotal.value )),
                            PidConceptoaPagarAsimilado: fieldSelectConceptoAsimiladoMonto.value === 'S/D' ? 0 : fieldSelectConceptoAsimiladoMonto.value,
                            PmontoDispersion: Number(formatNumero(fieldExcelDispersionMontoTotal.value)),
                            PidConceptoaPagarDispersion: fieldSelectConceptoDispersionMonto.value === 'S/D' ? 0 : fieldSelectConceptoDispersionMonto.value,
                            PmontoEfectivo: Number(formatNumero(fieldExcelEfectivoMontoTotal.value)),
                            PmontoARetornar: Number(formatNumero(fieldMontoRetornar.value)),
                            PcomisionPromotor: Number(formatNumero( fieldComisionPromotor.value )),
                            PcomisionPesos: Number(formatNumero( fieldComisionPesos.value )),
                            PcomisionPesosExtra: Number(formatNumero( fieldComisionPesosExtra.value )),
                            Piva: Number(formatNumero( fieldIVA.value )),
                            PdevIva: Number(formatNumero( fieldDevolucionIVA.value )),
                            Pretorno: Number(formatNumero( fieldRetorno.value )),
                            PjsonData: JSON.stringify( jsonDatosGuardado )
                        },
                        mostrarMensaje: true
                    }
                );
                if(data.bandera === 0)
                {
                    jsonDatosGuardado = {};
                    await limpiarDatos();
                    notify({
                        title: 'Solicitud',
                        text: data.mensaje
                    });
                    buttonDisabledPrecalculoGuardar.value = false;
                }
                else
                {
                    notify({
                        title: 'Solicitud',
                        text: data.mensaje
                    });
                    buttonDisabledPrecalculoGuardar.value = false;
                }
        
            }
        }
        
    };

    const limpiarDatos = async() => {
        jsonDepositos.value = [];
        fieldTotalDeposito.value = 0;
        fieldSubTotalDeposito.value = 0;
        fieldIVADeposito.value = 0;
        fieldComisionDeposito.value = 0;
        
        fieldExcelAsimiladoMontoTotal.value = '0.00';
        fieldSelectConceptoAsimiladoMonto.value = 'S/D';
        jsonRegistrosExcelAsimilados.value = [];

        fieldExcelDispersionMontoTotal.value = '0.00';
        fieldSelectConceptoDispersionMonto.value = 'S/D';
        jsonRegistrosExcelDispersion.value = [];
        
        fieldExcelEfectivoMontoTotal.value = '0.00';
        jsonRegistrosExcelEfectivo.value = [];

        fieldMontoRetornar.value = '0.00';
        fieldComisionPromotor.value = '0.00';
        fieldComisionPesos.value = '0.00';
        fieldComisionPesosExtra.value = '0.00';
        fieldIVA.value = '0.00';
        fieldDevolucionIVA.value = '0.00';
        fieldRetorno.value = '0.00';
    };

    //#endregion

    //#region Pestañana 04
    const handleConsultarSolicitudes = async() => {
        const data = await operacion(
            {
                numero : '51',
                proceso : '9',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'tablaSolicitudes',
                    PidUnicoGrupoEmpresarial: fieldSelectGrupoEmpresarial.value,
                    PfechaSolicitud: convertirFecha(fieldFechaConsultaSolicitudes.value),
                    Pestatus: fieldEstatusConsultaSolicitudes.value
                },
                mostrarMensaje: true
            }
        );
        if(data.bandera === 0)
        {
            notify({
                title: 'Solicitud',
                text: data.mensaje
            });
            jsonSolicitudesConsulta.value = data.tablas.Table1;
        }
        else
        {
            notify({
                title: 'Solicitud',
                text: data.mensaje
            });
        }
    };

    const handleMostrarFacturas = async(valores) => {
        const data = await operacion(
            {
                numero : '51',
                proceso : '10',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'tablaFacturas',
                    PidUnicoSolicitud: valores.idUnicoSolicitud
                },
                mostrarMensaje: true
            }
        );
        modalFacturas.value = true;
        if(data.bandera === 0)
        {
            notify({
                title: 'Solicitud',
                text: data.mensaje
            });
            jsonRegistrosFactura.value = data.tablas.Table1;
        }
        else
        {
            notify({
                title: 'Solicitud',
                text: data.mensaje
            });
        }
    };

    const handleDescargarComprobantePago = (base, nombre) => {
        // dataURLtoFile(base, nombre);
        const downloadLink = document.createElement("a");
        downloadLink.href = base;
        downloadLink.download = nombre;
        downloadLink.click();
    };

    const handleDescargarXml = (base, serie, folio) => {
        let type = "text/plain";
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
        new Blob([base], { type })
        );
        downloadLink.setAttribute("download", `${serie}_${folio}.xml`);
        downloadLink.click();
        window.URL.revokeObjectURL(downloadLink.href);
        document.body.removeChild(downloadLink);
    };

    //#endregion


    
    return{

        processSeleccionPest,
        jsonGrupoEmpresarial,
        fieldSelectGrupoEmpresarial,
        processBotonSeleccionarGrupoEmpresarial,
        jsonDatosGeneralesGrupoEmpresarial,
        fieldFormDatosGenerales,
        fieldFormDatosGeneralesTablaClientes,
        jsonCatalogosSATFolios,
        jsonEmpresasDatosFacturas,

        processModalPlantilla,
        fieldNombrePlantilla,
        fieldDescripcionPlantilla,
        disableButtonGuardarPlantilla,
        disableButtonEditarGuardarPlantilla,
        disableButtonCancelarGuardarPlantilla,
        jsonPlantillas,
        fieldSelectPlantillaSeleccionada,
        fieldNombrePlantillaSeleccionada,

        processSeleccionPestParam,

        fieldCheckTipoOperacion,
        disabledParametrosNomina,
        jsonBasesNomina,
        fieldBaseNomina,
        jsonEmpresasAgrupadoras,
        fieldEmpresaAgrupadora,
        jsonTipoPeriodo,
        fieldTipoPeriodo,
        jsonPuesto,
        fieldPuesto,
        fieldDepartamentos,
        jsonDepartamentos,

        jsonConceptos,
        fieldCheckConceptos,
        jsonConceptosDispersion,
        fieldCheckConceptosDispersion,
        jsonPromotores,
        fieldSelectPromotores,
        fieldPorcentajePromotores,
        fieldOperacionPorcentajePromotores,
        fieldComisionPesosPorcentaje,
        fieldComisionPesosOperacion,
        fieldActivarComisionPesosExtra,
        fieldComisionPesosExtraPorcentaje,
        fieldComisionPesosExtraOperacion,
        fieldIVAPorcentaje,
        fieldIVAOperacion,
        fieldDevolucionIVAPorcentaje,
        fieldDevolucionIVAOperacion,
        fieldRetornoPorcentaje,
        fieldRetornoOperacion,
        jsonPromotoresComision,

        jsonEmpresasPagadoras,
        fieldEmpresaPagadora,

        fieldNombreComision01Check,
        fieldNombreComision01,
        fieldPorcentajeComision01Asimilado,
        fieldPorcentajeComision01Normal,
        fieldPorcentajeComision01Operacion,

        fieldNombreComision02Check,
        fieldNombreComision02,
        fieldPorcentajeComision02Asimilado,
        fieldPorcentajeComision02Normal,
        fieldPorcentajeComision02Operacion,

        fieldSelectNombreEmpresaInterna,
        fieldPorcentajeComisionPorDepositoMovAsimilado,
        fieldPorcentajeComisionPorDepositoMovNormal,
        fieldPorcentajeComisionOperacion,

        jsonEmpresasInternas,
        jsonComisionPorDepositoEmpresa,

        fieldSelectCorreo,
        jsonCorreos,
        jsonCorreosAsignados,

        jsonMetodoPago,
        jsonFormaPago,
        jsonDataGruporEmpresarialPlantillaConceptos,
        fieldIdUnicoConcepto,
        fieldFacturaProdServ,
        fieldFacturaDescripcion,
        fieldFacturaClaveUnidad,
        fieldSelectFacturaMetodoPago,
        fieldSelectFacturaFormaPago,
        disabledButtonGrabarFacturaConcepto,

        fieldFolioSolicitud,
        fieldFechaSolicitud,
        fieldTotalDeposito,
        fieldSubTotalDeposito,
        fieldIVADeposito,
        fieldComisionDeposito,

        processModalDepositos,
        fieldMontoRetornar,
        fieldComisionMontoRetornar,
        fieldTotalComision,
        fieldTotalCredito,
        fieldTotalSaldoFavor,
        fieldRazonSocialDeposita,
        fieldRazonSocialRecibeDeposito,
        fieldDatosFacturacion,
        fieldTipoMovimiento,
        fieldMontoDeposito,
        fieldFactura,
        fieldComprobanteFile,
        fieldValidacion,
        jsonDepositos,
        jsonRegistrosExcelAsimilados,
        jsonRegistrosExcelDispersion,
        jsonRegistrosExcelEfectivo,
        
        fieldExcelAsimiladoMontoTotal,
        fieldExcelDispersionMontoTotal,
        fieldExcelEfectivoMontoTotal,

        fieldSelectConceptoAsimiladoMonto,
        fieldSelectConceptoDispersionMonto,
        jsonSelectConceptosAsimilados,
        jsonSelectConceptosDispersion,
        modalRegistrosExcelAsimilados,
        modalRegistrosExcelDispersion,
        modalRegistrosExcelEfectivo,

        fieldComisionPromotor,
        fieldComisionPesos,
        fieldComisionPesosExtra,
        fieldIVA,
        fieldDevolucionIVA,
        fieldRetorno,

        fieldFechaConsultaSolicitudes,
        fieldEstatusConsultaSolicitudes,
        jsonSolicitudesConsulta,
        buttonDisabledPrecalculoGuardar,

        modalFacturas,
        jsonRegistrosFactura,

        //===============================================================

        handleSelect,
        handleProcesoBotonSeleccionarGrupoEmpresarial,

        handleActivarDesactivarAcceso,
        handleUpdateClaveAcceso,
        handleUpdateLogoEmpresa,

        handleAbrirPlantilla,
        handleCerrarModalPlantilla,
        handleGuardarDatosPlantilla,
        handleEditarDatosPlantilla,
        handleCancelarDatosPlantilla,
        handleSeleccionPlantilla,

        handleSelectParam,
        handleParametrosDatosPlantilla,
        handleGrabarParametrosDatosPlantilla,

        handleEmpresaAgrupadora,
        handleTipoPeriodoPuestoConceptos,
        handleAgregarPromotor,
        hanldeQuitarPromotor,
        // handleAgregarComision,
        // hanldeQuitarComision,
        handleValidadrPromotorComision,

        handleAgregarComisionPorDepositoEmpresa,
        handleQuitarComisionPorDepositoEmpresa,

        handleAgregarCorreo,
        handleQuitarCorreo,

        handleFacturaGuardar,
        handleFacturaCancelar,
        handleFacturarEditar,
        handleFacturarQuitar,

        handleOpenModalDepositos,
        handleCloseModalDepositos,
        handleValidarFile,
        handleValidarDatosDeposito,
        handleGrabarDeposito,
        handleQuitarDeposito,

        handleDescargaLayout,
        handleCargaLayout,
        handlePrecalculo,
        handlePrecalculoGuardar,
        handleConsultarSolicitudes,
        handleChangeSerie,
        handleChangeUsoCFDI,

        handleMostrarFacturas,
        handleDescargarComprobantePago,
        handleDescargarXml,

        isNumber,
        isDecimal,
        isDecimal02
        

        // listaEmpresas,
        // botonEmpresa,
        // empresaSeleccionada,
        

        // empresaSeleccionadaData,
        
        // fechaEstadoCuentaInicio,
        // fechaEstadoCuentaFin,

        // handleSelect,
        // handleBotonSeleccionar,
        // handleConsultarEstadoCuenta
        // const jsonGrupoEmpresarial = ref({});
    }

};



export default useClientes;




