<template>
  <div
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
    id="sidenav-collapse-main"
  >
    
    <ul class="navbar-nav" v-if=" permiso === 'A' ? true : false ">

      <li class="mt-3 nav-item">
        <h6 class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6 ms-2">
          OPERACIONES
        </h6>
      </li>

      <li class="nav-item">
        <sidenav-collapse navText="Principal" :to="{ name: 'Principal' }">
          <template #icon>
            <i class="fa fa-bullseye fa-5x" style="color: blue; font-size: 20px;"></i>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse navText="Grupo Empresarial" :to="{ name: 'GrupoEmpresarial' }">
          <template #icon>
            <i class="fa fa-stumbleupon fa-5x" style="color: blue; font-size: 20px;"></i>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse navText="Nueva Solicitud" :to="{ name: 'NuevaSolicitud' }">
          <template #icon>
            <i class="fa fa-hand-o-right fa-5x" style="color: blue; font-size: 20px;"></i>
          </template>
        </sidenav-collapse>
      </li>
      

      <!-- <li class="nav-item">
        <sidenav-collapse navText="Solicitudes" :to="{ name: 'Flujos' }">
          <template #icon>
            <i class="fa fa-plug fa-5x" style="color: blue; font-size: 20px;"></i>
          </template>
        </sidenav-collapse>
      </li> -->

      <li class="nav-item">
        <sidenav-collapse navText="Autorizaciones" :to="{ name: 'Autorizaciones' }">
          <template #icon>
            <i class="fa fa-commenting fa-5x" style="color: blue; font-size: 20px;"></i>
          </template>
        </sidenav-collapse>
      </li>

      <li class="mt-3 nav-item">
        <h6 class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6 ms-2">
          CONFIGURACIONES
        </h6>
      </li>

      <li class="nav-item">
        <sidenav-collapse navText="Perfil" :to="{ name: 'Perfil' }">
          <template #icon>
            <i class="fa fa-user-secret fa-5x" style="color: blue; font-size: 20px;"></i>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse navText="Correos" :to="{ name: 'Correos' }">
          <template #icon>
            <i class="fa fa-envelope fa-5x" style="color: blue; font-size: 20px;"></i>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse navText="Promotores" :to="{ name: 'Promotores' }">
          <template #icon>
            <i class="fa fa-american-sign-language-interpreting fa-5x" style="color: blue; font-size: 20px;"></i>
          </template>
        </sidenav-collapse>
      </li>

      <!-- <li class="nav-item">
        <sidenav-collapse navText="Actividad" :to="{ name: 'Actividad' }">
          <template #icon>
            <i class="fa fa-history fa-5x" style="color: blue; font-size: 20px;"></i>
          </template>
        </sidenav-collapse>
      </li> -->

    </ul>


  </div>

  <div class="sidenav-footer position-absolute w-100 bottom-0" v-if=" permiso === 'A' ? true : false ">
    <div class="mx-3">
      <router-link :to="{ name: 'SamSuiteNomina' }">
        <a class="btn mt-4 w-100 bg-gradient-success" href="#" type="button">
            SAMSUITE - Nomina
        </a>
      </router-link>
    </div>
  </div>

</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";
// import SidenavCard from "./SidenavCard.vue";
// import Shop from "../../components/Icon/Shop.vue";
// import Office from "../../components/Icon/Office.vue";
// import CreditCard from "../../components/Icon/CreditCard.vue";
// import Box3d from "../../components/Icon/Box3d.vue";
// import CustomerSupport from "../../components/Icon/CustomerSupport.vue";
// import Document from "../../components/Icon/Document.vue";
// import Spaceship from "../../components/Icon/Spaceship.vue";
// import Settings from "../../components/Icon/Settings.vue";

export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    return {
      title: "Soft UI Dashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active",
      permiso: localStorage.getItem('permiso')
    };
  },
  components: {
    SidenavCollapse,
    // SidenavCard,
    // Shop,
    // Office,
    // CreditCard,
    // Box3d,
    // CustomerSupport,
    // Document,
    // Spaceship,
    // Settings,
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
};
</script>
<style scoped>
/* hol{
  font-size: 40px;
} */
</style>