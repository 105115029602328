<template>

  <div class="bg-white">
    <main class="mt-0 main-content">
      <section>
        <div class="page-header min-vh-100">
          <div class="container">
            <div class="row">
              
              <div class="col-6 d-lg-flex d-none h-100 my-auto pe-0 ps-0 position-absolute top-0 start-0 text-center justify-content-center flex-column">
                <div class="position-relative h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center imagenInicio"></div>
              </div>

              <div class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column ms-auto me-auto ms-lg-auto me-lg-5">
                
                <div class="card card-plain">
                  
                  <div class="pb-0 card-header bg-transparent mb-4">
                    <h4 class="font-weight-bolder">Iniciar Sesion</h4>
                    <p class="mb-0">
                      Introduce tu usuario y contraseña para iniciar sesion.
                    </p>
                  </div>
                  
                  <div class="card-body">
                    <form role="form" @submit.prevent="iniciarSesion">

                      <div class="form-group">
                        <label for="exampleInputEmail1">Usuario</label>
                        <input type="text" v-model="usuario" class="form-control" :class="invalido ? 'is-invalid' : ''" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Usuario" autocomplete="off" required>
                      </div>

                      <div class="form-group">
                        <label for="exampleInputPassword1">Contraseña</label>
                        <input type="password" v-model="contrasena" class="form-control" :class="invalido ? 'is-invalid' : ''" id="exampleInputPassword1" placeholder="Contraseña" autocomplete="off" required>
                      </div>


                      <button type="submit" class="btn btn-primary">Iniciar Sesion</button>


                    </form>

                  </div>

                </div>

              </div>

            </div>

          </div>
        </div>
      </section>
    </main>
  </div>
  
</template>

<script>
import proceso from './js/proceso.js';
export default {
  name: "Acceso",
  components: {},
  setup(){
    return{
      ...proceso()
    }
  }
};
</script>
<style scoped>
    @import './css/estilos.css';
</style>