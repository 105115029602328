import { ref } from 'vue';
import { convertirFecha, formatoMexico, formatNumero, layoutBancario } from '@/helpers/general';
// formatNumero, layoutBancario
import { operacion, operacionEnvioCorreo } from '@/helpers/axios';
// , operacionXmlFactura
import { notify } from '@kyvg/vue3-notification';
// 

// const toBase64 = file => new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = error => reject(error);
// });

const useAutorizaciones = () => {

    const fieldSelectGrupoEmpresarial = ref('S/D');
    const fieldFechaConsultaSolicitudes = ref(new Date());
    const fieldEstatusConsultaSolicitudes = ref('S/D');
    const jsonSolicitudesConsulta = ref([]);
    const jsonGrupoEmpresarial = ref([]);
    const botonConsultar = ref({
        texto: 'Consultar',
        disableConstroles: false
    });
    const modalFacturas = ref(false);
    const jsonRegistrosFactura = ref([]);

    const procesoAprobacion = ref(false);

    const handleDatosGrupoEmpresarial = async() => {
        const data = await operacion(
            {
                numero : '51',
                proceso : '5',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'tablaGrupoEmpresarial'
                },
                mostrarMensaje: true
            }
        );
        jsonGrupoEmpresarial.value = data.tablas.Table1
    };
    handleDatosGrupoEmpresarial();

    const handleJsonSolicitudes = async() => {

        jsonSolicitudesConsulta.value = [];

        const data = await operacion(
            {
                numero : '51',
                proceso : '9',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'tablaSolicitudes',
                    PidUnicoGrupoEmpresarial: fieldSelectGrupoEmpresarial.value,
                    PfechaSolicitud: convertirFecha(fieldFechaConsultaSolicitudes.value),
                    Pestatus: fieldEstatusConsultaSolicitudes.value
                },
                mostrarMensaje: true
            }
        );
        if(data.bandera === 0)
        {
            notify({
                title: 'Solicitud',
                text: data.mensaje
            });
            if(data.tablas.Table1.length > 0 )
            {
                data.tablas.Table1.map( (
                {
                    idUnicoGrupoEmpresarial,
                    idUnicoSolicitud,
                    fechaSolicitud,
                    nombrePlantilla,
                    estatus, 
                    totalDepositos,
                    comisionDeposito,

                    montoAsimilado, 
                    montoEfectivoSinRecibo, 
                    comisionPesos, 
                    comisionPesosExtra, 
                    iva, 
                    devIva, 
                    retorno, 

                    jsonData,
                    folioSolicitud
                }) => 
                    {
                        jsonSolicitudesConsulta.value = [...jsonSolicitudesConsulta.value, {
                            idUnicoGrupoEmpresarial,
                            idUnicoSolicitud,

                            fechaSolicitud,
                            nombrePlantilla,
                            estatus, 

                            totalDepositos: formatoMexico(Number(totalDepositos)),
                            comisionDeposito: formatoMexico(Number(comisionDeposito)),

                            montoAsimilado: formatoMexico(Number(montoAsimilado)),
                            montoEfectivoSinRecibo: formatoMexico(Number(montoEfectivoSinRecibo)),
                            comisionPesos: formatoMexico(Number(comisionPesos)),
                            comisionPesosExtra: formatoMexico(Number(comisionPesosExtra)),
                            iva: formatoMexico(Number(iva)),
                            devIva: formatoMexico(Number(devIva)),
                            retorno: formatoMexico(Number(retorno)),

                            jsonData,
                            folioSolicitud
                        }];
                    } 
                );
            }
            else
            {
                jsonSolicitudesConsulta.value = [];
            }
        }
        else
        {
            notify({
                title: 'Solicitud',
                text: data.mensaje
            });
        }
    };

    const handleConsultarSolicitudes = async() => {

        if(botonConsultar.value.texto === 'Consultar')
        {

            if(fieldSelectGrupoEmpresarial.value === 'S/D' || fieldEstatusConsultaSolicitudes.value === 'S/D')
            {
                notify({
                    title: 'Solicitud',
                    text: 'Falta informacion en los filtros.'
                });
            }
            else
            {
                botonConsultar.value = {
                    texto: 'Editar',
                    disableConstroles: true
                };
                await handleJsonSolicitudes();
            }

        }
        else if(botonConsultar.value.texto === 'Editar')
        {
            botonConsultar.value = {
                texto: 'Consultar',
                disableConstroles: false
            };
            jsonSolicitudesConsulta.value = [];
            fieldSelectGrupoEmpresarial.value = 'S/D';
            fieldFechaConsultaSolicitudes.value = new Date();
            fieldEstatusConsultaSolicitudes.value = 'S/D';
        }
        
    };

    const handleAprobarSolicitud = async(valores) => {
        procesoAprobacion.value = true;
        let configLayOutBancarios = [];
        let cuerpoCorreo = '';
        let correoAdjuntos = [];
        let contador = 1;
        let correoEnvio = '';

        const dataJson = JSON.parse( valores.jsonData );

        const dataCLB = await operacion(
            {
                numero : '51',
                proceso : '5',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'configLayout'
                },
                mostrarMensaje: true
            }
        );
        configLayOutBancarios = dataCLB.tablas.Table1;
        // const datosFactura = JSON.parse( dataJson.jsonDepositos );
        const dataFolio = await operacion(
            {
                numero : '51',
                proceso : '9',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'generarFolio',
                    PidUnicoGrupoEmpresarial: fieldSelectGrupoEmpresarial.value,
                    PfechaSolicitud: convertirFecha(fieldFechaConsultaSolicitudes.value),
                    PidUnicoSolicitud: valores.idUnicoSolicitud
                },
                mostrarMensaje: true
            }
        );

        if(dataFolio.bandera === 0)
        {
            notify({
                title: 'Solicitud',
                text: dataFolio.mensaje
            });
            const folioFinal = dataFolio.tablas.Table1[0].folioSolicitud;
            const { grupoEmpresarial } = jsonGrupoEmpresarial.value.find( e => e.idUnico === dataJson.fieldSelectGrupoEmpresarial );
            JSON.parse( dataJson.jsonDepositos ).map( (val) => {
                cuerpoCorreo += 
                `
                    <div>
                        <p>${val.razonSocialInterna}</p>
                        <table style="border-collapse: collapse;border: 1px solid;font-family:"Courier New", Courier, monospace; font-size:50%">
                            
                        
                            <tr style="border: 1px solid;">
                                <td style="border: 1px solid;">EMPRESA A FACTURAR:&nbsp;</td>
                                <td style="border: 1px solid;">${val.razonSocialCliente}</td>
                            </tr>

                            <tr style="border: 1px solid;">
                                <td style="border: 1px solid;">CONCEPTO:&nbsp;</td>
                                <td style="border: 1px solid;">${val.descripcion}</td>
                            </tr>

                            <tr style="border: 1px solid;">
                                <td style="border: 1px solid;">CODIGO SAT:&nbsp;</td>
                                <td style="border: 1px solid;">${val.prodServ}</td>
                            </tr>

                            <tr style="border: 1px solid;">
                                <td style="border: 1px solid;">MONTO IVA INCLUIDO:&nbsp;</td>
                                <td style="border: 1px solid;">${val.montoDeposito}</td>
                            </tr>

                            <tr style="border: 1px solid;">
                                <td style="border: 1px solid;">CLAVE UNIDAD:&nbsp;</td>
                                <td style="border: 1px solid;">${val.claveUnidad}</td>
                            </tr>

                            <tr style="border: 1px solid;">
                                <td style="border: 1px solid;">METODO PAGO:&nbsp;</td>
                                <td style="border: 1px solid;">${val.metodoPago}</td>
                            </tr>

                            <tr style="border: 1px solid;">
                                <td style="border: 1px solid;">FORMA PAGO:&nbsp;</td>
                                <td style="border: 1px solid;">${val.formaPago}</td>
                            </tr>

                        </table>
                       
                    </div>
                    <br>
                    <hr>
                `;
                correoAdjuntos = [...correoAdjuntos, {
                    comprobanteFileBase64: val.comprobanteFileBase64,
                    comprobanteFileName: `${contador} [${val.montoDeposito}]_${val.comprobanteFileName}`
                }];
                contador++;
            } );

            dataJson.jsonCorreosAsignados.map( (val) => {
                correoEnvio += `${val.correoElectronico.toLowerCase()},`
            } );
            correoEnvio = correoEnvio.substring(0, correoEnvio.length - 1);

            //Benefciiarios asimilados
            let layoutACrearAsimilado = [];
            let beneficiariosAsimilados = [];
            JSON.parse( dataJson.jsonRegistrosExcelAsimilados ).map( (dato) => {

                if( formatNumero(dato.retorno) > 0 && dato.tipoAlta === 'Persona' )
                beneficiariosAsimilados = [...beneficiariosAsimilados, {
                    idUnicoBeneficiario: dato.idUnicoBeneficiario,
                    tipoAlta: dato.tipoAlta,
                    idUnicoGrupo: dato.idUnicoGrupo,
                    usoCFDI: dato.usoCFDI,
                    codigoBeneficiario: dato.codigoBeneficiario,
                    apellidoPaterno: dato.apellidoPaterno,
                    apellidoMaterno: dato.apellidoMaterno,
                    nombre: dato.nombre,
                    nombreCompleto: `${dato.apellidoPaterno} ${dato.apellidoMaterno} ${dato.nombre}`,
                    razonSocial: dato.razonSocial,
                    rfc: dato.rfc,
                    curp: dato.curp,
                    cuentaBanco: dato.cuentaBanco,
                    clabeBanco: dato.clabeBanco,
                    idCatBanco: dato.idCatBanco,
                    numeroInstitutoBancoSantander: dato.numeroInstitutoBancoSantander,
                    claveTransferenciaBancoSantander: dato.claveTransferenciaBancoSantander,
                    numeroInstitutoKuspit: dato.numeroInstitutoKuspit,
                    monto: dato.monto,
                    retorno: dato.retorno,
                    idUnicoBancosLayOut: dato.idUnicoBancosLayOut,
                    clabeBancariaEmpresa: dato.clabeBancariaEmpresa,
                    cuentaBancariaEmpresa: dato.cuentaBancariaEmpresa,
                    bancoEmpresa: dato.bancoEmpresa,
                    tipoLayoutEmpresa: dato.tipoLayoutEmpresa,
                    empresaPagadora: dato.empresaPagadora,
                    correo: dataJson.fieldCorreoBeneficiario,
                    anioPago: dataJson.fieldFechaLayoutPago.substring(0, 4),
                    mesPago: dataJson.fieldFechaLayoutPago.substring(5, 7),
                    diaPago: dataJson.fieldFechaLayoutPago.substring(8, 10),
                    descripcionBanco: `A ${folioFinal}`,
                    activo: dato.activo
                }];

                if( !layoutACrearAsimilado.find( e => e.idUnicoBancosLayOut === dato.idUnicoBancosLayOut ) )
                {
                    
                    layoutACrearAsimilado = [...layoutACrearAsimilado, {
                        idUnicoBancosLayOut: dato.idUnicoBancosLayOut,
                        configLayOut: configLayOutBancarios.find( e => e.idUnicoBancosLayOut === dato.idUnicoBancosLayOut ).configLayOut,
                        configLayOutExtension: configLayOutBancarios.find( e => e.idUnicoBancosLayOut === dato.idUnicoBancosLayOut ).extension,
                        banco: dato.bancoEmpresa,
                        nombre: `A ${dato.empresaPagadora} ${dato.tipoLayoutEmpresa} ${folioFinal}`,
                        empresaPagadora: dato.empresaPagadora,
                        benefeciarios: []
                    }];
                }


            } );

            layoutACrearAsimilado.map( async(dato) => {
                
                const index = layoutACrearAsimilado.findIndex( e => e.idUnicoBancosLayOut === dato.idUnicoBancosLayOut ) ;
                layoutACrearAsimilado[index].benefeciarios = beneficiariosAsimilados.filter( e => e.idUnicoBancosLayOut === dato.idUnicoBancosLayOut );
                
            } );

            await Promise.all(
                layoutACrearAsimilado.map( async(dato) => {
                    if( dato.benefeciarios.length > 0 )
                    {
                        let lay = await layoutBancario( JSON.parse( dato.configLayOut ), dato.benefeciarios );
                        correoAdjuntos = [...correoAdjuntos, {
                            comprobanteFileBase64: lay.toString(),
                            comprobanteFileName: `${dato.nombre}${dato.configLayOutExtension}`
                        }];
                    }
                } )
            )

            // Benefciiarios Sin recibo
            let layoutACrearSinRecibo = [];
            let beneficiariosSinRecibo = [];
            JSON.parse( dataJson.jsonRegistrosExcelSinRecibo ).map( (dato) => {
                // && dato.tipoAlta === 'Persona'

                if( formatNumero(dato.retorno) > 0 )
                beneficiariosSinRecibo = [...beneficiariosSinRecibo, {
                    idUnicoBeneficiario: dato.idUnicoBeneficiario,
                    tipoAlta: dato.tipoAlta,
                    idUnicoGrupo: dato.idUnicoGrupo,
                    usoCFDI: dato.usoCFDI,
                    codigoBeneficiario: dato.codigoBeneficiario,
                    apellidoPaterno: dato.apellidoPaterno,
                    apellidoMaterno: dato.apellidoMaterno,
                    nombre: dato.nombre,
                    nombreCompleto: dato.tipoAlta === 'Persona' ? `${dato.apellidoPaterno} ${dato.apellidoMaterno} ${dato.nombre}` : dato.razonSocial,
                    razonSocial: dato.razonSocial,
                    rfc: dato.rfc,
                    curp: dato.curp,
                    cuentaBanco: dato.cuentaBanco,
                    clabeBanco: dato.clabeBanco,
                    idCatBanco: dato.idCatBanco,
                    numeroInstitutoBancoSantander: dato.numeroInstitutoBancoSantander,
                    claveTransferenciaBancoSantander: dato.claveTransferenciaBancoSantander,
                    numeroInstitutoKuspit: dato.numeroInstitutoKuspit,
                    monto: dato.monto,
                    retorno: dato.retorno,
                    idUnicoBancosLayOut: dato.idUnicoBancosLayOut,
                    clabeBancariaEmpresa: dato.clabeBancariaEmpresa,
                    cuentaBancariaEmpresa: dato.cuentaBancariaEmpresa,
                    bancoEmpresa: dato.bancoEmpresa,
                    tipoLayoutEmpresa: dato.tipoLayoutEmpresa,
                    empresaPagadora: dato.empresaPagadora,
                    correo: dataJson.fieldCorreoBeneficiario,
                    anioPago: dataJson.fieldFechaLayoutPago.substring(0, 4),
                    mesPago: dataJson.fieldFechaLayoutPago.substring(5, 7),
                    diaPago: dataJson.fieldFechaLayoutPago.substring(8, 10),
                    descripcionBanco: `${folioFinal}`,
                    activo: dato.activo
                }];

                if( !layoutACrearSinRecibo.find( e => e.idUnicoBancosLayOut === dato.idUnicoBancosLayOut ) )
                {
                    
                    layoutACrearSinRecibo = [...layoutACrearSinRecibo, {
                        idUnicoBancosLayOut: dato.idUnicoBancosLayOut,
                        configLayOut: configLayOutBancarios.find( e => e.idUnicoBancosLayOut === dato.idUnicoBancosLayOut ).configLayOut,
                        configLayOutExtension: configLayOutBancarios.find( e => e.idUnicoBancosLayOut === dato.idUnicoBancosLayOut ).extension,
                        banco: dato.bancoEmpresa,
                        nombre: `${dato.empresaPagadora} ${dato.tipoLayoutEmpresa} ${folioFinal}`,
                        empresaPagadora: dato.empresaPagadora,
                        benefeciarios: []
                    }];
                }


            } );

            layoutACrearSinRecibo.map( async(dato) => {
                
                const index = layoutACrearSinRecibo.findIndex( e => e.idUnicoBancosLayOut === dato.idUnicoBancosLayOut ) ;
                layoutACrearSinRecibo[index].benefeciarios = beneficiariosSinRecibo.filter( e => e.idUnicoBancosLayOut === dato.idUnicoBancosLayOut );
                
            } );

            await Promise.all(
                
                layoutACrearSinRecibo.map( async(dato) => {
                    if( dato.benefeciarios.length > 0 )
                    {
                        let lay = await layoutBancario( JSON.parse( dato.configLayOut ), dato.benefeciarios );
                        correoAdjuntos = [...correoAdjuntos, {
                            comprobanteFileBase64: lay.toString(),
                            comprobanteFileName: `${dato.nombre}${dato.configLayOutExtension}`
                        }];
                    }

                } )
            )

            // let layoutACrearEfectivo = [];
            
            
            if( JSON.parse( dataJson.jsonRegistrosExcelEfectivo ).length > 0 )
            {

                let beneficiariosEfecitvoTxt = '';

                JSON.parse( dataJson.jsonRegistrosExcelEfectivo ).map( (dato) => {
                    beneficiariosEfecitvoTxt += `${dato.apellidoPaterno}\t\t${dato.apellidoMaterno}\t\t${dato.nombre}\t\t${dato.retorno}`;
                    beneficiariosEfecitvoTxt += '\n';
                } );

                let beneficiariosEfectivo = new Blob([beneficiariosEfecitvoTxt], { type: 'text/plain' });
                let archivoTXT = await blobToBase64(beneficiariosEfectivo);

                correoAdjuntos = [...correoAdjuntos, {
                    comprobanteFileBase64: archivoTXT.toString(),
                    comprobanteFileName: `Beneficiarios Efectivo.txt`
                }];
            }
            
            const data = await operacionEnvioCorreo(
                {
                    idUnicoSolicitud: valores.idUnicoSolicitud,
                    grupoEmpresarial: `${grupoEmpresarial} ${folioFinal}`,
                    cuerpoCorreo: `${cuerpoCorreo} <p>Este correo es generado automaticamente</p> <p>Saludos</p>`,
                    correoEnvio: correoEnvio,
                    correoAdjuntos: correoAdjuntos
                }
            );
            
            if(data.bandera === 0)
            {

                notify({
                    title: 'Solicitud',
                    text: data.mensaje
                });
                await handleJsonSolicitudes();
                
            }



        }
        

        procesoAprobacion.value = false;
        // if(confirm('Estas seguro que deseas aprobar esta solicitud?.'))
        // {
        //     const dataJson = JSON.parse( valores.jsonData );
        //     const datosFactura = JSON.parse( dataJson.jsonDepositos );

        //     let cuerpoCorreo = '';
        //     let correoEnvio = '';
        //     let correoAdjuntos = [];
        //     let contador = 1;

        //     const dataFolio = await operacion(
        //         {
        //             numero : '51',
        //             proceso : '9',
        //             opcionTD : 'TODO',
        //             parametros : true,
        //             datosReceptor : 
        //             {
        //                 opcion: 'generarFolio',
        //                 PidUnicoGrupoEmpresarial: fieldSelectGrupoEmpresarial.value,
        //                 PfechaSolicitud: convertirFecha(fieldFechaConsultaSolicitudes.value),
        //                 PidUnicoSolicitud: valores.idUnicoSolicitud
        //             },
        //             mostrarMensaje: true
        //         }
        //     );

        //     if(dataFolio.bandera === 0)
        //     {
        //         notify({
        //             title: 'Solicitud',
        //             text: dataFolio.mensaje
        //         });


        //         const jsonFolio = dataFolio.tablas.Table1;



        //         const { grupoEmpresarial } = jsonGrupoEmpresarial.value.find( e => e.idUnico === dataJson.fieldSelectGrupoEmpresarial );

                // JSON.parse( dataJson.jsonDepositos ).map( (val) => {
                //     cuerpoCorreo += 
                //     `
                //         <div>
                //             <p>${val.razonSocialInterna}</p>
                //             <table style="border-collapse: collapse;border: 1px solid;font-family:"Courier New", Courier, monospace; font-size:50%">
                                
                            
                //                 <tr style="border: 1px solid;">
                //                     <td style="border: 1px solid;">EMPRESA A FACTURAR:&nbsp;</td>
                //                     <td style="border: 1px solid;">${val.razonSocialCliente}</td>
                //                 </tr>

                //                 <tr style="border: 1px solid;">
                //                     <td style="border: 1px solid;">CONCEPTO:&nbsp;</td>
                //                     <td style="border: 1px solid;">${val.descripcion}</td>
                //                 </tr>

                //                 <tr style="border: 1px solid;">
                //                     <td style="border: 1px solid;">CODIGO SAT:&nbsp;</td>
                //                     <td style="border: 1px solid;">${val.prodServ}</td>
                //                 </tr>

                //                 <tr style="border: 1px solid;">
                //                     <td style="border: 1px solid;">MONTO IVA INCLUIDO:&nbsp;</td>
                //                     <td style="border: 1px solid;">${val.montoDeposito}</td>
                //                 </tr>

                //                 <tr style="border: 1px solid;">
                //                     <td style="border: 1px solid;">CLAVE UNIDAD:&nbsp;</td>
                //                     <td style="border: 1px solid;">${val.claveUnidad}</td>
                //                 </tr>

                //                 <tr style="border: 1px solid;">
                //                     <td style="border: 1px solid;">METODO PAGO:&nbsp;</td>
                //                     <td style="border: 1px solid;">${val.metodoPago}</td>
                //                 </tr>

                //                 <tr style="border: 1px solid;">
                //                     <td style="border: 1px solid;">FORMA PAGO:&nbsp;</td>
                //                     <td style="border: 1px solid;">${val.formaPago}</td>
                //                 </tr>

                //             </table>

                //             <p>Ruta: ${dataJson.fieldRutaCliente}</p>
                            
                //         </div>
                //         <br>
                //         <hr>
                //     `;
                //     correoAdjuntos = [...correoAdjuntos, {
                //         comprobanteFileBase64: val.comprobanteFileBase64,
                //         comprobanteFileName: `${contador} [${val.montoDeposito}]_${val.comprobanteFileName}`
                //     }];
                //     contador++;
                // } )

        //         JSON.parse( dataJson.jsonCorreosAsignados ).map( (val) => {
        //             correoEnvio += `${val.correoElectronico.toLowerCase()},`
        //         } );
        //         correoEnvio = correoEnvio.substring(0, correoEnvio.length - 1);

        //         const data = await operacionEnvioCorreo(
        //             {
        //                 idUnicoSolicitud: valores.idUnicoSolicitud,
        //                 grupoEmpresarial: `${grupoEmpresarial} ${jsonFolio[0].folioSolicitud}`,
        //                 cuerpoCorreo: `${cuerpoCorreo} <p>Este correo es generado automaticamente</p> <p>Saludos</p>`,
        //                 correoEnvio: correoEnvio,
        //                 correoAdjuntos: correoAdjuntos
        //             }
        //         );
                
        //         if(data.bandera === 0)
        //         {
        //             notify({
        //                 title: 'Solicitud',
        //                 text: data.mensaje
        //             });

        //             for(let i = 0; i < datosFactura.length; i++)
        //             {
        //                 const dataNumero = await operacion(
        //                     {
        //                         numero : '51',
        //                         proceso : '6',
        //                         opcionTD : 'TODO',
        //                         parametros : true,
        //                         datosReceptor : 
        //                         {
        //                             opcion: 'nuevoFolio',
        //                             Prfc: datosFactura[i].rfcInterna,
        //                             Pserie: datosFactura[i].serieFacturaInterna
        //                         },
        //                         mostrarMensaje: true
        //                     }
        //                 );

        //                 const numeroFolio = dataNumero.tablas.Table1[0].numero;

        //                 const dataXmlFactura = await operacionXmlFactura({
        //                     id_factura: datosFactura[i].idUnicoDeposito,

        //                     empresa: datosFactura[i].empresaInterna,
        //                     empresa_razon_social: datosFactura[i].razonSocialInterna,
        //                     empresa_rfc: datosFactura[i].rfcInterna,
        //                     empresa_regimen_fiscal: datosFactura[i].regimenFiscalInterna,
        //                     empresa_lugar_expedicion: datosFactura[i].codigoPostalInterna,

        //                     cliente: datosFactura[i].empresaCliente,
        //                     cliente_razon_social: datosFactura[i].razonSocialCliente,
        //                     cliente_rfc: datosFactura[i].rfcCliente,
        //                     uso_cfdi: datosFactura[i].usoCFDICliente,

        //                     moneda: 'MXN',
        //                     forma_pago: datosFactura[i].formaPago,
        //                     metodo_pago: datosFactura[i].metodoPago,

        //                     sub_total: formatNumero(datosFactura[i].subTotal),
        //                     iva: formatNumero(datosFactura[i].iva),
        //                     total: formatNumero(datosFactura[i].montoDeposito),

        //                     serie: datosFactura[i].serieFacturaInterna,
        //                     folio: numeroFolio,

        //                     clave_prod_serv: datosFactura[i].prodServ,
        //                     unidad:  'SERVICIO',
        //                     clave_unidad: datosFactura[i].claveUnidad,
        //                     descripcion: datosFactura[i].descripcion,
        //                     cantidad: '1.00',
        //                     valor_unitario: formatNumero(datosFactura[i].subTotal),
        //                     importe: formatNumero(datosFactura[i].subTotal)
        //                 });

        //                 let estatus = '';
        //                 let xml = '';

        //                 if(dataXmlFactura.bandera === 0)
        //                 {
        //                     estatus = 'Pendiente por timbrar';
        //                     xml = dataXmlFactura.mensaje;
        //                 }
        //                 else
        //                 {
        //                     estatus = 'Error al generar el xml.';
        //                     xml = dataXmlFactura.mensaje;
        //                 }

        //                 const dataGrabarXMLSinTimbrar = await operacion(
        //                     {
        //                         numero : '51',
        //                         proceso : '10',
        //                         opcionTD : 'TODO',
        //                         parametros : true,
        //                         datosReceptor : 
        //                         {
        //                             opcion: 'grabarXMLFactura',
        //                             PidUnicoSolicitud: valores.idUnicoSolicitud,
        //                             Pid_factura: datosFactura[i].idUnicoDeposito,
        //                             Pempresa: datosFactura[i].empresaInterna,
        //                             Pempresa_razon_social: datosFactura[i].razonSocialInterna,
        //                             Pempresa_rfc: datosFactura[i].rfcInterna,
        //                             Pempresa_codigo_postal: datosFactura[i].codigoPostalInterna,
        //                             Pempresa_regimen_fiscal: datosFactura[i].regimenFiscalInterna,
        //                             Pcliente: datosFactura[i].empresaCliente,
        //                             Pcliente_razon_social: datosFactura[i].razonSocialCliente,
        //                             Pcliente_rfc: datosFactura[i].rfcCliente,
        //                             Pmetodo_pago: datosFactura[i].metodoPago,
        //                             Pmoneda: 'MXN',
        //                             Puso_cfdi: datosFactura[i].usoCFDICliente,
        //                             Pforma_pago: datosFactura[i].formaPago,
        //                             Psub_total: formatNumero(datosFactura[i].subTotal),
        //                             Piva: formatNumero(datosFactura[i].iva),
        //                             Ptotal: formatNumero(datosFactura[i].montoDeposito),
        //                             PstringXML: xml,
        //                             PUUID: '',
        //                             PstringXMLTimbrado: '',
        //                             Pserie: datosFactura[i].serieFacturaInterna,
        //                             Pfolio: numeroFolio,
        //                             Pestatus: estatus,
        //                             PcomprobanteFileName: datosFactura[i].comprobanteFileName,
        //                             PcomprobanteFileBase64: datosFactura[i].comprobanteFileBase64
        //                         },
        //                         mostrarMensaje: true
        //                     }
        //                 );

        //                 if(dataGrabarXMLSinTimbrar.bandera === 0)
        //                 {
        //                     notify({
        //                         title: 'Solicitud',
        //                         text: dataGrabarXMLSinTimbrar.mensaje
        //                     });
        //                 }
        //                 else
        //                 {
        //                     notify({
        //                         title: 'Solicitud',
        //                         text: dataGrabarXMLSinTimbrar.mensaje
        //                     });
        //                 }

        //             };


        //             await handleJsonSolicitudes();
        //         }
        //         else
        //         {
        //             notify({
        //                 title: 'Solicitud',
        //                 text: data.mensaje
        //             });
        //         }

        //     }
        //     else
        //     {
        //         notify({
        //             title: 'Solicitud',
        //             text: dataFolio.mensaje
        //         });
        //     }
        // }
    };

    const blobToBase64 = blob => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });

    const handleCancelarSolicitud = async(valores) => {

        if(confirm('Estas seguro que deseas cancelar esta solicitud?.'))
        {
            const data = await operacion(
                {
                    numero : '51',
                    proceso : '9',
                    opcionTD : 'TODO',
                    parametros : true,
                    datosReceptor : 
                    {
                        opcion: 'cancelarSolicitudes',
                        PidUnicoSolicitud: valores.idUnicoSolicitud
                    },
                    mostrarMensaje: true
                }
            );

            if(data.bandera === 0)
            {
                notify({
                    title: 'Solicitud',
                    text: data.mensaje
                });
                await handleJsonSolicitudes();
            }
            else
            {
                notify({
                    title: 'Solicitud',
                    text: data.mensaje
                });
            }
        }
    }

    const handleMostrarFacturas = async(valores) => {
        const data = await operacion(
            {
                numero : '51',
                proceso : '10',
                opcionTD : 'TODO',
                parametros : true,
                datosReceptor : 
                {
                    opcion: 'tablaFacturas',
                    PidUnicoSolicitud: valores.idUnicoSolicitud
                },
                mostrarMensaje: true
            }
        );
        modalFacturas.value = true;
        if(data.bandera === 0)
        {
            notify({
                title: 'Solicitud',
                text: data.mensaje
            });
            jsonRegistrosFactura.value = data.tablas.Table1;
        }
        else
        {
            notify({
                title: 'Solicitud',
                text: data.mensaje
            });
        }
    };

    const handleDescargarComprobantePago = (base, nombre) => {
        // dataURLtoFile(base, nombre);
        const downloadLink = document.createElement("a");
        downloadLink.href = base;
        downloadLink.download = nombre;
        downloadLink.click();
    };

    const handleDescargarXml = (base, serie, folio) => {
        let type = "text/plain";
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
        new Blob([base], { type })
        );
        downloadLink.setAttribute("download", `${serie}_${folio}.xml`);
        downloadLink.click();
        window.URL.revokeObjectURL(downloadLink.href);
        document.body.removeChild(downloadLink);
    };


    return{
        fieldSelectGrupoEmpresarial,
        fieldFechaConsultaSolicitudes,
        fieldEstatusConsultaSolicitudes,
        jsonSolicitudesConsulta,
        jsonGrupoEmpresarial,
        botonConsultar,
        modalFacturas,
        jsonRegistrosFactura,

        handleConsultarSolicitudes,
        handleAprobarSolicitud,
        handleCancelarSolicitud,
        handleMostrarFacturas,
        handleDescargarComprobantePago,
        handleDescargarXml,

        procesoAprobacion
    }
}

export default useAutorizaciones;