<template>
  
    <div class="bg-white sizePag">

        <div class="formElemento">

            <div class="btn-group" role="group" aria-label="Basic example">
                <button type="button" class="btn btn-info" :disabled="disabledBtnControl.btnNuevoDisabled" @click="handleNuevo">Nuevo</button>
                <button type="button" class="btn btn-info" :disabled="disabledBtnControl.btnEditarDisabled" @click="handleEditar">Editar</button>
                <button type="button" class="btn btn-warning" :disabled="disabledBtnControl.btnCancelarDisabled" @click="handleCancelar">Cancelar</button>
            </div>

            <form @submit.prevent="handleGuardarPromotor" class="formClass">

                <div class="form-group">
                    <label>Nombre del promotor:</label>
                    <input type="text" ref="refNombrePromotor" class="form-control" v-model="fieldNombrePromotor" placeholder="Nombre del promotor" :disabled="disabledForm" style="text-transform:uppercase;" autocomplete="off" required>
                </div>

                <div class="form-group">
                    <label>Correo de contacto:</label>
                    <input type="email" class="form-control" v-model="fieldCorreoContacto" placeholder="Correo de contacto" :disabled="disabledForm" style="text-transform:uppercase;" autocomplete="off" required>
                </div>

                <div class="form-group">
                    <label>Otro dato de contacto:</label>
                    <input type="text" class="form-control" v-model="fieldOtroDatoContacto" placeholder="Otro dato de contacto" :disabled="disabledForm" style="text-transform:uppercase;" autocomplete="off" required>
                </div>

                <button type="submit" class="btn btn-info" :disabled="disabledForm">Guardar</button>

            </form>

        </div>

        <div class="tableElemento">

            <table class="table mb-0">

                <thead>
                <tr>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Opcion</th>
                    <th v-for="(encabezado, index) in recordsPromotores.encabezados" :key="index" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">{{encabezado}}</th>
                </tr>
                </thead>

                <tbody>

                <tr v-for="(registro, index) in recordsPromotores.registros" :key="index">

                     <td class="align-middle">
                        <span class="border text-secondary font-weight-bold text-xs">
                            <span style="cursor: pointer;" @click="handleSelectDato(registro)">Seleccion</span>
                        </span>
                    </td>
                    
                    <td v-for="( dato, index ) in recordsPromotores.camposMostrar" :key="index">

                        <h6 class="mb-0 text-xs">{{ registro[dato] }}</h6>

                    </td>
              

                </tr>

                </tbody>

            </table>

        </div>

    </div>

</template>

<script>
import proceso from './js/proceso';
export default {
    name: "SamSuiteNomina",
    components: {},
    setup(){
        return{
            ...proceso()
        }
    }
}
</script>

<style>
  @import './css/estilos.css';
</style>